import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const BusinessAPI = {
    getBusinesses: async (data) => {
        const token = Token.getToken();

        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/admin/fetch-businesses?search=${data.search}&page=${data.page}&limit=${data.limit}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    },
    getBusinessDetails: async (business_slug) => {
        const token = Token.getToken();

        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/admin/fetch-business-details/${business_slug}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    },
    changeBusinessStatus: async (data) => {

        const token = Token.getToken();

        return axios({
            method: 'put',
            url: `${config.API_BASE_URL}/api/admin/change-business-status`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
}