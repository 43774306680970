import React, { useState, useReducer, useEffect } from 'react';
import '../../../assets/css/UserPanel/userPanel.css';
import { DashboardLayout } from '../Sidebar wrapper/Layout'
import { Form, Upload, Input, Radio, Switch, Select, Button, Space, Spin } from 'antd';
import { BusinessAPI } from '../../../apis/user/BusinessAPI';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../../assets/images/modalPopup/close-circle.svg';
import axios from 'axios';
import lang from '../../../language/en.json'
export default function DeliverySetting() {

    const params = useParams();
    const [form] = Form.useForm();
    const [loading, setLoader] = useState(false);
    const [isEnabledDelivery, setIsEnabledDelivery] = useState(true)
    const [isAllLocationDel, setisAllLocationDel] = useState(false)
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            postalcodes: [],
            Error: ""
        }
    );

    useEffect(async () => {
        const business_slug = params.business_id;
        await BusinessAPI.fetchBusiness(business_slug)
            .then((response) => {
                setIsEnabledDelivery(response.data.business.disAblePostalCode)
                setisAllLocationDel(response.data.business.isAllowAllPostalCodeForDelivery)
                setState({
                    postalcodes: response.data.business.available_postalcode
                });
                form.setFieldsValue({
                    "postalcodes": response.data.business.available_postalcode
                })

            }).catch((error) => {
                console.log(error);
            });
    }, [])

    const onFinish = async (value) => {
        // setLoader(true)
        setTimeout(async () => {
            const data = {
                business_slug: params.business_id,
                postalcodes: (isEnabledDelivery && !isAllLocationDel) ? value.postalcodes : state.postalcodes,
                disAblePostalCode: isEnabledDelivery,
                isAllowAllPostalCodeForDelivery: isAllLocationDel
            }

            await BusinessAPI.addAvailablePostalCode(data)
                .then(res => {
                    setLoader(false)
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.message,
                        imageUrl: CheckmarkCircle,
                        imageAlt: 'success image',
                        // imageHeight: 1500,
                        confirmButtonColor: '#00CA84'
                    });
                    // history.push('/');
                }).catch(function (error) {
                    setLoader(false);
                    Swal.fire({
                        text: error.response.data.message,
                        imageUrl: CloseCircle,
                        imageAlt: 'error image',
                        // imageHeight: 1500,
                        confirmButtonColor: '#00CA84'
                    });
                });

        }, 1000)
    }

    const onChange = (postalcodes) => {
        if (postalcodes.length > 0) {
            postalcodes.map((code) => {
                axios.get(`https://api.postcodes.io/outcodes/${code}`)
                    .then(function (response) {
                        if (response) {
                            setState({ Error: "" })
                        }

                    }).catch((error) => {
                        setState({ Error: lang.postal_code_validate_msg })
                        setLoader(false)
                        return;
                    })
            })

        }
    }

    return (

        <DashboardLayout page='delivery-settings'>
            <div className="w-100 sidebar-right-content-spacing">
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                >
                    <div className="sidebar-content py-4">
                        <h4>Postal Code</h4>
                    </div>
                    <div className="sidebar-content d-flex py-0" >
                        <Switch checked={isEnabledDelivery} htmlType="submit" onChange={(checked) => setIsEnabledDelivery(checked)} />
                        <p className="ms-3 ml-3">Enable Delivery</p>
                    </div>
                    <div className="sidebar-content d-flex py-0" >
                        <Switch checked={isAllLocationDel} htmlType="submit" onChange={(checked) => setisAllLocationDel(checked)} />
                        <p className="ms-3 ml-3">Delivery to all Locations</p>
                    </div>
                    <div class="my-4 sidebar-content py-0">
                        {
                            (isEnabledDelivery && !isAllLocationDel) &&
                            <>
                                <div className="mb-2">
                                    <label htmlFor="exampleInputEmail1" className="fw-500">Add Postal Code</label>
                                </div>
                                <Form.Item className='ant-select-lg'
                                    name="postalcodes"
                                >
                                    <Select onChange={(event) => onChange(event)} mode="tags" style={{ width: '100%' }} placeholder="Postal Codes"></Select>
                                </Form.Item>
                                {state.Error !== "" && <div className="text-danger"> {state.Error} </div>}
                            </>
                        }
                        <Form.Item className="form-item">
                            <div className='d-flex w-100 justify-content-end'>
                                {/* <button className="btn textUnderline mr-3">Save as draft</button> */}
                                <Spin spinning={loading} delay={500}>
                                    <Button
                                        disabled={state.Error != "" && isEnabledDelivery}
                                        className="btn btnPara-primary btn-lg" htmlType="submit">
                                        Save
                                    </Button>
                                </Spin>
                            </div>
                        </Form.Item>
                    </div>
                </Form>
            </div>
        </DashboardLayout>

    )
}
