import React, { useReducer, useEffect, useState } from 'react'
import vegIcon from '../../assets/images/svg-icons/veg_icon.svg';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { OrderAPI } from '../../apis/user/OrderAPI';
import moment from 'moment';
import { Pagination } from 'antd';

export default function PastOrders() {

    const [loading, setLoader] = useState(false);
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            orders: [],
            orderID: '',
            shopName: '',
            houseName: '',
            orderAddress: '',
            postalCode: '',
            dateTime: '',
            orderAmount: 0,
            finalOrderAmount: 0,
            orderDiscount: 0,
            deliveryCharge: 0,
            orderDiscountType: "Cash",
            orderStatus: '',
            orderProducts: [],
            totalProductDiscount: 0,
            payment_method: 'Stripe',
            count: 0
        }
    );

    useEffect(() => {
        setLoader(true)
        OrderAPI.fetchCustomerAllOrders()
            .then(function (response) {
                setLoader(false)
                const ordersAsc = response.data.orders.sort((a, b) => a.date_time.localeCompare(b.date_time))
                setState({ orders: ordersAsc.reverse(), count: response.data.count })
            }).catch(function (error) {
                setLoader(false)
                console.log(error);
            });
    }, []);

    const onClickViewDetail = (order) => {

        let total_product_discount_price = 0;
        order.carts.forEach(cart => {
            var pPrice = cart.product_price;
            if (cart.product_discounted_price != 0) {
                let dPrice = pPrice - cart.product_discounted_price;
                total_product_discount_price = total_product_discount_price + (dPrice * parseInt(cart.quantity))
            }
        });

        setState({
            orderID: order.order_id,
            payment_method: order.payment_method,
            shopName: order.business.business_name,
            postalCode: order.business.postal_code,
            houseName: order.address?.house_name || "",
            orderAddress: order.address?.address || "",
            dateTime: order.date_time,
            orderAmount: order.order_amount,
            finalOrderAmount: order.final_order_amount,
            orderStatus: order.order_status,
            orderDiscount: order.order_discount,
            deliveryCharge: order.delivery_charge,
            orderDiscountType: order.order_discount_type,
            orderProducts: order.carts,
            totalProductDiscount: parseFloat(total_product_discount_price).toFixed(2)
        })
    }
    const HandlePagination = (PageNumber) => {
        OrderAPI.PaginationCustomerAllOrders(PageNumber)
            .then((res) => {
                console.log(res)
                setState({ count: res?.data?.count })
                if (res.data?.orders) {
                    setState({ orders: res?.data?.orders })
                }
            })
    }
    return (
        <div>
            <div className="business-info">
                {
                    state.orders.length == 0 &&
                    <div className="media my-3 bg-white p-3 rounded shadow-sm w-100">
                        <div className="media-body mb-0 small">
                            <div className="my-3">
                                <h5 className='text-center'>Order not added yet</h5>
                            </div>
                        </div>
                    </div>

                }

                {/* add more boxes */}
                {
                    state.orders.map((order, i) => {
                        return (
                            <div className="media mt-3 mb-4 bg-white p-3 rounded shadow-sm">
                                <div className="media-body mb-0 ">
                                    <div className="my-2">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="media pb-4">
                                                    <img src={order.business.company_logo} alt="" width="128" height="108" className="bd-placeholder-img mr-3 rounded" />
                                                    {/* <svg className="bd-placeholder-img mr-3 rounded" width="128" height="108" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#007bff" /><text x="50%" y="50%" fill="#007bff" dy=".3em">32x32</text></svg> */}
                                                    <div className="media-body  mb-0">
                                                        <div className="w-100">
                                                            <h5 className="mb-1">{order.business.business_name}</h5>
                                                            <p className="d-block ft-size14 greyText mb-2">
                                                                {`${order?.address?.house_name || ""} ${order?.address?.address || ''} ${order?.address?.postal_code || ''} `}
                                                                {moment(order.date_time).format("dddd Do MMM, YYYY, h:mm A")}
                                                            </p>
                                                            <p className="d-block mb-0">Order #{order.order_id} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                {/* <div className="d-flex mb-4">
                                                    <p className="mb-0">{order.order_status} on {moment(order.date_time).format("dddd Do MMM, YYYY, h:mm A")} </p>
                                                    <CheckCircleOutlineRoundedIcon className="ml-2 greenText" />
                                                </div> */}
                                                <div className="">
                                                    <p className="mb-0"><b>Order Status:</b><span className='greenText'> {order.order_status}</span> </p>
                                                    <p className="mb-0"><b>Payment Type:</b> {order.payment_method}</p>
                                                    <p className="mb-0"><b>Payment Status:</b> {order.payment_status}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="mb-4 mt-0" />
                                        <div className="d-md-flex justify-content-md-between flex-wrap">
                                            <div className="d-flex flex-row flex-wrap">
                                                {/* <div className="mr-3"> <button className="btn btnPara-primary">Reorder</button></div>
                                                <div className="mr-3"> <button className="btn btn-outline-primary">Help</button></div> */}
                                                <div className="p-2 mr-3 align-self-center">
                                                    <a href="http://" target="_blank" rel='noreferrer'
                                                        className="para-link-green"
                                                        data-toggle="modal"
                                                        data-target="#order_details_modal"
                                                        onClick={() => onClickViewDetail(order)}
                                                    >
                                                        View Details
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="p-2">
                                                <p className="mb-0 ">
                                                    £{parseFloat(order.final_order_amount).toFixed(2)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                <div className="d-flex justify-content-end mt-5">
                    <Pagination defaultCurrent={1} onChange={HandlePagination} total={state.count} />
                </div>
            </div>

            <div className="modal modal_outer right_modal fade" id="order_details_modal" tabIndex="-1" role="dialog" ariaLabelledby="myModalLabel5">
                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content">

                        <div className="modal-body get_quote_view_modal_body">
                            <div className="#">
                                <div className="d-flex justify-content-between">
                                    <h4 className="mb-0" id="exampleModalLabel">Order #{state.orderID}</h4>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <hr />
                            <div>
                                <h4 className="mb-1">{state.shopName}</h4>
                                <p className="greyText ft-size14">
                                    {
                                        state.orderAddress != "" &&
                                        <span>{`${state.houseName}, ${state.orderAddress}, ${state.postalCode}`}, </span>
                                    }
                                    <span>{moment(state.dateTime).format("dddd Do MMM, YYYY, h:mm A")}</span>
                                </p>
                            </div>
                            <hr />
                            <div className="table-responsive">
                                <table className="table table-borderless">
                                    <tbody>
                                        {
                                            state.orderProducts.map((product, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td className="pl-0 py-1 pr-2">
                                                            <div className="d-flex">
                                                                <p className="mb-0">{product.product_name}</p>
                                                            </div>
                                                        </td>
                                                        <td className="py-1">x{product.quantity}</td>
                                                        <td className="py-1 pr-0 text-right">£{parseFloat(product.product_price).toFixed(2)}</td>
                                                        {/* <td className="py-1 pr-0 text-right">£{(product.product_discounted_price == 0 ? parseFloat(product.product_price) : parseFloat(product.product_discounted_price) * parseInt(product.quantity)).toFixed(2)}</td> */}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <hr />
                            <div className="#">
                                <div className="d-flex justify-content-between">
                                    <h6 className="mb-2 mr-2">Item total</h6>
                                    <p className="mb-2">£{state.orderAmount}</p>
                                </div>
                                {/* <div className="d-flex justify-content-between">
                                    <p className="mb-2 mr-2">Packaging charges</p>
                                    <p className="mb-2">£0</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p className="mb-2 mr-2">Taxes</p>
                                    <p className="mb-2">£0</p>
                                </div> */}
                                <div className="d-flex justify-content-between">
                                    <p className="mb-2 mr-2">Delivery Charge</p>
                                    <p className="mb-2">£{state.deliveryCharge}</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p className="mb-2 mr-2 greenText">Discount</p>
                                    {/* {
                                        state.orderDiscountType == 'Cash' ?
                                            <h5 className="mb-0 greenText">£{(parseFloat(state.orderDiscount) + parseFloat(state.totalProductDiscount)).toFixed(2)}</h5> :
                                            <h5 className="mb-0 greenText">£{(((parseFloat(state.orderAmount) / 100) * parseFloat(state.orderDiscount)) + parseFloat(state.totalProductDiscount)).toFixed(2)}</h5>
                                    } */}
                                    <h5 className="mb-0 greenText">£{parseFloat(state.orderDiscount).toFixed(2)}</h5>

                                </div>
                            </div>
                            <hr />
                            <div className="#">
                                <div className="d-flex justify-content-between">
                                    <h5 className="mb-2 mr-2">Bill total</h5>
                                    <h5 className="mb-2">
                                        {/* {
                                            state.finalOrderAmount > 0 ?
                                                <>
                                                    {
                                                        state.orderDiscountType == 'Cash' ?
                                                            <h5 className="mb-0">£{(parseFloat(state.orderAmount) - (parseFloat(state.orderDiscount) + parseFloat(state.totalProductDiscount)) + parseFloat(state.deliveryCharge)).toFixed(2)}</h5> :
                                                            <h5 className="mb-0">£{(parseFloat(state.orderAmount) - (((parseFloat(state.orderAmount) / 100) * parseFloat(state.orderDiscount)) + parseFloat(state.totalProductDiscount)) + parseFloat(state.deliveryCharge)).toFixed(2)}</h5>
                                                    }
                                                </> :
                                                <>0.00</>
                                        } */}
                                        <h5 className="mb-0">£{parseFloat(state.finalOrderAmount).toFixed(2)}</h5>

                                    </h5>
                                </div>
                                <p className="mb-2">Paid Via {state.payment_method}</p>
                            </div>
                            <hr />
                            <div className="#">
                                <div className="d-flex justify-content-between">
                                    <h5 className="mb-2 mr-2">Order status</h5>
                                    <h5 className="mb-2 greenText">{state.orderStatus}</h5>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* ==== End: order_details_modal  =====  */}
        </div>
    )
}
