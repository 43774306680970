import React from 'react';
import { DashboardLayout } from '../Layout/Layout';
import { Nav, Tab, Row, Col, } from 'react-bootstrap';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import '../../../assets/css/AdminPanel/adminPanel.css';
import UserBusinesses from './UserBusinesses';
import UserInfo from './UserInfo';
import UserPastOrders from './UserPastOrders';

export default function UserDetails() {

    return (
        <DashboardLayout page='promotions'>
            <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-TopContent" style={{ backgroundColor: '#F3F5E6' }}>
                    <div className="d-md-flex flex-wrap justify-content-md-between">
                        <div className="my-2">
                            <button className="btn btn-outline-dark btn-rounded my-2"> <ArrowBackOutlinedIcon style={{ fontSize: '16px' }} /> Back to users</button>
                        </div>
                    </div>
                    <div className="d-md-flex justify-content-md-between">
                        <div className="my-3">
                            <div className="media">
                                <img src='https://picsum.photos/200/300' alt="" className="bd-placeholder-img mr-2 rounded" width="56" height="56" />
                                <div className="media-body mb-0 border-gray">
                                    <h4 className="mb-0 fw-400">Neveah Simmons</h4>
                                    <p className="mb-0 greyText">neveah.simmons@example.com </p>
                                </div>
                            </div>
                        </div>
                        <div className="align-self-center">
                            <button className="btn btn-outline-primary my-2">Message User</button>
                        </div>
                    </div>
                </div>
                <div className="sidebar-content">
                    <Tab.Container id="#" defaultActiveKey="info">
                        <Row>
                            <Col md={12} lg={3}>
                                <div className="profile-tab-name border">
                                    <Nav variant="pills" className="flex-column mb-5">
                                        <Nav.Item>
                                            <Nav.Link eventKey="info">Info</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="PastOrders">Past Orders</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="Businesses" >Businesses</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <hr style={{ margin: '8px 16px' }} />
                                    <div>
                                        <button className="btn btn-logout redText">Deactivate Account</button>
                                        {/* <button className="btn btn-logout greenText">Activate Account</button> */}
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} lg={9}>
                                <div className="profile-tab-content mt-3">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="info">
                                            <UserInfo />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="PastOrders">
                                            <UserPastOrders />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="Businesses">
                                            <UserBusinesses />
                                        </Tab.Pane>

                                    </Tab.Content>
                                </div>
                            </Col>
                        </Row>
                    </Tab.Container>
                </div>
            </div>
        </DashboardLayout>
    )
}
