import React, { useState, useReducer, useEffect } from 'react';
import { Form, Input, Button, Spin, Row, Col } from 'antd'
import { DashboardLayout } from '../Layout/Layout';
import { useHistory, useParams } from 'react-router-dom';
import { BusinessAPI } from '../../../apis/admin/BusinessAPI';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import defaultDocument from '../../../assets/images/document.png';

const ViewBusiness = () => {
    const history = useHistory();
    const params = useParams()

    const [loading, setLoader] = useState(false);
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            company_logo: '',
            business_name: '',
            business_category: '',
            mobile_number: '',
            short_description: '',
            business_description: '',
            address: '',
            postal_code: '',
            tag_line: '',
            facebook_profile_link: '',
            instagram_profile_link: '',
            twitter_profile_link: '',
            google_profile_link: '',

            cuisine: '',
            average_meal_for_2: '',
            minimun_order_value: '',
            maximun_order_value: '',
            food_type: '',
            delivery: '',
            delivery_everywhere: '',
            open_hours: '',
            banner_image: [],
            other_photos: [],

            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],

            bannerImg: [],
            bannerImg_new: [],
            documents: null

        }
    );

    useEffect(async () => {
        const business_slug = params.business_slug;

        await BusinessAPI.getBusinessDetails(business_slug)
            .then((response) => {
                const business = response.data.business
                const documents = response.data.documents
                setState({
                    business_id: business.id,
                    company_logo: business.company_logo,
                    business_name: business.business_name,
                    business_category: business.category,
                    mobile_number: business.mobile_number,
                    short_description: business.short_description,
                    business_description: business.business_description,
                    address: business.address,
                    postal_code: business.postal_code,
                    tag_line: business.tag_line,

                    facebook_profile_link: business.facebook_profile_link,
                    instagram_profile_link: business.instagram_profile_link,
                    twitter_profile_link: business.twitter_profile_link,
                    google_profile_link: business.google_profile_link,

                    cuisine: business.cuisine,
                    average_meal_for_2: business.average_meal_for_2,
                    minimun_order_value: business.minimun_order_value,
                    maximun_order_value: business.maximun_order_value,
                    open_hours: business.open_hours,
                    food_type: business.food_type,
                    delivery: business.delivery,
                    delivery_everywhere: business.delivery_everywhere,

                    banner_image: business.banner_image,
                    other_photos: business.other_photos,

                    monday: business.monday,
                    tuesday: business.tuesday,
                    wednesday: business.wednesday,
                    thursday: business.thursday,
                    friday: business.friday,
                    saturday: business.saturday,
                    sunday: business.sunday,
                    documents: documents

                });

            }).catch((error) => {
                console.log(error);
            });
    }, [])

    return (
        <DashboardLayout page='Admin-User-Add'>
            <div className="w-100 mt-5">
                <div className="sidebar-content">
                    <div className="d-flex justify-content-between">
                        <h4 className="mb-3">Business Details</h4>
                        <button className="btn btn-sm btn-outline-green mb-3 text-secondary"
                            onClick={() => history.goBack()}><ArrowBackIcon />Back</button>
                    </div>
                    <div>
                        <Row>
                            <Col xs={6}>
                                <div className="form-group">
                                    <label><b>Business logo</b></label>
                                    <div className='d-flex  align-items-center '>
                                        <div>
                                            <img className='business-logoImage' src={state.company_logo} alt="upload" />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={18}>
                                <div className="form-group">
                                    <label><b>Business Name</b></label>
                                    <p>{state.business_name}</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Mobile Number</b></label>
                                    <p>{state.mobile_number}</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Tag Line</b></label>
                                    <p>{state.tag_line}</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Address</b></label>
                                    <p>{state.address}</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Postal Code </b></label>
                                    <p>{state.postal_code}</p>
                                </div>
                            </Col>
                            <Col xs={24}>
                                <div className="form-group">
                                    <label><b>Short Description</b></label>
                                    <p>{state.short_description}</p>
                                </div>
                            </Col>
                            <Col xs={24}>
                                <div className="form-group">
                                    <label><b>Describe business</b></label>
                                    <p className='about-shopContentBox' dangerouslySetInnerHTML={{ __html: state.business_description }} />
                                </div>
                            </Col>

                        </Row>

                        <Row>
                            <Col xs={24}>
                                <div className="form-group">
                                    <h4>Connect Social Media</h4>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Facebook profile link</b></label>
                                    <p>{state.facebook_profile_link}</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Instagram profile link</b></label>
                                    <p>{state.instagram_profile_link}</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Twitter profile link</b></label>
                                    <p>{state.twitter_profile_link}</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Google profile link</b></label>
                                    <p>{state.google_profile_link}</p>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24}>
                                <div className="form-group">
                                    <h4>Some specific questions for restaurants</h4>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Cuisine</b></label>
                                    <p>{state.cuisine}</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Average meal for 2</b></label>
                                    <p>{state.average_meal_for_2}</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Minimum Order Value</b></label>
                                    <p>{state.minimun_order_value}</p>
                                </div>
                            </Col>

                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Maximum Order Value</b></label>
                                    <p>{state.maximun_order_value}</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Food Type</b></label>
                                    <p>{state.food_type}</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Delivery</b></label>
                                    <p>{state.delivery}</p>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="form-group">
                                    <label><b>Delivery Everywhere</b></label>
                                    <p>{state.delivery_everywhere}</p>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24}>
                                <div className="form-group">
                                    <h4>Banner Images</h4>
                                    <div>
                                        {
                                            state.banner_image.map(image => {
                                                return (
                                                    <img src={image} width="130" height="130" className="m-2" />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>

                            <Col xs={24}>
                                <div className="form-group">
                                    <h4>Other Photos</h4>
                                    <div>
                                        {
                                            state.other_photos.map(image => {
                                                return (
                                                    <img src={image} width="130" height="130" className="m-2" />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24}>
                                <div className="form-group">
                                    <h4>Open hours</h4>
                                </div>
                            </Col>
                            <Col xs={1}></Col>
                            <Col xs={3}>
                                <div className="form-group">
                                    <h6>Monday</h6>
                                    <p>Status: {state.monday[0]}</p>
                                    <p>From: {state.monday[1]}</p>
                                    <p>TO: {state.monday[2]}</p>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className="form-group">
                                    <h6>Tuesday</h6>
                                    <p>Status: {state.tuesday[0]}</p>
                                    <p>From: {state.tuesday[1]}</p>
                                    <p>TO: {state.tuesday[2]}</p>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className="form-group">
                                    <h6>Wednesday</h6>
                                    <p>Status: {state.wednesday[0]}</p>
                                    <p>From: {state.wednesday[1]}</p>
                                    <p>TO: {state.wednesday[2]}</p>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className="form-group">
                                    <h6>Thursday</h6>
                                    <p>Status: {state.thursday[0]}</p>
                                    <p>From: {state.thursday[1]}</p>
                                    <p>TO: {state.thursday[2]}</p>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className="form-group">
                                    <h6>Friday</h6>
                                    <p>Status: {state.friday[0]}</p>
                                    <p>From: {state.friday[1]}</p>
                                    <p>TO: {state.friday[2]}</p>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className="form-group">
                                    <h6>Saturday</h6>
                                    <p>Status: {state.saturday[0]}</p>
                                    <p>From: {state.saturday[1]}</p>
                                    <p>TO: {state.saturday[2]}</p>
                                </div>
                            </Col>
                            <Col xs={3}>
                                <div className="form-group">
                                    <h6>Sunday</h6>
                                    <p>Status: {state.sunday[0]}</p>
                                    <p>From: {state.sunday[1]}</p>
                                    <p>TO: {state.sunday[2]}</p>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={24}>
                                <div className="form-group">
                                    <h4>Bussiness Documents</h4>
                                </div>
                            </Col>

                            {
                                state.documents &&
                                <>
                                    <Col xs={24}>
                                        <div className="form-group">
                                            <h6>ID Proof</h6>
                                            <div className='row'>
                                                {
                                                    state.documents.id_proof.map((file, index) => {
                                                        return (
                                                            <div className='col-md-2 m-1' key={index}>
                                                                {
                                                                    file.split('.').pop() == 'jpg' || file.split('.').pop() == 'jpeg' || file.split('.').pop() == 'png' ?
                                                                        <img src={file} className='w-100' style={{ 'height': '130px' }} /> :
                                                                        <img src={defaultDocument} className='w-100' style={{ 'height': '130px' }} />
                                                                }

                                                                <div className='mt-1 text-center'>
                                                                    <a className='btn btnPara-primary btn-sm w-100' href={file} target="_blank" rel="noopener noreferrer" download>View</a>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={24}>
                                        <div className="form-group">
                                            <h6>Registration Certificate</h6>
                                            <div className='row'>
                                                {
                                                    state.documents.registration_certificate.map((file, index) => {
                                                        return (
                                                            <div className='col-md-2 m-1' key={index}>
                                                                {
                                                                    file.split('.').pop() == 'jpg' || file.split('.').pop() == 'jpeg' || file.split('.').pop() == 'png' ?
                                                                        <img src={file} className='w-100' style={{ 'height': '130px' }} /> :
                                                                        <img src={defaultDocument} className='w-100' style={{ 'height': '130px' }} />
                                                                }

                                                                <div className='mt-1 text-center'>
                                                                    <a className='btn btnPara-primary btn-sm w-100' href={file} target="_blank" rel="noopener noreferrer" download>View</a>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xs={24}>
                                        <div className="form-group">
                                            <h6>Shop License</h6>
                                            <div className='row'>
                                                {
                                                    state.documents.shop_license.map((file, index) => {
                                                        return (
                                                            <div className='col-md-2 m-1' key={index}>
                                                                {
                                                                    file.split('.').pop() == 'jpg' || file.split('.').pop() == 'jpeg' || file.split('.').pop() == 'png' ?
                                                                        <img src={file} className='w-100' style={{ 'height': '130px' }} /> :
                                                                        <img src={defaultDocument} className='w-100' style={{ 'height': '130px' }} />
                                                                }

                                                                <div className='mt-1 text-center'>
                                                                    <a className='btn btnPara-primary btn-sm w-100' href={file} target="_blank" rel="noopener noreferrer" download>View</a>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </>
                            }
                        </Row>
                    </div>
                </div>
            </div>
        </DashboardLayout>

    );
};

export default ViewBusiness;