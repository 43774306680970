import React from 'react'
import { Row, Col } from 'react-bootstrap';
import { Input } from 'antd';
import { Fragment } from 'react';

export default function UserInfo() {
    return (
        <Fragment>
            <div className='p-3 border rounded-8'>
                <h4>Info</h4>
                <hr />
                <h6>Profile Picture</h6>
                <div className="userProfileImage">
                    <img src='https://picsum.photos/200/300' alt="" />
                </div>
                <Row>
                    <Col md={6}>
                        <div class="form-group">
                            <label for="exampleInputEmail1">First Name</label>
                            <Input defaultValue="Neveah" disabled />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Last Name</label>
                            <Input placeholder="Simmons" disabled />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Mobile Number</label>
                            <Input placeholder="+447485019915" disabled />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div class="form-group">
                            <label for="exampleInputPassword1">Email ID</label>
                            <Input placeholder="nevaeh.simmons@example.com" disabled />
                        </div>
                    </Col>
                </Row>
                <div className='mt-3'>
                    <p>Joined on 24/12/2020</p>
                    <p>Email Addess verified</p>
                </div>
            </div>
        </Fragment>
    )
}
