import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import cs

import Header from '../Components/Header';
// import Header2 from '../Components/Header2';
import MainFooter from '../Components/Footer/mainfooter';
import Home from '../pages/Home'
// import Home2 from '../pages/Home2'
import SellOnPara from '../pages/sellonpara';
import Setupasbusiness from '../pages/setUpAsBusiness';
import SOP_FORM_SUBMITTED from '../pages/sop_formSubmitted';
import Shops from '../pages/shops/shops'
import Shopdetails from '../pages/shops/shopdetails'
import ProductDetail from '../pages/shops/ProductDetail';
import SellerProfile from '../pages/seller_profile/SellerProfile';
import ProductsHome from '../Components/Business_ControlPanel/MyShop/Products/ProductsHome';
import Categories from '../Components/Business_ControlPanel/MyShop/categories';
import Dashboard from '../Components/Business_ControlPanel/Dashboard/dashboard';
import MANAGEORDERS from '../Components/Business_ControlPanel/MyShop/manage_orders';
import Customers from '../Components/Business_ControlPanel/MyShop/customers';
import Promotions from '../Components/Business_ControlPanel/Marketing/promotions';
import Ads from '../Components/Business_ControlPanel/Marketing/ads';
import Analytics from '../Components/Business_ControlPanel/Marketing/analytics';
import USER_LIST from '../Components/Business_ControlPanel/Settings/User_settings/user_list';
import SHOP_SETTINGS from '../Components/Business_ControlPanel/Settings/shop_settings';
import StoreDocuments from '../Components/Business_ControlPanel/Settings/StoreDocuments';
import UserRoles from '../Components/Business_ControlPanel/Settings/UserRoles';
import MYCART from '../pages/order_checkout/mycart';
import CHECKOUT from '../pages/order_checkout/checkout';
import OrderSummary from '../pages/order_checkout/OrderSummary';
import WHO_WE_ARE from '../Components/Footer/options/who_we_are';
import Contact from '../Components/Footer/options/contact';

import FAQs from '../Components/Footer/options/FAQs';
import Careers from '../Components/Footer/options/careers';
import Privacy from '../Components/Footer/options/privacy';
import Terms from '../Components/Footer/options/terms';
import ContentIntegrate from '../Components/Footer/options/ContentIntegrate';
import Security from '../Components/Footer/options/security';
import REPORT_FRAUD from '../Components/Footer/options/report_fraud';
import VIEW_CATEGORIES from '../Components/Footer/options/view_categories';
import WHY_SELL_ON_PARA from '../Components/Footer/options/why_sell_on_para';
import HOW_TO_GET_STARTED from '../Components/Footer/options/how_to_get_started';
import SUCCESS_STORIES from '../Components/Footer/options/success_stories';
import ResetPassword from '../Components/auth/ResetPassword';
import UserVerify from '../Components/auth/UserVerify';
import AddProduct from '../Components/Business_ControlPanel/MyShop/Products/AddProduct';
import EditProduct from '../Components/Business_ControlPanel/MyShop/Products/EditProduct';
import Messages from '../pages/seller_profile/Messages';
import ShopMessage from '../Components/Business_ControlPanel/MyShop/ShopMessage';
import { getCarts, getToken, getUserRole } from "../Token";
import DeliverySetting from '../Components/Business_ControlPanel/Settings/DeliverySetting';
import Documents from '../Components/Business_ControlPanel/Documents/Documents';
import RoleList from '../Components/Business_ControlPanel/ManageRoles/RoleList';
import AddRoles from '../Components/Business_ControlPanel/ManageRoles/AddRoles.js';
import EditRoles from '../Components/Business_ControlPanel/ManageRoles/EditRoles.js';
// import ChatBox from '../pages/seller_profile/ChatBox';


function UserRoute() {
    const [Addresses, setAddresses] = useState([])
    const [userLatLong, setUserLatLong] = useState({ lat: 0, long: 0 })
    const AddressesFunc = (address) => {
        setAddresses(address)
    }
    const [totalCart, setTotalCart] = useState(Object.keys(getCarts()).length)
    const notify = (message) => {
        toast(message)
    };

    const manageCartCount = (count) => {
        setTotalCart(count)
    };

    setTimeout(() =>{
        setTotalCart(Object.keys(getCarts()).length)
    }, 500)
    let isAdmin = window.location.href.indexOf("admin") > -1 ? true : false;
    // let lastseg = window.location.pathname.split("/").pop()
    return (
        <React.Fragment>

            {!isAdmin && <Header totalCart={totalCart} AddressesFunc={AddressesFunc} Addresses={Addresses} setUserLatLong={setUserLatLong} />}

            {/* {!isAdmin && lastseg != 'home2' && <Header />}
            { lastseg == 'home2' && <Header2 />} */}

            <Route exact path='/' component={Home} />
            {/* <Route exact path='/home2' component={Home2} /> */}
            {/* <Route exact path='/messages' component={ChatBox} /> */}
            <Route exact path='/messages:/business_slug' render={props => <Messages />} />
            <Route exact path='/reset-password/:token' component={ResetPassword} />
            <Route exact path='/user/verify/:token' component={UserVerify} />
            <Route exact path='/who-we-are' component={WHO_WE_ARE} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/faq' component={FAQs} />
            <Route exact path='/careers' component={Careers} />
            <Route exact path='/view-categories' component={VIEW_CATEGORIES} />
            <Route exact path='/why-sell-on-para' component={WHY_SELL_ON_PARA} />
            <Route exact path='/how-to-get-started' component={HOW_TO_GET_STARTED} />
            <Route exact path='/success-stories' component={SUCCESS_STORIES} />
            <Route exact path='/privacy' component={Privacy} />
            <Route exact path='/terms' component={Terms} />
            <Route exact path='/content-integrate' component={ContentIntegrate} />
            <Route exact path='/security' component={Security} />
            <Route exact path='/report-fraud' component={REPORT_FRAUD} />
            <Route exact path='/set-up-as-business' render={props => <Setupasbusiness notify={notify} />} />
            <Route exact path='/set-up-as-business/success' render={props => <SOP_FORM_SUBMITTED notify={notify} />} />
            <Route exact path='/businesses' render={props => <Shops rootProps={props} userLatLong={userLatLong} />} />
            <Route exact path='/businesses/filter/:filter_name' render={props => <Shops rootProps={props} userLatLong={userLatLong} />} />
            {/* <Route exact path='/businesses' component={Shops} /> */}
            {/* <Route exact path='/businesses/filter/:filter_name' component={Shops} /> */}
            {/* <Route exact path='/businesses/:shopname' component={() => (<Shopdetails totalCart={totalCart} manageCartCount={manageCartCount} />)} /> */}
            <Route exact path='/businesses/:shopname' render={
                (props) => <Shopdetails totalCart={totalCart} Addresses={Addresses} AddressesFunc={AddressesFunc} manageCartCount={manageCartCount} props={props} />

            } />
            <Route exact path='/businesses/product/:producy_id' render={(props) => <ProductDetail totalCart={totalCart} manageCartCount={manageCartCount} props={props} />} />
            <Route exact path='/my-cart' render={(props) => <MYCART totalCart={totalCart} manageCartCount={manageCartCount} props={props} />} />


            <Route exact path='/sell' render={props => (
                getToken() != null && getUserRole() == 'enduser' ? <SellOnPara /> : <Redirect to={{ pathname: '/' }} />
            )} />

            <Route exact path='/seller-profile/:pagename' render={props => (
                getToken() != null && getUserRole() == 'enduser' ? <SellerProfile /> : <Redirect to={{ pathname: '/' }} />
            )} />

            <Route exact path='/seller-profile/:pagename/:business_slug' render={props => (
                getToken() != null && getUserRole() == 'enduser' ? <SellerProfile /> : <Redirect to={{ pathname: '/' }} />
            )} />

            {/* <Route exact path='/seller/business/:business_id' render={props => (
                getToken() != null && getUserRole() == 'enduser' ? <Business /> : <Redirect to={{ pathname: '/' }} />
            )} /> */}
            <Route exact path='/seller/business/:business_id' render={props => (
                getToken() != null && getUserRole() == 'enduser' ? <Dashboard /> : <Redirect to={{ pathname: '/' }} />
            )} />

            {/* <Route path='/dashboard ' element={<Dashboard />} /> */}

            {/* <Route exact path='/seller/business/:business_id/dashboard' render={props => (
                getToken() != null && getUserRole() == 'enduser' ? <Dashboard /> : <Redirect to={{ pathname: '/' }} />
            )} /> */}
            <Route exact path='/seller/business/:business_id/my-shop/products' render={props => (
                getToken() != null && getUserRole() == 'enduser' ? <ProductsHome /> : <Redirect to={{ pathname: '/' }} />
            )} />

            <Route exact path='/seller/business/:business_id/my-shop/add-product' render={props => (
                getToken() != null && getUserRole() == 'enduser' ? <AddProduct /> : <Redirect to={{ pathname: '/' }} />
            )} />

            <Route exact path='/seller/business/:business_id/my-shop/add-product/:category_id' render={props => (
                getToken() != null && getUserRole() == 'enduser' ? <AddProduct /> : <Redirect to={{ pathname: '/' }} />
            )} />

            <Route exact path='/seller/business/:business_id/my-shop/edit-product/:product_id' render={props => (
                getToken() != null && getUserRole() == 'enduser' ? <EditProduct /> : <Redirect to={{ pathname: '/' }} />
            )} />

            <Route exact path='/seller/business/:business_id/my-shop/categories' render={props => (
                getToken() != null && getUserRole() == 'enduser' ? <Categories /> : <Redirect to={{ pathname: '/' }} />
            )} />

            <Route exact path='/my-cart/checkout' render={props => (
                getToken() != null && getUserRole() == 'enduser' && Object.keys(getCarts()).length > 0 ? <CHECKOUT manageCartCount={manageCartCount} props={props} /> : <Redirect to={{ pathname: '/' }} />
            )} />

            <Route exact path='/my-cart/checkout/order-summary/:order_id' render={props => (
                getToken() != null && getUserRole() == 'enduser' ? <OrderSummary /> : <Redirect to={{ pathname: '/' }} />
            )} />
            <Route exact path='/seller/business/:business_id/documents' render={props => <Documents />} />
            <Route exact path='/seller/business/:business_id/roles-list' render={props => <RoleList />} />
            <Route exact path='/seller/business/:business_id/create-role' render={props => <AddRoles />} />
            <Route exact path='/seller/business/:business_id/edit-role/:role_id' render={props => <EditRoles />} />

            <Route exact path='/seller/business/:business_id/my-shop/manage-orders' render={props => <MANAGEORDERS notify={notify} />} />
            <Route exact path='/seller/business/:business_id/my-shop/customers' render={props => <Customers notify={notify} />} />
            <Route exact path='/seller/business/:business_id/marketing/promotions' render={props => <Promotions notify={notify} />} />
            <Route exact path='/seller/business/:business_id/marketing/ads' render={props => <Ads notify={notify} />} />
            <Route exact path='/seller/business/:business_id/marketing/analytics' render={props => <Analytics notify={notify} />} />
            <Route exact path='/seller/business/:business_id/settings/user-settings' render={props => <USER_LIST notify={notify} confirmAlert={confirmAlert} />} />
            <Route exact path='/seller/business/:business_id/settings/shop-settings' render={props => <SHOP_SETTINGS notify={notify} />} />
            <Route exact path='/seller/business/:business_id/settings/delivery-settings' render={props => <DeliverySetting notify={notify} />} />

            <Route exact path='/seller/business/:business_id/settings/StoreDocuments' render={props => <StoreDocuments notify={notify} />} />
            <Route exact path='/seller/business/:business_id/settings/UserRoles' render={props => <UserRoles notify={notify} />} />

            <Route exact path='/seller/business/:business_id/messages' render={props => <ShopMessage notify={notify} />} />

            {/* End Products */}
            {!isAdmin && <MainFooter className='fixed' />}

        </React.Fragment>
    )
}

export default UserRoute
