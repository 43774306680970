import axios from "axios";
import config from "../../config";

export const AuthAPI = {
    signIn: async (data) => {
        return axios.post(`${config.API_BASE_URL}/api/user/sign-in`, data);
    },
    signUp: async (data) => {
        return axios.post(`${config.API_BASE_URL}/api/user/sign-up`, data);
    },
    userVerify: async (token) => {
        return axios.get(`${config.API_BASE_URL}/api/user/verify/`+token);
    },
    actionWithSocialLogin: async (data) => {
        return axios.post(`${config.API_BASE_URL}/api/user/action-with-social-login`, data);
    },
    forgotPasssword: async (data) => {
        return axios.post(`${config.API_BASE_URL}/api/user/forgot-password`, data);
    },
    resetPasssword: async (data) => {
        return axios.post(`${config.API_BASE_URL}/api/user/reset-password`, data);
    },
    checkEmail: async (data) => {
        return axios.post(`${config.API_BASE_URL}/api/user/check-email`, data);
    },

}