import React, { useState, useEffect, useReducer } from 'react'
import '../../../../assets/css/UserPanel/userPanel.css';
import { Redirect } from 'react-router-dom'
import { DashboardLayout } from '../../Sidebar wrapper/Layout';
import ADD_USER_ACCESS from './add_user_access';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../../assets/images/modalPopup/checkmark-circle.svg';
import { Token } from '../../../../Token';
import { BusinessAPI } from '../../../../apis/user/BusinessAPI';
import AlertTriangle from '../../../../assets/images/modalPopup/alert-triangle.svg';
import { RoleAPI } from '../../../../apis/user/RoleAPI';
import { notification } from 'antd';

const USER_LIST = (props) => {

    let history = useHistory();
    let params = useParams();
    const business_slug = params.business_id;

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            userList: "",
            business: "",
            users_enable_for_email: [],
            admin: false,
            roles: []
        }
    );

    useEffect(() => {
        const data = {
            business_slug
        }

        RoleAPI.fetchDropdownRoles(data)
            .then((response) => {

                setState({ roles: response.data.data })

            }).catch((error) => {
                console.log(error)
            });
        fetchUserList();
    }, [])

    const fetchUserList = () => {
        BusinessAPI.fetchBusinessUsers(business_slug)
            .then((response) => {
                if (response) {
                    if (response.data.business.user_id === Token.getUserID()) {
                        setState({ admin: true })
                    }
                    else setState({ admin: false })
                }

                setState({
                    userList: response.data.userList,
                    business: response.data.business,
                    users_enable_for_email: response.data.business?.users_enable_for_email
                })

            }).catch((error) => {
                props.notify(error)
            });
    }

    const removeUser = (user, type) => {

        let message = `Are you sure you want to revoke access for ${business_slug}`;
        if (type == 'remove') {
            message = "You won't be able to revert this!";
        }

        Swal.fire({
            title: 'Are you sure?',
            text: message,
            // icon: 'warning',
            imageUrl: AlertTriangle,
            showCancelButton: true,
            confirmButtonColor: '#00CA84',
            cancelButtonColor: '#FF5858',
            confirmButtonText: 'Yes, remove it!'
        }).then((result) => {
            if (result.isConfirmed) {

                var data = {
                    to_user_email: user.email,
                    business_slug: business_slug
                };

                BusinessAPI.removeBusinessUser(data)
                    .then(res => {
                        fetchUserList();
                        Swal.fire({
                            text: res.data.message,
                            imageUrl: CheckmarkCircle,
                            imageAlt: 'success image',
                            confirmButtonColor: '#00CA84'
                        });
                        if (type != 'remove') {
                            history.push('/seller-profile/pastOrders')
                        }
                    });
            }
        })
    }

    const [screen, setScreen] = useState('USERS')

    const onChangeEmailStatus = async (e, id) => {
        let status;
        if (e.target.value == "Yes") {
            status = 1;
        } else {
            status = 0;
        }

        const data = {
            status,
            user_id: id,
            business_slug: business_slug
        }

        BusinessAPI.changeBusinessEmailStatus(data)
            .then((res) => {
                notification.success({ message: res.data.message })
                console.log(res.data.message)
            })
    }

    const onChangeRole = async (e, id) => {

        const data = {
            role_id: e.target.value,
            user_id: id,
            business_slug: business_slug
        }

        console.log(data)

        RoleAPI.changeBusinessUserRole(data)
            .then((res) => {
                notification.success({ message: res.data.message })
                console.log(res.data.message)
            })
    }

    const component = (key) => {
        switch (key) {
            case 'USERS':
                return (
                    <div className="container-fluid p-0">
                        {/* <h2>User accessible to current Shop</h2> */}
                        <h2>Shop Accessability</h2>
                        <p>Current shop is accessible these users</p>
                        <div class="table-responsive rounded-paraTable">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">USER NAME</th>
                                        <th scope="col">EMAIL ID</th>
                                        <th scope="col">ENABLED ORDER EMAIL</th>
                                        <th scope="col">Role</th>
                                        <th scope="col" className="text-center">STATUS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {state.admin ?
                                        ((state.userList && state.business) ?
                                            state.userList.map((user, index) => {
                                                if (user.id === state.business.user_id) {
                                                    return <tr key={index}>
                                                        <td class="align-middle">{user.first_name} {user.last_name}</td>
                                                        <td class="align-middle">{user.email}</td>
                                                        <td class="align-middle">Yes</td>
                                                        <td className="align-middle text-success"><b>Admin</b></td>
                                                        <td className="text-center"><b>-</b></td>
                                                    </tr>
                                                } else
                                                    return <tr key={index}>
                                                        <td class="align-middle">{user.first_name} {user.last_name}</td>
                                                        <td class="align-middle">{user.email}</td>
                                                        <td>
                                                            <select className='mr-2 border-0'
                                                                onChange={(e) => onChangeEmailStatus(e, user.id)}
                                                            >
                                                                <option selected={state.users_enable_for_email.includes(user.id)} value="No">No</option>
                                                                <option selected={state.users_enable_for_email.includes(user.id)} value="Yes">Yes</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <select className='mr-2 border-0'
                                                                onChange={(e) => onChangeRole(e, user.id)}
                                                            >
                                                                {
                                                                    state.roles.map((role, i) => {
                                                                        return (
                                                                            <option key={i} selected={role.id == user.role_id} value={role.id}>{role.role_name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </td>
                                                        {/* <td class="align-middle">{user.role_name}</td> */}
                                                        <td className="text-center"><button className="btn btn-danger" onClick={() => removeUser(user, 'remove')}>Remove</button></td>
                                                    </tr>
                                            }) : null) : ((state.userList && state.business) ?
                                                state.userList.map((user, index) => {
                                                    if (user.id === state.business.user_id) {
                                                        return <tr>
                                                            <td class="align-middle">{user.first_name} {user.last_name}</td>
                                                            <td class="align-middle">{user.email}</td>
                                                            <td class="align-middle">Yes</td>
                                                            <td className="align-middle text-success"><b>Admin</b></td>
                                                            <td className="text-center"><b>-</b></td>
                                                        </tr>
                                                    } else if (user.id === Token.getUserID()) {
                                                        return <tr>
                                                            <td class="align-middle">{user.first_name} {user.last_name}</td>
                                                            <td class="align-middle">{user.email}</td>
                                                            <td>
                                                                <select className='mr-2 border-0'
                                                                    onChange={(e) => onChangeEmailStatus(e, user.id)}
                                                                >
                                                                    <option selected={state.users_enable_for_email.includes(user.id)} value="No">No</option>
                                                                    <option selected={state.users_enable_for_email.includes(user.id)} value="Yes">Yes</option>
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <select className='mr-2 border-0'
                                                                    onChange={(e) => onChangeRole(e, user.id)}
                                                                >
                                                                    {
                                                                        state.roles.map((role, i) => {
                                                                            return (
                                                                                <option key={i} selected={role.id == user.role_id} value={role.id}>{role.role_name}</option>
                                                                            )
                                                                        })
                                                                    }
                                                                </select>
                                                            </td>
                                                            {/* <td class="align-middle">{user.role_name}</td> */}
                                                            <td className="text-center"><button className="btn btn-danger" onClick={() => removeUser(user, 'opt-out')}>Opt Out</button></td>
                                                        </tr>
                                                    }

                                                }) : null)}
                                </tbody>
                            </table>
                        </div>
                        <div className="my-4">
                            <button className="btn btnPara-primary" style={{ display: state.admin ? "" : "none" }} onClick={() => setScreen('ADD_USER')}>ADD NEW USER</button>
                        </div>

                    </div>
                );

            case 'ADD_USER':
                return <ADD_USER_ACCESS changepage={changepage} notify={props.notify} />;

            default:
                break;
        }
    }

    const changepage = (key) => {
        setScreen(key)
    }

    if (Token.isLoggedIn()) {
        return (

            <DashboardLayout page='user_settings'>
                <div className='w-100 sidebar-right-content-spacing'>
                    <div className="sidebar-content">
                        {component(screen)}
                    </div>
                </div>
            </DashboardLayout>

        )
    }
    else {
        return <Redirect to='/' />
    }
}


export default USER_LIST