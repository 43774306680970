export default {
	social: {
		FB: "437873621529337",
		GOOGLE: "110881339555-ruhp35vn2rg9j0k4vnp7e9g0c20c2ab8.apps.googleusercontent.com"
	},
	GOOGLE_MAPS_API_KEY: "AIzaSyAxlZtPxXxJhmhfwEhSuEo8C9VJubGoQx8",
	DEFAULT_ADDRESS_LAT: 51.5072,
	DEFAULT_ADDRESS_LONG: 0.1276,
	publicStripeKey: 'pk_test_51J7gaxSFztOgZP6XnqdOHYgxtD3esoB68XnTxuSPde81azFR1XDfkXlRDaiZf9ePAmZjbcVop6R8Hs3GevZEtwZi00JPP3Y9iK',
	apiKey:"AIzaSyBoh7wbsM_m14Fem0y_ubeYvz997-LfYGs",
	authDomain:"mypara1.firebaseapp.com",
	projectId:"mypara1",
	storageBucket:"mypara1.appspot.com",
	messagingSenderId:110881339555,
	appId:"1:110881339555:web:7689b4016c5e4fc791e716",
	API_BASE_URL: "https://demoapi.mypara.co.uk",// development
	// API_BASE_URL: "https://api.mypara.co.uk",//production
	// API_BASE_URL: "http://localhost:8001" // localhost
};