import React, { useContext } from 'react'
import BUSINESSES_WITH_PARA from '../Components/setUpAsBusiness/businesses_with_para'
import Header from '../Components/setUpAsBusiness/header'
import ParaForEveryone from '../Components/setUpAsBusiness/paraForEveryone'
import SUABfooter from '../Components/setUpAsBusiness/SUABfooter'
import Testimony from '../Components/setUpAsBusiness/testimony'
import '../Components/styles/css/setUpAsBusiness/setUpAsBusiness.css'
import Ellipse1 from '../assets/images/pages/ellipse1.svg'
import Ellipse2 from '../assets/images/pages/ellipse2.svg'
import Ellipse3 from '../assets/images/pages/ellipse3.svg'
import { JetContext } from '../context/JetContext';
const Setupusbusiness = (props) => {
    const { user } = useContext(JetContext)
    const [userData] = user
    return (
        <div className=''>
            <img src={Ellipse1} className='ellipse1 content-to-hide' alt="ellipse 1" />
            <img src={Ellipse2} className='ellipse2 content-to-hide' alt="ellipse 2" />
            {/* <img src={Ellipse3} className='ellipse3 content-to-hide' alt="ellipse 3" /> */}

            <Header user={userData} notify={props.notify} />
            <BUSINESSES_WITH_PARA />
            <ParaForEveryone user={userData} notify={props.notify} />
            {/* <Testimony user={userData} notify={props.notify} /> */}
            <SUABfooter user={userData} notify={props.notify} />
        </div>
    )
}

export default Setupusbusiness