import React, { useState, useEffect, useReducer, useRef } from 'react';
import '../../../../assets/css/UserPanel/userPanel.css';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
// import ListGroup from 'react-bootstrap/ListGroup';
import { Form, Upload, Input, Radio, Switch, Select, Button, Space, Spin } from 'antd';
import { DashboardLayout } from '../../Sidebar wrapper/Layout';
import { useHistory, useParams } from 'react-router-dom';
import lang from '../../../../language/en.json';
import { CategoryAPI } from '../../../../apis/user/CategoryAPI';
import { PlusOutlined } from "@ant-design/icons";
import { Container, Row, Col } from 'react-bootstrap'
import { ProductAPI } from '../../../../apis/user/ProductAPI';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../../../assets/images/modalPopup/close-circle.svg';
import { BusinessAPI } from '../../../../apis/user/BusinessAPI';
import { Editor } from '@tinymce/tinymce-react';
import { CheckoutAPI } from '../../../../apis/user/CheckoutAPI';

export default function AddProduct(props) {

    const history = useHistory();
    const params = useParams();
    const business_slug = params.business_id;
    const product_id = params.product_id;

    const [form] = Form.useForm();
    const [loading, setLoader] = useState(false);

    const { Option } = Select;
    const { Dragger } = Upload;
    const { TextArea } = Input;
    const [categories, setCategories] = React.useState([]);
    const [business_id, setBusinesID] = React.useState();
    const [business_category, setBusinesCategory] = React.useState('');
	const [isEnabledDelivery, setIsEnabledDelivery] = React.useState(false);
    const [product_photos, setProductPhotos] = React.useState([]);
    // const [product_photos_new, setProductPhotosNew] = React.useState([])
    const [checked, setChecked] = React.useState(false);
    const [Description, setDescription] = useState('')
    const [short_description, setShort_description] = useState("")
    const editorRef = useRef(null);
    const [DeliveryType, setDeliveryType] = useState("");
    const uploadfilesRef = useRef()
    // const [product_photos, setproduct_photos] = useState([])

    useEffect(() => {
        setLoader(true)
        BusinessAPI.fetchBusiness(business_slug)
            .then(function (response) {
                setBusinesCategory(response.data.business.category)
                setIsEnabledDelivery(response.data.business.disAblePostalCode)
            }).catch(function (error) {
                console.log(error);
            });
        CheckoutAPI.getAvailablePostalCode(business_slug)
            .then(res => {
                setDeliveryType(res.data.business.delivery)
                setLoader(false)
            })

        CategoryAPI.getUserCategoriesForDropdown(business_slug)
            .then(res => {
                setCategories(res.data.categories)
            })
        // CategoryAPI.getUserCategoriesForDropdown()
        //     .then(res => {
        //         setCategories(res.data.categories)
        //     })

        ProductAPI.getProductDetail(product_id)

            .then(res => {
                setDescription(res.data.product.description)
                setShort_description(res.data.product.short_description)
                form.setFieldsValue({
                    "product_name": res.data.product.product_name,
                    "product_mark_addon": res.data.product.product_mark_addon,
                    "product_price": res.data.product.price,
                    "quantity": res.data.product.quantity,
                    "category_id": res.data.product.category,
                    "customize_addon": res.data.product.customize_addon,
                    "instant_delivery": res.data.product.instant_delivery,
                    "instant_delivery_price": res.data.product.instant_delivery_price,
                    "is_veg": res.data.product.is_veg,
                    "meta_description": res.data.product.meta_description,
                    "meta_tag": res.data.product.meta_tag,
                    "out_of_stock": res.data.product.out_of_stock,
                    "veg_category": res.data.product.veg_category,
                    "product_tags": res.data.product.product_tags,
                    "short_description": res.data.product.short_description,
                    "product_sku": res.data.product.sku,
                    "description": res.data.product.description,
                })
                setBusinesID(res.data.product.business_id)
                setProductPhotos(res.data.product.product_photos)
                setChecked(res.data.product.out_of_stock)
            })




    }, []);

    const fileProps = {
        name: 'file',
        multiple: true,
        accept: 'image/*',
        action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
        async onChange(info) {
            const { status } = info.file;
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const onFinish = async (value) => {

        value.description = Description
        value.short_description = short_description
        var photos = []
        if (value.product_photos != undefined) {
            await value.product_photos.fileList.map((element, index) => {
                const reader = new FileReader();
                reader.readAsDataURL(element.originFileObj)
                reader.onload = () => {
                    photos[index] = reader.result;
                }
            });
        }

        value.product_id = product_id;
        value.business_id = business_id;
        // value.product_photos = photos;
        value.product_photos = product_photos;
        value.product_photos_new = photos;
        setLoader(true);
        setTimeout(() => {
            ProductAPI.updateProduct(value)
                .then(res => {
                    setTimeout(() => {
                        history.push(`/seller/business/${business_slug}/my-shop/products`);
                        Swal.fire({
                            title: res.data.message,
                            imageUrl: CheckmarkCircle,
                            imageAlt: 'success image',
                            confirmButtonColor: '#00CA84'
                        });
                        setLoader(false);
                    }, 1000)
                }).catch(error => {
                    setLoader(false);
                    Swal.fire({
                        title: error.response.data.message,
                        imageUrl: CloseCircle,
                        imageAlt: 'error image',
                        confirmButtonColor: '#00CA84'
                    });
                })
        }, 2000)
    }

    const order = {
        po_number: "123abc",
        carrier: "Fastway",
        customize_addon: [
            {
                item_code: "Cheese",
                quantity: "30"
            }
        ]
    };

    //Create form fields based off how many customize_addon are in the order
    const itemInputs = order.customize_addon.map((item) => {
        return {
            item_code: item.item_code,
            quantity: item.quantity,
        };
    });
    // ==================

    const children = [];
    // for (let i = 10; i < 36; i++) {
    //     children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
    // }
    return (
        <DashboardLayout page='products'>
            <div className="w-100 sidebar-right-content-spacing">
                <Spin spinning={loading} delay={500}>

                    <div className="addProduct-section">
                        <div className="d-flex align-self-center addProduct-mainHeadingBox">
                            <button className="btn py-0" onClick={() => history.goBack()}> <ArrowBackIcon /></button>
                            <h3 className="mb-0">Edit Product</h3>
                        </div>
                        <Form
                            layout="vertical"
                            form={form}
                            onFinish={onFinish}
                        >
                            <div className="addProduct-Box">
                                <h5 className="addProduct-heading">Fill out the following information to start selling.</h5>
                                <div class="my-4">
                                    <div class="form-group">
                                        <label htmlFor="exampleInputEmail1" className="fw-500">Product Name <span className="">*</span></label>
                                        <p class="label-subText">Give your product a short, clear name</p>
                                        <Form.Item className='ant-select-lg'
                                            name="product_name"
                                            rules={[{ required: true, message: lang.product_name_require_error_msg }]}
                                        >
                                            <Input className="form-control" placeholder="Product Name" />
                                        </Form.Item>
                                    </div>
                                    {/* <div className="d-flex flex-wrap">
                                    <p className="mb-0 text-paraDark align-self-center">Mark product as addon</p>
                                    <Form.Item className='ant-select-lg mb-0'
                                        name="product_mark_addon"
                                    >
                                        <Switch className="mx-2" />
                                    </Form.Item>
                                    <p className="greyText mb-0">This means that this product will not appear as a listing on your shop page</p>
                                </div> */}
                                </div>

                                <div class="my-4">
                                    <div class="form-group">
                                        <label htmlFor="exampleInputEmail1" className="fw-500">SKU <span className="">*</span></label>
                                        <p class="label-subText">Unique identification to a specific product</p>
                                        <Form.Item className='ant-select-lg'
                                            name="product_sku"
                                            rules={[{ required: true, message: lang.sku_require_error_msg }]}
                                        >
                                            <Input className="form-control" placeholder="ABPZ11900" />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className="row my-4">
                                    <div className="col-md-4">
                                        <div className="mb-2">
                                            <label htmlFor="exampleInputEmail1" className="fw-500">Price <span className="">*</span></label>
                                        </div>
                                        <Form.Item className='ant-select-lg'
                                            name="product_price"
                                            rules={[{ required: true, message: lang.price_require_error_msg }]}
                                        >
                                            <Input
                                                placeholder="£9.99"
                                                suffix={
                                                    <LoyaltyIcon style={{ color: 'rgba(0,0,0,.45)' }} />
                                                }
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-2">
                                            <label htmlFor="exampleInputEmail1" className="fw-500">Stock</label>
                                        </div>
                                        <div className="d-flex  pt-2">
                                            <Form.Item className='mb-0'
                                                name="out_of_stock"
                                            // rules={[{ required: true, message: lang.stock_require_error_msg }]}
                                            >
                                                <Switch checked={checked} onChange={() => setChecked(!checked)} className="mx-2" />
                                            </Form.Item>
                                            <p className='align-self-center mb-0'>Out of stock</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-2">
                                            <label htmlFor="exampleInputEmail1" className="fw-500">Quantity</label>
                                        </div>
                                        <Form.Item className='mb-0'
                                            name="quantity"
                                            rules={[{ required: true, message: 'Please input your quantity.' }]}
                                        >
                                            <Input
                                                placeholder="Ex. 100"
                                            // suffix={
                                            // 	<LoyaltyIcon style={{ color: 'rgba(0,0,0,.45)' }} />
                                            // }
                                            />
                                        </Form.Item>
                                    </div>
                                </div>

                                <hr />

                                <div class="my-4">
                                    <div className="mb-2">
                                        <label htmlFor="exampleInputEmail1" className="fw-500">Add to category <span className="">*</span></label>
                                        <p class="label-subText">Add your product under a specifc category to make browsing easier</p>
                                    </div>
                                    <Form.Item className='ant-select-lg'
                                        name="category_id"
                                        rules={[{ required: true, message: lang.select_category_require_error_msg }]}
                                    >
                                        <Select
                                            showSearch
                                            // mode="multiple"
                                            style={{ width: '100%' }}
                                            placeholder="Search to Select"
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {
                                                categories.map(category => {
                                                    return (
                                                        <Option key={category.id} value={category.category?._id}>{category.category?.category_name}</Option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </div>

                                {/* <div>
								<ListGroup as="ul">
									<ListGroup.Item as="li">
										<p className="mb-0">Desserts</p>
									</ListGroup.Item>
									<ListGroup.Item as="li">
										<p className="mb-0">Ice creams</p>
									</ListGroup.Item>
								</ListGroup>
							</div> */}

                                <div class="my-4">
                                    <div className="mb-2">
                                        <label htmlFor="exampleInputEmail1" className="fw-500">Product Image <span className="">*</span></label>
                                        <p class="label-subText">Used to represent your product on your shop front page</p>
                                    </div>
                                    {/* <Form.Item
                                        name="product_photos"
                                    // rules={[{ required: true, message: lang.photos_require_error_msg }]}
                                    >
                                        <Dragger {...fileProps} className="rounded-8">
                                            <p className="ant-upload-text">Drag and drop your images here or <span className="greenText fw-500 textUnderline">Click to browse</span> Files</p>
                                            <p className="ant-upload-hint">1200x1200 (1:1) recommended, upto 1 mb each</p>
                                        </Dragger>
                                    </Form.Item> */}

                                    <input ref={uploadfilesRef} style={{ display: 'none' }} type="file" accept="image/png, image/jpeg, image/jpg" multiple onChange={(e) => {
                                        let file = e.target.files[0];
                                        // let fileType = file.type; // image/jpeg
                                        let fileSize = file.size; // 3MB

                                        if (fileSize > 1 * 1000000) {
                                            // fileSize > 5MB then show popup message
                                            alert(
                                                `File size is too large, please upload image of size less than 1MB.\nSelected File Size: ${fileSize / 1000000
                                                }MB`
                                            );
                                            return;
                                        }
                                        if (e.target.files) {
                                            /* Get files in array form */
                                            const files = Array.from(e.target.files);
                                            var arr = []

                                            /* Map each file to a promise that resolves to an array of image URI's */
                                            Promise.all(files.map(file => {

                                                return (new Promise((resolve, reject) => {
                                                    const reader = new FileReader();
                                                    reader.addEventListener('load', (ev) => {
                                                        resolve(ev.target.result);
                                                    });
                                                    reader.addEventListener('error', reject);
                                                    reader.readAsDataURL(file);
                                                }));
                                            }))
                                                .then(images => {
                                                    /* Once all promises are resolved, update state with image URI array */
                                                    var prev_img = [...product_photos]
                                                    images.forEach(a_raw_img => {
                                                        prev_img.push(a_raw_img)
                                                    })
                                                    setProductPhotos(prev_img)
                                                }, error => {
                                                    console.error(error);
                                                });
                                        }

                                    }} />
                                    {/* new image upload */}
                                    <div className="form-group" >

                                        <div id="uploadfiles_ays">

                                            <div id='upload_text_ays' style={{ height: '112px' }}>
                                                <div style={{ marginTop: '20px' }}><p className="ant-upload-text" ><span className="greenText fw-500 textUnderline ml-5 mt-1"><span id='underlinegreen' style={{ cursor: 'pointer' }} onClick={() => uploadfilesRef.current.click()}>Drag or  &nbsp;Upload</span></span> Files</p>
                                                    <p className="ant-upload-hint">1200x1200 (1:1) recommended, upto 1 mb each</p></div>
                                            </div>
                                        </div>

                                    </div>


                                    {/* {
                                        product_photos.map((img, i) => {
                                            return (
                                                <img key={i} src={img} alt='product edit' width="80" height="80" style={{ cursor: 'pointer' }} onClick={() => {
                                                    if (i > -1) {
                                                        var array1 = [...product_photos]
                                                        array1.splice(i, 1)
                                                        setProductPhotos(array1)
                                                    }
                                                }}  />
                                                
                                            )
                                        })
                                    }
 */}

                                    <div className='row'> {product_photos ? product_photos.map((image, index) => {

                                        return (

                                            <div className='d-flex ml-4' key={`serverimg+${index}`}>

                                                <span className="text-danger"
                                                    style={{ cursor: 'pointer' }} onClick={() => {

                                                        if (index > -1) {
                                                            var array1 = [...product_photos]
                                                            array1.splice(index, 1)
                                                            setProductPhotos(array1)
                                                        }

                                                    }}><span ><button type="button" class="close" data-dismiss="alert" aria-hidden="true" style={{ color: 'white', background: 'red', fontSize: '1.5rem', lineHeight: '0.8', opacity: '1', fontWeight: '400' }}>
                                                        ×</button></span>
                                                    <span><img src={image} style={{ height: '50px', width: '50px' }} /></span>
                                                </span>
                                            </div>
                                        )
                                    }) : null}
                                    </div>
                                </div>

                                {/* {DeliveryType !== "Pick up Only" && <div class="my-4"> */}
                                {
                                    isEnabledDelivery &&
                                    <div class="my-4">
                                        <div className="mb-2">
                                            <label htmlFor="exampleInputEmail1" className="fw-500">Instant delivery <span className="">*</span></label>
                                        </div>
                                        <div className="row">
                                            <div class="col-md-auto col-12 align-self-center">
                                                <Form.Item
                                                    name="instant_delivery"
                                                    rules={[{ required: true, message: lang.instant_delivery_require_error_msg }]}
                                                >
                                                    <Radio.Group>
                                                        <Radio value={true}>Yes</Radio>
                                                        <Radio value={false}>No</Radio>
                                                    </Radio.Group>
                                                </Form.Item>
                                            </div>
                                            <div class="col-md-6 col-12">
                                                <Form.Item className='ant-select-lg'
                                                    name="instant_delivery_price"
                                                // rules={[{ required: true, message: lang.photos_require_error_msg }]}
                                                >
                                                    <Input
                                                        placeholder="9.99"
                                                        suffix={
                                                            <LoyaltyIcon style={{ color: 'rgba(0,0,0,.45)' }} />
                                                        }
                                                    />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div class="my-4">
                                    <div className="mb-2">
                                        <label htmlFor="exampleInputEmail1" className="fw-500">Short Description</label>
                                    </div>
                                    <Form.Item
                                        name="short_description"
                                    // rules={[{ required: true, message: lang.short_description_require_error_msg }]}
                                    >
                                        <Input.TextArea value={short_description}
                                            maxLength={201}

                                            onChange={(event) => {
                                                setShort_description(event.target.value)

                                            }} className={`${short_description?.length > 200 && "border border-danger"}  form-control position-relative`} rows="3" placeholder="Vanilla filled chocolate goodness. A treat for the family gatherings and meetings with friends. This product is gluten free and Vegan, so dont forget to let loose!" />
                                        <span className="position-absolute " style={{ top: "74px", right: "20px", fontSize: "11px" }}>{short_description?.length}/200</span>
                                    </Form.Item>

                                </div>
                                <div className='boxes_ays'>
                                    <div className='row'>
                                        <div className='col-xl-12 col-md-12 col-xs-12  mb-4'>
                                            <label className='labeltext_ays fw-500' htmlFor="describeyourbusiness">Description</label>
                                            {/* <Form.Item
											name="description"
										> */}
                                            <Editor className="form-control"
                                                apiKey='68r82ciq6emwun43ptzljmjdzym1atmw19a9jzwm2y163ctj'
                                                value={Description}
                                                onEditorChange={(newText) => setDescription(newText)}
                                                onInit={(evt, editor) => editorRef.current = editor}
                                                id="descibeBusiness"
                                                init={{
                                                    selector: 'textarea',
                                                    height: 500,
                                                    menubar: false,
                                                    menubar: 'view',
                                                    plugins: 'pageembed',
                                                    toolbar: 'undo redo | formatselect | ' +
                                                        'bold italic underline| alignleft aligncenter ' +
                                                        'alignright alignjustify | bullist numlist outdent indent | ' +
                                                        'removeformat | pageembed',

                                                    content_style: 'body { font-family:Gotham; font-size:16px }'
                                                }}
                                            />
                                            {/* </Form.Item> */}

                                            {/* <textarea autoComplete='off' type="text" className="form-control " value={state.desc} onChange={(e) => setState({ desc: e.target.value })} id="descibeBusiness" placeholder="Descrilbe your business here in details...." /> */}
                                        </div>
                                    </div>
                                </div>
                                {
                                    business_category == 'Food' &&
                                    <div class="my-4">
                                        <div className="mb-2">
                                            <label htmlFor="exampleInputEmail1" className="fw-500">Veg/Non Veg</label>
                                        </div>
                                        <div>
                                            <Form.Item
                                                name="veg_category"
                                            >
                                                <Radio.Group>
                                                    <Radio value='Veg'>Veg</Radio>
                                                    <Radio value='No Veg'>None Veg</Radio>
                                                    <Radio value='Vegan'>Vegan</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </div>
                                    </div>
                                }

                                <div class="my-4">
                                    <div className="mb-2">
                                        <label htmlFor="exampleInputEmail1" className="fw-500">Add tags</label>
                                    </div>
                                    <Form.Item className='ant-select-lg'
                                        name="product_tags"
                                    >
                                        <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode">
                                            {children}
                                        </Select>
                                    </Form.Item>
                                </div>
                                <hr />

                                {/* <div>
                                <div className="mb-2">
                                    <label htmlFor="exampleInputEmail1" className="fw-500">Add customizations/Addons</label>
                                    <p class="label-subText">Allow the user to customize this product with add ons or toppings.</p>
                                </div>
                                <Form.List className="w-100 d-block" name="customize_addon" initialValue={itemInputs}>
                                    {(fields, { add, remove }) => (
                                        <>
                                            <div className="d-flex justify-content-end">
                                                <Form.Item>
                                                    <Button
                                                        onClick={() => add()}
                                                        icon={<PlusOutlined />}
                                                        className='btn btnPara-primary'
                                                    >
                                                        Create new addon
                                                    </Button>
                                                </Form.Item>
                                            </div>
                                            {fields.map((field) => (
                                                <Space className='w-100 d-block'
                                                    key={field.key}
                                                >
                                                    <Container className="p-0">
                                                        <Row>
                                                            <Col xs="12" md="5">
                                                                <Form.Item className=''
                                                                    {...field}
                                                                    name={[field.name, "item_code"]}
                                                                    fieldKey={[field.fieldKey, "item_code"]}
                                                                >
                                                                    <Input placeholder="Addon Name" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs="12" md="5">
                                                                <Form.Item className=''
                                                                    {...field}
                                                                    name={[field.name, "quantity"]}
                                                                    fieldKey={[field.fieldKey, "quantity"]}
                                                                >
                                                                    <Input placeholder="£10" />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs="12" md="2">
                                                                <Form.Item>
                                                                    <div className=''>
                                                                        <button className='btn btn-outline-red btn-block px-2' onClick={() => remove(field.name)}>Remove</button>
                                                                    </div>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </Space>
                                            ))}
                                        </>
                                    )}
                                </Form.List>
                            </div>
                            <hr /> */}
                                <div class="my-4">
                                    <div className="mb-2">
                                        <label htmlFor="exampleInputEmail1" className="fw-500">Meta Description</label>
                                    </div>
                                    <Form.Item className='ant-select-lg'
                                        name="meta_description"
                                    >
                                        <TextArea
                                            placeholder="Meta Description"
                                            autoSize={{ minRows: 3, maxRows: 5 }} />
                                    </Form.Item>

                                </div>

                                <div class="my-4">
                                    <div className="mb-2">
                                        <label htmlFor="exampleInputEmail1" className="fw-500">Meta tags</label>
                                    </div>
                                    {/* <input type="text" class="form-control" placeholder="Cake" /> */}
                                    <Form.Item className='ant-select-lg'
                                        name="meta_tag"
                                    >
                                        <Input className="form-control" placeholder="Cake" />
                                    </Form.Item>
                                </div>

                                <div className="form-group d-flex justify-content-end my-5">
                                    <Form.Item className="form-item">
                                        <div className='d-flex w-100 justify-content-end'>
                                            {/* <button className="btn textUnderline mr-3">Save as draft</button> */}
                                            <Spin spinning={loading} delay={500}>
                                                <Button className="btn btnPara-primary btn-lg" htmlType="submit">
                                                    Finish
                                                </Button>
                                            </Spin>
                                        </div>
                                    </Form.Item>
                                </div>
                            </div>
                        </Form>
                    </div>
                </Spin>
            </div>
        </DashboardLayout>
    )
}