import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const UserApi = {
    CreatAdminUser: async (data) => {
        const token = Token.getToken()

        return axios({
            method: "post",
            url: `${config.API_BASE_URL}/api/admin/create-user`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data,
        })
    },
    ChangePass: async (data) => {
        const token = Token.getToken()

        return axios({
            method: "post",
            url: `${config.API_BASE_URL}/api/admin/change-user-password`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data,
        })
    },
    getUserList: async () => {
        const token = Token.getToken();
        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/admin/get-user-list`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
    },
    getUserDetail: async (data) => {
        const token = Token.getToken();
        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/admin/get-user/${data}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
    },
    UpdateUserDetail: async (data) => {
        const token = Token.getToken();

        return axios({
            method: 'put',
            url: `${config.API_BASE_URL}/api/admin/update-user`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    user_StatusUpdate: async (data) => {

        const token = Token.getToken();

        return axios({
            method: 'put',
            url: `${config.API_BASE_URL}/api/admin/change-user-status`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },

    DeleteAdminUser: async (id) => {
        const token = Token.getToken();

        return axios({
            method: 'Delete',
            url: `${config.API_BASE_URL}/api/admin/delete-user/${id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
    },
    PagiNationAdminUser: async (pageNumber) => {
        const token = Token.getToken();
        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/admin/get-user-list?page=${pageNumber}&limit=10`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
    },
    getAllCustomerList: async (data) => {
        const token = Token.getToken();
        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/admin/get-customer-list?search=${data.search}&page=${data.page}&limit=${data.limit}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
    },
}