import React, { useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';

import './adminSidebar.css';
import config from '../../../config'
import axios from 'axios'
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import DataUsageOutlinedIcon from '@material-ui/icons/DataUsageOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { Token } from '../../../Token';

const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar, activelink }) => {
  let history = useHistory();
  const intl = useIntl();

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      overview: false,
      Applications: false,
      Users: false,
      Businesses: false,
      Categories: false,
      promotions: false,
      Tickets: false,
      analytics: false,
      Messages: false,
      businesses: []
    }
  );

  useEffect(() => {
    switch (activelink) {
      case 'overview':
        setState({
          overview: true,
          Applications: false,
          Users: false,
          Businesses: false,
          Categories: false,
          promotions: false,
          Tickets: false,
          analytics: false,
          Messages: false
        })
        break;
      case 'Applications':
        setState({
          overview: false,
          Applications: true,
          Users: false,
          Businesses: false,
          Categories: false,
          promotions: false,
          Tickets: false,
          analytics: false,
          Messages: false
        })
        break;
      default:
        break;
    }

    const token = Token.getToken();
    if (Token.isLoggedIn() && token) {
      setState({ fullname: Token.getFullName(), emailid: Token.getEmail() })
    //   var data = JSON.stringify({ "user_id": Token.getUserID() });

    //   var axiosConfig = {
    //     method: 'post',
    //     url: `${config.API_BASE_URL}/api/user/my-businesses`,
    //     headers: {
    //       'Authorization': `Bearer ${token}`,
    //       'Content-Type': 'application/json'
    //     },
    //     data: data
    //   };
    //   axios(axiosConfig)
    //     .then(function (response) {
    //       var data = response.data
    //       if (data.businesses.length > 0) {
    //         setState({ businesses: data.businesses })
    //       }
    //     }).catch(function (error) {
    //       console.log(error);
    //     });
    }
  }, [activelink])
  return (
    <ProSidebar
      image={image ? false : false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="lg"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div className="sidebar-topHeader">
          <p>Active</p>
          <h6 className="admin-name">{Token.getFullName()}</h6>
        </div>
      </SidebarHeader >

      <SidebarContent>
        <Menu iconShape="circle">{/* spacing sidebar link */}</Menu>
        <Menu iconShape="circle">

          {/* <MenuItem icon={<DataUsageOutlinedIcon />} active={state.overview}
            onClick={() => history.push('/admin/overview')} >
            {intl.formatMessage({ id: 'Overview' })}
          </MenuItem> */}

          {/* <MenuItem icon={<LocalOfferOutlinedIcon />} onClick={() => history.push('/admin/Applications')} >
            {intl.formatMessage({ id: 'Applications' })}
          </MenuItem>
          
          <MenuItem icon={<PersonOutlineOutlinedIcon />} onClick={() => history.push('/admin/Users')} >
          {intl.formatMessage({ id: 'Users' })}
        </MenuItem> */}

          {/* <MenuItem icon={<LockOpenRoundedIcon />} onClick={() => history.push('/admin/Businesses')} >
            {intl.formatMessage({ id: 'Businesses' })}
          </MenuItem> */}
          <MenuItem icon={<PeopleOutlineIcon/>} onClick={() => history.push('/admin/business-list')} >
            {intl.formatMessage({ id: 'Businesses' })}
          </MenuItem>

          <MenuItem icon={<PeopleOutlineIcon/>} onClick={() => history.push('/admin/customer-list')} >
            {intl.formatMessage({ id: 'Customers' })}
          </MenuItem>

          <MenuItem icon={<LockOpenRoundedIcon />} onClick={() => history.push('/admin/categories')} >
            {intl.formatMessage({ id: 'Categories' })}
          </MenuItem>

          <MenuItem icon={<LocalOfferOutlinedIcon />} onClick={() => history.push('/admin/Promotions')} >
            {intl.formatMessage({ id: 'Promotions' })}
          </MenuItem>
          <MenuItem icon={<PermContactCalendarOutlinedIcon/>} onClick={() => history.push('/admin/Contact-List')} >
            {intl.formatMessage({ id: 'Contact List' })}
          </MenuItem>
          <MenuItem icon={<PeopleOutlineIcon/>} onClick={() => history.push('/admin/Admin-User')} >
            {intl.formatMessage({ id: 'Admin Users' })}
          </MenuItem>


          {/* <MenuItem icon={<ReportProblemOutlinedIcon />} onClick={() => history.push('/admin/Tickets')} >
            {intl.formatMessage({ id: 'Tickets' })}
          </MenuItem> */}

          {/* <MenuItem icon={<SmsOutlinedIcon />} onClick={() => history.push('/admin/Messages')} >
            {intl.formatMessage({ id: 'Messages' })}
          </MenuItem> */}

        </Menu>
      </SidebarContent>

    </ProSidebar >
  );
};

export default Aside;