import React from 'react'

function Privacy() {
    return (
        <div className='container mb-5' style={{ marginTop: '76px' }}>
            <div className='d-flex justify-content-center pt-5' >
                <h2 className=''>Privacy</h2>
            </div>
            <div className='my-4 text-justify'>
                <p>We know that sharing your personal information with us is based on trust. We take this seriously and are committed to ensuring that we respect your privacy when you visit our website or use our services. Please review this Statement carefully to learn about our privacy practices.</p>
                <p>We operate an online service that provides users with recommendations for home food, local eateries &amp; bakeries, healthcare products, beauty care &amp; essential services &amp; products and lots more. In this Statement, these are collectively referred to as our Services.</p>
                <p>Our Services are owned and operated by PARA. By visiting our websites and related applications, whether on a computer, phone, tablet or similar device (these are all referred to as a &ldquo;Device&rdquo;), you are accepting the practices described below.</p>
                <p>As a global company, we visualize to operate in a number of countries and territories where the laws and customs differ. This Statement provides a general overview of our privacy practices.</p>
                <p style={{ "font-weight": "bold" }}>Information Collected</p>
                <p>When you access or use our Services, we collect information from and about you to provide a more personalised and relevant experience. Some information we collect automatically. Other information we collect from different sources, including affiliated entities, business partners and other independent third-party sources. When you use our Services by &ldquo;clicking-through&rdquo; from a third-party site or when you visit third-party sites via our Services, those third-party sites may share information with us about your use of their service. Information collected may include the following:</p>
                <ul>
                    <li>
                        <p>● Contact information, including name, phone number and postal and email addresses</p>
                    </li>
                    <li>
                        <p>● Billing or payment information (such as your credit card number, cardholder name, expiration date, authentication code and billing address)</p>
                    </li>
                    <li>
                        <p>● Username and password</p>
                    </li>
                    <li>
                        <p>● Photos, reviews, forum and social posts and videos you may have provided to us</p>
                    </li>
                    <li>
                        <p>● Geolocation information</p>
                    </li>
                    <li>
                        <p>● Device information, such as when you accessed our Services and information about the Device used (for example, IP address, software or internet browser used, preferred languages, unique Device identifiers and advertising identifiers)</p>
                    </li>
                    <li>
                        <p>● Online activity, including pages you have visited, content reviewed and apps reviewed</p>
                    </li>
                    <li>
                        <p>● Purchasing history</p>
                    </li>
                    <li>
                        <p>● Information about your travel plans and preferences</p>
                    </li>
                </ul>
                <p>We may also collect, in instances where you have provided it, information about other users, including their email address and other food or healthcare or beauty-related information. If you are sharing information with us about other individuals, you must obtain their consent. You must obtain the consent of any other individuals if you are providing their data to us.</p>
                <p>In addition to the categories noted above, we may also collect location information if you have instructed your Device to send such information via the privacy settings on that Device, or, for example, if you have uploaded photos tagged with location information. We may use your location information directly and/or share your location with third parties. We collect and share location information in order to provide you with relevant content, to analyze your use of our Services, to improve our Services and to provide contextual advertising or recommendations. For example, we may use your location to show you reviews of home food, local restaurants, healthcare / essentials &amp; beauty products &amp; services, when you are searching on the net.</p>
                <p>You can change the privacy settings of your Device at any time in order to turn off the functionality that collects and shares location information and/or the functionality to tag your photos with location information. However, turning off location-sharing may affect certain features that we offer. If you have any questions about the privacy settings of your Device, we suggest you contact the manufacturer of your Device or your mobile service provider for help.</p>
                <p>Please see the section on Cookies for more information regarding the use of cookies and other technology described in this section, including regarding your choices relating to such technologies.</p>
                <p style={{ "font-weight": "bold" }}>Information Uses</p>
                <p>We are committed to providing you with relevant content on our Services and respect the data privacy laws of the different jurisdictions in which we operate. We use information about you so we can help you enjoy and use our Services, including in the following ways:</p>
                <ul>
                    <li>
                        <p>● Register and manage your account, including to allow you access to and use of our Services</p>
                    </li>
                    <li>
                        <p>● Facilitate your business queries &amp; transactions</p>
                    </li>
                    <li>
                        <p>● Measure interest in and improve our Services</p>
                    </li>
                    <li>
                        <p>● Notify you about special offers and products or services available from us, our affiliates, or our partners that may be of interest to you</p>
                    </li>
                    <li>
                        <p>● Communicate with you</p>
                    </li>
                    <li>
                        <p>● Enable communication between registered users and owners/representatives of listings on our Services</p>
                    </li>
                    <li>
                        <p>● Enable us to publish your reviews, forum posts, photos, videos and other content</p>
                    </li>
                    <li>
                        <p>● Customise your experience, including customising the ads shown to you on our Services and across the internet</p>
                    </li>
                    <li>
                        <p>● Make inferences about your interests or preferences</p>
                    </li>
                    <li>
                        <p>● Provide you with an optimised experience and to group users of our Services based on, for example, usage and demographics</p>
                    </li>
                    <li>
                        <p>● Send you survey or market research invitations</p>
                    </li>
                    <li>
                        <p>● Respond to your questions and comments</p>
                    </li>
                    <li>
                        <p>● Resolve disputes or troubleshoot problems</p>
                    </li>
                    <li>
                        <p>● Prevent potentially prohibited or unlawful activities</p>
                    </li>
                    <li>
                        <p>● Enforce our terms of use and notices; and as otherwise described to you at the point of collection</p>
                    </li>
                </ul>
                <p>Please note some information about you is required in order for us to provide you with relevant offerings from us, our affiliates and our partners and to enable you to enjoy other benefits of being a registered user. Please review the &ldquo;Information Choices&rdquo; section below with respect to your choices related to the collection and use of your information.</p>
                <p style={{ "font-weight": "bold" }}>Information Sharing</p>
                <p>In order to provide some of our Services, we may need to share information with certain other third parties, including our group of companies, in the following circumstances:</p>
                <ul>
                    <li>
                        <p>● Our Group of Companies: We share information with companies in our corporate family so we can provide you with information about products and services, both travel-related and others, which might interest you. These companies and websites will comply with this Statement and all applicable laws governing the transmission of promotional communications.</p>
                    </li>
                    <li>
                        <p>● Suppliers: We share information with certain suppliers used to facilitate our Services, including to help with transactions, such as any food ordering you might make, for example, sharing data with a food supplier / local bakery if you make an enquiry using our Services.</p>
                    </li>
                    <li>
                        <p>● Business Partners: We may share information about you, your Devices and your use of our Services with our trusted business partners. For example, we may share information so that we can provide you with relevant content or in order to facilitate payment for products or services you may purchase on the websites of our partners. This sharing is generally pursuant to written agreements which include confidentiality, privacy and security obligations; however, note that we do not control the privacy practices of these third-party business partners.</p>
                    </li>
                    <li>
                        <p>● Social Media Sites: When you use our Services and elect to share information with third-party social media sites, the information you share will be governed by the privacy policies of those social media sites and the privacy settings you have set with those social media websites.</p>
                    </li>
                    <li>
                        <p>● Advertising Networks: We sometimes collect and share information about your interests with advertising networks. Some of these companies are members of the Network Advertising Initiative or the Digital Advertising Alliance, which offer a single location to opt-out of ad targeting from member companies. Other third parties, such as referring websites.Third parties may also assist us in collecting information by, for example, operating features of our website or facilitating the delivery of online advertising tailored to your interests. We may share audience segments and other information with third parties that use that information for tailored advertising to you.</p>
                    </li>
                </ul>
                <p>Third parties may only collect or access information as needed to perform their permitted functions.</p>
                <p>Certain Device operating system versions permit you to opt out of certain types of information sharing, including to certain advertising networks. Please check your Device settings if you want to limit such tracking.</p>
                <p style={{ "font-weight": "bold" }}>Information Choices</p>
                <p>You have options with respect to the collection and use of your information. You can access, update and even close your account by notifying PARA admin given in contact us.</p>
                <p>Your options:</p>
                <p>●&nbsp; &nbsp;&nbsp;You may choose the way we communicate with you. You can choose not to provide us with certain information, although it may be needed to take advantage of certain features offered on our websites.</p>
                <p>●&nbsp; &nbsp;&nbsp;You can prevent the collection of geolocation information, but note that turning off location sharing may affect certain features of our Services.</p>
                <p>●&nbsp; &nbsp;&nbsp;You also can add or update information and close your account. If you close your account, we will deactivate your account and remove your profile information from active view. We may retain some information associated with your account (including past transactions) for internal purposes including backups, fraud prevention, dispute resolution, investigations and legal compliance for the period necessary to fulfil the purposes outlined in this Statement.</p>
                <p>●&nbsp; &nbsp;&nbsp;As a registered member, you can modify your marketing subscriptions, including email subscription choices, at any time within your member settings. You also will be given the opportunity to unsubscribe from promotional messages, including emails with our services related opportunities, in any such email we send.</p>
                <p>Information Transfers</p>
                <p>We are a global company and we intend to operate in many different jurisdictions. If we transfer your information to other countries, we will use and protect that information as described in this Statement and in accordance with applicable law.</p>
                <p>Information Security</p>
                <p>We have implemented appropriate administrative, technical and physical security procedures to help protect your information.</p>
                <p>We only authorise specific employees to access personal information and they may do so only for permitted business functions. We use encryption when transmitting your information between your system and ours, and between our system and those of the parties with whom we share information. We also employ firewalls and intrusion detection systems to help prevent unauthorised access to your information. However, we cannot guarantee the security of information from unauthorised entry or use, hardware or software failure or other circumstances outside of our control.</p>
                <p>Information Retention</p>
                <p>We will retain copies of your information for as long as you maintain your account or as necessary in connection with the purposes set out in this Statement, unless applicable law requires a longer retention period. In addition, we may retain your information for the duration of any period necessary to establish, exercise or defend any legal rights.</p>
                <p style={{ "font-weight": "bold" }}>Do Not Track Signals</p>
                <p>Our website is not designed to currently respond to &ldquo;Do Not Track&rdquo; (&ldquo;DNT&rdquo;) signal requests from browsers. This is due to the lack of global standardised interpretation that defines &ldquo;Do Not Track&rdquo; signals. Once the industry has settled on standards related to this issue, we may re-evaluate this approach.</p>
                <p style={{ "font-weight": "bold" }}>PARA,&nbsp;</p>
                <p style={{ "font-weight": "bold" }}>21 Epsom Road,&nbsp;</p>
                <p style={{ "font-weight": "bold" }}>Beeston, Nottingham,&nbsp;</p>
                <p style={{ "font-weight": "bold" }}>NG9 5HQ</p>
                <p style={{ "font-weight": "bold" }}>United Kingdom</p>
            </div>
        </div >
    )
}

export default Privacy
