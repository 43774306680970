import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const DocumentApi = {
    DocumentUpdateAndCreate: async (data) => {
        const token = Token.getToken();
    //    data.user_id = Token.getUserID();
       
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/save-document`,
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data: data
        });
    },
    getDocument: async (business_slug) => {
        const token = Token.getToken();
        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/user/fetch-documents/${business_slug}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        });
    }
}