import React, { Component } from 'react';
import { DashboardLayout } from '../Layout/Layout';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import SampleProducts from '../../../assets/images/products/sample-image.svg';
import { Row, Col, Card } from 'react-bootstrap';


export class AddApplication extends Component {
    render() {
        return (
            <DashboardLayout page='promotions'>
                <div className="w-100 sidebar-right-content-spacing" style={{ backgroundColor: '#F3F5E6' }}>
                    <div className="sidebar-TopContent">
                        <h3 className="mb-0">Applications</h3>
                        <div className="d-md-flex flex-wrap justify-content-md-between">
                            <div className="my-2">
                                <button className="btn btn-outline-dark btn-rounded my-2"> <ArrowBackOutlinedIcon style={{ fontSize: '16px' }} /> Back to applications</button>
                            </div>
                            <div className="d-flex flex-wrap my-2">
                                <button className="btn btn-outline-dark mr-3 my-2">Request Info</button>
                                <button className="btn btnPara-red mr-3 my-2">Reject proposal</button>
                                <button className="btn btnPara-primary my-2">Approve proposal</button>
                            </div>
                        </div>
                        <div className="d-md-flex justify-content-md-between">
                            <div className="my-3">
                                <div className="media">
                                    <img src={SampleProducts} alt="" className="bd-placeholder-img mr-2 rounded" width="56" height="56" />
                                    <div className="media-body mb-0 border-gray">
                                        <p className="mb-0 h4 fw-400">Cakes by K</p>
                                        <div className="d-flex flex-wrap">
                                            <p className="mb-0 greyText">Shop owner: </p>
                                            <p className="mb-0 greyText">Neveah Simmons</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-wrap my-3 align-self-center greyText">
                                <p>Application number: </p>
                                <p>12u423989ou39</p>
                            </div>
                        </div>
                    </div>

                    <div className="sidebar-content" style={{ backgroundColor: '#FAFAFA' }}>
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="card">
                                        <h3>asdasadadad</h3>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="card">
                                        <h3>asdasadadad</h3>
                                    </div>
                                </div>
                            </div>
                            <Row>
                                <Col xs={6} md={4}>
                                    <Card>
                                        <Card.Body>This is some text within a card body.</Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={6} md={4}>
                                    <Card>
                                        <Card.Body>This is some text within a card body.</Card.Body>
                                    </Card>
                                </Col>
                                <Col xs={6} md={4}>
                                    <Card>
                                        <Card.Body>This is some text within a card body.</Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </DashboardLayout>
        )
    }
}

export default AddApplication
