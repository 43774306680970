import React, { useState } from 'react'
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../assets/images/modalPopup/close-circle.svg';
import { Form, Input, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import SignUpFlower from '../../assets/images/adminPanel/Auth/signup-flower.svg';
import { AuthAPI } from '../../apis/user/AuthAPI';
import { Modal } from 'react-bootstrap'


export default function ForgotPassword(props) {

    const [form] = Form.useForm();
    const [loading, setLoader] = useState(false);
    const params = useParams();

    const onFinish = (value) => {
        setLoader(true)
        AuthAPI.forgotPasssword(value)
            .then(res => {
                setLoader(false)
                props.setState({ _isShowForgotModel: false })
                Swal.fire({
                    title: 'Success!',
                    text: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    // imageHeight: 1500,
                    confirmButtonColor: '#00CA84'
                });
                // history.push('/');
            }).catch(function (error) {
                setLoader(false);
                Swal.fire({
                    text: error.response.data.message,
                    imageUrl: CloseCircle,
                    imageAlt: 'error image',
                    // imageHeight: 1500,
                    confirmButtonColor: '#00CA84'
                });
            });
    }
    return (
        <div>
            <Modal className="loginFormModal" centered
                show={props.state._isShowForgotModel}
                onHide={() => props.setState({ _isShowForgotModel: false })}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="p-0">
                    <div className="userLogin-box">
                        <div className="userLogin-headerBox">
                            <h4 className="align-self-end mb-0 text-white fw-600">Forgot Password</h4>
                            <div className="SignUpFlower-box">
                                <img src={SignUpFlower} alt="" />
                            </div>
                        </div>
                        <div className="userLogin-formBox">
                            <Form
                                form={form}
                                onFinish={onFinish}
                            >
                                <div className="form-group mt-3">
                                    <label for="exampleInputEmail1">Email address</label>
                                    <Form.Item
                                        className="form-item"
                                        name="email"
                                        rules={[
                                            { required: true, message: 'Please input your email address.' },
                                            { type: 'email', message: 'Please enter valid email address.' }
                                        ]}
                                    >
                                        <Input placeholder="Email address" />
                                    </Form.Item>
                                </div>
                                <div className="form-group mt-3">
                                    <Form.Item className="form-item">
                                        <Spin spinning={loading} delay={500}>
                                            <button className="btn btnPara-primary btn-md btn-block" htmlType="submit">
                                                Submit
                                            </button>
                                        </Spin>
                                    </Form.Item>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}
