import '../../../../src/assets/css/AdminPanel/adminPanel.css';
import React, { useState } from 'react'
import { DashboardLayout } from '../Layout/Layout'
import { Redirect } from 'react-router-dom'
import SampleProducts from '../../../assets/images/products/sample-image.svg';

export default function Businesses() {
    return (
        <DashboardLayout page='businesses'>
            <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-content">
                    <h3 className="mb-3">Business</h3>
                    <div className="paraTabs">
                        <div className="table-responsive paraRounded-table">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="fw-500">NAME</th>
                                        <th scope="col" className="fw-500">OWNER</th>
                                        <th scope="col" className="fw-500">EMAIL</th>
                                        <th scope="col" className="fw-500">CATEGORY</th>
                                        <th scope="col" className="fw-500">STATUS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <div className="d-flex">
                                                <img src={SampleProducts} alt="" className="mr-2" width="32" height="32" />
                                                <p className="mb-0 pl-1 align-self-center ">Name of business</p>
                                            </div>
                                        </td>
                                        <td className="align-middle"><p className="mb-0">Darlene Robertson</p></td>
                                        <td className="align-middle"><p className="mb-0">kenzi.lawson@example.com</p></td>
                                        <td className="align-middle"><p className="mb-0">COVID essentials</p></td>

                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                {/*  apply js on option-redText and option-greenText */}
                                                <select className="form-selectOption option-greenText">
                                                    <option className="option-greenText" value="apple">Active</option>
                                                    <option className="option-redText" value="banana">Inactive</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <div className="d-flex">
                                                <img src={SampleProducts} alt="" className="mr-2" width="32" height="32" />
                                                <p className="mb-0 pl-1 align-self-center ">Name of business</p>
                                            </div>
                                        </td>
                                        <td className="align-middle"><p className="mb-0">Darlene Robertson</p></td>
                                        <td className="align-middle"><p className="mb-0">kenzi.lawson@example.com</p></td>
                                        <td className="align-middle"><p className="mb-0">COVID essentials</p></td>

                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                {/*  apply js on option-redText and option-greenText */}
                                                <select className="form-selectOption option-redText">
                                                    <option className="option-greenText" value="apple">Active</option>
                                                    <option className="option-redText" value="banana">Inactive</option>
                                                </select>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}



