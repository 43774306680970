import React, { Fragment, useReducer, useState, useRef } from 'react'

import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';


import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '@material-ui/lab/Rating';
import { DropzoneArea } from "material-ui-dropzone";
import config from '../config';
import axios from 'axios'
import { Spinner } from 'react-bootstrap';
import { TextField, FormControl } from '@material-ui/core';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../assets/images/modalPopup/close-circle.svg';
import { Token } from '../Token';

const ADDREVIEWMODAL = (props) => {
    const token = Token.getToken();
    const titleRef = useRef();
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            rating: 0,
            review_title: "",
            detailed_review: '',
            photos: [],
            form_submitting: false,
            titleErrorStatus: false,
            titleErrorMessage: "",
            formValidated: false,
            addRatingErorMessage: "",
            addRatingErorMessageStatus: 'none'
        }
    );

    const handleChange = (files) => {
        setState({ photos: files });
    }

    const handleSubmit = async () => {
        var photos = state.photos
        var photos1 = []
        await photos.map((element, index) => {
            const reader = new FileReader();
            reader.readAsDataURL(element)
            reader.onload = () => {
                // if (reader.readyState === 2) {
                photos1[index] = reader.result;
                // }
            }
        });

        var review_data = {
            "rating": state.rating,
            "title": state.review_title,
            "tell_us_more": state.detailed_review,
            "photos": photos1,
            // "user_id": props.business.user_id,
            "user_id": Token.getUserID(),
            "business_id": props.business.id
        }

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }

        if (titleRef.current.value === '' && state.rating === 0) {
            setState({ titleErrorStatus: true, titleErrorMessage: 'Please add title to your review', addRatingErorMessage: "Please rate before submitting", addRatingErorMessageStatus: "" })

        } else if (titleRef.current.value === "" && state.rating !== 0) {
            setState({ titleErrorStatus: true, titleErrorMessage: 'Please add title to your review' })
        } else if (titleRef.current.value && state.rating === 0) {
            setState({ addRatingErorMessage: "Please rate before submitting", addRatingErorMessageStatus: "" })
        } else if (titleRef.current.value && state.rating !== 0) {
            setState({ form_submitting: true })
            setTimeout(() => {
                axios.post(`${config.API_BASE_URL}/api/user/add-review`, review_data, {
                    headers: headers
                }).then(res => {
                    Swal.fire({
                        text: res.data.message,
                        imageUrl: CheckmarkCircle,
                        imageAlt: 'success image',
                        confirmButtonColor: '#00CA84'
                    });
                    props.reviewModalVisibility(false)
                    window.location.reload();

                }).catch(err => {
                    if (err.response) {
                        Swal.fire({
                            text: err.response.data.message,
                            imageUrl: CloseCircle,
                            imageAlt: 'error image',
                            confirmButtonColor: '#00CA84'
                        });
                    }
                })

            }, 2000)
        }
    }

    const handleTitle = (value) => {
        if (titleRef.current.value === '') {
            setState({ review_title: value, titleErrorStatus: true, titleErrorMessage: 'Please add title to your review' })
        }
        else {
            setState({ review_title: value, titleErrorStatus: false, titleErrorMessage: '' })
        }
    }

    return (
        <Fragment>
            <Modal className='d-flex justify-content-center align-center'
                style={{
                    overflow: 'visible',
                }}
                open={true}
                onClose={() => {
                    props.reviewModalVisibility(false)
                }}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={true} style={
                    {
                        overflow: 'auto',
                        backgroundColor: 'white',
                        margin: '10px 0px'
                    }}>
                    <div className='col-xl-4 col-md-5 col-xs-12 paraModal-box' >
                        <div className='d-flex'>
                            <div className='modal-title'>Add a Review</div>
                            <div className='close-btn-box'>
                                <CloseIcon className="btn-close" onClick={() => {
                                    props.reviewModalVisibility(false)
                                }} />
                            </div>
                        </div>
                        <div className='add-review-box mt-3'>

                            <div className='mb-4'>
                                <div className='form-group'>
                                    <span style={{ color: '#666666' }}>Add a rating</span>
                                </div>
                                <div className="form-group">
                                    <Rating name="half-rating"
                                        defaultValue={0} size="large"
                                        value={state.rating} onChange={(e) => setState({ rating: e.target.value, addRatingErorMessageStatus: 'none', addRatingErorMessage: "" })}
                                        icon={<StarRoundedIcon fontSize="inherit" />}
                                    />

                                    <p className='text-danger' style={{ display: state.addRatingErorMessageStatus }}>{state.addRatingErorMessage}</p>
                                </div>
                            </div>

                            <div className='mb-4'>
                                <div className="form-group">
                                    <label htmlFor="outlined-error-helper-text">Add a title</label>
                                    <FormControl fullWidth>
                                        <TextField
                                            inputRef={titleRef}
                                            error={state.titleErrorStatus}
                                            title="Sum up your review in single line"
                                            id="outlined-error-helper-text"
                                            helperText={state.titleErrorMessage}
                                            variant="outlined"
                                            onChange={(e) => handleTitle(e.target.value)}
                                        />
                                    </FormControl>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="outlined-error-helper-text">Tell us more</label>
                                    <FormControl fullWidth>
                                        <TextField
                                            title='What&apos;s most important to know'
                                            id="outlined-error-helper-text"
                                            multiline
                                            rows={3}
                                            variant="outlined"
                                            onChange={(e) => setState({ detailed_review: e.target.value })}
                                        />
                                    </FormControl>
                                </div>
                            </div>

                            <div className='mb-4'>
                                <div className="form-group">
                                    <p>Add Photos</p>
                                </div>

                                <DropzoneArea
                                    acceptedFiles={["image/*", "video/*", "application/*"]}
                                    onChange={handleChange.bind(this)}
                                    initialFiles={state.photos}
                                    filesLimit={10}
                                    showAlerts={true}
                                />
                            </div>
                            <div className="form-group">
                                {state.form_submitting ? <button className='btn btnPara-primary btn-block' disabled> <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />Submitting Review...</button> :
                                    <button className='btn btnPara-primary btn-block' onClick={() => handleSubmit()} >Submit</button>
                                }
                            </div>

                        </div>
                    </div>
                </Fade>
            </Modal>

        </Fragment>
    )
}
export default ADDREVIEWMODAL