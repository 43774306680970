import React, { useEffect, useReducer, useState } from 'react'
import { Row, Col, } from 'react-bootstrap'
import { Link, useHistory, useParams } from 'react-router-dom';
import vegIcon from '../../assets/images/svg-icons/veg_icon.svg';
import CakesIcon from '../../assets/images/shopDetails/cakesbyk.svg';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import { OrderAPI } from '../../apis/user/OrderAPI';
import moment from 'moment';
import { Token } from '../../Token';
import { MessageAPI } from '../../apis/user/MessageAPI';
import { Spin } from 'antd';


function OrderSummary() {

    const history = useHistory();
    const params = useParams();
    const order_id = params.order_id;
    const [loading, setLoader] = useState(true);

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            orderID: '',
            businessId: '',
            shopName: '',
            companyLogo: '',
            houseName: '',
            orderAddress: '',
            postalCode: '',
            dateTime: '',
            orderAmount: 0,
            orderDiscount: 0,
            finalOrderAmount: 0,
            deliveryCharge: 0,
            orderDiscountType: "Cash",
            orderNote: '',
            orderProducts: [],
            totalProductDiscount: 0,
            paymentMethod: '',
            paymentStatus: '',
        }
    );

    useEffect(() => {
        setLoader(true)
        OrderAPI.fetchOrder(order_id)
            .then(res => {
                let total_product_discount_price = 0;
                res.data.order.carts.forEach(cart => {
                    var pPrice = cart.product_price;
                    if (cart.product_discounted_price != 0) {
                        let dPrice = pPrice - cart.product_discounted_price;
                        total_product_discount_price = total_product_discount_price + (dPrice * parseInt(cart.quantity))
                    }
                });

                setState({
                    orderID: res.data.order.order_id,
                    businessId: res.data.order.business._id,
                    shopName: res.data.order.business.business_name,
                    companyLogo: res.data.order.business.company_logo,
                    postalCode: res.data.order.business.postal_code,
                    houseName: res.data?.order?.address?.house_name || "",
                    orderAddress: res.data?.order?.address?.address || "",
                    dateTime: res.data.order.date_time,
                    orderNote: res.data.order.leaveANote,
                    orderAmount: res.data.order.order_amount,
                    orderDiscount: res.data.order.order_discount,
                    finalOrderAmount: res.data.order.final_order_amount,
                    deliveryCharge: res.data.order.delivery_charge,
                    orderDiscountType: res.data.order.order_discount_type,
                    orderProducts: res.data.order.carts,
                    totalProductDiscount: parseFloat(total_product_discount_price).toFixed(2),
                    paymentMethod: res.data.order.payment_method,
                    paymentStatus: res.data.order.payment_status
                })
                setLoader(false)

            }).catch(error => {
                console.log(error)
            })
    }, []);

    const onCreateMessageThred = () => {
        const data = {
            business_id: state.businessId
        }

        MessageAPI.createMessageRoom(data)
            .then(res => {
                // console.log('messagess', res.data)
                history.push('/seller-profile/messages')
            })
    }
    return (
        <Spin spinning={loading} delay={500}>
            <div className='w-100' style={{ marginTop: '120px', marginBottom: '120px' }}>
                <div className='container my-5'>
                    <div className='mt-5'>
                        <div className='d-flex'>
                            <div className='greenText mr-2 '><CheckCircleOutlineRoundedIcon style={{ fontSize: '32px' }} /></div>
                            <h4 className='mb-0 align-self-auto'>Order Placed</h4>
                        </div>
                    </div>
                    <hr />

                    <Row>
                        <Col lg={7}>
                            <div className="order-place-info">
                                <div className="my-2 mt-3 mb-4">
                                    <div className="d-flex pb-4">
                                        <img src={state.companyLogo} alt="" className="orderImage" />
                                        <div className="align-self-center  mb-0">
                                            <h5 className="mb-1">{state.shopName}</h5>
                                            <p className="d-block greyText mb-2">
                                                {`${state.orderAddress} ${state.postalCode}`}
                                            </p>
                                        </div>
                                    </div>
                                    <hr className="mb-4 mt-0" />

                                    <div>
                                        <h6>Order #{state.orderID}</h6>
                                        <Row className="mb-4">
                                            <Col md={6}>
                                                <div className='my-3'>
                                                    <p className="ft-size14 greyText mb-0">{state.orderAddress}</p>
                                                    <p className="mb-2">{moment(state.dateTime).format('D MMM, YYYY H:m A')}</p>
                                                </div>
                                                <div className='my-3'>
                                                    <p className="ft-size14 greyText mb-0">Payment type</p>
                                                    <p className="mb-2">{state.paymentMethod}</p>
                                                </div>
                                                <div className='my-3'>
                                                    <p className="ft-size14 greyText mb-0">Note/Special instructions</p>
                                                    <p className="mb-2">{state?.orderNote != '' ? state?.orderNote : '-'}</p>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className='my-3'>
                                                    <p className="ft-size14 greyText mb-0">Delivery address</p>
                                                    <p className="mb-2">{state?.houseName || ""}</p>
                                                </div>
                                                <div className='my-3'>
                                                    <p className="ft-size14 greyText mb-0">Payment status</p>
                                                    <h6 className="greenText mb-2">{state.paymentMethod}</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={5}>
                            <div className="p-3 greyBox border rounded-8">
                                <div className="table-responsive">
                                    <table className="table table-borderless mb-0">
                                        <tbody>
                                            {
                                                state.orderProducts.map((product, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="pl-0 py-1 pr-2">
                                                                <div className="d-flex">
                                                                    <p className="mb-0">{product.product_name}</p>
                                                                </div>
                                                            </td>
                                                            <td className="py-1">x{product.quantity}</td>
                                                            <td className="py-1 pr-0 text-right">£{product.product_price}</td>
                                                            {/* <td className="py-1 pr-0 text-right">£{(product.product_discounted_price == 0 ? parseFloat(product.product_price) : parseFloat(product.product_discounted_price) * parseInt(product.quantity)).toFixed(2)}</td> */}
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <hr />
                                <div className="#">
                                    <div className="d-flex justify-content-between">
                                        <h6 className="mb-2 mr-2">Item total</h6>
                                        <p className="mb-2">£{state.orderAmount}</p>
                                    </div>
                                    {/* <div className="d-flex justify-content-between">
                                    <p className="mb-2 mr-2">Packaging charges</p>
                                    <p className="mb-2">£0</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p className="mb-2 mr-2">Taxes</p>
                                    <p className="mb-2">£0</p>
                                </div> */}
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2 mr-2">Delivery Charge</p>
                                        <p className="mb-2">£{state.deliveryCharge}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p className="mb-2 mr-2 greenText">Discount</p>
                                        {/* {
                                            state.orderDiscountType == 'Cash' ?
                                                <h5 className="mb-0 greenText">£{(parseFloat(state.orderDiscount) + parseFloat(state.totalProductDiscount)).toFixed(2)}</h5> :
                                                <h5 className="mb-0 greenText">£{(((parseFloat(state.orderAmount) / 100) * parseFloat(state.orderDiscount)) + parseFloat(state.totalProductDiscount)).toFixed(2)}</h5>
                                            } */}
                                        <h5 className="mb-0 greenText">£{parseFloat(state.orderDiscount).toFixed(2)}</h5>
                                    </div>
                                </div>
                                <hr />

                                <div className="#">
                                    <div className="d-flex justify-content-between">
                                        <h5 className="mb-2 mr-2">Bill total</h5>
                                        {/* {
                                            state.finalOrderAmount > 0 ?
                                                <>
                                                    {
                                                        state.orderDiscountType == 'Cash' ?
                                                        <h5 className="mb-0">£{(parseFloat(state.orderAmount) - (parseFloat(state.orderDiscount) + parseFloat(state.totalProductDiscount)) + parseFloat(state.deliveryCharge)).toFixed(2)}</h5> :
                                                        <h5 className="mb-0">£{(parseFloat(state.orderAmount) - (((parseFloat(state.orderAmount) / 100) * parseFloat(state.orderDiscount)) + parseFloat(state.totalProductDiscount)) + parseFloat(state.deliveryCharge)).toFixed(2)}</h5>
                                                    }
                                                    </> :
                                                    <>0.00</>
                                                } */}
                                        <h5 className="mb-0">£{parseFloat(state.finalOrderAmount).toFixed(2)}</h5>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="d-md-flex justify-content-md-between flex-wrap">
                        <div className="d-flex flex-row flex-wrap">
                            <div className="mr-3">
                                <button
                                    onClick={onCreateMessageThred}
                                    className="btn btnPara-primary">Message Store
                                </button>
                            </div>
                            {/* <div className="mr-3"> <button className="btn btn-outline-primary">Help</button></div>
                        <div className="p-2 mr-3 align-self-center">
                            <Link href="#" target="_blank" className="para-link-green">Track order</Link>
                        </div> */}
                        </div>
                        {/* <div className="p-2">
                        <Link href="#" className="para-link-dark fw-500 "><u>Print invoice</u></Link>
                    </div> */}
                    </div>

                </div>


            </div>
        </Spin>

    )
}

export default OrderSummary