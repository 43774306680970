import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const BusinessAPI = {
    fetchBusinessDashboard: async (business_slug) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), business_slug: business_slug };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/business-dashbaord`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    fetchBusinesses: async () => {
        const token = Token.getToken();

        var data = { "user_id": Token.getUserID() };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/my-businesses`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    fetchFavouriteBusinesses: async () => {
        const token = Token.getToken();

        var data = { "user_id": Token.getUserID() };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-favourite-stores`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    saveBusiness: async (data) => {
        const token = Token.getToken();
        data.user_id = Token.getUserID();

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/save-business`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
        // return await axios.post(`${config.API_BASE_URL}/api/user/save-business`, data);
    },
    fetchBusiness: async (business_slug) => {
        const token = Token.getToken();

        var data = { "user_id": Token.getUserID() };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/business/detail/${business_slug}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    updateBusiness: async (data, business_id) => {
        const token = Token.getToken();
        data.user_id = Token.getUserID();
        data.business_id = business_id;

        return axios({
            method: 'put',
            url: `${config.API_BASE_URL}/api/user/update-business`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    checkBusinesFavorite: async (business_id) => {
        const token = Token.getToken();

        const data = {
            business_id: business_id,
            user_id: Token.getUserID()
        }

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/check-business-favorite`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    addRemoveWihList: async (business_id, act) => {

        const token = Token.getToken();

        const data = {
            business_id: business_id,
            user_id: Token.getUserID(),
            act: act
        }

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/add-remove-favorite`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    guestGetBusiness: async (filterCat, userLatLong) => {
        const lat = userLatLong.lat || 0
        const long = userLatLong.long || 0
        return axios.get(`${config.API_BASE_URL}/api/get-businesses/${filterCat}?lat=${lat}&long=${long}`)
    },
    guestViewBusiness: async (shopSlug, selectedPostlaCode) => {
        // return axios.get(`${config.API_BASE_URL}/api/business/detail/${shopId}`);
        const data = {
            shopSlug, selectedPostlaCode
        }
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/business/detail`,
            data: data
        });
    },
    shareBusiness: async (data) => {
        const token = Token.getToken();
        data.from_user_id = Token.getUserID();

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/share-business`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    fetchBusinessUsers: async (business_slug) => {
        const token = Token.getToken();

        var data = { "user_id": Token.getUserID() };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-business-users/${business_slug}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    onSearchEmail: async (text) => {

        const token = Token.getToken();

        const data = {
            user_id: Token.getUserID(),
            text: text
        }

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/onsearch-email`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    removeBusinessUser: async (data) => {

        const token = Token.getToken();
        data.from_user_id = Token.getUserID();
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/remove-business-user`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    addAvailablePostalCode: async (data) => {
        const token = Token.getToken();
        data.from_user_id = Token.getUserID();
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/add-available-postalcode`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    changeBusinessEmailStatus: async (data) => {
        const token = Token.getToken();
        data.from_user_id = Token.getUserID();
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/change-business-email-status`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    }
}