import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const ProductAPI = {
    getProducts: async (business_slug) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), business_slug: business_slug };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-products`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    getProductsPagination: async (pageNumber,business_slug) => {
        const token = Token.getToken();
        var data = {page:pageNumber,limit:10, user_id: Token.getUserID(), business_slug: business_slug };

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-products`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },               
            data: data

        });
    },
    getProductDetail: async (product_id) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), product_id: product_id };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/product/detail`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    saveProduct: async (data) => {
        const token = Token.getToken();

        data.user_id = Token.getUserID();

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/save-product`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    updateProduct: async (data) => {
        const token = Token.getToken();
        data.user_id = Token.getUserID();

        return axios({
            method: 'put',
            url: `${config.API_BASE_URL}/api/user/update-product`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    deleteProduct: async (product_id) => {
        const token = Token.getToken();
        const data = {
            product_id: product_id
        }

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/delete-product`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },

    checkAvailableProductQuantity: async (carts) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), carts: carts };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/check-available-product-quantity`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
}