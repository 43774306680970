import React from 'react'

function WHO_WE_ARE() {
    return (
        <div className='container mb-5 text-justify' style={{ marginTop: '76px' }}>
            <div className='d-flex justify-content-center pt-5' >
                <h2 className=''>Who we are?</h2>
            </div>
            <div className='my-4'>
                <ul className='pl-3'>
                    <li><p> Para, founders found the need for taking the local home food suppliers digital, when one of its founders was struggling to get home food while on business trips.</p></li>
                    <li><p> Para, is bringing your Trusted Local Food Suppliers Online and empowering them to deliver the neighbourhood shopping experience of the future. </p></li>
                    <li><p> Online Ordering, Fastest delivery, Exciting Offers & Savings, and much more. Order your food cravings for the day in one place – north indian, south indian, cakes, pastries, famous Bengali sweets, Bangladeshi foods, gujarati thalis, confectionaries and a lot more! Save the Para link and Order anytime. </p></li>
                    <li><p> Our mission is to digitise and organise UKs 1M + home food suppliers, small restaurants & joints and to eventually power the offline retail network in the world, to deliver the neighbourhood shopping experience of the future. </p></li>
                    <li><p> Para is a full suite PaaS solution enabling local restaurants & home food suppliers to go online, digitise their consumer relationships, access business data and insights, and manage their business operations more effectively. </p></li>
                    <li><p> Focused on the home food and local food restaurants – consumers now have their entire neighbourhood in the palm of their hand. They can discover their local restaurants, cafes, food joints, sweet & confectionary shops & home food suppliers and their offers online – order online by creating a single shopping list across all suppliers and get feeling of local food shopping.</p></li>
                    <li><p> We offer users the convenience of reaching the right home food suppliers to their home - be it fresh cake & pasties, continental food, gourmet food, indian foods or thalis, or an pan-asian food etc…, from a single virtual store.</p></li>
                </ul>
            </div>

            <div className='my-4'>
                <h3>Here’s why it’s so important to keep up with your Listings with PARA</h3>
                <ul className='pl-3'>
                    <li>
                        <h5>Potential Customers Don’t Know Who You Are</h5>
                        <p>Consumers in the UK need a way to discover your business without knowing its name. Getting listed allows potential customers to discover you when searching for the services, categories, and amenities you provide.</p>
                    </li>
                    <li>
                        <h5>First Encounters Matter</h5>
                        <p>Your business listing could be your first encounter with a customer, before they even make it to your website or social pages. Studies show that in this digital era people don’t just disengage with a business that has inaccurate or incomplete information; they lose trust in that business who have little or no digital imprint.</p>
                    </li>
                    <li>
                        <h5>Free Listings with eCommerce & Doing Promotion Matters</h5>
                        <p>Many business directories today allow you to only list your business freely, Para is the only Platform which provides it to you as a service with capability to promote your Listing, build your audience, engage with users, and transact with users.</p>
                        <p>It matters because if you don’t have contact information in your audience’s go-to resources, how will they know you exist? If your contact information is wrong, how will you build trust? If you don’t know you have a listing, how can you manage your reputation? In today’s digital landscape, it is more important than ever to create a digital imprint and monitor them and Para Platform exactly does that for you.</p>
                    </li>
                </ul>
            </div>

        </div>
    )
}

export default WHO_WE_ARE
