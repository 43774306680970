import { Input } from 'antd';
import moment from 'moment';
import { useEffect, useReducer, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';
import '../../../../src/assets/css/AdminPanel/adminPanel.css';
import { PromotionAPI } from '../../../apis/admin/PromotionAPI';
import AlertTriangle from '../../../assets/images/modalPopup/alert-triangle.svg';
import CheckmarkCircle from '../../../assets/images/modalPopup/checkmark-circle.svg';
import { DashboardLayout } from '../Layout/Layout';
import InputBase from '@material-ui/core/InputBase';

const Promotions = () => {


    const { TextArea } = Input;
    const [loading, setLoader] = useState(false);
    const [search, setSearch] = useState('')

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            promotions: [],
            coupon_id: '',
            coupon_type: '',
            coupon_code: '',
            cart_total_grater_amount: '',
            discount: '',
            limit: '',
            start_date: '',
            end_date: '',
            showRejection: false,
            showPrmotionMOdel: false,
            rejected_feedback: '',
            on_status: ''
        }
    );

    useEffect(() => {
        getCoupons(search);
    }, []);

    const onSearch = async (search) => {
        getCoupons(search)
        setSearch(search)
    }
    
    const getCoupons = (search) => {
        PromotionAPI.getCoupons(search)
            .then(res => {
                setState({ promotions: res.data.coupons })
                setLoader(false);
            }).catch(error => {
                setLoader(false);
            })
    }

    const onChangePromotion = (status, coupon_id) => {
        if (status == 'Rejected') {
            setState({
                showRejection: true,
                on_status: status,
                coupon_id: coupon_id
            });
        } else {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                imageUrl: AlertTriangle,
                showCancelButton: true,
                confirmButtonColor: '#00CA84',
                cancelButtonColor: '#FF5858',
                confirmButtonText: 'Yes, approve it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoader(true)
                    let data = {
                        coupon_id: coupon_id,
                        coupon_status: status
                    }

                    PromotionAPI.changeCouponStatus(data)
                        .then(function (res) {
                            getCoupons(search);
                            setLoader(false)
                            Swal.fire({
                                text: res.data.message,
                                imageUrl: CheckmarkCircle,
                                imageAlt: 'success image',
                                confirmButtonColor: '#00CA84'
                            });
                        }).catch(function (error) {
                            setLoader(false)
                            console.log(error);
                        });
                }
            });
        }
    }

    const onSaveRejectedFeedback = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            imageUrl: AlertTriangle,
            showCancelButton: true,
            confirmButtonColor: '#00CA84',
            cancelButtonColor: '#FF5858',
            confirmButtonText: 'Yes, reject it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoader(true)
                let data = {
                    coupon_id: state.coupon_id,
                    coupon_status: state.on_status,
                    rejected_feedback: state.rejected_feedback
                }
                PromotionAPI.changeCouponStatus(data)
                    .then(function (res) {
                        setLoader(false)
                        getCoupons(search);
                        setState({ showRejection: false });
                        Swal.fire({
                            text: res.data.message,
                            imageUrl: CheckmarkCircle,
                            imageAlt: 'success image',
                            confirmButtonColor: '#00CA84'
                        });
                    }).catch(function (error) {
                        setLoader(false)
                        console.log(error);
                    });
            }
        });
        setState({ showRejection: false })
    }


    return (
        <DashboardLayout page='promotions'>
            <div className="w-100 ">
                <div className="sidebar-content">
                    <h3 className="mb-3">Promotions</h3>
                    {/* <div className='border rounded-8'>
                        <div className='row'>
                            <div className="col-12 col-sm-12 align-self-center" style={{ borderRight: '1px solid #dee2e6' }}>
                                <div className='p-2'>
                                    <div className='d-flex w-100 h-100' >
                                        <InputBase className='w-100' placeholder="Search Applications" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="paraTabs">
                        <div className="table-responsive paraRounded-table mt-4">
                        <div className="d-flex mb-3">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control product-search"
                                        placeholder="Search code and type"
                                        value={search}
                                        onChange={(e) => onSearch(e.target.value)}
                                    />
                                </div>
                            </div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="fw-500">PROMO CODE</th>
                                        <th scope="col" className="fw-500">PROMO TYPE</th>
                                        <th scope="col" className="fw-500">BUSINESS</th>
                                        <th scope="col" className="fw-500">DISCOUNT</th>
                                        <th className="fw-500">START</th>
                                        <th className="fw-500">END</th>
                                        <th scope="col" className="fw-500 text-right">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        state.promotions.map((promotion, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td className="align-middle"><p className="mb-0">{promotion.coupon_code}</p></td>
                                                    <td className="align-middle"><p className="mb-0">{promotion.coupon_type}</p></td>
                                                    <td className="align-middle">
                                                        <div className="d-flex">
                                                            <img src={promotion.business.company_logo} alt="" className="mr-2" width="32" height="32" />
                                                            <div><p className="mb-0 pl-1 align-self-center ">{promotion.business.business_name}</p> <br />
                                                            {
                                                                promotion.coupon_type == 'Product Promotion' &&
                                                                <>
                                                                    {
                                                                        promotion.products.map((product, i) => {
                                                                            return (
                                                                                <p key={i}>{`#${++i}) ${product.product_name}`} </p>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            }</div>
                                                            
                                                        </div>
                                                    </td>
                                                    <td className="align-middle">
                                                        {/* <p className="mb-0">15% upto £10</p> */}
                                                        {
                                                            promotion.coupon_type == 'Product Promotion' ?
                                                                <>
                                                                    {
                                                                        promotion.product_discounts.map((pd, i) => {
                                                                            return (
                                                                                <p className="mb-0" key={i}>{promotion.discount_type == 'Cash' ? '£' : '%'}{pd.discount_amount}</p>
                                                                            )
                                                                        })
                                                                    }
                                                                </> :
                                                                <p className="mb-0">{promotion.discount_type == 'Cash' ? '£' : '%'}{promotion.discount}</p>
                                                        }
                                                    </td>
                                                    <td className="align-middle">
                                                        <p className="mb-0">
                                                            {
                                                                promotion.coupon_type != 'Product Promotion' &&
                                                                moment(promotion.start_date).format('DD-MM-YYYY')
                                                            }
                                                        </p>
                                                    </td>
                                                    <td className="align-middle">
                                                        <p className="mb-0">
                                                            {
                                                                promotion.coupon_type != 'Product Promotion' &&
                                                                moment(promotion.end_date).format('DD-MM-YYYY')
                                                            }
                                                        </p>
                                                    </td>
                                                    <td className="align-middle">
                                                        {
                                                            promotion.is_approved != 'Approved' && promotion.is_approved != 'Rejected' ?
                                                                <div className='d-flex justify-content-end'>
                                                                    <button className='btn btn-borderless-red mr-2'
                                                                        onClick={() => onChangePromotion('Rejected', promotion.id)}>
                                                                        Reject
                                                                    </button>
                                                                    <button className='btn btn-borderless-green'
                                                                        onClick={() => onChangePromotion('Approved', promotion.id)}>
                                                                        Approve
                                                                    </button>
                                                                </div> :
                                                                <div className='d-flex justify-content-end'>
                                                                    {
                                                                        promotion.is_approved == 'Rejected' ?
                                                                            <button className='btn btn-borderless-red mr-2'
                                                                                onClick={() => Swal.fire({
                                                                                    title: 'Reason for rejection',
                                                                                    text: promotion.rejected_feedback,
                                                                                    imageUrl: CheckmarkCircle,
                                                                                    imageAlt: 'success image',
                                                                                    confirmButtonColor: '#00CA84'
                                                                                })}>
                                                                                {promotion.is_approved}
                                                                            </button> :
                                                                            <button className='btn btn-borderless-green' disabled>
                                                                                {promotion.is_approved}
                                                                            </button>
                                                                    }
                                                                </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* ===== Modal: Reason for rejection ========= */}
                    <Modal centered show={state.showRejection} onHide={() => setState({ showRejection: false })}>
                        <Modal.Header className='d-flex justify-content-center'>
                            <Modal.Title>Reason for rejection</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <TextArea className='form-control' rows={4} placeholder="Enter Comment"
                                    onChange={(e) => setState({ rejected_feedback: e.target.value })} />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btnPara-primary'
                                onClick={onSaveRejectedFeedback}>
                                Send Feedback
                            </button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </DashboardLayout >
    )

}

export default Promotions
