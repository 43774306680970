import React, { useEffect, useState, Fragment } from 'react';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import LocationOnSharpIcon from '@material-ui/icons/LocationOnSharp';
import StarIcon from '@material-ui/icons/Star';
import { BusinessAPI } from '../../apis/user/BusinessAPI';
import { ClockCircleOutlined, PhoneFilled, EyeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import moment from 'moment';

export default function FavouriteStores() {

    const [stores, setStores] = useState([]);

    useEffect(() => {
        BusinessAPI.fetchFavouriteBusinesses()
            .then(res => {
                setStores(res.data.businesses)
            });

    }, [])
    return (
        <div className="profile-info">
            <div className="media my-3 p-3">
                <div className="media-body mb-0 small">
                    <div className="row">
                        {
                            stores.length == 0 &&
                            <div className="media my-3 bg-white p-3 rounded shadow-sm w-100">
                                <div className="media-body mb-0 small">
                                    <div className="my-3">
                                        <h5 className='text-center ' style={{ flex: 'auto' }}>Business not added yet</h5>
                                    </div>
                                </div>
                            </div>

                        }

                        {
                            stores.map(store => {
                                let ratingCount = 0;
                                const weekday = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
                                const d = new Date();
                                let today = weekday[d.getDay()];
                                var currentTime = moment().format("HH:mm:ss");
                                var fromTime = moment(store[today][1], ["H:mma"]).format("HH:mm:ss");
                                var toTime = moment(store[today][2], ["H:mma"]).format("HH:mm:ss");

                                let todayActiveTime = (currentTime > fromTime && currentTime < toTime) ? true : false;
                                let shopDayStatus = store[today][0];

                                return (
                                    <div key={Math.random()} className="col-xl-4 col-lg-4 col-md-4 pr-0">
                                        <div className="card flashSale-card">
                                            <div className="card-body p-0">
                                                {/* <div className="favouriteStores-imgBox" style={{ backgroundImage: `${store.company_logo}` }}></div> */}
                                                <Link to={{ pathname: `/businesses/${store.business_slug}` }} className="mb-1">
                                                    <div className="text-center mb-2">
                                                        <img className="flashSale-img" src={store.company_logo} alt="Card " />
                                                    </div>
                                                    <h4 className="mb-1 px-2 text-capitalize">{store.business_name}</h4>
                                                    <p className='discription px-2 text-justify discription'>{store.short_description}</p>
                                                    <div className="d-flex location-map px-1 py-2">
                                                        <p className="shop-location two-line-truncate">
                                                            <LocationOnSharpIcon className="shop-locationIcon" />{store.address}
                                                        </p>
                                                    </div>

                                                    <div className='d-flex ratignRangecontent px-2 py-1 justify-content-between'>

                                                        {/* <div className={'shop-ratingBox'}>
                                                            <div className="d-flex shop-rating">
                                                                <StarIcon />
                                                                <p className="mb-0">5</p>
                                                            </div>
                                                        </div> */}

                                                        {/* {

                                                            store.ratingreviews.length > 0 &&
                                                            <Fragment>
                                                                {
                                                                    store.ratingreviews.map((review) => {
                                                                        ratingCount = parseInt(ratingCount) + parseInt(review.rating);
                                                                    })
                                                                }
                                                                <div className={(ratingCount / store.ratingreviews.length) <= 3 ? 'shop-ratingBox ratingBox-red' : 'shop-ratingBox'}>
                                                                    <div className="d-flex shop-rating">
                                                                        <StarIcon />
                                                                        <p className="mb-0">{ratingCount / store.ratingreviews.length}</p>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        } */}
                                                        <div className='d-flex align-items-center discount-offBox ml-auto'>
                                                            <small className='fw-500 text-danger d-flex align-items-baseline'>
                                                                <ClockCircleOutlined className='pr-1 ClockCircleOutlined' />
                                                                {/* Open */}
                                                                {shopDayStatus == 'open' && todayActiveTime == true ? 'Open' : `Closed-Opens at ${store[today][1]}`}
                                                            </small>
                                                        </div>

                                                    </div>
                                                </Link>

                                                <div className='d-flex flashSale-card-viewmenu mt-1' >
                                                    <div className='col-xl-6 col-md-6 col-sm-6 p-0 border-end'>
                                                        <p className='text-dark border mb-0 p-1 d-flex align-items-center justify-content-center' style={{ fontSize: "13px" }}>
                                                            <PhoneFilled className='p-2' style={{ fontSize: '13px' }} />
                                                            <a href={`tel:${store.mobileNumber}`}
                                                                // onClick={() => setShowMobileNumber(true)}
                                                                className="text-dark">Call</a>
                                                            {/* { setShowMobileNumber && <p href="tel:+4733378901" className="show-number">{item.mobileNumber}</p>} */}

                                                        </p>
                                                    </div>
                                                    <div className='col-xl-6 col-md-6 col-sm-6 p-0'>
                                                        <p className='text-dark border mb-0 p-1 d-flex align-items-center justify-content-center' style={{ fontSize: "13px" }}>
                                                            <EyeOutlined className='p-2' style={{ fontSize: "13px", }} />
                                                            <Link to={{ pathname: `/businesses/${store.business_slug}` }} className="grind-view-detial text-dark " >View Detail</Link>
                                                        </p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
