import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const OrderAPI = {
    orderPlace: async (data) => {
        const token = Token.getToken();
        data.user_id = Token.getUserID();

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/order-place`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    fetchCustomerAllOrders: async () => {

        const token = Token.getToken();
        let data = {
            user_id: Token.getUserID()
        }

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-all-cutomer-order`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    PaginationCustomerAllOrders: async (pageNumber) => {

        const token = Token.getToken();
        var data = {page:pageNumber,limit:10, user_id: Token.getUserID()};

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-all-cutomer-order`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    PaginationShopAllOrders: async (pageNumber,business_slug) => {

        const token = Token.getToken();
        var data = {page:pageNumber,limit:10, user_id: Token.getUserID(), business_slug: business_slug };


        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-all-shop-order`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    fetchShopAllOrders: async (business_slug) => {

        const token = Token.getToken();
        let data = {
            user_id: Token.getUserID(),
            business_slug: business_slug
        }

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-all-shop-order`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    fetchOrder: async (order_id) => {

        const token = Token.getToken();
        let data = {
            order_id: order_id,
            user_id: Token.getUserID()
        }

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-order`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    changeOrderStatus: async (order_status, order_id) => {

        const token = Token.getToken();
        let data = {
            order_id: order_id,
            order_status: order_status,
            user_id: Token.getUserID()
        }

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/change-order-status`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    changePaymentStatus: async (payment_status, order_id) => {

        const token = Token.getToken();
        let data = {
            order_id: order_id,
            payment_status: payment_status,
            user_id: Token.getUserID()
        }

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/change-pyament-status`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
}