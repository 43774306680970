import { Button, Checkbox, Form, Input, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { DashboardLayout } from '../Sidebar wrapper/Layout';
import { RoleAPI } from '../../../apis/user/RoleAPI';
import { useParams, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../../assets/images/modalPopup/close-circle.svg';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BusinessAPI } from '../../../apis/user/BusinessAPI';

const AddRoles = () => {

    const params = useParams()
    const business_slug = params.business_id
    const [loading, setLoading] = useState(false);
    const history = useHistory()

    const [productsPermission, setProductsPermission] = useState([]);
    const [categoriesPermission, setCategoriesPermission] = useState([]);
    const [manageOrdersPermission, setManageOrdersPermission] = useState([]);
    const [customersPermission, setCustomersPermission] = useState([]);
    const [promotionsPermission, setPromotionsPermission] = useState([]);
    const [userSettingsPermission, setUserSettingsPermission] = useState([]);
    const [businessSettingsPermission, setBusinessSettingsPermission] = useState([]);
    const [deliverySettingsPermission, setDeliverySettingsPermission] = useState([]);
    const [documentsPermission, setDocumentsPermission] = useState([]);
    const [messagesPermission, setMessagesPermission] = useState([]);
    const [businessCategory, setBusinessCategory] = useState('Service')

    useEffect(async () => {
        await BusinessAPI.fetchBusiness(business_slug)
            .then((response) => {
                setBusinessCategory(response.data.business.category)
            }).catch((error) => {
                console.log(error);
            });
    }, [])

    const manageRolesData = [
        {
            module_name: "MyBusiness",
            child: [
                {
                    module_name: "Products",
                    child: [],
                    is_manage: false,
                    is_created: true,
                    is_edit: true,
                    is_delete: true,
                    list: true
                },
                {
                    module_name: "Categories",
                    child: [],
                    is_manage: false,
                    is_created: true,
                    is_edit: false,
                    is_delete: false,
                    list: true
                },
                {
                    module_name: "Manage Orders",
                    child: [],
                    is_manage: true,
                    is_created: false,
                    is_edit: false,
                    is_delete: false,
                    list: false
                },
                {
                    module_name: "Customers",
                    child: [],
                    is_manage: false,
                    is_created: false,
                    is_edit: false,
                    is_delete: false,
                    list: true
                },
            ]
        },
        {
            module_name: "Marketing",
            child: [
                {
                    // module_name: "Flash Sale",
                    module_name: "Promotions",
                    child: [],
                    is_manage: false,
                    is_created: true,
                    is_edit: true,
                    is_delete: true,
                    list: true
                },
                // {
                //     module_name: "Coupon Promotions",
                //     child: [],
                //     is_manage: false,
                //     is_created: true,
                //     is_edit: true,
                //     is_delete: true,
                //     list: true
                // },
                // {
                //     module_name: "Product Promotions",
                //     child: [],
                //     is_manage: false,
                //     is_created: true,
                //     is_edit: true,
                //     is_delete: true,
                //     list: true
                // },
            ],

        },
        {
            module_name: "Settings",
            child: [
                // {
                //     module_name: "User Settings",
                //     child: [],
                //     is_manage: true,
                //     is_created: false,
                //     is_edit: false,
                //     is_delete: false,
                //     list: false
                // },
                {
                    module_name: "Business Settings",
                    child: [],
                    is_manage: true,
                    is_created: false,
                    is_edit: false,
                    is_delete: false,
                    list: false
                },
                {
                    module_name: "Delivery Settings",
                    child: [],
                    is_manage: true,
                    is_created: false,
                    is_edit: false,
                    is_delete: false,
                    list: false
                },
                {
                    module_name: "Documents",
                    child: [],
                    is_manage: true,
                    is_edit: false,
                    is_created: false,
                    is_edit: false,
                    is_delete: false,
                    list: false

                },
            ]
        },
        {
            module_name: "Messages",
            child: [],
            is_manage: true,
            is_created: false,
            is_edit: false,
            is_delete: false,
            list: false,

        }
    ]

    const handleSelection = (select, module_name) => {
        switch (module_name) {
            case 'Products':
                setProductsPermission(select)
                break;
            case 'Categories':
                setCategoriesPermission(select)
                break;
            case 'Customers':
                setCustomersPermission(select)
                break;
            case 'Manage Orders':
                setManageOrdersPermission(select)
                break;
            case 'Business Settings':
                setBusinessSettingsPermission(select)
                break;
            case 'User Settings':
                setUserSettingsPermission(select)
                break;
            case 'Delivery Settings':
                setDeliverySettingsPermission(select)
                break;
            case 'Documents':
                setDocumentsPermission(select)
                break;
            case 'Promotions':
                setPromotionsPermission(select)
                break;
            case 'Messages':
                setMessagesPermission(select)
                break;
            default:
                break;
        }

    }

    const onFinish = (values) => {
        setLoading(true)
        const data = {
            role_name: values.role_name,
            business_slug: business_slug,
            modules: {
                productsPermission,
                categoriesPermission,
                manageOrdersPermission,
                customersPermission,
                userSettingsPermission,
                businessSettingsPermission,
                deliverySettingsPermission,
                documentsPermission,
                promotionsPermission,
                messagesPermission
            }
        }

        RoleAPI.saveRole(data)
            .then(res => {
                setLoading(false)
                history.push(`/seller/business/${business_slug}/roles-list`);
                Swal.fire({
                    text: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                });
            }).catch(error => {
                setLoading(false)
                Swal.fire({
                    text: error.response.data.message,
                    imageUrl: CloseCircle,
                    imageAlt: 'error image',
                    confirmButtonColor: '#00CA84'
                });
            })

        console.log('Success:', data);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <DashboardLayout page='Add-Roles'>
            <div className="w-100 sidebar-right-content-spacing mt-5">

                <div className="sidebar-content">
                    <div className="d-flex justify-content-between">
                        <h4 className="mb-3">Add Role </h4>
                        <button className="btn btn-sm btn-outline-green mb-3 text-secondary"
                            onClick={() => history.goBack()}><ArrowBackIcon />Back</button>
                    </div>
                    <Form
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <label>Role Name</label>
                        <Form.Item
                            name="role_name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your role name!',
                                },
                            ]}
                        >
                            <Input placeholder='Name' />
                        </Form.Item>

                        {
                            manageRolesData.map((data) => {
                                if (data.child?.length > 0) {
                                    return <div>
                                        <h5>{data.module_name}</h5>
                                        {
                                            data.child.map((child) => {
                                                return <>
                                                    <label>{child.module_name}</label>
                                                    <Form.Item
                                                        className="form-item"
                                                        name={`${child.module_name.split(" ").join("").toLowerCase()}`}
                                                    >
                                                        <Checkbox.Group style={{ width: '100%', }} className="d-flex ManageRoles" onChange={(e) => handleSelection(e, child.module_name)}>
                                                            {child.is_manage && <><Checkbox value="Manage">Manage</Checkbox><br /></>}
                                                            {child.list && <> <Checkbox value="List">List</Checkbox><br /></>}
                                                            {child.is_created && <><Checkbox value="Create">Create</Checkbox> <br /></>}
                                                            {child.is_edit && <><Checkbox value="Edit">Edit</Checkbox> <br /></>}
                                                            {child.is_delete && <> <Checkbox value="Delete">Delete</Checkbox> <br /></>}
                                                        </Checkbox.Group>
                                                    </Form.Item>
                                                </>
                                            })
                                        }
                                    </div>
                                }

                                if (data.child?.length == 0) {
                                    return <div>
                                        <h5>{data.module_name}</h5>
                                        <Form.Item
                                            className="form-item"
                                            name={`${data.module_name.split(" ").join("").toLowerCase()}`}
                                        >
                                            <Checkbox.Group style={{ width: '100%', }} className="d-flex ManageRoles" onChange={(e) => handleSelection(e, data.module_name)}>
                                                {data.is_manage && <><Checkbox value="Manage">Manage</Checkbox><br /></>}
                                                {data.list && <> <Checkbox value="List">List</Checkbox><br /></>}
                                                {data.is_created && <><Checkbox value="Create">Create</Checkbox> <br /></>}
                                                {data.is_edit && <><Checkbox value="Edit">Edit</Checkbox> <br /></>}
                                                {data.is_delete && <> <Checkbox value="Delete">Delete</Checkbox> <br /></>}
                                            </Checkbox.Group>
                                        </Form.Item>
                                    </div>
                                }
                            })
                        }

                        <Form.Item
                            className="form-item d-flex justify-content-end"
                        >
                            <Spin spinning={loading} delay={500}>
                                <Button
                                    className="btn btnPara-primary" htmlType='submit'>
                                    Save
                                </Button>
                            </Spin>
                        </Form.Item>
                    </Form>
                </div>
            </div >
        </DashboardLayout >
    );
};

export default AddRoles;