import React, { useRef } from 'react'
import '../styles/css/SellOnPara/knowbusiness.css'
import lang from '../../language/en.json';

const KnowBusiness = (props) => {
    const logoRef = useRef()

    const imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                props.setState({ company_logo: reader.result, company_logo_error_msg: '' })
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    return (
        <div className='my-2'>
            <div className="text-center">
                <h4 className="mb-0">Getting to know your business.</h4>
                <p>Information about your business so we can help you maximise your conversions.</p>
            </div>
            <div style={{ border: '1px #E6E6E6 solid', backgroundColor: '#E6E6E6' }}></div>
            <div className='my-2'>
                <div className="form-group">
                    <label className='label' htmlFor="companylogo">Company logo</label>
                    <div className='d-flex'>
                        <div>
                            <img className='' width='104px' height='104x' src={props.state.company_logo != '' ? props.state.company_logo : 'https://para-assets.s3.eu-west-1.amazonaws.com/upload.svg'} style={{ borderRadius: '52px' }} alt="upload" />
                            <input style={{ display: 'none' }} type='file' ref={logoRef}
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={imageHandler} />
                        </div>
                        <button className='btn btn-uploadLogo btn-md align-self-center' onClick={() => logoRef.current.click()}>Upload Logo</button>
                    </div>
                    {props.state.company_logo_error_msg !== "" && <div className="text-danger"> {props.state.company_logo_error_msg} </div>}
                </div>
                <div className="form-group">
                    <label className='label' htmlFor="businessname">Business Name</label>
                    <input type="text" className="form-control text-capitalize" name='business_name' autoComplete='off'
                        value={props.state.business_name} onChange={(e) => {
                            props.setState({ business_name: e.target.value })
                            if (e.target.value != '') {
                                props.setState({ business_name_error_msg: '' });
                            } else {
                                props.setState({ business_name_error_msg: lang.business_name_require_error_msg });
                            }
                        }}
                        placeholder="Enter your Business Name" />
                    {props.state.business_name_error_msg !== "" && <div className="text-danger"> {props.state.business_name_error_msg} </div>}

                </div>
                <div className="form-group">
                    <label className='label' htmlFor="businessname">Category of Business</label>
                    <select defaultValue="Food" className='form-control' value={props.state.business_category}
                        onChange={(e) => props.setState({ business_category: e.target.value })}>
                        {
                            props.state.business_categories.map((cat, i) => {
                                return (<option key={i} value={cat.category_name}>{cat.category_name}</option>)
                            })
                        }
                    </select>
                </div>
                <div className="form-group">
                    <label className='label' htmlFor="businessdescription">Describe your business</label>
                    <textarea name="business_description" value={props.state.business_description}
                        type="password" className="form-control " id="exampleInputPassword1"
                        placeholder="Briefly describe your business" style={{ height: '80px' }}
                        onChange={(e) => {
                            props.setState({ business_description: e.target.value })
                            if (e.target.value != '') {
                                props.setState({ business_description_error_msg: '' })
                            } else {
                                props.setState({ business_description_error_msg: lang.business_description_require_error_msg });
                            }
                        }} />
                    {props.state.business_description_error_msg !== "" && <div className="text-danger"> {props.state.business_description_error_msg} </div>}
                </div>
            </div>

        </div>
    )
}


export default KnowBusiness