import React, { useEffect, useState } from 'react';
import { Spin, notification, Pagination } from 'antd'
import { DashboardLayout } from '../Sidebar wrapper/Layout'
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { RoleAPI } from '../../../apis/user/RoleAPI';
import { useParams, Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../../assets/images/modalPopup/close-circle.svg';
import AlertTriangle from '../../../assets/images/modalPopup/alert-triangle.svg';
import moment from 'moment';

const RoleList = () => {
    const params = useParams()
    const business_slug = params.business_id
    const [loading, setLoading] = useState(false);

    const [roleList, setRoleList] = useState([])
    const history = useHistory()
    const [totalData, setTotalData] = useState(0)
    const [search, setSearch] = useState('')
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)

    useEffect(() => {
        loadData(search, 1)
    }, []);

    const handlePagination = (PageNumber) => {
        loadData(search, PageNumber)
        setPage(PageNumber)
    }

    const loadData = (search, page) => {
        setLoading(true)
        const data = {
            business_slug, search, limit, page
        }

        RoleAPI.fetchRoles(data)
            .then((res) => {
                setTotalData(res.data.count)
                setRoleList(res.data.data)
                setLoading(false)
            }).catch(function (error) {
                setLoading(false)
                console.log(error);
            });
    }

    const deleteRole = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            imageUrl: AlertTriangle,
            showCancelButton: true,
            confirmButtonColor: '#00CA84',
            cancelButtonColor: '#FF5858',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                RoleAPI.deleteRole(id)
                    .then(res => {
                        setLoading(false)
                        const newRoleList = roleList.filter((role) => role.id != id)
                        setRoleList(newRoleList)
                        Swal.fire({
                            title: res.data.message,
                            imageUrl: CheckmarkCircle,
                            imageAlt: 'success image',
                            confirmButtonColor: '#00CA84'
                        });
                    }).catch(function (error) {
                        setLoading(false)
                        Swal.fire({
                            title: error.response.data.message,
                            // title: "Somthing went wrong.",
                            imageUrl: CloseCircle,
                            imageAlt: 'error image',
                            confirmButtonColor: '#00CA84'
                        });
                    });
            }
        })
    }

    return (
        <DashboardLayout page='Role List'>
            <div className="w-100 " style={{ marginTop: "100px" }}>

                <div className="sidebar-content">

                    <div className="d-flex justify-content-between">
                        <h3 className="mb-3">Role List</h3>
                        <Link to={`/seller/business/${business_slug}/create-role`} className="btn btn-sm btn-outline-green mb-3 text-secondary">
                            Add New Role
                        </Link>
                    </div>
                    <div className="paraTabs">
                        <div className="table-responsive paraRounded-table mt-4">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="fw-500">Role Name</th>
                                        <th scope="col" className="fw-500">Created At</th>
                                        <th scope="col" className="fw-500">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {roleList.map((role, i) => {
                                        return <tr key={role.id}>
                                            <td className="align-middle"><p className="mb-0">{role.role_name}</p></td>
                                            <td className="align-middle"><p className="mb-0">{moment(role.createdAt).format('DD-MM-YYYY')}</p></td>
                                            <td className="align-middle">
                                                <Link to={`/seller/business/${business_slug}/edit-role/${role.id}`} className="btn btn-sm btn-outline-green ml-3">
                                                    <EditOutlinedIcon />
                                                </Link>
                                                <button className={`btn btn-sm btn-outline-red ml-3`}
                                                    onClick={() => deleteRole(role.id)}><DeleteOutlineOutlinedIcon /></button>
                                            </td>
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-5">
                        <Pagination defaultCurrent={1} onChange={handlePagination} total={totalData} />
                    </div>
                </div>

            </div>

        </DashboardLayout >
    );
};

export default RoleList;