import React, { useReducer, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import '../../styles/css/Header.css';
import Sitelogo from '../../../assets/images/home/siteLogo.svg';
import profileIcon from '../../../assets/images/profile/profileIcon.svg';
import { Button } from 'antd';
import { Token } from '../../../Token';
import { ProfileAPI } from '../../../apis/admin/ProfileAPI';

function AdminHeader() {
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            profilepic: "",
            full_name: ""
        }
    );

    useEffect(async () => {

        if (Token.isLoggedIn()) {
            await ProfileAPI.fetchProfile()
                .then(res => {
                    setState({
                        profilepic: res.data.profile_picture,
                        full_name: res.data.first_name + ' ' + res.data.last_name,
                    });
                }).catch(error => {
                    // logout();
                })
        }
    }, [])

    const logout = () => {
        Token.clearStorage();
        window.location.reload(false);
    }


    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                <div className="container-fluid">
                    <div className='d-flex'>
                        <Link className="navbar-brand " to="/admin/overview"> <img title="Para" width='40px' height='50px' src={Sitelogo} alt="site logo" /></Link>
                    </div>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse bg-light" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">

                            {
                                Token.isLoggedIn() && Token.getUserRole() == 'admin' &&
                                <>
                                    <div className="dropdown img content-to-hide admin-user-icon">
                                        <button id='logoutbutton' data-toggle="dropdown" >
                                            <frame style={{ width: '45px' }}>
                                                <img width='100%' height='100%'
                                                    src={state.profile_picture ? state.profile_picture : profileIcon}
                                                    alt="profile logo" title={state.full_name} />
                                            </frame>
                                        </button>
                                    </div>

                                    <li className="nav-item mx-2 my-1">
                                        <Button className='btn btnPara-darkness btn-rounded'>
                                            <span className="pi pi-sign-in" onClick={logout} >
                                                Logout
                                            </span>
                                        </Button>
                                    </li>
                                </>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default AdminHeader
