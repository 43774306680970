import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PersonOutlineRoundedIcon from '@material-ui/icons/PersonOutlineRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import ScheduleRoundedIcon from '@material-ui/icons/ScheduleRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import { useEffect, useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import LocationIcon from '../../assets/images/svg-icons/location-icon.svg';
import '../../Components/styles/css/Order_Checkout/checkout.css';
// import { Input, TimePicker, Calendar, Col, Row, Radio, Collapse, Space, Select } from 'antd';
import DeleteIcon from '@mui/icons-material/Delete';
import { Collapse, DatePicker, Select, Space, Spin } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { OrderAPI } from '../../apis/user/OrderAPI';
import { ProfileAPI } from '../../apis/user/ProfileAPI';
import CODcon from '../../assets/images/checkout/cod.jpg';
import StripeIcon from '../../assets/images/checkout/Stripe.svg';
import CloseCircle from '../../assets/images/modalPopup/close-circle.svg';
import { clearFromCart, getCarts, getCartShop, getDiscount, removeDiscount, manageCart, Token, getFlashSaleDiscount } from '../../Token';
import ManageAddresses from '../seller_profile/ManageAddresses';
import Checkout from "react-stripe-checkout"
import config from '../../config';
import { CheckoutAPI } from '../../apis/user/CheckoutAPI';
import { ProductAPI } from '../../apis/user/ProductAPI';
const { Panel } = Collapse;
const { Option } = Select;

function CHECKOUT(props) {

    let history = useHistory();
    const [loading, setLoader] = useState(false);
    const [SelectPayment, setSelectPayment] = useState('onCollection');
    const [selectedCustomerAddress, setSelectedCustomerAddress] = useState(true)
    const [isShowBusinessAddress, setIsShowBusinessAddressection] = useState(false)
    const [isShowCustomerAddress, setIsShowCustomerAddresSection] = useState(false)
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            businessName: '',
            businessAddress: '',
            selectedCustomerAddress: {},
            selectedDateTime: '',
            leaveANote: '',
            addresses: [],
            count: 0,
            DeliverType: "",
            totalDiscountPrice: 0,
            totalCheckoutPrice: 0,
            cartProducts: [],
            isShowSelectedCustomerAddress: false,
            isDoneDateTime: false,
            isSelectedBusinessAddress: false,
            isDoneLeaveNote: false,
            drawerVisible: false,
            finalAmount: 0,
            totalProductDiscount: 0,
            couponDiscount: getDiscount() == null ? 0 : getDiscount().discount,
            couponDiscountType: getDiscount() == null ? 'Cash' : getDiscount().discount_type,
            cartTotalGraterAmount: getDiscount() == null ? 'Cash' : getDiscount().cart_total_grater_amount,
            totalDeliveryCharge: 0,
            coupon_id: getDiscount() == null ? null : getDiscount().coupon_id,
            isAvailableForSlectedPostalCode: false,
            allProductQuantityAvailable: true,
            notAvailableProducts: [],
            isEnabledDelivery: false
        }
    );

    useEffect(() => {
        if (Token.isLoggedIn()) {
            getTotalCheckoutPrice();
            setLoader(true)
            ProfileAPI.fetchAddresses()
                .then(function (response) {
                    // setLoader(false)
                    setState({ addresses: response.data.addresses })
                }).catch(function (error) {
                    // setLoader(false)
                    console.log(error);
                });

            ProductAPI.checkAvailableProductQuantity({ carts: getCarts() })
                .then(function (response) {
                    setState({
                        allProductQuantityAvailable: response.data.is_available_quantity,
                        notAvailableProducts: response.data.notAvailableProducts
                    })

                    let productName = ''
                    response.data.notAvailableProducts.map((pr, i) => {
                        productName += `${++i}) ${pr.product_name}`
                    })

                    if (!response.data.is_available_quantity) {
                        Swal.fire({
                            title: 'Product Not Available',
                            text: productName,
                            imageUrl: CloseCircle,
                            imageAlt: 'error image',
                            confirmButtonColor: '#00CA84'
                        });

                    }
                }).catch(function (error) {
                    // setLoader(false)
                    console.log(error);
                });
        }
    }, [])

    useEffect(() => {
        const shopCart = localStorage.getItem('cart_shop')
        if (shopCart) {
            setLoader(true)
            const shop_cartsParse = JSON.parse(shopCart)
            const business_slug = (shop_cartsParse.business_slug)
            CheckoutAPI.getAvailablePostalCode(business_slug)
                .then(res => {
                    let checkEnableDelivery = res.data.business?.disAblePostalCode;
                    setState({
                        isEnabledDelivery: checkEnableDelivery,
                        DeliverType: res.data.business.delivery,
                        businessName: res.data.business.business_name,
                        businessAddress: `${res.data.business.address}, ${res.data.business.postal_code}`
                    })

                    if (checkEnableDelivery) {
                        setIsShowCustomerAddresSection(true)
                        setIsShowBusinessAddressection(true)
                    } else {
                        setIsShowCustomerAddresSection(false)
                        setIsShowBusinessAddressection(false)
                    }

                    setLoader(false)

                })

            if (state.selectedCustomerAddress.postal_code) {
                let postal_code = state.selectedCustomerAddress?.postal_code
                if (postal_code) {
                    postal_code = postal_code.split(" ")[0];
                }
                CheckoutAPI.getAvailablePostalCode(business_slug, postal_code)
                    .then(res => {

                        setState({
                            isAvailableForSlectedPostalCode: res.data?.isAvailableForSlectedPostalCode
                        })
                    })
            }
        }
    }, [state.selectedCustomerAddress.postal_code])

    const getTotalCheckoutPrice = (address_type = null) => {
        let total_product_price = 0;
        let total_product_discount_price = 0;
        let totalDeliveryCharge = 0;
        let pArr = [];
        for (var i = 0; i < Object.keys(getCarts()).length; i++) {
            var pPrice = getCarts()[Object.keys(getCarts())[i]].product_price;
            if (getCarts()[Object.keys(getCarts())[i]].product_discounted_price != 0) {
                let dPrice = pPrice - getCarts()[Object.keys(getCarts())[i]].product_discounted_price;
                total_product_discount_price = total_product_discount_price + (dPrice * parseInt(getCarts()[Object.keys(getCarts())[i]].quantity))
            }

            if (getCarts()[Object.keys(getCarts())[i]].instant_delivery_price != 0) {
                totalDeliveryCharge = parseFloat(totalDeliveryCharge) + parseFloat(getCarts()[Object.keys(getCarts())[i]].instant_delivery_price)
            }

            total_product_price = total_product_price + (pPrice * parseInt(getCarts()[Object.keys(getCarts())[i]].quantity));
            pArr[i] = getCarts()[Object.keys(getCarts())[i]];
        }

        if (state.cartTotalGraterAmount > total_product_price) {
            removeDiscount()
            setState({
                couponDiscount: 0,
                couponDiscountType: 'Cash',
                cartTotalGraterAmount: null
            })
            window.location.reload()
        }

        let totalCouponCodeAndProductDiscount = state.couponDiscountType == 'Cash' ?
            (parseFloat(state.couponDiscount) + parseFloat(total_product_discount_price)).toFixed(2) :
            (((parseFloat(total_product_price) / 100) * parseFloat(state.couponDiscount)) + parseFloat(total_product_discount_price)).toFixed(2)

        const flashSaleDiscount = getFlashSaleDiscount();
        let totalFlashDscount = 0
        if (flashSaleDiscount) {
            totalFlashDscount = flashSaleDiscount.discount_type == 'Cash' ? (parseFloat(flashSaleDiscount.discount)).toFixed(2) :
                ((parseFloat(total_product_price) / 100) * parseFloat(flashSaleDiscount.discount)).toFixed(2)
        }

        if ((address_type == 'business address' && state.isEnabledDelivery) || !state.isEnabledDelivery) {
            totalDeliveryCharge = 0
        }

        const totalDiscountPrice = parseFloat(parseFloat(totalCouponCodeAndProductDiscount) + parseFloat(totalFlashDscount)).toFixed(2)
        let finalAmount = parseFloat(parseFloat(total_product_price) - parseFloat(totalDiscountPrice)).toFixed(2)
        finalAmount = parseFloat(parseFloat(finalAmount) + parseFloat(totalDeliveryCharge)).toFixed(2)

        setState({
            finalAmount: finalAmount <= 0 ? 0 : finalAmount,
            totalDiscountPrice: totalDiscountPrice,
            totalCheckoutPrice: total_product_price,
            totalProductDiscount: parseFloat(total_product_discount_price).toFixed(2),
            totalDeliveryCharge: parseFloat(totalDeliveryCharge).toFixed(2),
            cartProducts: pArr
        })
    }

    const onSelectAddress = (address, type) => {
        if (type == 'customer') {

            if (state.isAvailableForSlectedPostalCode == false) {
                setState({
                    isShowSelectedCustomerAddress: true,
                    selectedCustomerAddress: address,
                    isSelectedBusinessAddress: false
                })
            } else {
                setState({
                    selectedCustomerAddress: address,
                    isShowSelectedCustomerAddress: true,
                    isSelectedBusinessAddress: false
                })
            }
            setTimeout(() => { getTotalCheckoutPrice("customer address") }, 200)
        } else {
            setState({
                isSelectedBusinessAddress: true,
                isShowSelectedCustomerAddress: false,
                isAvailableForSlectedPostalCode: false
            })
            setTimeout(() => { getTotalCheckoutPrice("business address") }, 200)
        }

    }


    function placeOrder(token, paymentMethod) {
        if ((!state.isShowSelectedCustomerAddress && !state.isSelectedBusinessAddress) && state.isEnabledDelivery) {
            setSelectedCustomerAddress(false)
            return
        } else if (state.isShowSelectedCustomerAddress && state.isAvailableForSlectedPostalCode == false) {
            return
        }

        if (SelectPayment == '') {
            setSelectPayment("error")
            return
        } else if ((SelectPayment == "onCollection" || SelectPayment == "Stripe")) {

            let stripeToken = token != null ? token.id : ''
            const data = {
                stripeToken: stripeToken,
                paymentMethod: paymentMethod,
                amount: parseFloat(state.totalCheckoutPrice).toFixed(2),
                totalDeliveryCharge: parseFloat(state.totalDeliveryCharge),
                discount: state.totalDiscountPrice,
                discount_type: 'Cash',
                finalAmount: state.finalAmount,
                address: (state.selectedCustomerAddress && !state.isSelectedBusinessAddress && state.isEnabledDelivery) ? state.selectedCustomerAddress : "",
                date_time: moment() || state.selectedDateTime,
                leaveANote: state.leaveANote,
                business_slug: getCartShop().business_slug,
                carts: getCarts(),
                coupon_id: state.coupon_id
            }

            setLoader(true)
            OrderAPI.orderPlace(data)
                .then(function (res) {
                    setLoader(false)
                    props.manageCartCount(Object.keys(getCarts()).length)
                    clearFromCart();
                    setTimeout(() => { history.push('/my-cart/checkout/order-summary/' + res.data.order.id); }, 200)
                }).catch(function (error) {
                    setLoader(false)
                    Swal.fire({
                        text: error.response.data.message,
                        imageUrl: CloseCircle,
                        imageAlt: 'error image',
                        confirmButtonColor: '#00CA84'
                    });
                });
        }
    }

    return (
        <div className='' style={{ marginTop: '76px' }}>
            <div className="black-box">
                <div className='container'>
                    <div className="text-white">
                        <h4 className="shop-name mb-0 text-white">Secure Checkout</h4>
                    </div>
                </div>
            </div>

            <div className='checkout-section'>
                <div className='container'>
                    <Spin spinning={loading} delay={500}>
                        <div className="row">
                            <div className="col-md-7">
                                <div>
                                    <Space className="w-100" direction="vertical">
                                        {
                                            (isShowBusinessAddress && state.isAvailableForSlectedPostalCode == false) &&
                                            <>
                                                {
                                                    state.isSelectedBusinessAddress == false ?
                                                        <Collapse bordered={false} collapsible="header"
                                                            defaultActiveKey={['1']}
                                                            expandIcon={({ isActive }) => <RoomOutlinedIcon />}
                                                            className={`site-collapse-custom-collapse ${selectedCustomerAddress ? '' : "redCollapse"} `}

                                                        >
                                                            <Panel header="Collect from" key="1"
                                                                className='site-collapse-custom-panel'
                                                                extra={selectedCustomerAddress ? (
                                                                    <CheckCircleOutlineRoundedIcon
                                                                        onClick={event => {
                                                                            event.stopPropagation();
                                                                        }}
                                                                    />
                                                                ) : (<CancelIcon />)}
                                                            >
                                                                <div className="card card-grey mb-3 delivery-addressBox">
                                                                    <div className="card-body ">
                                                                        <div className="d-flex mb-2 border rounded p-3">
                                                                            <div>
                                                                                <img src={LocationIcon} alt="Address" style={{ width: '14px' }} />
                                                                            </div>
                                                                            <div className="ml-3">
                                                                                <h6 className="mb-0">{state.businessName}</h6>
                                                                                <p>{state.businessAddress}</p>
                                                                                <button
                                                                                    className="btn btnPara-primary"
                                                                                    onClick={() => onSelectAddress(state.businessAddress, 'business')}
                                                                                >Select</button>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Panel>
                                                        </Collapse> :

                                                        <Collapse bordered={false} collapsible="header" ghost
                                                            defaultActiveKey={['1']}
                                                            expandIcon={({ isActive }) => <PersonOutlineRoundedIcon />}
                                                            className={`site-collapse-custom-collapse greenCollapse `}
                                                        >
                                                            <Panel header="Collect from" key="1"
                                                                className='site-collapse-custom-panel'
                                                                extra={(
                                                                    <CheckCircleOutlineRoundedIcon
                                                                        onClick={event => {
                                                                            event.stopPropagation();
                                                                        }}
                                                                    />
                                                                )}
                                                            >
                                                                <div className="d-flex mb-3">
                                                                    <div className="ml-3">
                                                                        <h6 className="mb-0">{state.businessName}</h6>
                                                                        <p>{state.businessAddress}</p>
                                                                        <button
                                                                            className="btn btn-sm btnPara-primary"
                                                                            onClick={() => setState({ isSelectedBusinessAddress: false })}
                                                                        >Change Select</button>
                                                                    </div>
                                                                </div>
                                                            </Panel>
                                                        </Collapse>
                                                }
                                            </>
                                        }

                                        {
                                            (isShowCustomerAddress && state.isSelectedBusinessAddress == false) &&
                                            <>
                                                {
                                                    state.isAvailableForSlectedPostalCode == false ?
                                                        <Collapse bordered={false} collapsible="header"
                                                            defaultActiveKey={['1']}
                                                            expandIcon={({ isActive }) => <RoomOutlinedIcon />}
                                                            className={`site-collapse-custom-collapse ${selectedCustomerAddress ? '' : "redCollapse"} `}
                                                        >
                                                            <Panel header="Deliver to" key="1"
                                                                className='site-collapse-custom-panel'
                                                                extra={selectedCustomerAddress ? (
                                                                    <CheckCircleOutlineRoundedIcon
                                                                        onClick={event => {
                                                                            event.stopPropagation();
                                                                        }}
                                                                    />
                                                                ) : (<CancelIcon />)}

                                                            >
                                                                <div className="card card-grey mb-3 delivery-addressBox">
                                                                    <div className="card-body ">
                                                                        <p className="text-danger">
                                                                            {(state.isShowSelectedCustomerAddress && state.isAvailableForSlectedPostalCode == false) && "We cannot deliver to your selected address !"}
                                                                        </p>

                                                                        {
                                                                            state.addresses.map((address, i) => {
                                                                                return (
                                                                                    <div key={i} className="d-flex mb-2 border rounded p-3">
                                                                                        <div>
                                                                                            <img src={LocationIcon} alt="Address" style={{ width: '14px' }} />
                                                                                        </div>
                                                                                        <div className="ml-3">
                                                                                            <h6 className="mb-0">{address.house_name}</h6>
                                                                                            <p>{`${address.address}, ${address.postal_code}`}</p>
                                                                                            <button
                                                                                                className="btn btnPara-primary"
                                                                                                onClick={() => onSelectAddress(address, 'customer')}
                                                                                            >Select</button>

                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }

                                                                    </div>
                                                                </div>
                                                                <div className="card card-grey add-delivery-addressBox">
                                                                    <div className="card-body">
                                                                        <div className="d-flex">
                                                                            <div>
                                                                                <AddRoundedIcon />
                                                                            </div>
                                                                            <div className="ml-3 align-self-center">
                                                                                <p className="mb-0"
                                                                                    role="button"
                                                                                    onClick={() => {
                                                                                        setState({ drawerVisible: true })
                                                                                    }}
                                                                                >
                                                                                    Add New Address
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Panel>
                                                        </Collapse> :

                                                        <Collapse bordered={false} collapsible="header" ghost
                                                            defaultActiveKey={['1']}
                                                            expandIcon={({ isActive }) => <PersonOutlineRoundedIcon />}
                                                            className={`site-collapse-custom-collapse greenCollapse `}
                                                        >
                                                            <Panel header="Deliver to" key="1"
                                                                className='site-collapse-custom-panel'
                                                                extra={(
                                                                    <CheckCircleOutlineRoundedIcon
                                                                        onClick={event => {
                                                                            event.stopPropagation();
                                                                        }}
                                                                    />
                                                                )}
                                                            >
                                                                <div className="d-flex mb-3">
                                                                    <div className="ml-3">
                                                                        <h6 className="mb-0">{state?.selectedCustomerAddress.house_name}</h6>
                                                                        <p>{`${state?.selectedCustomerAddress?.address}, ${state?.selectedCustomerAddress?.postal_code}`}</p>
                                                                        <button
                                                                            className="btn btn-sm btnPara-primary"
                                                                            onClick={() => setState({ selectedCustomerAddress: {}, isShowSelectedCustomerAddress: false, isAvailableForSlectedPostalCode: false })}
                                                                        >Change Selection</button>
                                                                    </div>
                                                                </div>
                                                            </Panel>
                                                        </Collapse>
                                                }
                                            </>
                                        }

                                        {
                                            state.isDoneLeaveNote == false ?

                                                <Collapse bordered={false} collapsible="header"
                                                    defaultActiveKey={['1']}
                                                    expandIcon={({ isActive }) => <EditOutlinedIcon />}
                                                    className="site-collapse-custom-collapse"
                                                >
                                                    <Panel header="Leave a note?" key="1"
                                                        className='site-collapse-custom-panel'
                                                        extra={(
                                                            <CheckCircleOutlineRoundedIcon
                                                                onClick={event => {
                                                                    event.stopPropagation();
                                                                }}
                                                            />
                                                        )}
                                                    >
                                                        <div className="my-3 Leave-note-box">
                                                            <div className="form-group">
                                                                <textarea className="form-control"
                                                                    rows="3" placeholder="Write message here"
                                                                    onChange={(e) => setState({ leaveANote: e.target.value })}
                                                                ></textarea>
                                                            </div>
                                                            <div className="form-group d-flex">
                                                                <button className="btn btnPara-primary mr-3" onClick={() => setState({ isDoneLeaveNote: true })}>Next</button>
                                                                <button className="btn btn-outline-primary" onClick={() => setState({ isDoneLeaveNote: true })}>Skip</button>
                                                            </div>
                                                        </div>
                                                    </Panel>
                                                </Collapse> :

                                                <Collapse bordered={false} collapsible="header"
                                                    defaultActiveKey={['1']}
                                                    expandIcon={({ isActive }) => <EditOutlinedIcon />}
                                                    className="site-collapse-custom-collapse greenCollapse"
                                                >
                                                    <Panel header="Leave a note?" key="1"
                                                        className='site-collapse-custom-panel'
                                                        extra={(
                                                            <CheckCircleOutlineRoundedIcon
                                                                onClick={event => {
                                                                    event.stopPropagation();
                                                                }}
                                                            />
                                                        )}
                                                    >
                                                        <div className="d-flex mb-3">
                                                            <div className="ml-3">
                                                                <h6 className="mb-0">Note: {state.leaveANote}</h6>
                                                                <p>Your vendor will confirm your pickup time or suggest an alternate time for your delivery.</p>
                                                            </div>
                                                        </div>
                                                    </Panel>
                                                </Collapse>
                                        }
                                        {/* Payments Method */}
                                        {/* <Collapse bordered={false} collapsible="header"
                                        defaultActiveKey={['1']}
                                        expandIcon={({ isActive }) => <CreditCardRoundedIcon />}
                                        className="site-collapse-custom-collapse"
                                    >
                                        <Panel header="Payments" key="0"
                                            className='site-collapse-custom-panel'
                                            extra={(
                                                <CheckCircleOutlineRoundedIcon
                                                    onClick={event => {
                                                        event.stopPropagation();
                                                    }}
                                                />
                                            )}
                                        >
                                        </Panel>
                                    </Collapse> */}

                                        <Collapse bordered={false} collapsible="header"
                                            defaultActiveKey='1'
                                            expandIcon={({ isActive }) => <CreditCardRoundedIcon />}
                                            className={`site-collapse-custom-collapse ${SelectPayment == "onCollection" || SelectPayment == "Stripe" ? 'greenCollapse' : ""} ${SelectPayment == "error" ? 'redCollapse' : ""}`}
                                        >
                                            <Panel header="Payment Method" key="1"
                                                className='site-collapse-custom-panel'
                                                extra={SelectPayment == "error" ? (<CancelIcon />) : (
                                                    <CheckCircleOutlineRoundedIcon
                                                        onClick={event => {
                                                            event.stopPropagation();
                                                        }}
                                                    />
                                                )}
                                            >
                                                <Collapse bordered={false}
                                                    defaultActiveKey='1'
                                                    className="site-collapse-custom-inner-collapse">
                                                    <Panel showArrow={false} header="Preferred Payment Methods" key="1" className='site-collapse-custom-inner-panel'>
                                                        {/* <div class="d-flex justify-content-between preferred-payment-methods-box">
                                                        <div className="d-flex justify-content-start">
                                                            <img src={payPalIcon} alt="" className='mr-3' />
                                                            <p className="align-self-center mb-0 mr-2 ft-size16">PayPal</p>
                                                        </div>
                                                        <div>
                                                            <button className="btn btnPara-primary">Pay £46</button>
                                                        </div>
                                                    </div> */}
                                                        {/* <div className={`d-flex cursor-pointer justify-content-between preferred-payment-methods-box  ${SelectPayment == "Stripe" && "border-success border"}`} onClick={() => setSelectPayment("Stripe")}>
                                                            <div className={`d-flex justify-content-start`} >
                                                                <img src={StripeIcon} alt="" className='mr-3' />
                                                                <p className="align-self-center mb-0 mr-2 ft-size16">Stripe</p>
                                                            </div> */}
                                                        {/* unnecessary code start <div>
                                                                {
                                                                    state.finalAmount > 0 ?

                                                                        <Checkout
                                                                            name={Token.getFullName()}
                                                                            description="Save Time With Checkout"
                                                                            // label="LINK ACCOUNT"
                                                                            panelLabel="Proceed Payment"
                                                                            email={Token.getEmail()}
                                                                            amount={
                                                                                state.couponDiscountType == 'Cash' ?
                                                                                    (parseFloat(state.totalCheckoutPrice) - (parseFloat(state.couponDiscount) + parseFloat(state.totalProductDiscount)) + parseFloat(state.totalDeliveryCharge)).toFixed(2) * 100 :
                                                                                    (parseFloat(state.totalCheckoutPrice) - (((parseFloat(state.totalCheckoutPrice) / 100) * parseFloat(state.couponDiscount)) + parseFloat(state.totalProductDiscount)) + parseFloat(state.totalDeliveryCharge)).toFixed(2) * 100
                                                                            }
                                                                            currency="GBP"
                                                                            stripeKey={config.publicStripeKey}
                                                                        >
                                                                            <button role="button" className="btn para-link-yellow fw-500"
                                                                                disabled={(state.isShowSelectedCustomerAddress && state.isDoneDateTime && state.isDoneLeaveNote) ? false : true}
                                                                            >
                                                                                PAY
                                                                            </button>
                                                                        </Checkout>:
                                                                        <button role="button" className="btn para-link-yellow fw-500"
                                                                            disabled={(state.isShowSelectedCustomerAddress && state.isDoneDateTime && state.isDoneLeaveNote) ? false : true}
                                                                            onClick={() => placeOrder(null, 'Stripe')}
                                                                        >
                                                                            Place Order
                                                                        </button>
                                                                }
                                                            </div> unnecessary code start */}
                                                        {/* </div> */}
                                                        <div className={`d-flex justify-content-between cursor-pointer preferred-payment-methods-box border-success border ${SelectPayment == "onCollection" && "border-success border"}`} onClick={() => setSelectPayment("onCollection")}>
                                                            <div className={`d-flex justify-content-start `} >
                                                                <img src={CODcon} alt="" className='mr-3' width="50px" height="50px" />
                                                                <p className="align-self-center mb-0 mr-2 ft-size16">Payment on Collection</p>
                                                            </div>
                                                            <div>
                                                                {/* <button role="button" className="btn para-link-yellow fw-500"
                                                                    disabled={(state.isShowSelectedCustomerAddress && state.isDoneDateTime && state.isDoneLeaveNote) ? false : true}
                                                                    onClick={() => placeOrder(null, 'Payment on Collection')}
                                                                >
                                                                    Place Order
                                                                </button> */}
                                                            </div>
                                                        </div>
                                                        {
                                                            SelectPayment == "error" ? <p className='text-danger'>Please Select a Payment</p> : ""
                                                        }

                                                    </Panel>
                                                </Collapse>

                                                {/* <Collapse bordered={false} defaultActiveKey="1" className="site-collapse-custom-inner-collapse">
                                                <Panel showArrow={false} header="Credit Card/Debit Card" key="0" className='site-collapse-custom-inner-panel'>
                                                    <div class="credit-debit-card-box">
                                                        <div className="d-flex">
                                                            <div>
                                                                <img src={DebitCardIcon} alt="Address" className='img-fluid' />
                                                            </div>
                                                            <div className="ml-3">
                                                                <h6 className="mb-0 ft-size16 fw-400">6522-XXXXXXXX-8298</h6>
                                                                <p className="mb-0 text-muted ft-size12">Debit card | Valid till 10/23</p>
                                                            </div>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <div className='d-flex justify-content-start'>
                                                                <div>
                                                                    <Input placeholder="CVV" className='mr-3' style={{ width: '92px' }} />
                                                                </div>
                                                                <button className="btn btnPara-primary">Pay £46</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='text-center mt-3'>
                                                        <button className="btn para-link-yellow fw-500">ADD NEW CARD</button>
                                                    </div>
                                                </Panel>
                                            </Collapse>
                                            <Collapse bordered={false} defaultActiveKey="1" className="site-collapse-custom-inner-collapse">
                                                <Panel showArrow={false} header="Wallets" key="0" className='site-collapse-custom-inner-panel'>
                                                    <div className="d-flex justify-content-between">
                                                        <p className="mb-0 text-muted ft-size16">Cash On Delivery</p>
                                                        <div class="d-flex">
                                                            <p className='mb-0 ft-size16 mr-2 redText'>Unavailable</p>
                                                            <InfoOutlinedIcon className='redText' />
                                                        </div>
                                                        // <div className="ml-3">
                                                        //     <p className="mb-0 ft-size16">Unavailable</p>
                                                        // </div>
                                                    </div>
                                                </Panel>
                                            </Collapse> */}
                                                {/* <div className='my-3'>
                                                <Radio className='ft-size16'>Pay with cash on pickup</Radio>
                                            </div> */}
                                            </Panel>
                                        </Collapse>

                                    </Space>
                                    <div>
                                        <div className="d-flex justify-content-end w-full">
                                            {
                                                SelectPayment == "Stripe" ?
                                                    <>
                                                        <div>
                                                            {
                                                                state.finalAmount > 0 ?

                                                                    <Checkout
                                                                        name={Token.getFullName()}
                                                                        description="Save Time With Checkout"
                                                                        // label="LINK ACCOUNT"
                                                                        panelLabel="Proceed Payment"
                                                                        email={Token.getEmail()}
                                                                        amount={parseFloat(state.finalAmount).toFixed(2) * 100}
                                                                        currency="GBP"
                                                                        stripeKey={config.publicStripeKey}
                                                                        token={(token) => placeOrder(token, 'Stripe')}
                                                                    >
                                                                        <button role="button" className="btn  btnPara-primary mr-3 fw-500"
                                                                            disabled={!state.allProductQuantityAvailable}
                                                                        >
                                                                            Place Order
                                                                        </button>
                                                                    </Checkout> :
                                                                    <button role="button" className="btn  btnPara-primary mr-3 fw-500"
                                                                        // disabled={(state.isShowSelectedCustomerAddress && state.isDoneDateTime && state.isDoneLeaveNote) ? false : true}
                                                                        disabled={!state.allProductQuantityAvailable}
                                                                        onClick={() => placeOrder(null, 'Stripe')}
                                                                    >
                                                                        Place Order
                                                                    </button>
                                                            }
                                                        </div>
                                                    </> :
                                                    <button role="button" className="btn  btnPara-primary  mr-3 fw-500"
                                                        disabled={!state.allProductQuantityAvailable}
                                                        onClick={() => placeOrder(null, 'Payment on Collection')}
                                                    >
                                                        Place Order
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="card card-para">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item">
                                            <h5 className="mb-2 pt-2">{getCartShop().business_name}</h5>
                                            <hr className="mt-0" />
                                            <div className="table-responsive">
                                                <table className="table table-borderless mb-0">
                                                    <tbody>
                                                        {
                                                            state.cartProducts.map((product, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td className="pl-0 py-2">
                                                                            <div className="d-flex">
                                                                                <p className="mb-0"><Link to={`/businesses/product/${product.product_id}`} className="text-dark">{product.product_name}</Link></p>
                                                                                {/* <a href="javascript:void(0)" rel="noopener noreferrer" className="para-link-yellow ft-size14">
                                                                                    Customize <ArrowForwardIosRoundedIcon style={{ fontSize: '12px' }} />
                                                                                </a> */}
                                                                                {product.veg_category == "Veg" && <span className="vegLable ml-1" style={{ width: '12px', height: '12px' }}></span>
                                                                                }
                                                                                {product.veg_category == "Vegan" && <span className="veganLabel ml-1" style={{ width: '12px', height: '12px' }}></span>
                                                                                }
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle py-2">
                                                                            <div className="incrementDecrement-box">
                                                                                <button className="btn-decrement"
                                                                                    onClick={() => {
                                                                                        let qty = parseInt(product.quantity) - 1;
                                                                                        if (qty > 0) {
                                                                                            setState({ count: qty });
                                                                                            manageCart(
                                                                                                {
                                                                                                    category: product.category,
                                                                                                    id: product.product_id,
                                                                                                    product_name: product.product_name,
                                                                                                    price: product.product_price,
                                                                                                    quantity: product.available_quantity,
                                                                                                    discounted_price: product.product_discounted_price,
                                                                                                    instant_delivery: product.instant_delivery,
                                                                                                    instant_delivery_price: product.instant_delivery_price,
                                                                                                    business: product.business
                                                                                                }, product.product_image, qty)
                                                                                            setTimeout(() => { getTotalCheckoutPrice() }, 200)
                                                                                        }
                                                                                    }}  >
                                                                                    <RemoveRoundedIcon />
                                                                                </button>
                                                                                <p className="mb-0">{product.quantity}</p>
                                                                                {/* <input
                                                                                    type="number"
                                                                                    value={product.quantity}
                                                                                    onChange={(e) => {
                                                                                        const qty = e.target.value;
                                                                                        setState({ count: qty })
                                                                                        manageCart(
                                                                                            {
                                                                                                category: product.category,
                                                                                                id: product.product_id,
                                                                                                product_name: product.product_name,
                                                                                                price: product.product_price,
                                                                                                quantity: product.available_quantity,
                                                                                                discounted_price: product.product_discounted_price,
                                                                                                instant_delivery: product.instant_delivery,
                                                                                                instant_delivery_price: product.instant_delivery_price,
                                                                                                business: product.business
                                                                                            }, product.product_image, qty)
                                                                                        setTimeout(() => { getTotalCheckoutPrice() }, 200)

                                                                                    }}
                                                                                /> */}
                                                                                <button className="btn-increment"
                                                                                    onClick={() => {
                                                                                        let qty = parseInt(product.quantity) + parseInt(1);
                                                                                        setState({ count: qty })
                                                                                        manageCart(
                                                                                            {
                                                                                                category: product.category,
                                                                                                id: product.product_id,
                                                                                                product_name: product.product_name,
                                                                                                price: product.product_price,
                                                                                                quantity: product.available_quantity,
                                                                                                discounted_price: product.product_discounted_price,
                                                                                                instant_delivery: product.instant_delivery,
                                                                                                instant_delivery_price: product.instant_delivery_price,
                                                                                                business: product.business
                                                                                            }, product.product_image, qty)
                                                                                        setTimeout(() => { getTotalCheckoutPrice() }, 200)

                                                                                    }}>
                                                                                    <AddRoundedIcon />
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle py-2 pr-0 text-right">
                                                                            <p className="mb-0">£{parseFloat(product.product_price).toFixed(2)}</p>
                                                                            {/* <p className="mb-0">£{(product.product_discounted_price == 0 ? parseFloat(product.product_price) : parseFloat(product.product_discounted_price) * parseInt(product.quantity)).toFixed(2)}</p> */}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            <hr />
                                            <div className="#">
                                                <h6 className="">Bill details</h6>
                                                <div className="d-flex justify-content-between">
                                                    <p className="mb-0 mr-2">Item total</p>
                                                    <p className="mb-0">£{parseFloat(state.totalCheckoutPrice).toFixed(2)}</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p className="mb-0 mr-2">Delivery Charge</p>
                                                    <p className="mb-0">£{state.totalDeliveryCharge}</p>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <p className="mb-0 mr-2 greenText">Discount</p>
                                                    <p className="mb-0 greenText">
                                                        <h5 className="mb-0 greenText">£{parseFloat(state.totalDiscountPrice).toFixed(2)}</h5>
                                                        {/* {
                                                            state.couponDiscountType == 'Cash' ?
                                                                <h5 className="mb-0 greenText">£{(parseFloat(state.couponDiscount) + parseFloat(state.totalProductDiscount)).toFixed(2)}</h5> :
                                                                <h5 className="mb-0 greenText">£{(((parseFloat(state.totalCheckoutPrice) / 100) * parseFloat(state.couponDiscount)) + parseFloat(state.totalProductDiscount)).toFixed(2)}</h5>
                                                        } */}
                                                    </p>
                                                </div>

                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="d-flex justify-content-between py-2">
                                                <h5 className="mb-0 mr-2 ft-size18">Total To Pay</h5>
                                                <h5 className="mb-0 ft-size18">£{parseFloat(state.finalAmount).toFixed(2)}</h5>
                                                {/* {
                                                    state.finalAmount > 0 ?
                                                        <>
                                                            {
                                                                state.couponDiscountType == 'Cash' ?
                                                                    <h5 className="mb-0 ft-size18">£{(parseFloat(state.totalCheckoutPrice) - (parseFloat(state.couponDiscount) + parseFloat(state.totalProductDiscount)) + parseFloat(state.totalDeliveryCharge)).toFixed(2)}</h5> :
                                                                    <h5 className="mb-0 ft-size18">£{(parseFloat(state.totalCheckoutPrice) - (((parseFloat(state.totalCheckoutPrice) / 100) * parseFloat(state.couponDiscount)) + parseFloat(state.totalProductDiscount)) + parseFloat(state.totalDeliveryCharge)).toFixed(2)}</h5>
                                                            }
                                                        </> :
                                                        <>0.00</>
                                                } */}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Spin>

                </div>
            </div>
            {
                state.drawerVisible &&
                <ManageAddresses
                    address_model_title="Add"
                    drawerVisible={state.drawerVisible}
                    useFor="checkout"
                    setState={setState}
                />
            }
        </div>
    )
}

export default CHECKOUT