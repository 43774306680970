import React, { useState } from 'react'
import { GuestAPI } from '../../../apis/GuestAPI';
import '../../../assets/css/contact.css'
import partical from '../../../assets/images/Contact/ellipse1.69ba3a06.svg'
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../../assets/images/modalPopup/close-circle.svg';
import { Spin } from 'antd'


function Contact() {
    const [loading, setLoader] = useState(false);

    // const [formdata, setFormdata] = useState({
    //     name: "",
    //     email: "",
    //     number: "",
    //     message: "",
    // });

    const onSubmit = async (event) => {
        event.preventDefault()
        const name = event.target.name.value;
        const email = event.target.email.value;
        const message = event.target.message.value;
        const number = event.target.number.value;
        // setFormdata({
        //     name, email, number, message,
        // })
        setLoader(true)

        const data = {
            name, email, number, message,
        }

        GuestAPI.saveContactDetails(data)
            .then(res => {
                Swal.fire({
                    title: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                });
                setLoader(false);

            }).catch(error => {
                setLoader(false);
                Swal.fire({
                    title: error.response.data.message || error.Error,
                    imageUrl: CloseCircle,
                    imageAlt: 'error image',
                    confirmButtonColor: '#00CA84'
                });
            })

        console.log(data)
    }


    return (
        <div>
            <div className='container mb-5' style={{ marginTop: '76px' }}>
                <div className='d-flex justify-content-center pt-5' >
                    <h2 className=''>Contact</h2>
                </div>
            </div>
            <section id="contact">
                <div className="container">

                    <div className="row">
                        <div className="col-md-4 d-flex justify-content-center ">
                            <div className="contact-info">

                                <h3 className="text-white fw-500">Contact Information</h3>
                                <p className="text-white">Fill up the form and our Team will get back to you within 24 hours</p>
                                <p >
                                    <a className="text-white" href="mailto:name@example.com">name@gmail.com</a>
                                </p>
                                <p className="text-white">
                                    017-866-86408
                                </p>
                                <p className='text-white'>
                                    House 1 Street 2
                                </p>
                            </div>
                            <div className="particalContact">
                                <img width="142" src={partical} alt="" />
                            </div>
                        </div>

                        <div className="col-md-8">
                            <form
                                id="contact-form"
                                className="contact-form mt-6"
                                onSubmit={onSubmit}
                            >
                                <div className="row">
                                    <div className="column col-md-6">
                                        <div className="form-group">
                                            <input
                                                required
                                                type="text"
                                                className="form-control"
                                                name="name"
                                                id="InputName"
                                                placeholder="Your name"
                                            />
                                        </div>
                                    </div>

                                    <div className="column col-md-6">
                                        <div className="form-group">
                                            <input
                                                required
                                                type="email"
                                                className="form-control"
                                                id="InputEmail"
                                                name="email"
                                                placeholder="Email address"
                                            />
                                        </div>

                                    </div>

                                    <div className="column col-md-12">
                                        <div className="form-group">
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="InputSubject"
                                                name="number"
                                                placeholder="ex. 01786686408"
                                            />
                                        </div>

                                    </div>

                                    <div className="column col-md-12">
                                        <div className="form-group">
                                            <textarea
                                                required
                                                name="message"
                                                id="InputMessage"
                                                className="form-control"
                                                rows="5"
                                                placeholder="Message"
                                            ></textarea>
                                        </div>

                                    </div>
                                </div>
                                <Spin spinning={loading} delay={500}>

                                    <button
                                        type="submit"
                                        name="submit"
                                        id="submit"
                                        value="Submit"
                                        className="btn btnPara-primary my-4"
                                    >
                                        Send Message
                                    </button>
                                </Spin>

                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    )
}

export default Contact
