// import React from 'react';
import React, { useReducer, useEffect, useState } from 'react';
import { Form, Input, Button, Drawer, Spin } from 'antd';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../assets/images/modalPopup/checkmark-circle.svg';
import AlertTriangle from '../../assets/images/modalPopup/alert-triangle.svg';
import axios from 'axios';
import config from '../../config';
import lang from '../../language/en.json';
import { Token } from '../../Token';
import { ProfileAPI } from '../../apis/user/ProfileAPI';

const postcodes = require('node-postcodes.io');
const AnyReactComponent = ({ text }) => <div><LocationOnIcon /></div>;

export default function ManageAddresses(props) {

    const [form] = Form.useForm();
    const [loading, setLoader] = useState(false);
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            address_model_title: 'Add',
            address: '',
            address_id: '',
            address_error_msg: '',
            address_postal_code: '',
            address_postal_validate_msg: '',
            address_latitude: parseFloat(config.DEFAULT_ADDRESS_LAT),
            address_longitude: parseFloat(config.DEFAULT_ADDRESS_LONG),
            addresses: []
        }
    );

    useEffect(() => {
        getAddresses();
        // form.setFieldsValue({ address_country_name: 'United Kingdom' })
    }, [])

    const getAddresses = () => {
        setTimeout(() => {
            ProfileAPI.fetchAddresses()
                .then(function (response) {
                    setLoader(false)
                    setState({ addresses: response.data.addresses })
                    if (props.useFor == "checkout") {
                        props.setState({ addresses: response.data.addresses })
                    }
                }).catch(function (error) {
                    setLoader(false)
                    console.log(error);
                });
        }, 1000)
    };

    const onClose = () => {
        setState({ address: '', address_id: '' })

        form.setFieldsValue({
            address_house_name: null,
            address_house_locality: null,
            address_house_number: null,
            address_line_2: null,
            address_town_name: null,
            address_country_name: 'United Kingdom',
            address_postal_code: null
        })
        props.setState({ drawerVisible: false });
    };

    const handleSelectForMap = async (address, placeId) => {
        setState({ address: address })
        geocodeByAddress(address)
            .then(results => {
                getLatLng(results[0]);
                var locality = "";
                // var state = "";
                // var country = "";
                var postal_code = "";
                var address_line_2 = "";
                var postal_town = "";
                for (var i = 0, len = results[0].address_components.length; i < len; i++) {

                    var ac = results[0].address_components[i];
                    if (ac.types.indexOf("locality") >= 0) locality = ac.long_name;
                    // if (ac.types.indexOf("administrative_area_level_1") >= 0) state = ac.long_name;
                    // if (ac.types.indexOf("country") >= 0) country = ac.long_name;
                    if (ac.types.indexOf("route") >= 0) address_line_2 = ac.long_name;
                    if (ac.types.indexOf("postal_town") >= 0) postal_town = ac.long_name;
                    if (ac.types.indexOf("postal_code") >= 0) postal_code = ac.long_name;
                }

                form.setFieldsValue({
                    // address_house_locality: locality,
                    address_town_name: locality != '' ? locality : postal_town,
                    address_line_2: address_line_2,
                    // address_country_name: country,
                    address_postal_code: postal_code
                })

                // console.log('formatted_address', {
                //     locality: locality,
                //     state: state,
                //     country: country,
                //     postal_code: postal_code
                // })
            }).then(async (latLng) => {
                setState({
                    address_latitude: latLng.lat,
                    address_longitude: latLng.lng
                });
                let result = await postcodes.geo(parseFloat(latLng.lat), parseFloat(latLng.lng))
                if (result.result != null) {
                    form.setFieldsValue({ address_postal_code: result.result[0].postcode })
                    setState({ address_postal_validate_msg: '' });
                }

            }).catch(error => console.error('Error', error));
    };

    const onFinishAddress = async (value) => {
        if (state.address == '') {
            setState({ address_error_msg: lang.address_require_error_msg });
            return false;
        } else {
            setState({ address_error_msg: '' });
        }

        let result = await postcodes.validate(value.address_postal_code);

        if (result.result == true) {
            setState({ address_postal_validate_msg: '' });
        } else {
            setState({ address_postal_validate_msg: lang.postal_code_validate_msg });
            return false;
        }

        value.address = state.address;
        value.address_latitude = state.address_latitude;
        value.address_longitude = state.address_longitude;
        value.address_country_name = 'United Kingdom';

        setLoader(true)
        if (props.address_model_title == 'Add') {
            ProfileAPI.saveAddress(value)
                .then(res => {

                    Swal.fire({
                        title: 'Success!',
                        text: res.data.message,
                        imageUrl: CheckmarkCircle,
                        imageAlt: 'success image',
                        // imageHeight: 1500,
                        confirmButtonColor: '#00CA84'
                    });
                    props.setState({ drawerVisible: false })
                }).catch(error => {

                })
        } else {
            value.address_id = state.address_id;
            ProfileAPI.updateAddress(value)
                .then(res => {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.message,
                        imageUrl: CheckmarkCircle,
                        imageAlt: 'success image',
                        confirmButtonColor: '#00CA84'
                    });
                    props.setState({ drawerVisible: false })
                }).catch(error => {

                })
        }

        getAddresses();
    }

    const onEditAddress = (address) => {
        props.setState({ address_model_title: 'Edit' })

        setState({
            address: address.address,
            address_id: address.address_id
        })

        form.setFieldsValue({
            address_postal_code: address.postal_code,
            address_house_name: address.house_name,
            address_line_2: address.address_line_2,
            address_town_name: address.town_name,
            // address_house_locality: address.house_locality,
            // address_house_number: address.house_number,
            // address_country_name: address.country_name,
            address_latitude: parseFloat(address.latitude),
            address_longitude: parseFloat(address.longitude),
        })
    }

    const onDeleteAddress = (address) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            imageUrl: AlertTriangle,
            showCancelButton: true,
            confirmButtonColor: '#00CA84',
            cancelButtonColor: '#FF5858',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                ProfileAPI.deleteAddress(address.address_id)
                    .then(res => {
                        setState({ addresses: res.data.addresses })
                        Swal.fire({
                            title: 'Deleted!',
                            text: res.data.message,
                            imageUrl: CheckmarkCircle,
                            imageAlt: 'success image',
                            confirmButtonColor: '#00CA84',
                        })
                    });
            }
        })
    }

    return (
        <div className="profile-info container-fluid">

            <div className="row">
                {
                    state.addresses.length == 0 &&
                    <div className="media my-3 bg-white p-3 rounded shadow-sm w-100">
                        <div className="media-body mb-0 small">
                            <div className="my-3">
                                <h5 className='text-center' style={{ flex: 'auto' }}>Address not added yet</h5>
                            </div>
                        </div>
                    </div>
                }
                {
                    state.addresses.map(add => {
                        return (
                            <div key={add.id} className="col-md-6 my-3">
                                <div className="media bg-white p-3 rounded shadow-sm d-flex flex-column h-100">
                                    <div className="mb-auto">
                                        <h5 className="">{add.house_name}</h5>
                                        {/* <p>3, 2nd floor, 5a, Pedder Rd, IT Colony, Malabar Hill, Mumbai, Maharashtra 400026, India</p> */}
                                        <p>{`${add.address}, ${add.postal_code}`}</p>
                                    </div>
                                    <div className="d-flex flex-row flex-wrap">
                                        <div className="mr-3">
                                            <button type="primary" className="btn btn-outline-primary" onClick={() => {
                                                onEditAddress(add);
                                                props.setState({ drawerVisible: true })
                                            }}>
                                                Edit address
                                            </button>
                                        </div>
                                        <div className="mr-3 align-self-center">
                                            <a href="javascript:(0)" role="button" className="para-link-red" rel="noopener noreferrer" onClick={() => onDeleteAddress(add)}>Delete</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            {/* ==== Start: Add Address modal  =====  */}

            {/* ==== End: Add Addess modal  =====  */}


            <Drawer className='h4'
                // title={props.address_model_title + ' Address'}
                placement={'left'}
                closable={false}
                onClose={onClose}
                visible={props.drawerVisible}
                key={'left'}
            >
                <div className="">
                    <div className="#">
                        <div className="d-flex justify-content-between">
                            <h4 className="mb-0" id="exampleModalLabel">{props.address_model_title} Address</h4>
                            <button type="primary" className="close" onClick={onClose}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="my-3">
                        <div style={{ height: '50vh', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={'AIzaSyAxlZtPxXxJhmhfwEhSuEo8C9VJubGoQx8'}
                                center={{
                                    lat: state.address_latitude ? state.address_latitude : parseFloat(config.DEFAULT_ADDRESS_LAT),
                                    lng: state.address_longitude ? state.address_longitude : parseFloat(config.DEFAULT_ADDRESS_LONG)
                                }}
                                defaultZoom={6}
                            >
                                <AnyReactComponent
                                    lat={state.address_latitude}
                                    lng={state.address_longitude}
                                    text={state.address}
                                />
                            </GoogleMapReact>
                        </div>
                    </div>
                    <Form
                        form={form}
                        onFinish={onFinishAddress}
                    >
                        {/* <div class="form-group">
                            <label>Country <super className="redText"><b>*</b></super></label>
                            <Form.Item
                                name='address_country_name'
                                rules={[
                                    { required: true, message: lang.country_name_require_error_msg }
                                ]}
                            >
                                <Input readOnly placeholder="Country" />
                            </Form.Item>
                        </div> */}

                        <div class="form-group">
                            <label htmlFor="exampleInputEmail1">Address Line 1<super className="redText"><b>*</b></super></label>
                            <PlacesAutocomplete
                                value={state.address}
                                onChange={(address) => setState({ address: address })}
                                onSelect={handleSelectForMap}
                                searchOptions={{
                                    componentRestrictions: { country: 'gb' },
                                    // types: ['city']
                                }}
                            >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                        <input
                                            className="form-control"
                                            {...getInputProps({
                                                // placeholder: 'Prefilled as per location detected',
                                                placeholder: 'Address Line 1',
                                                className: 'location-search-input form-control',
                                            })}
                                        />

                                        <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                    ? 'suggestion-item--active'
                                                    : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                    <div
                                                        {...getSuggestionItemProps(suggestion, {
                                                            className,
                                                            style,
                                                        })}
                                                    >
                                                        <span>{suggestion.description}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </PlacesAutocomplete>
                            {state.address_error_msg !== "" && <div className="text-danger"> {state.address_error_msg} </div>}
                        </div>

                        <div class="form-group">
                            <label>Address Line 2<super className="redText"><b>*</b></super></label>
                            <Form.Item
                                name='address_line_2'
                                rules={[
                                    { required: true, message: lang.street_name_require_error_msg }
                                ]}
                            >
                                <Input placeholder="Address Line 2" />
                            </Form.Item>
                        </div>

                        <div class="form-group">
                            <label>Postal Code <super className="redText"><b>*</b></super></label>
                            <Form.Item
                                name='address_postal_code'
                                rules={[
                                    { required: true, message: lang.postal_code_require_error_msg }
                                ]}
                            >
                                <Input onChange={async (e) => {
                                    let result = await postcodes.validate(e.target.value);
                                    // let result = await postcodes.autocomplete(e.target.value)
                                    // console.log(result)
                                    if (result.result == false && e.target.value != '') {
                                        setState({ address_postal_validate_msg: lang.postal_code_validate_msg });
                                    } else {
                                        setState({ address_postal_validate_msg: '' });
                                    }
                                }}
                                    placeholder="Postal Code" />
                            </Form.Item>
                            {state.address_postal_validate_msg !== "" && <div role="alert" class="ant-form-item-explain-error mb-0"> {state.address_postal_validate_msg} </div>}
                        </div>

                        {/* <div class="form-group">
                            <label>House Number <super className="redText"><b>*</b></super></label>
                            <Form.Item
                                name='address_house_number'
                                rules={[
                                    { required: true, message: lang.house_number_require_error_msg }
                                ]}
                            >
                                <Input placeholder="House Number" />
                            </Form.Item>
                        </div>
                        <div class="form-group">
                            <label>House Locality <super className="redText"><b>*</b></super></label>
                            <Form.Item
                                name='address_house_locality'
                                rules={[
                                    { required: true, message: lang.house_locality_require_error_msg }
                                ]}
                            >
                                <Input placeholder="House Locality" />
                            </Form.Item>
                        </div> */}
                        <div class="form-group">
                            <label>Town <super className="redText"><b>*</b></super></label>
                            <Form.Item
                                name='address_town_name'
                                rules={[
                                    { required: true, message: lang.town_name_require_error_msg }
                                ]}
                            >
                                <Input placeholder="Town" />
                            </Form.Item>
                        </div>

                        <div class="form-group">
                            {/* <label>Name this address <super className="redText"><b>*</b></super></label> */}
                            <label>Contact name <super className="redText"><b>*</b></super></label>
                            <Form.Item
                                name='address_house_name'
                                rules={[
                                    { required: true, message: lang.house_name_require_error_msg }
                                ]}
                            >
                                <Input placeholder="contact name" />
                            </Form.Item>
                        </div>

                        <Spin spinning={loading} delay={500}>
                            <div className="form-group">
                                <Button htmlType="submit" className="btn btnPara-primary btn-block">
                                    {props.address_model_title == 'Add' ? 'Add' : 'Update'} Address
                                </Button>
                            </div>
                        </Spin>
                    </Form>
                </div>
            </Drawer>
        </div>
    )
}
