import React, { useReducer, useRef, useEffect, useState } from 'react';
import '../../assets/css/UserPanel/userPanel.css';
import editprofileicon from '../../assets/images/sellerProfile/edit-profile-icon.svg';
import profileimg from '../../assets/images/sellerProfile/profile-img.svg';
import axios from 'axios';
import config from '../../config';
import { CircularProgress } from '@material-ui/core/';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../assets/images/modalPopup/checkmark-circle.svg';
import { Token } from '../../Token';
import { ProfileAPI } from '../../apis/user/ProfileAPI';

export default function MyInfo() {
    const [loading, setLoader] = useState(false);
    const profile_pictureRef = useRef()

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            first_name: "",
            last_name: "",
            profile_picture: "",
            mobile_number: "",
            email: "",
            buttondisable: false,
            progressVisible: "none"
        }
    );

    useEffect(() => {
        ProfileAPI.fetchProfile()
            .then(function (response) {
                const profile = response.data
                setState({
                    first_name: profile.first_name,
                    last_name: profile.last_name,
                    profile_picture: profile.profile_picture,
                    mobile_number: profile.mobile_number,
                    email: profile.email
                })

                setState({
                    first_name: profile.first_name,
                    last_name: profile.last_name,
                    email: profile.email,
                    mobile_number: profile.mobile_number
                })
                
                Token.storeFullName(profile.first_name + ' ' + profile.last_name)
                Token.storeEmail(profile.email)
            }).catch(function (error) {
                console.log(error);
            });
    }, [])
    const profileUpdate = () => {
        var profile_img = ""
        if (state.profile_picture === undefined) {
            profile_img = ""
        } else {
            profile_img = state.profile_picture
        }
        var data = {
            "user_id": Token.getUserID(),
            "first_name": state.first_name,
            "last_name": state.last_name,
            "email": state.email,
            "mobile_number": state.mobile_number,
            "profile_picture": profile_img
        }
        setState({ buttondisable: true, progressVisible: "" })

        const token = Token.getToken();

        var axiosConfig = {
            method: 'put',
            url: `${config.API_BASE_URL}/api/user/update-profile`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(axiosConfig)
            .then(function (response) {

                if (response.data.message === 'Updated successfully.') {
                    setState({ first_name: state.first_name, last_name: state.last_name, email: state.email })
                    setState({ buttondisable: false, progressVisible: "none" });
                } else {
                    setState({ buttondisable: false, progressVisible: "none" })
                }

                Swal.fire({
                    title: 'Success!',
                    text: response.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    // imageHeight: 1500,
                    confirmButtonColor: '#00CA84',
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })

            })
            .catch(function (error) {
                setState({ buttondisable: false, progressVisible: "none" });
            });

    }

    const imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setState({ profile_picture: reader.result });
            }
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    return (
        <div className="profile-info">
            <div className="media my-info my-3 p-3 bg-white rounded shadow-sm">
                <div className="media-body">
                    <div>
                        <h4>My Info</h4>
                    </div>
                    <hr />
                    {/* <form> */}
                    <div className="form-group">
                        <label>First Name</label>
                        <input type="text" className="form-control" id="" value={state.first_name} placeholder="First Name" onChange={(e) => setState({ first_name: e.target.value })} />
                    </div>
                    <div className="form-group">
                        <label>Last Name</label>
                        <input type="text" className="form-control" id="" value={state.last_name} placeholder="Last Name" onChange={(e) => setState({ last_name: e.target.value })} />
                    </div>
                    <div className="form-group">
                        <label>Profile Picture</label>
                        <div className="position-relative">
                            {state.profile_picture === undefined || state.profile_picture === "" ? <img src={profileimg} alt="" /> : <img  src={state.profile_picture} alt="" />}
                            <div className="upload-btn-wrapper">
                                <input type="file" accept="image/png, image/jpeg, image/jpg" style={{ display: 'none' }} ref={profile_pictureRef} onChange={imageHandler} />
                                <button className="upload-btn" onClick={() => profile_pictureRef.current.click()}><img src={editprofileicon} alt="" className="img-fluid edit-profile-icon" /></button>

                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Mobile Number</label>
                        <input type="Number" className="form-control" value={state.mobile_number} id="" placeholder="9876543210" onChange={(e) => setState({ mobile_number: e.target.value })} />
                    </div>
                    <div className="form-group">
                        <label>Email ID</label>
                        <input type="text" className="form-control" value={state.email} id="" placeholder="aamirz1996@gmail.com" onChange={(e) => setState({ email: e.target.value })} />
                    </div>
                    <hr className="mt-4" />
                    <div className="text-right d-flex justify-content-end align-items-center mt-3">
                        <button disabled={state.buttondisable} className="btn btnPara-primary" onClick={profileUpdate}>Save</button><CircularProgress className='ml-2' style={{ display: state.progressVisible }} />
                    </div>
                    {/* </form> */}
                </div>
            </div>
        </div>
    )
}
