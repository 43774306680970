import React from 'react';
import { Fragment } from 'react';

export default function UserBusinesses() {
    return (
        <Fragment>
            <div className='p-3 mb-3 border rounded-8'>

                <div className="userBusinessesList mb-3 d-md-flex justify-content-md-between">
                    <div className="d-flex">
                        <div>
                            <img src='https://picsum.photos/200/300' alt="" className="bd-placeholder-img mr-2 rounded" width="56" height="56" />
                        </div>
                        <div className="align-self-center">
                            <h6 className="mb-0">Cakes by K</h6>
                            <p className="mb-0 greyText">Admin</p>
                        </div>
                    </div>
                    <div className="align-self-center">
                        <button className="btn btn-outline-primary my-2">View Shop</button>
                    </div>
                </div>

                <div className="userBusinessesList mb-3 d-md-flex justify-content-md-between">
                    <div className="d-flex">
                        <div>
                            <img src='https://picsum.photos/200/300' alt="" className="bd-placeholder-img mr-2 rounded" width="56" height="56" />
                        </div>
                        <div className="align-self-center">
                            <h6 className="mb-0">Cakes by K</h6>
                            <p className="mb-0 greyText">Admin</p>
                        </div>
                    </div>
                    <div className="align-self-center">
                        <button className="btn btn-outline-primary my-2">View Shop</button>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}
