import React, { useState } from 'react'
import '../../Components/styles/css/setUpAsBusiness/SUABfooter.css'
import HomeEssential from '../../assets/images/home/Home_Essentials.svg'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../assets/images/modalPopup/close-circle.svg';
import SignIn from '../auth/SignIn';
import { Token } from '../../Token';

const SUABfooter = (props) => {
    const [state, setState] = useState({
        _isShowSingInModel: false,

    });
    return (

        <div className='px-2 px-md-5 text-justify'>
            <div className='suabfooter'>
                <p className='topText'>
                    Para enables businesses to digitise their consumer relationships, access business data and
                    insights, while managing their business operations more effectively.
                </p>
                <p className='middleText'> Some benfits of going Digital with Para </p>
                <div className='row cardBox'>
                    <div className='col-xl-4 col-md-6 col-xs-12 '>
                        <div>
                            <img src={HomeEssential} alt="Home Essential" />
                        </div>
                        <p className='cardHeading'>
                            Amplify Online Presence & Attract New Customers
                        </p>
                        <p className='cardDescription'>
                            Digital is going to be the place
                            where most users would be present,
                            it is imperative that businesses adapt
                            and reach the users by getting a digital
                            presence and amplify it to increase the reach.
                        </p>
                    </div>
                    <div className='col-xl-4 col-md-6 col-xs-12 '>
                        <div>
                            <img src={HomeEssential} alt="Home Essential" />
                        </div>
                        <p className='cardHeading'>
                            Improve Visibility & Tap Roaming Audience
                        </p>
                        <p className='cardDescription'>
                            An advanced filter which allows you to
                            become highly visible to your local community
                            and niche audiences. Our intelligent system
                            taps the roaming audience, who are looking
                            for local businesses nearby.
                        </p>
                    </div>
                    <div className='col-xl-4 col-md-6 col-xs-12 '>
                        <div>
                            <img src={HomeEssential} alt="Home Essential" />
                        </div>
                        <p className='cardHeading'>
                            Increase Engagement
                        </p>
                        <p className='cardDescription'>
                            Potential customers now can engage directly
                            with your business. The engagement processes

                            — to connect via phone, Whatsapp, submit a
                            lead form and other features, are all
                            available at your finger tips.
                        </p>
                    </div>
                    <div className='col-xl-4 col-md-6 col-xs-12 '>
                        <div>
                            <img src={HomeEssential} alt="Home Essential" />
                        </div>
                        <p className='cardHeading'>
                            Promote Word of Mouth
                        </p>
                        <p className='cardDescription'>
                            Word of mouth has always been a
                            trusted source for consumers,
                            predominantly taking the form of online
                            reviews. With the connected social portfolio
                            management
                            feature, customers can now publish reviews more quickly.
                        </p>
                    </div>
                    <div className='col-xl-4 col-md-6 col-xs-12 '>
                        <div>
                            <img src={HomeEssential} alt="Home Essential" />
                        </div>
                        <p className='cardHeading'>
                            Strengthen Your Reputation
                        </p>
                        <p className='cardDescription'>
                            The fear of negative reviews prevents many businesses from getting listed online, shutting them off from getting positive reviews, which is a gold mine. A mix of reviews strengthens your reputation, by helping you built a better experience.
                        </p>
                    </div>
                    <div className='col-xl-4 col-md-6 col-xs-12 '>
                        <div>
                            <img src={HomeEssential} alt="Home Essential" />
                        </div>
                        <p className='cardHeading'>
                            Increase Brand Awareness
                        </p>
                        <p className='cardDescription'>
                            Every encounter a person has with your business increases it’s awareness and familiarity with them, which makes them more receptive to future outreach. Features like advanced search, multiple form of Ads and nearby listings, gets the job done.
                        </p>
                    </div>
                </div>
                <div className='row justify-content-center'>
                    {/* {
                        props.user ?
                            <Link to='/Sell'> <button className="text-light nextButton_SUABfooter col-xs-6 label">Im ready to set up my business on para <ArrowRightAltIcon /></button></Link> :
                            <button
                                onClick={() => setState({ _isShowSingInModel: true })}

                                className="text-light nextButton_SUABfooter col-xs-6 label">Im ready to set up my business on para <ArrowRightAltIcon />
                            </button>
                    } */}


                    {
                        Token.isLoggedIn() ?
                            <Link to='/Sell'> <button className="text-light nextButton_SUABfooter col-xs-6 label">Im ready to set up my business on para <ArrowRightAltIcon /></button></Link> :
                            <button
                                onClick={() => setState({ _isShowSingInModel: true })}

                                className="text-light nextButton_SUABfooter col-xs-6 label">Im ready to set up my business on para <ArrowRightAltIcon />
                            </button>
                    }

                    {
                        state._isShowSingInModel ?
                            <SignIn
                                state={state}
                                setState={setState}
                            /> :
                            null
                    }
                </div>
            </div>
        </div>
    )
}

export default SUABfooter