import React from 'react';
import '../../../assets/css/UserPanel/userPanel.css';
import { DashboardLayout } from '../Sidebar wrapper/Layout'
import SampleProducts from '../../../assets/images/products/sample-image.svg';

import { Popover, Button } from 'antd';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Tooltip } from 'antd';
import { Select } from 'antd';
import { Menu, Dropdown, Space, message, } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import MoreHorizOutlinedIcon from '@material-ui/icons/MoreHorizOutlined';

const { Option } = Select;
function handleChange(value) {
    console.log(`selected ${value}`);
}

const menu = (
    <Menu>
        <Menu.Item>Active</Menu.Item>
        <Menu.Item>Deactivate</Menu.Item>
        <Menu.Item danger>Delete</Menu.Item>
    </Menu>
);

function handleButtonClick(e) {
    message.info('Click on left button.');
}


export default function UserRoles() {
    return (
        <DashboardLayout page='User Roles'>
            <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-content">
                    <h4>Manage Roles</h4>
                    <div class="table-responsive categories-table mt-4">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td colspan="4">
                                        <div className="d-flex">
                                            <p className="mb-0 mr-2 align-self-center">Roles</p>
                                            <Select defaultValue="All" style={{ width: 120 }} onChange={handleChange}>
                                                <Option value="All">All</Option>
                                                <Option value="Admin">Admin</Option>
                                                <Option value="Owner">Owner</Option>
                                            </Select>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table">
                            <thead>
                                <tr className="table-info">
                                    <th className='fw-400'>NAME</th>
                                    <th className='fw-400'>Role</th>
                                    <th className='fw-400'>Joined on</th>
                                    <th className='fw-400'></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className="align-middle">
                                        <div className="d-flex">
                                            <img src={SampleProducts} alt="" className="mr-2" width="56" height="56" />
                                            <div className="align-self-center">
                                                <p className="mb-0 fw-500">Aamir Zoomkawala</p>
                                                <p className="mb-0 greyText ft-size14 fw-400">aamirz1996@gmail.com</p>
                                            </div>
                                        </div>
                                    </th>
                                    <td className="align-middle">
                                        <div className="d-flex">
                                            <p className="mb-0 mr-1">Admin</p>
                                            <p className="mb-0 align-self-center">
                                                <Tooltip title="prompt text">
                                                    <InfoOutlinedIcon style={{ fontSize: '16px', color: '#666666' }} />
                                                </Tooltip>
                                            </p>
                                        </div>
                                    </td>
                                    <td className="align-middle">34/48/2020</td>
                                    <td className="align-middle text-center">
                                        <Space wrap>
                                            <Dropdown overlay={menu}>
                                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                    <MoreHorizOutlinedIcon />
                                                </a>
                                            </Dropdown>
                                        </Space>
                                    </td>
                                </tr>
                                <tr>
                                    <th className="align-middle">
                                        <div className="d-flex">
                                            <img src={SampleProducts} alt="" className="mr-2" width="56" height="56" />
                                            <div className="align-self-center">
                                                <p className="mb-0 fw-500">Aamir Zoomkawala</p>
                                                <p className="mb-0 greyText ft-size14 fw-400">aamirz1996@gmail.com</p>
                                            </div>
                                        </div>
                                    </th>
                                    <td className="align-middle">
                                        <div className="d-flex">
                                            <p className="mb-0 mr-1">Owner</p>
                                            <p className="mb-0 align-self-center">
                                                <Tooltip title="prompt text">
                                                    <InfoOutlinedIcon style={{ fontSize: '16px', color: '#666666' }} />
                                                </Tooltip>
                                            </p>
                                        </div>
                                    </td>
                                    <td className="align-middle"><p className="mb-0"><span className='font-italic'>Invited</span></p></td>
                                    <td className="align-middle text-center">
                                        <Space wrap>
                                            <Dropdown overlay={menu}>
                                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                    <MoreHorizOutlinedIcon />
                                                </a>
                                            </Dropdown>
                                        </Space>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </DashboardLayout>

    )
}
