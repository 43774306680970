import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const OverviewApi = {
    getDashboardStatistics: async () => {
        const token = Token.getToken();
        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/admin/fetch-dashboard-data`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    }

}