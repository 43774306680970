import React from 'react'

function WHY_SELL_ON_PARA() {
    return (

        <div className='container mb-5' style={{ marginTop: '76px' }}>
            <div className='d-flex justify-content-center pt-5' >
                <h2 className=''>Why sell on para?</h2>
            </div>
            <div className='my-4 text-justify'>
                <h3>Benefits of Listing in PARA</h3>
                <p>Not only listing in Para important for getting your business noticed and creating a positive first impression, but they are also highly beneficial to the growth of your business. Read on to learn about the many we offer your business.</p>
                <ul className='list-unstyled'>
                    <li>
                        <h5>1. Amplify Online Presence & Attract New Customers</h5>
                        <p> Para, founders found the need for taking the local home food suppliers digital, when one of its founders was struggling to get home food while on business trips.</p>
                    </li>
                    <li>
                        <h5>2. Improve Local Visibility & Tap The Roaming Audience</h5>
                        <p>Though they are massive, Para has advanced filtering and suggesting capabilities, allowing you to become highly visible to your local community and niche audiences. Para digital marketers are skilled to tap the roaming audience, who may be looking for local businesses like you when in a new place.</p>
                    </li>
                    <li>
                        <h5>3. Increase Engagement</h5>
                        <p>The easier it is for a potential customer to engage with your business—whether it’s to make a phone call, submit a contact form, or even go to your location—the more likely they are to do so. Para creates a channel by which potential customers can engage directly with your business or easily get to you to enquire, transact, engaged with promotions and offers and be present in the mind of users customers.</p>
                    </li>
                    <li>
                        <h5>4. Promote Word of Mouth</h5>
                        <p>Word of mouth has always been a trusted source for consumers, and now predominantly takes the form of online reviews. With Para’s connected social portfolio management feature, customers can now publish reviews more quickly—even during their experience. Getting listed in Para equips happy customers to spread the word about your business.</p>
                    </li>
                    <li>
                        <h5>5. Strengthen Your Reputation</h5>
                        <p>The fear of negative reviews prevents many businesses from getting listed online, but this shuts them off from getting positive reviews too, which are essential for a good reputation. As long as you know how to properly handle one, the cost of one negative review is outweighed by the benefits of many positive reviews. Plus, a mix of reviews can even strengthen your reputation, as it shows that you are a genuine company with nothing to hide.</p>
                    </li>
                    <li>
                        <h5>6. Increase Brand Awareness</h5>
                        <p>When a user performs a search in Para, it will display a list of relevant results, each with a brief snapshot so that the user can decide which one to click on to learn more. This means that even if a user doesn’t click on your listing, they still see your business. Every encounter a person has with your business increases their awareness and familiarity with your brand, which makes them more receptive to future outreach.</p>
                    </li>
                    <li>
                        <h5>7. Improve SEO</h5>
                        <p>Para listings improves your SEO by sending signals to Google about your online presence and helping to validate your trustworthiness.  Google wants to be trusted by its users, so the more information it can obtain about your business online, and the more consistent that information is, the higher you will rank and Para facilitates you to do so.</p>
                    </li>
                    <li>
                        <h5>8. Show Up on the First Page of Google</h5>
                        <p>It is possible to get on the first page of Google through SEO practices, but it can take a few months to start seeing results. Getting listed on Para gives you a high chance of showing up on the first page of results for relevant searches.</p>
                        <p>Getting Listed on Para has immense benefits, so get started today with creating, claiming, and updating your listings online.</p>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default WHY_SELL_ON_PARA