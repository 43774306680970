import './App.css';
import { Route, Router } from 'react-router-dom';
import React, { Fragment, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'gotham-fonts/css/gotham-rounded.css'
// import Header from './Components/Header';
import ScrollToTop from './scrolltotop';
import 'react-toastify/dist/ReactToastify.css';
import history from './Components/Business/history'
import { JetProvider } from './context/JetContext';

import UserRoute from './routes/UserRoute';
import AdminRoute from './routes/AdminRoute';

import { Token } from './Token';
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles

AOS.init({
    // Global settings:
    once: true,
    disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    initClassName: 'aos-init', // class applied after initialization
    animatedClassName: 'aos-animate', // class applied on animation
    useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


    // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    offset: 120, // offset (in px) from the original trigger point
    delay: 0, // values from 0 to 3000, with step 50ms
    duration: 400, // values from 0 to 3000, with step 50ms
    easing: 'ease', // default easing for AOS animations
    // once: false, whether animation should happen only once - while scrolling down
    mirror: false, // whether elements should animate out while scrolling past them
    anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});

const App = () => {

  const [authenticated, setAuthenticated] = useState(false)
  const [userId, setUserId] = useState()
  const [userToken, setUserToken] = useState()
  const [seller, setSeller] = useState('normalDashboard')

  useEffect(() => {
    
    var path = window.location.pathname
    if (path.includes('/seller/business')) {
      setSeller('businessDashboard')
    } else {
      setSeller('normalDashboard')
    }

    if (Token.isLoggedIn()) {
      setAuthenticated(true)
      setUserId(Token.getUserID())
      setUserToken(Token.getToken())
    } else {
      setAuthenticated(false)
    }
  }, [window.location.pathname])

  return (
    <Fragment>
      <Router history={history}>
        <ScrollToTop >
          <JetProvider>
            {/* <Header /> */}
            <Route path="/" component={UserRoute} />
            <Route path="/admin" component={AdminRoute} />
            
          </JetProvider>
        </ScrollToTop>
      </Router>
    </Fragment>
  );
}


export default App


