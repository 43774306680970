import React from 'react'
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import lang from '../../language/en.json';

const postcodes = require('node-postcodes.io');

const BusinessAddress = (props) => {

    const handleSelectForMap = async (address, placeId) => {
        props.setState({ address: address })
        if (address) {
            props.setState({ address_error_msg: '' });
        } else {
            props.setState({ address_error_msg: lang.address_require_error_msg });
        }

        geocodeByAddress(address)
            .then(results =>
                getLatLng(results[0])
            ).then(async (latLng) => {
                props.setState({ address_lat: latLng.lat, address_long: latLng.lng });
                let result = await postcodes.geo(parseFloat(latLng.lat), parseFloat(latLng.lng))
                if (result.result != null) {
                    props.setState({ postal_code: result.result[0].postcode })
                }

            }).catch(error => console.error('Error', error));
    };
    return (
        <div className='my-2'>
            <div className="text-center">
                <h4 className="mb-0">Getting to know your business.</h4>
                <p>Information about your business so we can help you maximise your conversions.</p>
            </div>
            <div style={{ border: '1px #E6E6E6 solid', backgroundColor: '#E6E6E6' }}></div>
            <div className='my-2'>

                <div className="w-100" style={{ height: '220px' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={'AIzaSyAxlZtPxXxJhmhfwEhSuEo8C9VJubGoQx8'}
                        center={{ lat: props.state.address_lat, lng: props.state.address_long }}
                        defaultZoom={6}
                    >
                        <div
                            lat={props.state.address_lat}
                            lng={props.state.address_long}>
                            <LocationOnIcon />
                        </div>
                    </GoogleMapReact>
                </div>

                <div class="form-group">
                    <label >Address</label>
                    <PlacesAutocomplete
                        value={props.state.address}
                        onChange={(address) => props.setState({ address: address })}
                        onSelect={handleSelectForMap}
                        searchOptions={{
                            componentRestrictions: { country: 'gb' },
                            // types: ['city']
                        }}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                                <input
                                    className="form-control"
                                    {...getInputProps({
                                        placeholder: 'Prefilled as per location detected',
                                        className: 'location-search-input form-control',
                                    })}
                                />

                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    {props.state.address_error_msg !== "" && <div className="text-danger"> {props.state.address_error_msg} </div>}
                </div>
                <div class="form-group">
                    <label>Postal Code</label>
                    <input type="text" class="form-control"
                        placeholder="Postal Code" value={props.state.postal_code}
                        onChange={async (e) => {
                            props.setState({ postal_code: e.target.value })
                            let result = await postcodes.validate(e.target.value)
                            if (result.result) {
                                props.setState({ postal_error_msg: '' });
                            } else {
                                props.setState({ postal_error_msg: lang.postal_code_validate_msg });
                            }
                        }} />
                    {props.state.postal_error_msg !== "" && <div className="text-danger"> {props.state.postal_error_msg} </div>}
                </div>
            </div>
        </div>
    )
}

export default BusinessAddress
