import React from 'react'

function Terms() {
    return (
        <div className='container mb-5' style={{ marginTop: '76px' }}>
            <div className='d-flex justify-content-center pt-5' >
                <h2 className=''>Terms</h2>
            </div>
            <div className='my-4 text-justify'>
                <p>This Business Supplier Agreement (Agreement) is made and entered into on the date of submission of the business details. The terms of agreement is between PARA Group., a company incorporated under the Companies Act, having its registered office at Nottingham, UK, through Mr. Abhijit Mitra, its duly Authorised Representative (hereinafter referred to as Businesses), of the First Part; AND</p>
        
                <p>Business Supplier a company incorporated under the Companies Act, having its registered office UK (hereinafter referred to as Company), represented by such business owner who submits and accepts the terms.</p>
        
                <p>WHEREAS:</p>
                <p>A. Business is engaged in doing business and is interested to expand digital presence.</p>
                <p>B. Company owns PARA located at the following URL: https://mypara.co.uk hereinafter referred to as the PARA WebSite and have many registered users to whom Company offer various services,</p>
                <p>C. Business is desirous of setting up an online listing / store on PARA Website and has offered to sell its products through the said online listing/store, Company has agreed to create the said online store upon the following terms and conditions.</p>
                <p>D. &nbsp;The above referred Businesses and Company are hereinafter collectively referred to as Parties and individually as Party.</p>
        
                <p>NOW THIS AGREEMENT WITNESSETH HEREWITH:</p>
        
                <p>1. Definitions</p>
                <p>For the purpose of this Agreement, the following words and phrases shall have the meaning assigned to them under this Article.</p>
                <p>1.1. Company shall mean PARA UK.</p>
                <p>1.2. Customer shall mean any individual, group of individuals, firm, company or any other entity placing an order for the Products of the Businesses through the Online Store.</p>
                <p>1.3. Price shall mean the cost at which the Products are to be delivered to the Customer inclusive of Shipping charges, if any.</p>
                <p>1.4. Effective Date shall mean the date on which this Agreement is executed.</p>
                <p>1.5. Form shall mean Form for Supplier ECommerce Service Agreement to be filled in and executed by the Business at the time of execution of this Agreement annexed hereto as Annexure A.</p>
                <p>1.6. Business shall mean the entity incorporated or otherwise more specifically described hereinabove, which sells its products through the Online Store and more particularly described in the attached Form.</p>
                <p>1.7. Online Store/Listing shall mean a virtual electronic Listing / Store created on the PARA Digital PaaS portal for promoting, sales &amp; client engagement of the Businesses Products either through web site of the Company or any other gadget or instrument displaying the particulars of the Businesses Products available for above services , or any other means by which the Customer places an order for the Product of the Businesses.&nbsp;</p>
                <p>1.8. Order shall mean an order for purchase of products wherein the customer has agreed to purchase the product upon the terms and conditions and at the Price indicated on the online listing / store of the Businesses.</p>
                <p>1.9. Products shall mean merchandise items of the Businesses put up for sale on the Online Store by the Businesses.</p>
                <p>1.10. Price means the sale price of a product inclusive of delivery charges and applicable taxes.</p>
                <p>1.11. PARA Digital PaaS means an online platform owned and operated by the Company that facilitates the shopping transaction, promotions between the Businesses and the Customer.</p>
                <p>1.12. Shipping / Delivery Charges shall mean the logistics/courier/postal charges including all taxes incurred for delivering the product(s) to the Customer.</p>
                <p>1.13. Shipment / Delivery Cost shall mean the cost and taxes recovered by the Company from the Businesses per order for handling the logistics.</p>
                <p>1.14. Sign-up Fees shall mean the non-refundable fees payable by the Businesses at the time of execution of this Agreement towards the initial creation of online listing / store.</p>
                <p>1.15. Service charge shall mean the margin per transaction charged by the Company to the Businesses at the rates agreed to between the parties upon the sale of product on online store and same would be mentioned on Annexure A.</p>
                
                <p>2. Arrangement</p>
                <p>2.1. The Company shall offer to the Businesses its services for facilitating online promotions &amp; sale of the product which shall include hosting and technology, customer support, logistics services as a free service.</p>
                <p>2.2. Based on mutual discussions, it is agreed by and between the parties hereto that the Businesses shall put up for promotion &amp; sale of its Products on the said Online Store, subject to the terms and conditions hereinafter contained. Businesses further agrees and acknowledges that the shopping transactions with customer shall be governed by the Terms of Use of PARA Digital PaaS (incorporated in this agreement by way of reference and forms part of this Agreement) along-with this Agreement, the supplier will be directly responsible for quality of service to customer, will be solely responsible to resolve any arbitration with customer and PARA is immune to such arbitrations and legal proceedings.</p>
                
                <p>3. Consideration and Payment Terms</p>
                <p>3.1. Businesses shall be able to avail the basic digital store and ecommerce features &amp; facilities at no charge. In future if there are any premium subscriptions with rich business features and the Business opts for such subscription, then the clauses would be automatically applicable unless there is a separate written agreement between the Company and Business Owner.</p>
                <p>3.2. The premium subscription is a non- refundable fee.</p>
                <p>3.3. There are no transaction based charges / commission / fees, but the policy may change and the businesses would be notified well in advance.</p>
                <p>3.4. In the event any order is reversed due to Damaged product, Quality Issue, Not delivered or Wrong Item delivered, Businesses agrees that the Company has rights to levy a penalty (upto a maximum limit of GBP50) and the said charges will need to be paid within 7days of Inovoiing the &nbsp;Businesses.</p>
                <p>3.5. Further Company shall advise the transaction flow &amp; charges once the ecommerce transaction is applied via PARA website.</p>
                
                <p>4. Obligations of the Businesses</p>
                <p>The Businesses shall:</p>
                <p>4.1. Through the interface provided by the Company on the creation of an Online Store of Businesses, shall upload the product description, images, disclaimer, delivery timelines, price and such other details for the products to be displayed and offered for sale through the said online store.</p>
                <p>4.2. Businesses shall ensure not to upload any description/image/text/graphic that is unlawful, illegal, objectionable, obscene, vulgar, opposed to public policy, prohibited or is in violation of intellectual property rights including but not limited to Trademark and Copyright of any third party. Businesses shall ensure to upload the product description and image only for the product which is offered for sale through the Online Store and for which the said Online Store is created.</p>
                <p>4.3. Businesses shall provide full, correct, accurate and true description of the product so as to enable the customers to make an informed decision.</p>
                <p>4.4. Businesses shall be solely responsible for the quality, quantity, merchantability, guarantee, warranties and all legalities with customers directly in respect of the products offered for sale through their online listing / store.</p>
                <p>4.5. At all times have access to the Internet and its email account to check the status of approved orders,</p>
                <p>4.6. On receipt of the approved order, Businesses shall dispatch / deliver the products within a period not exceeding 48 hours or within the time as specified in the product description on its online store.</p>
                <p>4.7. In respect of the orders for Products placed through the Online Store, Businesses shall submit proof of dispatch to the satisfaction of Company within 48 hours of the request made by Company.</p>
                <p>4.8. In the event the products are not accepted by the Customer due to any wrong / damaged products dispatched, then the same shall be replaced by the Businesses at no extra cost to the aggrieved customer. Since the Company is a Facilitator, the Businesses hereby authorizes the Company to entertain all claims of return of the Product in the mutual interest of the Businesses as well as the Customer.</p>
                <p>4.9. Update the Order Status including Airway Bill Number on a daily basis,</p>
                <p>4.10. The Businesses shall not send any of its promotional or any other information with the Products ordered by the customer and also shall ensure that no material or literature is sent which may be detrimental to the business/commercial interests of the Company,</p>
                <p>4.11. The Businesses shall dispatch the Products of the same description, quality and quantity and price as are described and displayed on the Online Store and for which the Customer has placed the order.</p>
                <p>4.12. Businesses shall charge an amount equivalent to the amount displayed on the online store to the customer and paid by/charged to the customer.</p>
                <p>4.13. The Businesses shall not offer any Products for Sale on the Online Store, which are prohibited for sale, dangerous, against the public policy, banned, unlawful, and illegal or prohibited under the UK laws.</p>
                <p>4.14. The Businesses shall ensure that they own all the legal rights in the Products that are offered for sale on the Online Store.</p>
                <p>4.15. The Businesses shall pass on the legal title, rights and ownership in the Products sold to the Customer.</p>
                <p>4.16. Businesses shall be solely responsible for any dispute that may be raised by the customer relating to the goods, merchandise and services provided by the Businesses.</p>
                <p>4.17. The Businesses shall at all time during the pendency of this agreement endeavour to protect and promote the interests of the Company and ensure that third parties rights including intellectual property rights are not infringed.</p>
                <p>4.18. The Businesses shall at all times be responsible for compliance of all applicable laws and regulations including but not limited to Intellectual Property Rights, Local Sales Tax, Central Sales Tax, Service tax, Value added tax, Standards of Weights &amp; Measures legislation, Sale of Goods Act, Excise and Import duties, Drugs and Cosmetics Act, Drugs and Remedial Magic Act, Code of Advertising Ethics, etc.</p>
                
                <p>5. Warranties, Representations and Undertakings of the Businesses</p>
                
                <p>The Businesses warrants and represents that</p>
                <p>5.1. They have the right and full authority to enter into this Agreement with the Company.</p>
                <p>5.2. All their obligations under this Agreement are legal, valid and binding obligations enforceable in law.</p>
                <p>5.3. There are no proceedings pending, which may have a material adverse effect on their ability to perform and meet their obligations under this Agreement;</p>
                <p>5.4. That they are an authorized business establishment and hold all the requisite permissions, authorities, approvals and sanctions to conduct their business and to enter into an arrangement with the Company. They shall at all times ensure compliance with all the requirements applicable to their business and for the purposes of this arrangement including but not limited to Intellectual Property Rights, Sales Tax, Central Sales Tax, Service tax, Standards of Weights &amp; Measures legislation, Sale of Goods Act, Value added tax, Excise and Import duties, etc. They confirm that they have paid and shall continue to discharge all their obligations towards statutory authorities.</p>
                <p>5.5. That they have adequate rights under relevant laws including but not limited to various Intellectual Property Legislation(s) to enter into this Agreement with the Company and perform the obligations contained herein and that it has not violated/ infringed any intellectual property rights of any third party 6. That they shall provide the Company with copies of any document required by the Company for the purposes of this performance of its obligations under this arrangement within 24 hours of getting a written notice from the Company.</p>
                <p>5.6. That the complete product responsibility and liability shall solely vest with Businesses and that the Businesses shall be solely responsible to the customer for the sale of the Product by Businesses including but not limited to its delivery to the Customer and that Businesses or the Customer shall not raise any claim on the Company in this regard.</p>
                <p>5.7. Businesses agrees and undertakes not to upload any text, images, graphics (for description and display of product on the online store) that is vulgar, obnoxious, inaccurate, false, incorrect, misleading, intimidating, against the public policy.</p>
                <p>5.8. Businesses shall pay the Company a service charge as specified by the Company on every transaction it enables and that Businesses shall provide all completed transaction details to the Company for record keeping and reconciliation.</p>
                <p>5.9. That Businesses shall draw the invoice / bill directly in the name of the Customer.</p>
                <p>5.10. Businesses shall prior to release of any promotion/advertisement material seek prior written approval for the same from the Company, in so far as the same relates to services offered pursuant to the terms of this Agreement.</p>
                
                <p>6. Company reserves the right:</p>
                <p>6.1. Businesses agrees and acknowledges that the Company, at all times during the continuance of this Agreement, shall have the right to remove/block/delete any text, graphic, image(s) uploaded on the online store by the Businesses without any prior intimation to Businesses in the event the said text, image, graphic is found to be in violation of law, breach of any of the terms of this Agreement, terms and conditions of PARA Digital PaaS Website. In such an event, the Company reserve the right to forthwith remove/close the online store of the Businesses without any prior intimation or liability to the Businesses.</p>
                <p>6.2. Company reserves the right to provide and display appropriate disclaimers and terms of use on PARA Digital PaaS portal.</p>
                <p>6.3. At any time if the Company believes that the services are being utilized by the Businesses or its Customer in contravention of the terms and provisions of this Agreement, Terms and conditions of use of PARA Digital PaaS, the Company shall have the right either at its sole discretion or upon the receipt of a request from the legal / statutory authorities or a court order to discontinue/terminate the said service(s) to Customer or the End user as the case may be, without liability to refund the amount to the Businesses to forthwith remove/block/close the online store of the Businesses and furnish such details about the Businesses and/or its customers upon a request received from the Legal/ Statutory Authorities or under a Court order.</p>
                <p>7. Indemnity</p>
                <p>7.1. The Businesses indemnifies and shall hold indemnified the Company, its directors, officers, employees, representatives, agents from and against all losses, damages, claims, suits, legal proceedings and otherwise howsoever arising from or in connection with any claim including but not limited to claim for any infringement of any intellectual property rights or any other rights of any third party or of law, concerning quality, quantity and any claim in relation to the Businesses?s product, the breach of any of the Businesses?s warranties, representations or undertakings or in relation to the non-fulfillment of any of its obligations under this Agreement or arising out of the Businesses infringing any applicable laws, regulations including but not limited to Intellectual Property Rights, Local Sales Tax, Central Sales Tax, Service tax, Value Added tax, The Standards of Weights &amp; Measures legislation, Excise and Import duties, etc . For the purpose of this clause reference to the Company shall also include the Mobile Operators and such other agencies through whom the Company shall make the Online Store available to the Customers.</p>
                <p>7.2. The Company agrees to indemnify and to keep indemnified the Businesses in respect of all claims losses and expenses (including the cost of litigation if any) arising out of any breach or default part of the Company to perform its obligations under this Agreement.</p>
                <p>7.3. This article shall survive the termination or expiration of this Agreement.</p>
                
                <p>8. Company not Liable</p>
                <p>8.1. The Company on the basis of representation by the Businesses has created the online store of the Businesses on PARA Digital PaaS portal to enable Businesses to offer the Businessess products for sale through the said Online Store. This representation is the essence of the Contract.</p>
                <p>8.2. The Company shall under no circumstances be liable or responsible for any loss, injury or damage to the Businesses, or any other party whomsoever, arising on account of any transaction under this Agreement or as a result of the Products being in any way damaged, defective, in unfit condition, infringing/ violating any laws / regulations / intellectual property rights of any third party. Businesses agrees and acknowledges that</p>
                <p>8.3. Businesses shall be solely liable for any claims, damages, allegation arising out of the Products offered for sale through its online store (including but not limited to quality, quantity, price, merchantability, use for a particular purpose, or any other related claim) and shall hold the Company harmless and indemnified against all such claims and damages.</p>
                <p>8.4. Further the Company shall not be liable for any claims, damages arising out of any negligence, misconduct or misrepresentation by the Businesses or any of its representatives.</p>
                <p>8.5. The Businesses hereby agrees, confirms and acknowledges that the Product is owned by the Businesses and that the Company is merely a facilitator for promotion &amp; sale of the Businessess Product, hence the Company is not responsible/ liable for the Product, its design, its function and condition manufacturing and selling and financial obligations, warranties, guarantees whatsoever. The Company reserves its right to state appropriate Disclaimers on its website/ online store.</p>
                
                <p>9. Term, Termination and effects of Termination</p>
                <p>9.1.Term:</p>
                <p>The Term of this Agreement shall commence on the date of execution of the contract and shall continue for a period of 12 months unless terminated earlier. The Agreement may be extended for such further period as may be mutually agreed by and between the parties hereto in writing to this effect.</p>
                
                <p>9.2.This Agreement may be terminated by the Company in the event:</p>
                <p>9.2.1. Businesses fails to make payment of the agreed amount, by giving 48 hours written notice,</p>
                <p>9.2.2. Businesses commit a material breach of any representation, obligations, covenant, warranty or term of this agreement and the same is not rectified within 30 days after written notice given by the Company.</p>
                <p>9.2.3. If a Petition for insolvency is filed against the Businesses.</p>
                <p>9.2.4. If the Businesses are in infringement of the third party rights including intellectual property rights.</p>
                <p>9.2.5. This Agreement may be terminated by either party giving the other 30 days written notice.</p>
                
                <p>9.3.Effect of Termination:</p>
                <p>In the event of termination/expiry of this Agreement, the Company shall remove the Links and shall discontinue display of the Products on Online store with immediate effect. Company shall not be liable for any loss or damages (direct, indirect or inconsequential) incurred by the Businesses by virtue of termination of this agreement.</p>
                
                <p>During the period under notice both the parties shall be bound to perform its obligations incurred under this agreement and this sub-clause shall survive the termination of this agreement.</p>
                
                <p>10. Arbitration</p>
                <p>10.1. Any dispute arising out of or related to or connected with any provisions under this Agreement shall be referred to the arbitration of a single arbitrator to be appointed jointly by the parties.</p>
                <p>10.2. The arbitration shall be conducted in UK in accordance with the Arbitration and Conciliation Act or any modification or re-enactment for the time being in force.</p>
                <p>10.3. The language of arbitration shall be English. The arbitration shall be held at International Court of Law, London, UK.</p>
                <p>10.4. The award of the arbitrator or arbitrators as the case may be shall be final and binding on the parties.</p>
                
                <p>11.Jurisdiction and Governing law</p>
                <p>11.1. The obligations, performance, interpretation and contents shall be governed by UK law.</p>
                <p>11.2. Subject to the provisions of negotiation and arbitration each Party irrevocably and unconditionally submits to the jurisdiction of International Courts at London, UK .</p>
                
                <p>12. Notices</p>
                <p>All notices and other communication under this Agreement shall be in writing and in English and either delivered by hand or sent by telex, fax or courier in each case to the addresses set out at the beginning of this Agreement.</p>
                
                <p>13. Intellectual Property Rights</p>
                <p>It is expressly agreed and clarified that, except as specified agreed in this Agreement, each Party shall retain all right, title and interest in their respective trademarks and logos and that nothing contained in this Agreement, nor the use of the trademark / logos on the publicity, advertising, promotional or other material in relation to the Services shall be construed as giving to any Party any right, title or interest of any nature whatsoever to any of the other Party&rsquo;s trademarks and / or logos.</p>
                
                <p>14. Entire Agreement</p>
                <p>This Agreement embodies the entire agreement and understanding of the Parties and supersedes any and all other prior and contemporaneous agreements, arrangements and understandings (whether written or oral) between the Parties with respect to its subject matter.</p>
                
                <p>15. Assignment</p>
                <p>Neither this Agreement nor any part of it is assignable, transferable, sub-licensable, subcontract-able or conveyable by Businesses, either by operation of law or otherwise, without the express, prior, written consent of the Company signed by an authorized representative of such Party.</p>
                
                <p>16. Confidentiality:</p>
                <p>Businesses agrees and undertake to maintain the confidentiality of the information and user/customer data disclosed, generated or made available to Businesses under this Agreement.</p>
                
                <p>The said information shall not be used by the Businesses for any purpose other than for the performance of its obligations under this Agreement. Businesses agrees that the unauthorized disclosure or use of such Information would cause irreparable harm and significant injury, the degree of which may be difficult to ascertain. Accordingly, Businesses agrees that the Company shall have the right to obtain an immediate injunction from any court of competent jurisdiction enjoining breach of this Agreement and/or disclosure of the Confidential Information. Company shall also have the right to pursue any other rights or remedies available at law or equity for such a breach.</p>
                
                <p>17. Limitation of liability:</p>
                <p>Under no circumstances, except in case of breach of contract, will either party be liable to the other party for lost profits, or for any indirect, incidental, consequential, special or exemplary damages arising from the subject matter of this Agreement, regardless of the type of claim and even if that party has been advised of the possibility of such damages, such as, but not limited to loss of revenue or anticipated profits or loss business, unless such loss or damages is proven by the aggrieved party to have been deliberately caused by the other party.</p>
                
                <p>18. Relationship of Parties</p>
                <p>Nothing in this Agreement will be construed as creating a relationship of partnership, joint venture, agency or employment between the Parties. The Company shall not be responsible for the acts or omissions of the Businesses, and Businesses shall not represent neither has, any power or authority to speak for, represent, bind or assume any obligation on behalf of the Company.</p>
                
                <p>19. Waiver and Amendment</p>
                <p>19.1. No waiver of any breach of any provision of this Agreement constitutes a waiver of any prior, concurrent or subsequent breach of the same or any other provisions, and will not be effective unless made in writing and signed by an authorised representative of the waiving Party.</p>
                <p>19.2. Except as expressly set out in this Agreement, no amendment is binding on the Parties unless it is in writing and signed by a duly authorized representative of each of the Parties.</p>
                
                <p>20. Force Majeure</p>
                <p>Neither Party shall be responsible or liable for any delay or failure to perform its obligations (other than an obligation to make payment) under this Agreement due to unforeseen circumstances or any event which is beyond that Party&apos;s reasonable control and without its fault or negligence, but not limited to, acts of God, war, riots, embargoes, strikes, lockouts, acts of any Government authority, delays in obtaining licenses or rejection of applications under the Statutes, failure of telephone connections or power failure, fire or floods.</p>
                
                <p>By reading the terms, Business Owner is fully aware and agrees to the policies and procedures of the company and by clicking the box of terms by default it is deemed acceptance.</p>
            </div>
        </div>
    )
}

export default Terms