import axios from "axios";
import config from "../config";

export const GuestAPI = {
    getBusinesses: async () => {
        return await axios.get(`${config.API_BASE_URL}/api/fetch-businesses`);
    },
    getSerachContent: async (data) => {
        return await axios.post(`${config.API_BASE_URL}/api/fetch-search-data`, { serach: data });
    },
    getBusinessCategories: async () => {
        return await axios.get(`${config.API_BASE_URL}/api/fetch-business-categories`);
    },
    getFilterBusiness: async (data) => {
        return await axios.post(`${config.API_BASE_URL}/api/fetch-filter-business`, data);
    },
    getDropdownFilterBusiness: async (data) => {
        return await axios.post(`${config.API_BASE_URL}/api/fetch-dropdown-filter-business`, data);
    },
    getProductDetail: async (product_id) => {
        return await axios.get(`${config.API_BASE_URL}/api/fetch-product-detail/`+product_id);
    },
    getProductByCategory: async (data) => {
        return await axios.post(`${config.API_BASE_URL}/api/fetch-product-by-category`, data);
    },
    saveContactDetails: async (data) => {
        return await axios.post(`${config.API_BASE_URL}/api/save-contact-detail`, data);
    },
}