import React, { useState, useReducer, useEffect, Fragment } from 'react'
import '../../../assets/css/UserPanel/userPanel.css';
import { useParams, Link } from 'react-router-dom';
import { DashboardLayout } from '../Sidebar wrapper/Layout'
import { Redirect } from 'react-router-dom'
import { Token } from '../../../Token';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import { Card, Row, Col, Modal, Container, Form } from 'react-bootstrap';
import { Card, Row, Col, Modal, Container } from 'react-bootstrap';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import SampleProducts from '../../../assets/images/products/sample-image.svg';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';
import { Tabs, Tab } from 'react-bootstrap';
import { PercentageOutlined } from '@ant-design/icons';
import { Form, Input, Switch, Checkbox, DatePicker, Spin, InputNumber } from 'antd';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import { ProductAPI } from '../../../apis/user/ProductAPI';
import moment from 'moment';
import { PromotionAPI } from '../../../apis/user/PromotionAPI';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../../assets/images/modalPopup/close-circle.svg';
import AlertTriangle from '../../../assets/images/modalPopup/alert-triangle.svg';

const Promotions = () => {

    const [form] = Form.useForm();
    const params = useParams();
    const business_slug = params.business_id;
    const [loading, setLoader] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [showNewPromotion, setNewPromotionShow] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [promotionsPermission, setPromotionsPermission] = useState([]);
    const steps = ['Promotion Type', 'Promotion details', 'Preview'];

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            _isFlashSale: false,
            _isCouponPromotion: false,
            _isProductPromotion: false,
            promotions: [],
            products: [],
            coupon_id: '',
            coupon_type: '',
            coupon_code: '',
            cart_total_grater_amount: '',
            discount: '',
            limit: '',
            start_date: moment().format('YYYY-MM-DD'),
            end_date: moment().format('YYYY-MM-DD'),
            is_cash_discount: false,
            is_publish_active: true,
            selected_products: [],
            selected_product_discounted_amount: [],
            is_edit: false,
        }
    );


    useEffect(() => {
        setLoader(true);
        getCoupons();

        ProductAPI.getProducts(business_slug)
            .then(res => {
                setState({ products: res.data.products })
                setLoader(false);
            }).catch(error => {
                setLoader(false);
            })
    }, []);

    const getCoupons = (type) => {
        PromotionAPI.getCoupons(business_slug)
            .then(res => {
                setState({ promotions: res.data.coupons })
                setIsAdmin(res.data.isAdmin)
                const per = res.data.permissions?.promotionsPermission || [];
                setPromotionsPermission(per)
                setLoader(false);
            }).catch(error => {
                setLoader(false);
            })
    }

    const onClickCoupnType = (is_edit, type) => {
        setState({
            _isFlashSale: type == 'Flash Sale' ? true : false,
            _isCouponPromotion: type == 'Coupon Promotion' ? true : false,
            _isProductPromotion: type == 'Product Promotion' ? true : false,
            is_edit: is_edit
        })

        if (!is_edit) {
            setState({
                start_date: moment().format('YYYY-MM-DD'),
                end_date: moment().format('YYYY-MM-DD')
            })
            form.setFieldsValue({
                oupon_type: '',
                coupon_code: '',
                cart_total_grater_amount: '',
                discount: '',
                limit: '',
                selected_products: '',
            })
        }
        setNewPromotionShow(true)
        handleReset();
    }

    const onFinish = (value) => {
        if (state.start_date > state.end_date) {
            Swal.fire({
                title: "Please select Valid Date",
                imageUrl: CloseCircle,
                imageAlt: 'error image',
                confirmButtonColor: '#00CA84'
            });
            return
        }
        else {
            let productPromotionDiscountAmount = [];
            let coupon_type = '';
            if (state._isFlashSale) {
                coupon_type = 'Flash Sale';
            } else if (state._isCouponPromotion) {
                coupon_type = 'Coupon Promotion';
            } else if (state._isProductPromotion) {
                coupon_type = 'Product Promotion';
                let j = 0;
                for (var i = 0; i < Object.keys(value).length; i++) {
                    if (Object.keys(value)[i].includes("product_descount_amount_")) {
                        if (Object.values(value)[i] !== undefined) {
                            productPromotionDiscountAmount[j] = {
                                product_id: Object.keys(value)[i].replace('product_descount_amount_', ''),
                                discount_amount: Object.values(value)[i] == '' ? 0 : parseFloat(Object.values(value)[i])
                            }
                            j++;
                        }
                    }
                }
            }

            setState({
                coupon_type: coupon_type,
                coupon_code: value.coupon_code,
                cart_total_grater_amount: value.cart_total_grater_amount,
                discount: value.discount,
                limit: value.limit,
                selected_products: value.selected_products,
                selected_product_discounted_amount: productPromotionDiscountAmount
            })
            handleNext();
        }

    }

    const handleNext = () => {
        if ((activeStep === steps.length - 1)) {
            let data = {
                business_slug: business_slug,
                coupon_id: state.coupon_id,
                coupon_type: state.coupon_type,
                coupon_code: state.coupon_code,
                cart_total_grater_amount: state.cart_total_grater_amount,
                discount: state.discount,
                limit: state.limit,
                selected_products: state.selected_products,
                selected_product_discounted_amount: state.selected_product_discounted_amount,
                is_cash_discount: state.is_cash_discount,
                is_publish_active: state.is_publish_active,
                start_date: state.start_date,
                end_date: state.end_date
            }

            setLoader(true);
            if (!state.is_edit) {
                PromotionAPI.saveCoupon(data)
                    .then(res => {
                        setNewPromotionShow(false)
                        setActiveStep(0)
                        setLoader(false);
                        form.setFieldsValue({
                            oupon_type: '',
                            coupon_code: '',
                            cart_total_grater_amount: '',
                            discount: '',
                            limit: '',
                            selected_products: '',
                        })
                        setState({
                            start_date: moment().format('YYYY-MM-DD'),
                            end_date: moment().format('YYYY-MM-DD')
                        })

                        Swal.fire({
                            title: res.data.message,
                            imageUrl: CheckmarkCircle,
                            imageAlt: 'success image',
                            confirmButtonColor: '#00CA84'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                getCoupons();
                            }
                        });

                    }).catch(error => {
                        setLoader(false);
                        Swal.fire({
                            title: error.response.data.message,
                            imageUrl: CloseCircle,
                            imageAlt: 'error image',
                            confirmButtonColor: '#00CA84'
                        });
                    })

            } else {
                PromotionAPI.updateCoupon(data)
                    .then(res => {
                        setNewPromotionShow(false)
                        setActiveStep(0)
                        setLoader(false);
                        form.setFieldsValue({
                            oupon_type: '',
                            coupon_code: '',
                            cart_total_grater_amount: '',
                            discount: '',
                            limit: '',
                            selected_products: '',
                        })

                        setState({
                            start_date: moment().format('YYYY-MM-DD'),
                            end_date: moment().format('YYYY-MM-DD')
                        })
                        Swal.fire({
                            title: res.data.message,
                            imageUrl: CheckmarkCircle,
                            imageAlt: 'success image',
                            confirmButtonColor: '#00CA84'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                getCoupons();
                            }
                        });

                    }).catch(error => {
                        setLoader(false);
                        Swal.fire({
                            title: error.response.data.message,
                            imageUrl: CloseCircle,
                            imageAlt: 'error image',
                            confirmButtonColor: '#00CA84'
                        });
                    })
            }

        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
        // !(activeStep === steps.length - 1) && setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 0) {
            setNewPromotionShow(false)
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleReset = () => { setActiveStep(0); };

    const onChangePromotionStatus = (e, coupon_id) => {
        setLoader(true)
        PromotionAPI.changeCouponStatus(e.target.value, coupon_id)
            .then(function (res) {
                setLoader(false)
                Swal.fire({
                    text: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                });
            }).catch(function (error) {
                setLoader(false)
                console.log(error);
            });
    }

    const onDeletePromotion = (coupon_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            imageUrl: AlertTriangle,
            showCancelButton: true,
            confirmButtonColor: '#00CA84',
            cancelButtonColor: '#FF5858',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoader(true)
                PromotionAPI.deleteCoupon(coupon_id)
                    .then(function (res) {
                        getCoupons();
                        setLoader(false)
                        Swal.fire({
                            text: res.data.message,
                            imageUrl: CheckmarkCircle,
                            imageAlt: 'success image',
                            confirmButtonColor: '#00CA84'
                        });
                    }).catch(function (error) {
                        setLoader(false)
                        console.log(error);
                    });
            }
        });
    }

    const getPromotionDetail = (promotion) => {
        form.resetFields();

        var pIds = [];
        promotion.product_discounts.forEach((pd, i) => {
            pIds[i] = pd.product_id;
            form.setFieldsValue(JSON.parse(`\n{ "product_descount_amount_${pd.product_id}": ${pd.discount_amount} }`))
        });

        if (promotion.start_date) {

            setState({
                start_date: moment(promotion.start_date).format('YYYY-MM-DD'),
                end_date: moment(promotion.end_date).format('YYYY-MM-DD'),
            })
        }

        form.setFieldsValue({
            coupon_type: promotion.coupon_type,
            coupon_code: promotion.coupon_code,
            cart_total_grater_amount: promotion.cart_total_grater_amount,
            discount: promotion.discount,
            limit: promotion.limit,
            products: promotion.products,
            selected_products: pIds
        })

        setState({
            coupon_id: promotion.id,
            coupon_type: promotion.coupon_type,
            coupon_code: promotion.coupon_code,
            cart_total_grater_amount: promotion.cart_total_grater_amount,
            discount: promotion.discount,
            limit: promotion.limit,
            selected_products: promotion.selected_products
        })
        onClickCoupnType(true, promotion.coupon_type)
        setActiveStep(1)
    }

    if (Token.isLoggedIn()) {
        return (
            <DashboardLayout page='promotions'>
                <div className="w-100 sidebar-right-content-spacing">
                    <div className="sidebar-content">
                        <div className="blackBox-heading d-flex justify-content-between ">
                            <p className="align-self-center mb-0">You are viewing {Token.getFullName()}</p>
                            <Link className="btn py-0" to={`/seller/business/${params.business_id}`}><ArrowBackIcon /> Home</Link>
                        </div>
                        <h4>Promotions</h4>
                        {
                            (promotionsPermission.includes("Create") || isAdmin) &&
                            <Row>
                                <Col lg={4} className='my-2 flex-column'>
                                    <Card className="bgGreen text-white h-100">
                                        <Link className='text-white'
                                            onClick={() => onClickCoupnType(false, 'Flash Sale')} >
                                            <Card.Body className=' align-items-start flex-column h-100'>
                                                <div className="mb-auto w-100">
                                                    <Card.Title>Flash Sale</Card.Title>
                                                    <p>Offer a fixed £ amount off on orders placed by user Eg. £2 off on all orders</p>
                                                </div>
                                                <span>Create promotion<ArrowForwardRoundedIcon /></span>

                                            </Card.Body>
                                        </Link>

                                    </Card>
                                </Col>
                                <Col lg={4} className='my-2 flex-column'>
                                    <Card className="bgGreen text-white h-100">
                                        <Link className='text-white' onClick={() => onClickCoupnType(false, 'Coupon Promotion')} >
                                            <Card.Body className=' align-items-start flex-column h-100'>
                                                <div className="mb-auto w-100">
                                                    <Card.Title>Coupon promotion</Card.Title>
                                                    <p>Offer a % discount on the total order with an amount cap. Eg. 20% off on orders over £5 (upto £3)</p>
                                                </div>
                                                <span>Create Promotion<ArrowForwardRoundedIcon />
                                                </span>

                                            </Card.Body>

                                        </Link>
                                    </Card>
                                </Col>
                                <Col lg={4} className='my-2 flex-column'>
                                    <Card className="bgGreen text-white h-100">
                                        <Link className='text-white' onClick={() => onClickCoupnType(false, 'Product Promotion')} >
                                            <Card.Body className=' align-items-start flex-column'>
                                                <div className="mb-auto w-100">
                                                    <Card.Title>Product Promotion</Card.Title>
                                                    <p>Offer discounts for specific products or items in your store.  Eg. 20% off on all desserts</p>
                                                </div>

                                                <span>Create Promotion<ArrowForwardRoundedIcon /></span>

                                            </Card.Body>


                                        </Link>
                                    </Card>
                                </Col>
                            </Row>
                        }

                        <div className="promotions-box mt-4">
                            <h4>Active Promotions</h4>
                            <p className="text-ParaGrey">Allow users to apply discount codes on the total order and save money in order to boost your sales.</p>
                            <div className="paraTabs">
                                <Tabs defaultActiveKey="flashsale" transition={false}>
                                    <Tab eventKey="flashsale" title="Flash Sale">
                                        <div class="table-responsive rounded-paraTable mt-4">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th className="fw-500">DISCOUNT TYPE</th>
                                                        <th className="fw-500">DISCOUNT</th>
                                                        {/* <th className="fw-500">AVAILED</th> */}
                                                        <th className="fw-500">START</th>
                                                        <th className="fw-500">END</th>
                                                        <th className="fw-500">STATUS</th>
                                                        <th className="fw-500">APPROVE</th>
                                                        <th className="fw-500">Created By</th>
                                                        <th className="fw-500">ACTION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        state.promotions.map((promotion, i) => {
                                                            if (promotion.coupon_type == 'Flash Sale')
                                                                return (
                                                                    <tr key={i}>
                                                                        <td className="align-middle">{promotion.coupon_type}</td>
                                                                        <td className="align-middle">{promotion.discount_type == 'Cash' ? `£${promotion.discount}` : `${promotion.discount}%`}</td>
                                                                        {/* <td className="align-middle">{promotion.limit}</td> */}
                                                                        <td className="align-middle">{moment(promotion.start_date).format('DD-MM-YYYY')}</td>
                                                                        <td className="align-middle">{moment(promotion.end_date).format('DD-MM-YYYY')}</td>
                                                                        <td className="align-middle text-center">
                                                                            <div className="d-inline">
                                                                                <select className="form-selectOption option-redText d-inline"
                                                                                    onChange={(e) => {
                                                                                        onChangePromotionStatus(e, promotion.id)
                                                                                    }}>
                                                                                    <option className="option-greenText" value="Active" selected={promotion.coupon_status == 'Active'}>Active</option>
                                                                                    <option className="option-redText" value="Inactive" selected={promotion.coupon_status == 'Inactive'}>Inactive</option>
                                                                                </select>
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle">{promotion.is_approved}</td>
                                                                        <td className="align-middle">{promotion.user.first_name + " " + promotion.user.last_name}</td>
                                                                        <td>
                                                                            {
                                                                                (promotionsPermission.includes("Edit") || isAdmin) &&
                                                                                <button className="btn btn-sm btn-outline-green ml-3"
                                                                                    onClick={() => getPromotionDetail(promotion)}><EditOutlinedIcon /></button>
                                                                            }

                                                                            {
                                                                                (promotionsPermission.includes("Delete") || isAdmin) &&
                                                                                <button className="btn btn-sm btn-outline-red ml-3"
                                                                                    onClick={() => onDeletePromotion(promotion.id)}><DeleteOutlineOutlinedIcon /></button>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="couponpromotions" title="Coupon promotions">
                                        <div class="table-responsive rounded-paraTable mt-4">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th className="fw-500">COUPON CODE</th>
                                                        <th className="fw-500">DISCOUNT TYPE</th>
                                                        <th className="fw-500">DISCOUNT</th>
                                                        {/* <th className="text-center fw-500">AVAILED</th> */}
                                                        <th className="fw-500">START</th>
                                                        <th className="fw-500">END</th>
                                                        <th className="text-center fw-500">STATUS</th>
                                                        <th className="fw-500">APPROVE</th>
                                                        <th className="fw-500">Created By</th>
                                                        <th className="text-center fw-500">ACTION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        state.promotions.map((promotion, i) => {
                                                            if (promotion.coupon_type == 'Coupon Promotion')
                                                                return (
                                                                    <tr key={i}>
                                                                        <td className="align-middle"><p className="mb-0">{promotion.coupon_code}</p></td>
                                                                        <td className="align-middle"><p className="mb-0">{promotion.coupon_type}</p></td>
                                                                        <td className="align-middle"><p className="mb-0">{promotion.discount_type == 'Cash' ? `£${promotion.discount}` : `${promotion.discount} %`}</p></td>
                                                                        {/* <td className="align-middle"><p className="mb-0">{promotion.limit}</p></td> */}
                                                                        <td className="align-middle"><p className="mb-0">{moment(promotion.start_date).format('DD-MM-YYYY')}</p></td>
                                                                        <td className="align-middle"><p className="mb-0">{moment(promotion.end_date).format('DD-MM-YYYY')}</p></td>
                                                                        <td className="align-middle text-center">
                                                                            <div className="d-inline">
                                                                                <select className="form-selectOption option-redText d-inline"
                                                                                    onChange={(e) => {
                                                                                        onChangePromotionStatus(e, promotion.id)
                                                                                    }}>
                                                                                    <option className="option-greenText" value="Active" selected={promotion.coupon_status == 'Active'}>Active</option>
                                                                                    <option className="option-redText" value="Inactive" selected={promotion.coupon_status == 'Inactive'}>Inactive</option>
                                                                                </select>
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle">{promotion.is_approved}</td>
                                                                        <td className="align-middle">{promotion.user.first_name + " " + promotion.user.last_name}</td>

                                                                        <td>
                                                                            {
                                                                                (promotionsPermission.includes("Edit") || isAdmin) &&
                                                                                <button className="btn btn-sm btn-outline-green ml-3"
                                                                                    onClick={() => getPromotionDetail(promotion)}><EditOutlinedIcon /></button>
                                                                            }

                                                                            {
                                                                                (promotionsPermission.includes("Delete") || isAdmin) &&
                                                                                <button className="btn btn-sm btn-outline-red ml-3"
                                                                                    onClick={() => onDeletePromotion(promotion.id)}><DeleteOutlineOutlinedIcon /></button>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="productpromotions" title="Product promotions">
                                        <div class="table-responsive rounded-paraTable mt-4">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th className="fw-500">PRODUCT NAME</th>
                                                        <th className="text-center fw-500">AMOUNT</th>
                                                        {/* <th className="text-center fw-500">PROMOTION</th> */}
                                                        {/* <th className="text-center fw-500">AVAILED</th> */}
                                                        <th className="text-center fw-500">STATUS</th>
                                                        <th className="fw-500">APPROVE</th>
                                                        <th className="fw-500">Created By</th>
                                                        <th className="text-center fw-500">ACTION</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        state.promotions.map((promotion, i) => {
                                                            if (promotion.coupon_type == 'Product Promotion')
                                                                return (
                                                                    <tr key={i}>
                                                                        <td className="align-middle">
                                                                            {
                                                                                promotion.products.map((product, i) => {
                                                                                    return (
                                                                                        <p className="mb-0" key={i}>{`#${++i}) ${product.product_name}`}</p>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            {
                                                                                promotion.product_discounts.map((pd, i) => {
                                                                                    return (
                                                                                        <p className="mb-0" key={i}>{promotion.discount_type == 'Cash' ? `£${pd.discount_amount}` : `${pd.discount_amount} %`}</p>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </td>
                                                                        {/* <td className="align-middle"><p className="mb-0">{promotion.limit}</p></td> */}
                                                                        <td className="align-middle text-center">
                                                                            <div className="d-inline">
                                                                                <select className="form-selectOption option-redText d-inline"
                                                                                    onChange={(e) => {
                                                                                        onChangePromotionStatus(e, promotion.id)
                                                                                    }}>
                                                                                    <option className="option-greenText" value="Active" selected={promotion.coupon_status == 'Active'}>Active</option>
                                                                                    <option className="option-redText" value="Inactive" selected={promotion.coupon_status == 'Inactive'}>Inactive</option>
                                                                                </select>
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle">{promotion.is_approved}</td>
                                                                        <td className="align-middle">{promotion.user.first_name + " " + promotion.user.last_name}</td>

                                                                        <td>
                                                                            {
                                                                                (promotionsPermission.includes("Edit") || isAdmin) &&
                                                                                <button className="btn btn-sm btn-outline-green ml-3"
                                                                                    onClick={() => getPromotionDetail(promotion)}><EditOutlinedIcon /></button>
                                                                            }

                                                                            {
                                                                                (promotionsPermission.includes("Delete") || isAdmin) &&
                                                                                <button className="btn btn-sm btn-outline-red ml-3"
                                                                                    onClick={() => onDeletePromotion(promotion.id)}><DeleteOutlineOutlinedIcon /></button>
                                                                            }

                                                                        </td>
                                                                    </tr>
                                                                )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab>

                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ===== Modal: New promotion ======== */}

                <Modal centered size="lg" show={showNewPromotion} onHide={() => setNewPromotionShow(false)}>
                    <Modal.Header className='d-flex justify-content-center'>
                        <Modal.Title className='ft-size18'>New promotion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='stepper-progressBar-box'>
                            <Stepper activeStep={activeStep} alternativeLabel className='p-0'>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <div>
                                <div>
                                    {/* <Typography className='my-2'>{getStepContent(activeStep)}</Typography> */}
                                    <Typography className='my-4'>

                                        {(() => {
                                            switch (activeStep) {
                                                case 0:
                                                    return <div>
                                                        <ul className="onCheck-colorBox pl-0">
                                                            <li className="onCheck-colorBox-list">
                                                                <input type="radio" name="checkBorder_color"
                                                                    id="checkBorder-color1" className="input-colorBorder"
                                                                    onClick={() => onClickCoupnType(false, 'Coupon Promotion')}
                                                                />
                                                                <label htmlFor="checkBorder-color1">
                                                                    <div className={state._isCouponPromotion ? 'colorBox-card p-3' : 'colorBox-cardP p-3'}>
                                                                        <h6>Coupon Promotion</h6>
                                                                        <p className='mb-0'>Coupon discounts are availed by users on checkout by entering a ‘discount code’. Discount codes can be a % or £ style discount with a limit and a condition. Eg. 20% off on orders over £40 upto £3</p>
                                                                    </div>
                                                                </label>
                                                            </li>
                                                            <li className="onCheck-colorBox-list">
                                                                <input type="radio" name="checkBorder_color"
                                                                    id="checkBorder-color2" className="input-colorBorder"
                                                                    onClick={() => onClickCoupnType(false, 'Flash Sale')}
                                                                />
                                                                <label htmlFor="checkBorder-color2">
                                                                    <div className={state._isFlashSale ? 'colorBox-card p-3' : 'colorBox-cardP p-3'}>
                                                                        <h6>Flash Sale</h6>
                                                                        <p className='mb-0'>This type of Promotion allows you to have a flat amount off on all orders for all users, for a specific time duration. Flash sales do not required a code to be availed. Eg. For the next 3 hours £2 off on all orders. </p>
                                                                    </div>
                                                                </label>
                                                            </li>
                                                            <li className="onCheck-colorBox-list">
                                                                <input type="radio"
                                                                    name="checkBorder_color"
                                                                    id="checkBorder-color3"
                                                                    className="input-colorBorder"
                                                                    onClick={() => onClickCoupnType(false, 'Product Promotion')}
                                                                />
                                                                <label htmlFor="checkBorder-color3">
                                                                    <div className={state._isProductPromotion ? 'colorBox-card p-3' : 'colorBox-cardP p-3'}>
                                                                        <h6>Product Promotion</h6>
                                                                        <p className='mb-0'>Offer discounts on best sellers or products that arent selling. This type of promotion allows you markdown certain products or categories of products for a certain time period.</p>
                                                                    </div>
                                                                </label>
                                                            </li>
                                                        </ul>
                                                    </div>;
                                                case 1:
                                                    return <div>
                                                        {
                                                            state._isFlashSale &&
                                                            <div class="alert alert-para-primary flash-sale d-flex" role="alert">
                                                                <div className="align-self-center mr-3">
                                                                    <WbIncandescentOutlinedIcon />
                                                                </div>
                                                                <p className='mb-0'>Flash sales are accesable to all shoppers and is a flat rate that applies to all products in your shop.</p>
                                                            </div>
                                                        }
                                                        <Form
                                                            form={form}
                                                            onFinish={onFinish}
                                                        >
                                                            {
                                                                state._isProductPromotion == false ?
                                                                    <div className='py-3 rounded-8 greyBox'>
                                                                        <Container>
                                                                            {
                                                                                state._isCouponPromotion &&
                                                                                <>
                                                                                    <Row>
                                                                                        <Col xs={6}>
                                                                                            <label className="form-label">Coupon Code</label>
                                                                                            <Form.Item
                                                                                                className="form-item"
                                                                                                name="coupon_code"
                                                                                                rules={[
                                                                                                    { required: true, message: 'Please input your coupon code.' }
                                                                                                ]}
                                                                                            >
                                                                                                <Input disabled={state.is_edit} className='text-dark' size="large" placeholder="Enter coupon code" />
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                        <Col xs={6}>
                                                                                            <label className="form-label">If cart total is greater than</label>
                                                                                            <Form.Item
                                                                                                className="form-item"
                                                                                                name="cart_total_grater_amount"
                                                                                                rules={[
                                                                                                    { required: true, message: 'Please input your amount.' }
                                                                                                ]}
                                                                                            >
                                                                                                <Input placeholder="Enter amount (£)" type="number" step="0.01" min="0.00" />
                                                                                            </Form.Item>
                                                                                        </Col>
                                                                                    </Row>
                                                                                    <hr />
                                                                                </>
                                                                            }
                                                                            <Row>
                                                                                <Col xs={6}>
                                                                                    <label className="form-label">Discount Amount</label>
                                                                                    <Form.Item
                                                                                        className="form-item"
                                                                                        name="discount"
                                                                                        rules={[
                                                                                            { required: true, message: 'Please input your discount amount.' }
                                                                                        ]}
                                                                                    >
                                                                                        <Input placeholder="Eg. 40" type="number" step="0.01" min="0.00" />

                                                                                    </Form.Item>
                                                                                </Col>

                                                                                {
                                                                                    state._isCouponPromotion &&
                                                                                    <Col xs={6}>
                                                                                        <label className="form-label">Limit <span style={{ fontSize: "12px", color: "#000" }} className="form-label">(Number of users that can use the Coupon)</span></label>
                                                                                        <Form.Item
                                                                                            className="form-item"
                                                                                            name="limit"
                                                                                            rules={[
                                                                                                { required: true, message: 'Please input your limit.' }
                                                                                            ]}
                                                                                        >
                                                                                            <Input placeholder="Eg. 40" type="number" min="0" />
                                                                                        </Form.Item>
                                                                                    </Col>
                                                                                }
                                                                                <Col xs={12}>
                                                                                    <div className='d-flex mt-3'>
                                                                                        <Switch defaultChecked={state.is_cash_discount}
                                                                                            onChange={(checked) => setState({ is_cash_discount: checked })}
                                                                                        />
                                                                                        <p className='mb-0 small ml-2'>Switch to cash discount</p>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <hr />

                                                                            <Row>
                                                                                <Col xs={6}>
                                                                                    <label className="form-label">Start date</label>
                                                                                    <Form.Item
                                                                                        className="form-item"
                                                                                        name="start_date"
                                                                                        rules={[
                                                                                            { required: state.start_date == '' ? true : false, message: 'Please select your Start date.' }
                                                                                        ]}
                                                                                    >
                                                                                        <DatePicker className='form-control'
                                                                                            defaultValue={moment(state.start_date, 'YYYY-MM-DD')}
                                                                                            format="YYYY-MM-DD"
                                                                                            disabledDate={current => { return current && current < moment().subtract('1', 'days') }}
                                                                                            onChange={
                                                                                                (value, dateString) => {
                                                                                                    value !== null ? setState({ start_date: dateString }) : setState({ start_date: '' })
                                                                                                }
                                                                                            } />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                                <Col xs={6}>
                                                                                    <label className="form-label">End Date</label>
                                                                                    <Form.Item
                                                                                        className="form-item"
                                                                                        name="end_date"
                                                                                        rules={[
                                                                                            { required: state.end_date == '' ? true : false, message: 'Please select your end date.' }
                                                                                        ]}
                                                                                    >
                                                                                        <DatePicker className='form-control'
                                                                                            defaultValue={moment(state.end_date, 'YYYY-MM-DD')}
                                                                                            format="YYYY-MM-DD"
                                                                                            disabledDate={current => { return current && current < moment(state.start_date) }}
                                                                                            onChange={
                                                                                                (value, dateString) => {
                                                                                                    value !== null ? setState({ end_date: dateString }) : setState({ end_date: '' })
                                                                                                }
                                                                                            }
                                                                                        />
                                                                                    </Form.Item>
                                                                                </Col>
                                                                            </Row>
                                                                        </Container>
                                                                    </div> :

                                                                    <div class="apply-promotion-tableBox">
                                                                        <div class="table-responsive rounded-paraTable">
                                                                            <table class="table">
                                                                                <tbody>
                                                                                    <Form.Item
                                                                                        className="mb-0 table-promotion-row"
                                                                                        name="selected_products"
                                                                                        rules={[
                                                                                            { required: true, message: 'Please select atleast one product.' }
                                                                                        ]}

                                                                                    >
                                                                                        <Checkbox.Group>
                                                                                            {
                                                                                                state.products.map((product, i) => {
                                                                                                    return (
                                                                                                        < Row key={product.id} className='table-promotion-list'>
                                                                                                            <Col md={1}>
                                                                                                                <Checkbox value={product.id} className='apply-promotion-row apply-promotion-row-selected' />
                                                                                                            </Col>
                                                                                                            <Col md={5}>
                                                                                                                <div className="d-flex">
                                                                                                                    <img src={product.product_photos.length > 0 ? product.product_photos[0] : SampleProducts} alt="" className="mr-2" width="56" height="56" />
                                                                                                                    <div className="align-self-center">
                                                                                                                        <p className="mb-0 fw-500">{product.product_name}</p>
                                                                                                                        <p className="mb-0 greyText ft-size13">Current price:<span className='greenText'> £{product.price}</span></p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                            <Col md={3}>
                                                                                                                {/* <p className="mb-0 text-center align-middle">Price after discount</p> */}
                                                                                                                <p className="mb-0 text-center align-middle">(£) Discount Amount</p>
                                                                                                            </Col>
                                                                                                            <Col md={3}>
                                                                                                                <p className="mb-0 text-center align-middle">
                                                                                                                    <Form.Item
                                                                                                                        className="form-item"
                                                                                                                        name={`product_descount_amount_${product.id}`}
                                                                                                                    // rules={[
                                                                                                                    //     { required: true, message: 'Please input amount.' }
                                                                                                                    // ]}
                                                                                                                    >
                                                                                                                        <Input placeholder="Enter amount" type="number" step="0.01" min="0.00"
                                                                                                                            defaultValue={0} />
                                                                                                                    </Form.Item>
                                                                                                                </p>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Checkbox.Group>
                                                                                    </Form.Item>

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                            }
                                                            <hr />
                                                            <div className='d-flex justify-content-end'>
                                                                <button
                                                                    onClick={() => setNewPromotionShow(false)}
                                                                    className="btn btn-outline-danger mr-3"
                                                                    htmlType="button"
                                                                >
                                                                    Cancel
                                                                </button>
                                                                <button
                                                                    disabled={activeStep === 0 || (state.is_edit && activeStep === 1)}
                                                                    onClick={handleBack}
                                                                    className="btn btn-outline-secondary mr-3"
                                                                    htmlType="button"
                                                                >
                                                                    Back
                                                                </button>
                                                                <button htmlType="submit" className="btn btnPara-primary btnWithSvg" >
                                                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                                    <ArrowForwardRoundedIcon style={{ fontSize: '20px', top: '8px' }} />
                                                                </button>
                                                            </div>
                                                        </Form>
                                                    </div>
                                                case 2:
                                                    return <div>
                                                        {
                                                            !state._isProductPromotion &&
                                                            <Fragment>
                                                                <div class="d-flex justify-content-between">
                                                                    <h6>Code</h6>
                                                                    <h6>{state.coupon_code}</h6>
                                                                </div>
                                                                <div class="d-flex justify-content-between">
                                                                    <h6>Action</h6>
                                                                    <h6>Cart total</h6>
                                                                </div>
                                                                {
                                                                    (state.cart_total_grater_amount != '' && state.cart_total_grater_amount != undefined) &&
                                                                    <div class="d-flex justify-content-between">
                                                                        <h6>Condition</h6>
                                                                        <h6>Cart total Greater than £{state.cart_total_grater_amount}</h6>
                                                                    </div>
                                                                }

                                                                <div class="d-flex justify-content-between">
                                                                    <h6>Discount</h6>
                                                                    <h6>{` ${state.is_cash_discount ? ` £${state.discount}` : `${state.discount} %`}`}</h6>
                                                                </div>
                                                                {
                                                                    state._isCouponPromotion &&
                                                                    <div class="d-flex justify-content-between">
                                                                        <h6>Limit</h6>
                                                                        <h6>{state.limit}</h6>
                                                                    </div>
                                                                }
                                                                <div class="d-flex justify-content-between">
                                                                    <h6>Start Date</h6>
                                                                    <h6>{moment(state.start_date).format('DD/MM/YYYY')}</h6>

                                                                </div>
                                                                <div class="d-flex justify-content-between">
                                                                    <h6>End Date</h6>
                                                                    <h6>{moment(state.end_date).format('DD/MM/YYYY')}</h6>
                                                                </div>
                                                            </Fragment>
                                                        }
                                                        <hr />
                                                        {
                                                            (state.cart_total_grater_amount != '' && state.cart_total_grater_amount != undefined) &&
                                                            <>
                                                                <h6 className='ft-size18 greenText'>{`${state.is_cash_discount ? '£' : '%'} ${state.discount} `} off on cart total greater than £{state.cart_total_grater_amount}</h6>
                                                                <hr />
                                                            </>
                                                        }

                                                        <div class="d-flex justify-content-between mt-4">
                                                            <h6 className='mb-0'>Publish Active</h6>
                                                            <Switch defaultChecked={state.is_publish_active} onChange={(checked) => setState({ is_publish_active: checked })} />
                                                        </div>
                                                    </div>;
                                                default:
                                                    return 'Unknown stepIndex';
                                            }
                                        })()}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        {
                            activeStep != 1 &&
                            <>
                                <hr />
                                <div className='d-flex justify-content-end'>
                                    <button
                                        onClick={() => setNewPromotionShow(false)}
                                        className="btn btn-outline-danger mr-3"
                                        htmlType="button"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleBack}
                                        className="btn btn-outline-secondary mr-3"
                                        htmlType="button"
                                    >
                                        Back
                                    </button>

                                    <button htmlType="button" className="btn btnPara-primary btnWithSvg" onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        <ArrowForwardRoundedIcon style={{ fontSize: '20px', top: '8px' }} />
                                    </button>
                                </div>
                            </>
                        }
                    </Modal.Body>
                </Modal >

            </DashboardLayout >
        )
    }
    else {
        return <Redirect to='/' />
    }
}

export default Promotions
