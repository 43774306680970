import React, { useEffect, useState } from 'react';
import { Spin, notification } from 'antd'
import { DashboardLayout } from '../Layout/Layout';
import { UserApi } from '../../../apis/admin/UserApi';
import { Pagination } from 'antd';

const CustomerList = () => {
    const [loading, setLoader] = useState(false);
    const [UserList, setUserList] = useState([])
    const [TotalData, setTotalData] = useState(0)
    const [search, setSearch] = useState('')
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)

    useEffect(() => {
        loadData(search, 1)
    }, []);

    const HandlePagination = (PageNumber) => {
        loadData(search, PageNumber)
        setPage(PageNumber)
    }

    const loadData = async (search, page) => {
        const data = {
            search, limit, page
        }

        UserApi.getAllCustomerList(data)
            .then((res) => {
                setTotalData(res.data.count)
                setUserList(res.data.data)
            })
    }

    const onSearch = async (search) => {
        loadData(search, page)
        setSearch(search)
    }

    const OnDropDownActive = async (e, id) => {
        let status;
        if (e.target.value == "Active") {
            status = 1;
        }
        if (e.target.value == "Inactive") {
            status = 0;
        }
        const data = {
            status,
            user_id: id

        }

        UserApi.user_StatusUpdate(data)
            .then((res) => {
                notification.success({ message: res.data.message })
            })
    }

    return (
        <DashboardLayout page='Admin-User'>
            <div className="w-100 " style={{ marginTop: "100px" }}>

                <div className="sidebar-content">

                    <div className="d-flex justify-content-between">
                        <h3 className="mb-3">Customers</h3>
                    </div>
                    
                    <div className="paraTabs">
                        <div className="table-responsive paraRounded-table mt-4">
                            <div className="d-flex mb-3">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control product-search"
                                        placeholder="Search customer"
                                        value={search}
                                        onChange={(e) => onSearch(e.target.value)}
                                    />
                                </div>
                            </div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="fw-500">Name</th>
                                        <th scope="col" className="fw-500">Email</th>
                                        <th scope="col" className="fw-500">Phone Number</th>
                                        <th scope="col" className="fw-500">Status</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {UserList?.map((user, i) => {
                                        return <tr key={user.id}>
                                            <td className="align-middle"><p className="mb-0">{user.first_name + " " + user.last_name}</p></td>
                                            <td className="align-middle"><p className="mb-0">{user.email}</p></td>
                                            <td className="align-middle"><p className="mb-0">{user.mobile_number || '-'}</p></td>
                                            <td className="align-middle"><p className="mb-0 text-success">
                                                <select className='mr-2 border-0'
                                                    onChange={(e) => OnDropDownActive(e, user.id)}
                                                >
                                                    <option value="Inactive" className=" d-none">Inactive</option>
                                                    <option selected={user.user_status} value="Inactive">Inactive</option>
                                                    <option selected={user.user_status} value="Active">Active</option>
                                                </select>
                                            </p></td>
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-5">
                        <Pagination defaultCurrent={1} onChange={HandlePagination} total={TotalData} />
                    </div>
                </div>

            </div>

        </DashboardLayout >
    );
};

export default CustomerList;