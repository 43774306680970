import React from 'react'
import '../Components/styles/css/SOP_formSubmitted/sop_formSubmitted.css'
import Swal from 'sweetalert2';
import AlertTriangle from '../assets/images/modalPopup/alert-triangle.svg';
import { useHistory } from "react-router-dom";
import { Token } from '../Token';



const SOP_FORM_SUBMITTED = (props) => {

    let history = useHistory();

    const editprofile=()=>{

        if (Token.isLoggedIn()) {
            history.push('/seller-profile/pastOrders')
        } else{
            // props.notify('Please login to edit your profile')
            Swal.fire({
                title: 'Please login to edit your profile',
                imageUrl: AlertTriangle,
                imageAlt: 'warning image',
                confirmButtonColor: '#00CA84'
            });
        }
    }

    return (
        <div className=' rootDiv  d-flex justify-content-center'>
            <img src='https://para-assets.s3.eu-west-1.amazonaws.com/SOP_formSubmitted/ellipse11.svg' className='ellipse111 content-to-hide' alt="ellipse" />
            <img src='https://para-assets.s3.eu-west-1.amazonaws.com/SOP_formSubmitted/ellipse22.svg' className='ellipse222 content-to-hide' alt="ellipse" />
            {/* <img src={Ellipse333} className='ellipse3 content-to-hide' /> */}
            <img src='https://para-assets.s3.eu-west-1.amazonaws.com/SOP_formSubmitted/thumbsUp.svg' className='thumbsup content-to-hide' alt="thumbsup" />
            <img src='https://para-assets.s3.eu-west-1.amazonaws.com/SOP_formSubmitted/arrowdown.svg' className='arrowdown content-to-hide' alt="arowdown" />
            <img src='https://para-assets.s3.eu-west-1.amazonaws.com/SOP_formSubmitted/key.svg' className='key content-to-hide' alt="key" />
            <img src='https://para-assets.s3.eu-west-1.amazonaws.com/SOP_formSubmitted/hand_money.svg' className='moneyhand content-to-hide' alt="money_hand" />
            <div className='  justify-content-center'>
                <div className='col-xl-12 col-xs-12  ' >
                    <div className='box11  row d-flex justify-content-center '>
                        <div className='row col-xl-8 col-md-8    justify-content-center'>
                            <div className='row textDiv  d-flex justify-content-start '>
                                <div className='col-xl-1 col-md-1   d-flex justify-content-center align-items-start mt-3'>
                                    <img src='https://para-assets.s3.eu-west-1.amazonaws.com/SOP_formSubmitted/Icon_Outline_checkmark-circle.svg' alt="checked" />
                                </div>

                                <div className='col-xl-10 col-md-10   hurrahText  '>
                                    <p className=''>
                                        Hurrah!
                                        <br></br>
                                        Your form has be submitted </p>
                                    <p className='descText11  mt-3 col-xl-7 col-md-7  d-flex justify-content-start '>
                                        A Para representative will get in touch with you shortly after screening your information
                                    </p>
                                </div>



                            </div>
                            <div className=' d-flex justify-content-center browseParaButtonBox'>
                                <button variant="contained" className='buttonBrowsePara' onClick={editprofile}>Edit Profile</button>
                            </div>

                            <div className='d-flex justify-content-center '>


                            </div>

                        </div>
                    </div>
                </div>
                <div className='container-lg'>



                    <div className='box-border1 '>
                        <div className='row'>
                            <div className='smallbox  col-xl-4 col-md-4 col-xs-12 '>
                                <p className='fraction text-center'>
                                    1/3
                                </p>
                                <p className='description text-center'>
                                    of Millenials prefer to interact with companies through digital platforms.
                                </p>

                            </div>
                            <div className='smallbox  col-xl-4 col-md-4 col-xs-12'>
                                <p className='fraction text-center'>
                                    74%
                                </p>
                                <p className='description text-center'>
                                    of retail companies are digitizing their business to sell online.
                                </p>
                            </div>
                            <div className='smallbox  col-xl-4 col-md-4 col-xs-12'>
                                <p className='fraction text-center'>
                                    30%
                                </p>
                                <p className='description text-center'>
                                    month on month increase in revenue for retail businesses that went digital in 2019
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default SOP_FORM_SUBMITTED
