import React, { Fragment, useEffect, useReducer, useState } from 'react'
import nonvegIcon from '../../assets/images/svg-icons/nonveg-icon.svg';
import { getCarts, manageCart, Token } from '../../Token';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import StarIcon from '@material-ui/icons/Star';
import Rating from '@material-ui/lab/Rating';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
// import Swal from 'sweetalert2';
// import CloseCircle from '../../assets/images/modalPopup/close-circle.svg';
// import AlertTriangle from '../../assets/images/modalPopup/alert-triangle.svg';
// import CheckmarkCircle from '../../assets/images/modalPopup/checkmark-circle.svg';
// import { Spin } from 'antd'
// import ProductImage from '../../assets/images/products/product-detail.svg'
import AlertTriangle from '../../assets/images/modalPopup/alert-triangle.svg';
import { useParams, useHistory } from 'react-router-dom';
import { GuestAPI } from '../../apis/GuestAPI';
import { Link } from 'react-router-dom'
import { color } from '@mui/system';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ReactImageMagnify from 'react-image-magnify';
import { Accordion, Button, Col, Navbar, Row, Spinner } from 'react-bootstrap';
import { Avatar } from '@material-ui/core';
import Fancybox from '../Fancybox';
import ProductReviewModal from '../../Components/ProductReviewModal';
import Swal from 'sweetalert2';
import ArrowForwardRounded from '@material-ui/icons/ArrowForwardRounded';
import reviewicon from '../../assets/images/shopDetails/reviewicon.svg';

function ProductDetail(props) {
  const params = useParams();
  const history = useHistory();
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      product_id: params.producy_id,
      product_name: "",
      short_description: "",
      price: 0,
      quantity: 0,
      discounted_price: 0,
      instant_delivery: 0,
      instant_delivery_price: 0,
      product_photos: [],
      product_selected_photo: '',
      sku: '',
      product: {},
      business: {},
      stateCards: getCarts(),
      count: 0,
      addReviewModal: false,
      openReviewModel: "",
      ratings: [],
      avg_rating: 0,
      description: '',
      isCartAdded: false,
      totalCheckoutPrice: 0,
      veg_category: "",
      flashCoupon: {}
    }
  );

  useEffect(() => {
    setTotalCheckoutPrice();
    GuestAPI.getProductDetail(params.producy_id)
      .then(function (response) {
        let product = response.data.product;
        let flashCoupon = response.data.flashCoupon;
        setState({
          business: response.data.business,
          product: product,
          product_name: product.product_name,
          short_description: product.short_description,
          price: product.price,
          quantity: product.quantity,
          discounted_price: product.discounted_price,
          instant_delivery: product.instant_delivery,
          instant_delivery_price: product.instant_delivery_price,
          product_photos: product.product_photos,
          product_selected_photo: product.product_photos.length > 0 ? product.product_photos[0] : '',
          sku: product.sku,
          Option: 'Description',
          ratings: product.ratings,
          avg_rating: product.avg_rating,
          description: product.description,
          veg_category: product.veg_category,
          flashCoupon: flashCoupon
        })

      }).catch(function (error) {
        console.log(error);
      });
  }, [])

  const setTotalCheckoutPrice = () => {
    let total_product_price = 0;
    let checkCart = false
    for (var i = 0; i < Object.keys(getCarts()).length; i++) {
      checkCart = true
      total_product_price = total_product_price + (getCarts()[Object.keys(getCarts())[i]].product_discounted_price * parseInt(getCarts()[Object.keys(getCarts())[i]].quantity));
    }
    props.manageCartCount(Object.keys(getCarts()).length)
    setState({
      isCartAdded: checkCart,
      totalCheckoutPrice: total_product_price,
      stateCards: getCarts()
    })
  }

  const [SelectedImage, setSelectedImage] = useState(0)
  const handlechange = (category) => {
    setState({ Option: category })

  }

  const handleReviewModal = (numText) => {
    if (Token.isLoggedIn()) {
      setState({ addReviewModal: true, openReviewModel: numText })
    } else {
      Swal.fire({
        title: 'Please login to review',
        imageUrl: AlertTriangle,
        imageAlt: 'warning image',
        confirmButtonColor: '#00CA84'
      });
    }
  }
  const reviewModalVisibility = (value) => {
    setState({ addReviewModal: value })
  }

  // if (state.product_photos.length === 0) {
  if (Object.keys(state.product).length === 0) {
    return <div className='d-flex align-items-center justify-content-center spinnerBackground'
      style={{ height: "60vh", width: "100%" }}>
      <Spinner animation="border" variant="success" />
    </div>
  }
  return (
    <div style={{ marginTop: '100px' }}>
      <div className='container-fluid'>
        <div className="blackBox-heading d-flex justify-content-between " style={{ backgroundColor: '#f2f2f2', color: 'black' }}>
          <p className="align-self-center mb-0">You are viewing {state.product_name}</p>
          <button className="btn py-0 text-dark" onClick={() => history.goBack()}> <ArrowBackIcon /> Back</button>
        </div>
        <div className="row product-detail pt-2 pb-5">
          <div className='col-lg-2'>
            {
              state.product_photos.map((img, i) => {
                return (
                  <div key={i} className='col-lg-3'>
                    <div className='small-img small-img-productcontentImage '
                      role="button"
                      onClick={() => setState({ product_selected_photo: img })} >
                      <img src={img} onClick={() => setSelectedImage(i)} alt='product Image' className={SelectedImage === i ? "border_red" : ""} />
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className='col-lg-4 justify-content-start imageMg' style={{ textAlign: '-webkit-center', }}>

            <ReactImageMagnify {...{
              smallImage: {
                alt: 'Wristwatch by Ted Baker London',
                isFluidWidth: true,
                width: 500,
                height: 700,
                src: state.product_selected_photo,
              },
              largeImage: {
                src: state.product_selected_photo,
                width: 500,
                height: 700,
              },
              shouldUsePositiveSpaceLens: true

            }} />

            {/* <img src={state.product_selected_photo} alt='product Image' className='w-100'
                style={{ objectFit: 'cover', borderRadius: '2px' }} /> */}


          </div>
          <div className='col-lg-4'>
            <div className='d-flex'>
              <h4 className="">{state.product_name}</h4>
              {state.veg_category == "Veg" && <span className="vegLable ml-1" style={{ width: '15px', height: '15px' }}></span>
              }
              {state.veg_category == "Vegan" && <span className="veganLabel ml-1" style={{ width: '15px', height: '15px' }}></span>
              }
            </div>

            {/* <div className="review-ratingBox ml-2 my-3">
              <div className="review-rating d-flex" >
                <StarIcon />
                <p className="mb-0">{state.avg_rating}</p>
              </div>
            </div> */}
            {state?.avg_rating > 0 && <div class="py-0 my-2 pr-3  border-right d-flex">
              {/* <Rate allowHalf defaultValue={2.5} /> */}
              <div className="shopDetails-allRating-box ">
                <Rating icon={<StarRoundedIcon />} name="half-rating-read" defaultValue={state?.avg_rating} precision={0.5} readOnly className="mb-0" />
              </div>
              <p id="reviews"
                className="fw-500 ml-2 text-dark mb-0 align-self-center"
                style={{ cursor: 'pointer' }}
              // onClick={() => {
              //   this.handlechange('reviews')
              //   window.scrollTo({
              //     top: this.myRef.current.offsetTop,
              //     behavior: 'smooth',
              //   }
              //   )
              // }}
              >
                {state.ratings.length} Reviews</p>
            </div>}
            <div className="d-flex">
              {
                parseFloat(state.price) == parseFloat(state.discounted_price) ?
                  <p className="mb-0 price1">Price:	 £{parseFloat(state.price).toFixed(2)}</p> :
                  <Fragment>
                    <p className="rough-price mb-0 d-inline">£{parseFloat(state.price).toFixed(2)}</p>
                    <p className="mb-0">£{parseFloat(state.discounted_price).toFixed(2)}</p>
                  </Fragment>
              }
            </div>
            {/* <div>
              Available Quantity: {state.quantity}
            </div> */}
            {
              state.instant_delivery &&
              <p className="mb-0">Delivery Charge: £{parseFloat(state.instant_delivery_price).toFixed(2)}</p>
            }
            <hr />
            {(state?.product?.out_of_stock || state.quantity == 0) ? <p className="text-danger">Out of Stock</p> : <div className='d-flex align-items-center'>
              <p className='mb-0'>Quantity: </p>
              <div className="incrementDecrement-box ml-3">
                <button className="btn-decrement"
                  onClick={() => {
                    let qty = state.stateCards[state.product_id] != undefined ? parseInt(state.stateCards[state.product_id].quantity) - 1 : parseInt(state.count) - 1;
                    if (qty >= 1) {
                      setState({ count: qty });
                      manageCart(state.product, state.product_photos[0], qty, state.business, state.flashCoupon)
                      setTimeout(() => { setTotalCheckoutPrice() }, 200)
                    }
                  }}>
                  <RemoveRoundedIcon />
                </button>
                <p className="mb-0">
                  {state.stateCards[state.product_id] != undefined ? state.stateCards[state.product_id].quantity : 0}
                </p>
                {/* <input
                  type="number"
                  value={state.stateCards[state.product_id] != undefined ? state.stateCards[state.product_id].quantity : 0}
                  onChange={(e) => {
                    const qty = e.target.value;
                    if (qty >= 0) {
                      setState({ count: qty });
                      manageCart(state.product, state.product_photos[0], qty, state.business, state.flashCoupon)
                      setTimeout(() => { setTotalCheckoutPrice() }, 200)
                    }
                  }}
                /> */}
                <button className="btn-increment"
                  onClick={() => {
                    let qty = state.stateCards[state.product_id] != undefined ? parseInt(state.stateCards[state.product_id].quantity) + parseInt(1) : parseInt(state.count) + parseInt(1);
                    setState({ count: qty });
                    manageCart(state.product, state.product_photos[0], qty, state.business, state.flashCoupon)
                    setTimeout(() => { setTotalCheckoutPrice() }, 200)
                  }}>
                  <AddRoundedIcon />
                </button>
              </div>

            </div>}

            <br />
            {/* <small>Customisable</small> */}
            <hr />
            <b>About this item: </b>
            <p className='pt-2 description-last'>
              {state.short_description}</p>
          </div>
        </div>
        <div className='container'>
          <Navbar expand="lg" className='shopDetail-navbar mb-4 navbar navbar-expand-lg navbar-light ' style={{ backgroundColor: "rgb(242 242 242)" }}>
            <Navbar.Brand id={`${state.Option === 'Description' ? 'selected' : "notselected"}`} onClick={() => handlechange('Description')}>Description</Navbar.Brand>
            <Navbar.Brand id={`${state.Option === 'Reviews' ? 'selected' : "notselected"}`} onClick={() => handlechange('Reviews')}>Reviews</Navbar.Brand>

            <Navbar.Toggle aria-controls="navbarScroll" className='ml-auto' />
            {/* <Navbar.Collapse id="navbarScroll">
                                        <Nav className='ml-auto'>
                                            <Form className="col-xs-12">
                                                <TextField id="outlined-basic" label="Search within Restaurant" variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton>
                                                                    <SearchIcon style={{ color: '#00CA84' }} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Form>
                                        </Nav>
                                    </Navbar.Collapse> */}
          </Navbar>
        </div>

        <div className='container position-relative'>

          {state.Option === "Description" ?
            <div className='about-shopContentBox' dangerouslySetInnerHTML={{ __html: state.description }}
            />
            : null}
          {state.Option == 'Reviews' ? <div className="reviews-contentBox">
            <div id="reviewsheading" className='d-flex justify-content-between'>
              <p className=' mt-4'>Reviews</p>
              <button className='btn btnPara-primary my-4' onClick={() => handleReviewModal('second')}>Add a review</button>
            </div><hr />

            <div className="reviews-content">
              {
                state.ratings.map((data, index) => {
                  return (
                    <div>
                      <div className="media product-details">
                        <div>
                          <Avatar className='reviews-userProfile mr-3 mb-2' alt="Remy Sharp" src={reviewicon} />
                          <div className="review-ratingBox ml-2">
                            <div className="review-rating d-flex" >
                              <StarIcon />
                              <p className="mb-0">{data?.rating}</p>
                            </div>
                          </div>
                        </div>
                        <div className="media-body">
                          <h5 className="review-title">{data?.user?.first_name} {data?.user?.last_name}</h5>
                          <h4 className="review-subtitle mt-0">{data?.title}</h4>
                          <p className="review-info">{data.tell_us_more.split(" ").map((word, index) => {
                            if (index < 40) {
                              return word + " "
                            }
                          })}</p>

                          {
                            data.photos.length > 0 &&

                            <Accordion defaultActiveKey="0">
                              <Accordion.Toggle as={Button} variant="" eventKey="1" className="review-read-more">
                                Read more
                              </Accordion.Toggle>
                              <Accordion.Collapse eventKey="1">
                                <div className="py-2">
                                  <p className="review-info">
                                    {data.tell_us_more.split(" ").map((word, index) => {
                                      if (index > 40) {
                                        return word + " "
                                      }
                                    })}
                                  </p>
                                  <div className='d-flex justify-content-start flex-wrap mb-2'>
                                    {data.photos ? <Fancybox options={{ infinite: false }}>
                                      {data.photos.map((image, index) => {
                                        return (
                                          <img data-fancybox="" data-src={image} key={`other_image_${index}`} className='img-thumbnail mr-2' src={image} alt="about_images" />
                                        )
                                      })}

                                    </Fancybox> : null}
                                  </div>
                                </div>
                              </Accordion.Collapse>
                            </Accordion>
                          }
                        </div>
                      </div>
                      <hr />
                    </div>

                  )
                })
              }


              {
                state.addReviewModal && state.openReviewModel == 'second' ?
                  <ProductReviewModal
                    reviewModalVisibility={reviewModalVisibility}
                    business_id={state.product_id}
                  /> :
                  null
              }
            </div>
          </div>

            : null

          }
          {/* <div className='delivery-order-list'>
          <div className="">
            <h4 className="">Add to your order!</h4>
            <div className="row">
              {
                [1, 2, 3, 4].map((product, i) => {
                  return (
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                      <div className="addYourOrder-box mb-4">
                        <div className="addYourOrder-imgBox" style={{ backgroundImage: "url(https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350)" }}></div>
                        <div className="addYourOrder-infoBox mt-2">
                          <h6 className="mb-0">Name of Shop <img src={nonvegIcon} alt="nonveg" className="" width="16" height="16" /></h6>
                          <p className="">£13</p>
                        </div>
                        <div>
                          <button className="btn btn-addYourOrder">Add To Cart +</button>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
           </div> */}
        </div>
        {
          state.isCartAdded &&

          <Row>
            <Col>
              <div className="position-relative proceed-to-checkoutBoxNew">
                <div className="card proceed-to-checkoutBox">
                  <div className="card-body">
                    <div className="d-flex justify-content-between">

                      <Link
                        to={'/my-cart'}
                        className="proceed-to-checkoutLink align-self-center  "
                      >
                        Proceed To Checkout <ArrowForwardRounded />
                        <div>
                          <p className='mb-0 total-heading'>Total</p>
                          <p className='mb-0 total-price'>£{parseFloat(state.totalCheckoutPrice).toFixed(2)}</p>

                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        }
      </div>
    </div>
  )
}

export default ProductDetail
