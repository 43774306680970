import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Checkbox } from 'antd'
import { DashboardLayout } from '../Layout/Layout';
import { useHistory, useParams } from 'react-router-dom';
import { UserApi } from '../../../apis/admin/UserApi';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../../assets/images/modalPopup/close-circle.svg';

const AdminUserEdit = () => {
    const history = useHistory();

    const [form] = Form.useForm();
    const [Data, setData] = useState({})
    const [loading, setLoader] = useState(false);
    const { id } = useParams()
    useEffect(() => {
        UserApi.getUserDetail(id)
            .then(res => {
                console.log(res)
                setData(res.data)
            })
        // form.resetFields();
    }, []);
    const onFinish = async (value) => {

        const data = {
            user_id: id,
            first_name: value.first_name,
            last_name: value.last_name,
            email: value.email,
            password: value.password,
            mobile_number: value.mobile_number,
        }
        setLoader(true)
        UserApi.UpdateUserDetail(data)
            .then(res => {
                history.push(`/admin/Admin-User`);
                Swal.fire({
                    title: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                });
                setLoader(false);

            }).catch(error => {
                console.log(error)
                setLoader(false);
                Swal.fire({
                    title: error.response.data.message || error.Error,
                    imageUrl: CloseCircle,
                    imageAlt: 'error image',
                    confirmButtonColor: '#00CA84'
                });
            })

    }

    form.setFieldsValue(Data)

    return (
        <DashboardLayout page="Admin-User-Edit">
            <div className="w-100 mt-5">
                <div className="sidebar-content">
                    <div>
                        <div >
                            <Form
                                form={form}
                                onFinish={onFinish}
                            >
                                <div className="mb-0">
                                    <label>First Name</label>
                                    <Form.Item
                                        className="form-item mb-3"
                                        name="first_name"
                                        rules={[
                                            { required: true, message: 'Please input your first name.' }
                                        ]}
                                    >
                                        <Input placeholder="First Name" />
                                    </Form.Item>
                                </div>
                                <div className="mb-0">
                                    <label>Last Name</label>
                                    <Form.Item
                                        className="form-item mb-3"
                                        name="last_name"
                                        rules={[
                                            { required: true, message: 'Please input your last name.' }
                                        ]}
                                    >
                                        <Input placeholder="Last Name" />
                                    </Form.Item>
                                </div>
                                <div className="mb-0">
                                    <label> Email</label>
                                    <Form.Item
                                        className="form-item mb-3"
                                        name="email"
                                        rules={[
                                            { required: true, message: 'Please input your email address.' },
                                            { type: 'email', message: 'Please enter valid email address.' }
                                        ]}
                                    >
                                        <Input placeholder="Email Address" />
                                    </Form.Item>
                                </div>
                                <div className="mb-0">
                                    <label> Phone Number</label>
                                    <Form.Item
                                        className="form-item mb-3"
                                        name="mobile_number"
                                        rules={[
                                            { required: true, message: 'Please input your Phone Number.' },
                                        ]}
                                    >
                                        <Input type="number" placeholder="Phone Number" />
                                    </Form.Item>
                                </div>
                                <div className="mb-0">
                                    <label>Enter Password</label>
                                    <Form.Item
                                        className="form-item mb-3"
                                        name="password"
                                        rules={[
                                            // { required: true, message: 'Please input your password.' },
                                            { min: 8, message: 'Password must be atleast 8 characters long.' },
                                            {
                                                pattern: new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/),
                                                message: "Password should contain uppercase characters (A-Z), lowercase characters (a-z), digits (0-9) and special characters @!*& etc."
                                            }
                                        ]}
                                    >
                                        <Input.Password
                                            onPaste={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }} onCopy={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }}
                                            placeholder="Input password" />
                                    </Form.Item>
                                </div>

                                <div className="mb-0">
                                    <label>Confirm  Password</label>
                                    <Form.Item
                                        className="form-item mb-3"
                                        name="confirmPassword"
                                        rules={[
                                            // { required: true, message: 'Please input your confirm password.' },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject('The two password that you entered do not match!');
                                                },
                                            })
                                        ]}
                                    >
                                        <Input.Password
                                            onPaste={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }} onCopy={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }}
                                            placeholder="Confirm password" />
                                    </Form.Item>
                                </div>



                                <div className="mb-3">
                                    <Form.Item className="form-item mb-2">
                                        <Spin spinning={loading} delay={500}>
                                            <button className="btn btnPara-primary btn-md btn-block" htmlType="submit">
                                                Continue
                                            </button>
                                        </Spin>
                                    </Form.Item>
                                </div>
                            </Form>

                        </div>

                    </div>
                </div>
            </div>
        </DashboardLayout>

    );
};

export default AdminUserEdit;