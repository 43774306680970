import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const PromotionAPI = {
    getCoupons: async (business_slug) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), business_slug: business_slug };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-coupons`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    getCouponDetail: async (coupon_id) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), coupon_id: coupon_id };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-coupon-detail`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    saveCoupon: async (data) => {
        const token = Token.getToken();

        data.user_id = Token.getUserID();

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/save-coupon`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    updateCoupon: async (data) => {
        const token = Token.getToken();
        data.user_id = Token.getUserID();

        return axios({
            method: 'put',
            url: `${config.API_BASE_URL}/api/user/update-coupon`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    deleteCoupon: async (coupon_id) => {
        const token = Token.getToken();
        const data = {
            coupon_id: coupon_id
        }

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/delete-coupon`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    changeCouponStatus: async (coupon_status, coupon_id) => {

        const token = Token.getToken();
        let data = {
            coupon_id: coupon_id,
            coupon_status: coupon_status,
            user_id: Token.getUserID()
        }

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/change-coupon-status`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    applyCouponCode: async (coupon_code, business_id, order_amount) => {

        const token = Token.getToken();
        let data = {
            coupon_code: coupon_code,
            user_id: Token.getUserID(),
            business_id: business_id,
            order_amount: order_amount
        }

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/apply-coupon-code`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
}