
import '../../../../src/assets/css/AdminPanel/adminPanel.css';
import React, { useState } from 'react'
import { DashboardLayout } from '../Layout/Layout'
import { Redirect } from 'react-router-dom';

import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from "@material-ui/icons/Tune";
import SampleProducts from '../../../assets/images/products/sample-image.svg';


export default function Users() {
    return (
        <DashboardLayout page='users'>
            <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-content">
                    <h3 className="mb-3">Users</h3>
                    <div className='border rounded-8'>
                        <div className='row'>
                            <div className="col-6 col-sm-7 align-self-center" style={{ borderRight: '1px solid #dee2e6' }}>
                                <div className='p-2'>
                                    <div className='d-flex w-100 h-100' >
                                        <SearchIcon className="greyText mt-1 mr-3" />
                                        <InputBase className='w-100' placeholder="Search Applications" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-5 align-self-center">
                                <div className=''>
                                    <button className='btn btn-lg btn-block ft-size16'>
                                        Add User
                                    </button>
                                </div>
                            </div>
                            <div className="col-12 align-self-center" >
                                <div className='' style={{ borderTop: '1px solid #dee2e6' }}>
                                    <div className="filter-box text-center">
                                        <button className="btn btn-filter"><TuneIcon /> filter</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 className="mt-4">640 users</h5>
                    <div className="paraTabs">
                        <div className="table-responsive paraRounded-table mt-3">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="fw-500">NAME</th>
                                        <th scope="col" className="fw-500">EMAIL ID</th>
                                        <th scope="col" className="fw-500">NUMBER</th>
                                        <th scope="col" className="fw-500 text-center">ORDERS</th>
                                        <th scope="col" className="fw-500 text-center">BUSINESSES</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <div className="d-flex">
                                                <img src={SampleProducts} alt="" className="mr-2" width="32" height="32" />
                                                <p className="mb-0 pl-1 align-self-center ">Name of business</p>
                                            </div>
                                        </td>
                                        <td className="align-middle"><p className="mb-0">alma.lawson@example.com</p></td>
                                        <td className="align-middle">
                                            <p className="mb-0">070 4099 2620 </p>
                                        </td>
                                        <td className="align-middle text-center">
                                            <p className="mb-0">32</p>
                                        </td>
                                        <td className="align-middle text-center">
                                            <p className="mb-0">1</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <div className="d-flex">
                                                <img src={SampleProducts} alt="" className="mr-2" width="32" height="32" />
                                                <p className="mb-0 pl-1 align-self-center ">Name of business</p>
                                            </div>
                                        </td>
                                        <td className="align-middle"><p className="mb-0">debbie.baker@example.com</p></td>
                                        <td className="align-middle">
                                            <p className="mb-0">078 8502 2342 </p>
                                        </td>
                                        <td className="align-middle text-center">
                                            <p className="mb-0">123</p>
                                        </td>
                                        <td className="align-middle text-center">
                                            <p className="mb-0">1</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}
