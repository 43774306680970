import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const PromotionAPI = {
    getCoupons: async (search) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID() };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/admin/fetch-coupons?search=${search}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    getCouponDetail: async (coupon_id) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), coupon_id: coupon_id };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/admin/fetch-coupon-detail`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    changeCouponStatus: async (data) => {

        const token = Token.getToken();
        data.user_id = Token.getUserID();

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/admin/change-coupon-status`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
}