import React, { useEffect } from 'react'
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../assets/images/modalPopup/close-circle.svg';
import { useParams, useHistory } from 'react-router-dom';
import { AuthAPI } from '../../apis/user/AuthAPI';

function UserVerify() {

    const history = useHistory();
    const params = useParams();
    const token = params.token;

    useEffect(() => {
        AuthAPI.userVerify(token)
            .then(res => {
                Swal.fire({
                    title: `${res.data.title}`,
                    text: `${res.data.message}`,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // history.push('/')
                        window.location.href = '/set-up-as-business'
                    }
                });

            }).catch(err => {
                if (err.response) {
                    Swal.fire({
                        title: err.response.data.message,
                        imageUrl: CloseCircle,
                        imageAlt: 'error image',
                        confirmButtonColor: '#00CA84'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            history.push('/')
                        }
                    });
    
                }
            })
    }, [])
    return (
        <div style={{ marginTop: '500px' }}>
        </div>
    )
}

export default UserVerify
