import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const CartAPI = {

    getCarts: async () => {
        const token = Token.getToken();

        var user_id = Token.getUserID();

        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/user/fetch-carts/${user_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    },
    saveCarts: async (data) => {
        const token = Token.getToken();

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/save-carts`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    clearCarts: async () => {
        const token = Token.getToken();

        var user_id = Token.getUserID();

        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/user/clear-carts/${user_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    }
}
