import React, { useState } from 'react';
import { ContactListApi } from '../../../apis/admin/ContactListApi';
import { DashboardLayout } from '../Layout/Layout';
import moment from 'moment';

const ContactList = () => {
    const [contactsList, setContactsList] = useState([])
    ContactListApi.getContactList()
        .then(response => setContactsList(response.data.contacts))
    return (
        <DashboardLayout page='Contact-list'>
            <div className="w-100 ">
                <div className="sidebar-content">
                    <h3 className="mb-3">Contact List</h3>
                    <div className="paraTabs">
                        <div className="table-responsive paraRounded-table mt-4">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="fw-500">Name</th>
                                        <th scope="col" className="fw-500">Email</th>
                                        <th scope="col" className="fw-500">Phone Number</th>
                                        <th scope="col" className="fw-500">Date</th>
                                        <th scope="col" className="fw-500">Message</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        contactsList.map((contact,i) => {
                                         return   <tr key={i}>
                                                <td className="align-middle"><p className="mb-0">{contact.name}</p></td>
                                                <td className="align-middle"><p className="mb-0">{contact.email}</p></td>
                                                <td className="align-middle"><p className="mb-0">{contact.number}</p></td>
                                                <td className="align-middle"><p className="mb-0">{moment(contact.createdAt).format('DD-MM-YYYY')}</p></td>
                                                <td className="align-middle"><p className="mb-0">{contact.message}</p></td>

                                            </tr>
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout >
    );
};

export default ContactList;