import React from 'react'
import { Link } from 'react-router-dom'
// import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import gifting from '../../assets/images/home/gifting.svg';

const Gifting = () => {
    return (
        <div className='gifting-section'>
            <div className='container-md'>
                <div className='text-center'>
                    <h4 className="gifting-heading fw-500">Send gifts with Para</h4>
                </div>
                <div className='row'>
                    <div className='col-xl-6  col-xs-12 my-4 flex-column'
                        data-aos="zoom-in"
                        data-aos-delay="50"
                        data-aos-duration="1000"
                        data-aos-anchor-placement="top-center"
                    >
                        <div className='d-flex  flex-column h-100 gifting-box '>
                            <div className="mb-auto w-100">
                                <img src={gifting} alt="Gifting" />
                                <h2>Gifting for businesses and corporates</h2>
                                <p className='gifting-info'>
                                Order Remote Team Lunch / Dinner, Arrange Virtual Weekend Party / Friday Party, Gift a Spa etc...                                </p>
                            </div>
                            <div>
                                <Link to='/businesses'>
                                    <button className="btn btn-show-more btn-md btn-rounded">View Business</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-6  col-xs-12 my-4 flex-column'
                        data-aos="zoom-in"
                        data-aos-delay="50"
                        data-aos-duration="1000"
                        data-aos-anchor-placement="top-center">
                        <div className='d-flex flex-column h-100  gifting-box'>
                            <div className="mb-auto w-100">
                                <img src={gifting} alt="Gifting" />
                                <h2>Gifting for friends and family</h2>
                                <p className='gifting-info'>
                                Order a Cake, Order a Lunch, Order a Spa, Gift a Healthcare Coupon, Order a Pizza etc...                                </p>
                            </div>
                            <div>
                                <Link to='/businesses'>
                                    <button className="btn btn-show-more btn-md btn-rounded">View Business</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Gifting