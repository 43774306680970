import React, { useState } from 'react'
import '../../../assets/css/UserPanel/userPanel.css';
import { DashboardLayout } from '../Sidebar wrapper/Layout'
import { Redirect } from 'react-router-dom'
import { Token } from '../../../Token';

const Analytics = () => {
    if (Token.isLoggedIn()) {
        return (
            <DashboardLayout page='analytics'>
                <div className="w-100 sidebar-right-content-spacing">
                    <div className="sidebar-content">
                        <h4>Analytics</h4>
                    </div>
                </div>
            </DashboardLayout>
        )
    }
    else {
        return <Redirect to='/' />
    }
}

export default Analytics
