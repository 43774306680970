import React, { useReducer, useRef, useEffect } from 'react';
import '../../assets/css/UserPanel/userPanel.css';
import { Nav, Tab, Row, Col, } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import PastOrders from './PastOrders';
import PaymentMethods from './PaymentMethods';
import FavouriteStores from './FavouriteStores';
import ManageAddresses from './ManageAddresses';
import MyBusinesses from './MyBusinesses';
import MyInfo from './MyInfo';
import { Token } from '../../Token';
// import ChatBox from './ChatBox';
import Messages from './Messages';

function SellerProfile() {

    let history = useHistory();
    let parts = window.location.pathname.split("/");
    let pageName = parts.pop() || parts.pop();
    pageName = pageName.split('-');
    let mBusinessSlug = '';
    if (pageName.length == 2) {
        mBusinessSlug = pageName[1];
        pageName = pageName[0];
    } else {
        pageName = pageName[0];
    }
    
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            fullname: "",
            email: "",
            heading: pageName,
            editprofile_buttonvisible: true,
            _is_show_add_address_button: pageName == 'manageAddresses' ? true : false,
            address_model_title: 'Add',
            drawerVisible: false
        }
    );

    useEffect(() => {
        setState({ fullname: Token.getFullName(), email: Token.getEmail() })
    }, [])

    const handleSelect = async (key) => {
        if (key === "pastOrders") {
            setState({ heading: "pastOrders", editprofile_buttonvisible: "" })
        } else if (key === "manageAddresses") {
            setState({ heading: "manageAddresses", editprofile_buttonvisible: "" })
        } else if (key === "paymentMethods") {
            setState({ heading: "paymentMethods", editprofile_buttonvisible: "" })
        } else if (key === "favouriteStores") {
            setState({ heading: "favouriteStores", editprofile_buttonvisible: "" })
        } else if (key === "myBusinesses") {
            setState({ heading: "myBusinesses", editprofile_buttonvisible: "" })
        } else if (key === "messages") {
            setState({ heading: "messages", editprofile_buttonvisible: "" })
        } else {
            setState({ heading: "myInfo", editprofile_buttonvisible: "none" })
        }
    }

    const logout = () => {
        Token.clearStorage();
        window.location.reload(false);
    }

    return (
        <div>
            <div className="topHeader-section">

                <div className="container">
                    <div className="d-flex justify-content-between topHeader-box">
                        <div className="#">
                            <h4 className="heading">{state.fullname}</h4>
                            <p className="sub-heading mb-0">{state.email}</p>
                        </div>
                        <div className="d-flex align-items-center">
                            {
                                !state._is_show_add_address_button &&
                                <button className="btn btn-outline-light" style={{ display: state.editprofile_buttonvisible }} onClick={() => {
                                    setState({ editprofile_buttonvisible: "none", heading: "myInfo" })
                                }}>Edit Profile</button>
                            }

                            {
                                state._is_show_add_address_button &&
                                <button className="btn btn-outline-light" data-toggle="modal"
                                    onClick={() => {
                                        setState({
                                            address_model_title: 'Add',
                                            drawerVisible: true
                                        })
                                    }}
                                >Add Address</button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div className="sellerProfile-section">
                <div className="container">
                    <Tab.Container id="#" defaultActiveKey={state.heading} activeKey={state.heading} onSelect={handleSelect}>
                        <Row>
                            <Col md={12} lg={3}>
                                <div className="profile-tab-name">
                                    <Nav variant="pills" className="flex-column mb-5">
                                        <Nav.Item>
                                            <Nav.Link eventKey="pastOrders"
                                                onClick={() => {
                                                    setState({ _is_show_add_address_button: false })
                                                    history.push('/seller-profile/pastOrders')
                                                }}
                                            >Past Orders</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="manageAddresses"
                                                onClick={() => {
                                                    setState({ _is_show_add_address_button: true })
                                                    history.push('/seller-profile/manageAddresses')
                                                }}>Manage Addresses</Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="paymentMethods"
                                                onClick={() => {
                                                    setState({ _is_show_add_address_button: false })
                                                    history.push('/seller-profile/paymentMethods')
                                                }}
                                            >Payment Methods</Nav.Link>
                                        </Nav.Item> */}
                                        <Nav.Item>
                                            <Nav.Link eventKey="favouriteStores"
                                                onClick={() => {
                                                    setState({ _is_show_add_address_button: false })
                                                    history.push('/seller-profile/favouriteStores')
                                                }}
                                            >Favourite Businesses</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="myBusinesses"
                                                onClick={() => {
                                                    setState({ _is_show_add_address_button: false })
                                                    history.push('/seller-profile/myBusinesses')
                                                }}
                                            >My Businesses</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="messages"
                                                onClick={() => {
                                                    setState({ _is_show_add_address_button: false })
                                                    history.push('/seller-profile/messages')
                                                }}
                                            >Messages</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="myInfo"
                                                onClick={() => {
                                                    setState({ _is_show_add_address_button: false })
                                                    history.push('/seller-profile/myInfo')
                                                }}
                                            >My Info</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <hr style={{ margin: '8px 16px' }} />
                                    <div>
                                        <button className="btn btn-logout" onClick={logout}>Log out</button>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} lg={9}>
                                <div className="profile-tab-content">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="pastOrders">
                                            <PastOrders />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="manageAddresses">
                                            <ManageAddresses
                                                address_model_title={state.address_model_title}
                                                drawerVisible={state.drawerVisible}
                                                useFor="profile"
                                                setState={setState}
                                            />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="paymentMethods">
                                            <PaymentMethods />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="favouriteStores">
                                            <FavouriteStores />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="myBusinesses">
                                            <MyBusinesses />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="messages">
                                            <Messages mBusinessSlug={mBusinessSlug} />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="myInfo">
                                            <MyInfo />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </Col>
                        </Row>
                    </Tab.Container>

                </div>
            </div>
        </div>
    )
}

export default SellerProfile
