import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const CustomerAPI = {
    getCustomers: async (business_slug) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), business_slug: business_slug };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/get-customers`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    PaginationGetCustomers: async (pageNumber,business_slug) => {
        const token = Token.getToken();

        var data = {page:pageNumber,limit:10, user_id: Token.getUserID(), business_slug: business_slug };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/get-customers`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },

}