import React from 'react';

const ContentIntegrate = () => {
    return (<div style={{ marginTop: "90px", padding: "0px 100px" }} >
        <div >
            <div >
                <h2 className="text-center">Content Policy
                </h2>
            </div>
        </div>

        <div className='text-justify'>
            <h3>MyPara Approach to Content Integrity</h3>
            {/* <p>&nbsp;</p> */}
            <p>At MyPara, we’ve created a community where members can read one another’s Business reviews, interact, engage &amp; transact. Once in a while, someone will try to game our system by attempting to post a fake review or put an objectionable content, hence we feel that it is important to share our approach, our policies, and the actions we take to identify, block, and remove fraudulent reviews / content. Here’s what we believe.</p>
            <p><b>We believe the best business advice comes from other businesses and users.</b></p>
            <p><span style={{ fontWeight: 400 }}>At MyPara, our users can submit reviews of their experiences with our food, health &amp; beauty businesses.&nbsp;</span></p>
            <p><b>We believe in “the right to write.”</b></p>
            <p><span style={{ fontWeight: 400 }}>The MyPara community has a wealth of valuable experience, and every user should feel confident sharing their opinions. When you order a food, book a party, book a health product etc…, you have a fundamental right to talk about that experience with others. We are immensely proud of the community we are building, which helps customers do just that.</span></p>
            <p><span style={{ fontWeight: 400 }}>We believe every experience counts, not just the ones where you paid the bill. If you have a party of 12 with our home delivery partners, we believe that all of them should have a voice. That’s what MyParastands for.</span></p>
            <p><span style={{ fontWeight: 400 }}>Because we believe in promoting personal experiences, we do not take lightly the decision to remove a review from our site. It might be easier to give in to censorship and remove reviews that a business owner disagrees with. But it goes against what we stand for, which is the right for genuine consumers to share their experiences.</span></p>
            <p><span style={{ fontWeight: 400 }}>In keeping with this philosophy, while we do require reviewers to certify that they are reviewing their own experiences before they can submit their review to MyPara, we don’t seek third-party verification.</span></p>
            <p><b>We believe that everyone should play by the same rules. Businesses are not able to influence MyParato improve their reviews, ratings or Business Ranking.</b></p>
            <p><span style={{ fontWeight: 400 }}>We have unique processes for moderating the content submitted to MyPara. Those processes are applied in the same way to all content, complaints. We never have and never will give our advertisers or anyone else preferential treatment.</span></p>
            <p><b>We believe that businesses who don’t play by the rules should be penalised.</b></p>
            <p><span style={{ fontWeight: 400 }}>Businesses know that consumers rely heavily on reviews when making any decisions. Unfortunately, we have seen that some unscrupulous businesses will try to cheat in order to attract more customers. We define “cheating” as positively reviewing their own business (or finding others to do so on their behalf) or negatively reviewing their competitors.</span></p>
            <p><span style={{ fontWeight: 400 }}>These activities constitute fraud. Posting fake reviews violates the MyParaTerms of Use, as well as unfair competition and/or consumer protection laws in many countries. We invest massive amounts of time, effort and money into identifying and blocking fraudulent activity on our site.</span></p>
            <p><b>How MyPara identifies and blocks fraud</b></p>
            <ul>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>We have a team with years of experience learning what normal reviews and reviewing behaviour tend to look like on our platform. We’re experts at spotting patterns of fraudulent activity. In near future we will apply AI ML and patterns for our platform to identify potential threats.</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Once reviews are posted, we allow business owners and community members to flag content for our review if they believe it is fraudulent. All reports from our community and owners are reviewed and analysed by our team of content specialists.</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>We proactively engage with companies trying to hire people to write reviews as well as property owners attempting to buy fake reviews in order to catch those that try to circumvent our systems.</span></li>
            </ul>
            <p><b>How MyPara penalises businesses found to engage in fraudulent activity</b></p>
            <ul>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>We block or remove the fake reviews.</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Each fraudulent submission we identify has a negative impact on a business’s rank.</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>We may disqualify properties engaging in fraud from any special recognition, such as Customers’ Choice Awards.</span></li>
                <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>We aggressively pursue companies that offer to boost a business’ reputation by writing fake reviews on its behalf. When we catch them, we remove all of the fake reviews they’ve posted, and we penalise their clients.</span></li>
            </ul>
            <p><span style={{ fontWeight: 400 }}>You may be surprised that MyParadoesn’t simply remove the details on our site for a business caught engaging in review fraud. In fact, that’s exactly what some of these businesses want us to do, so they can prevent consumers from researching and commenting on them. But we believe consumers have the right to know what other MyParausers are saying.</span></p>
            <p><b>Finally, we believe it’s important for our community to be informed.</b></p>
            <p><span style={{ fontWeight: 400 }}>We’re confident that we’re taking the right steps to keep our content fresh and useful.</span></p>
            <p><b>For more information…</b></p>
            <p><span style={{ fontWeight: 400 }}>Write to MyParaBusiness team –&nbsp; <a href="mailto:support@myyneem.com">support@myyneem.com</a></span></p>
        </div>
    </div>

    );
};

export default ContentIntegrate;