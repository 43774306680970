import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import FoodBaverage from '../../assets/images/home/food_baverage.svg'
import HomeEssential from '../../assets/images/home/Home_Essentials.svg'
import BeautyGrooming from '../../assets/images/home/Beauty_Grooming.svg'
import GiftingExperience from '../../assets/images/home/Gifting_Experience.svg'
import '../styles/css/home/feature_carousal.css'
import Slider from "react-slick";
import '../styles/css/home/slick.css';
import '../styles/css/home/slick-theme.css';

const FeatureCarousal = () => {
    let history = useHistory();
    const [size, setSize] = useState([window.innerWidth])
    window.onresize = () => {
        if (window.innerWidth !== size) {
            setSize(window.innerWidth)
        }
    }
    if (size <= 744) {

        var slide = 1
    }
    if (size > 744 && size < 1200) {

        var slide = 2
    }
    if (size > 1200) {

        var slide = 4
    }

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slide,
        slidesToScroll: 1
    };
    const FoodBusiness = () => {
        const searchTexts = "food"
        if (searchTexts) {
            localStorage.setItem('searchUrl', searchTexts.toString())
            history.push(`/businesses`);
        }
    }
    const ServiceBusiness = () => {
        const searchTexts = "service"
        if (searchTexts) {
            localStorage.setItem('searchUrl', searchTexts.toString())
            history.push(`/businesses`);
        }
    }
    return (
        <div className=''>
            <Slider {...settings}>
                <div className='featureCarousal-item-box'>
                    <div className='d-flex justify-content-center'>
                        <img src={FoodBaverage} alt="" />
                    </div>
                    <div className='d-flex justify-content-center '>
                        <h6 className=''> Food & Beverage</h6>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <p>Local restaurants, home bakers, cooks and caterers </p>
                    </div>
                    <div onClick={FoodBusiness} className='d-flex justify-content-center' style={{ cursor: 'pointer' }}>
                        <Link to='/businesses'>Business</Link>
                    </div>
                </div>


                <div className='featureCarousal-item-box'>
                    <div className='d-flex justify-content-center'>
                        <img src={HomeEssential} alt="" />
                    </div>
                    <div className='d-flex justify-content-center '>
                        <h6 className=''>Home Essentials</h6>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <p >Groceries, healthcare essentials and supermarket items.</p>
                    </div>
                    <div onClick={ServiceBusiness} style={{ cursor: 'pointer' }} className='d-flex justify-content-center'>
                        <Link to='/businesses'>Business</Link>
                    </div>
                </div>


                <div className='featureCarousal-item-box'>
                    <div className='d-flex justify-content-center'>
                        <img src={BeautyGrooming} alt="" />
                    </div>
                    <div className='d-flex justify-content-center '>
                        <h6 className=''>Beauty & Grooming</h6>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <p >Creams, lotions and other Spa and beauty products.</p>
                    </div>
                    <div onClick={ServiceBusiness} style={{ cursor: 'pointer' }} className='d-flex justify-content-center'>
                        <Link to='/businesses'>Business</Link>
                    </div>
                </div>


                <div className='featureCarousal-item-box'>
                    <div className='d-flex justify-content-center'>
                        <img src={GiftingExperience} alt="" />
                    </div>
                    <div className='d-flex justify-content-center '>
                        <h6 className=''>Gifting & Experiences</h6>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <p>Plan unique gifts and experiences from your Para.</p>
                    </div>
                    <div onClick={ServiceBusiness} style={{ cursor: 'pointer' }} className='d-flex justify-content-center'>
                        <Link to='/businesses'>Business</Link>
                    </div>
                </div>
                {/* <div className='featureCarousal-item-box'>
                    <div className='d-flex justify-content-center'>
                        <img src={FoodBaverage} alt="" />
                    </div>
                    <div className='d-flex justify-content-center '>
                        <h6 className=''>Food & Baverage</h6>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <p>Local restaurants, home bakers, cooks and caterers</p>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Link to='/businesses'>Business</Link>
                    </div>
                </div> */}

            </Slider>
        </div>
    )
}
export default FeatureCarousal