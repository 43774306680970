import React from 'react'
import { Link } from 'react-router-dom'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import footer from '../../assets/images/footer/footer.svg'
import '../styles/css/home/footer.css'
const Footer = () => {
    return (
        <div className='online-business-section bg-white'
            data-aos="fade-up"
            // data-aos-anchor-placement="center-center"
            data-aos-duration="500"
        >
            <div className='container-md pt-5'>
                <div className='online-business-box'
                >
                    <div className='row text-dark'>
                        <div className='col-xl-6 col-lg-6 col-md-12 order-lg-last'>
                            <div className='online-business-innerbox'>
                                <img src={footer} alt="" className="online-business-img" />
                            </div>
                        </div>
                        <div className='col-xl-6 col-lg-6 col-md-12 d-flex justify-content-center ml-auto'>
                            <div className='online-business-innerbox'>
                                <h2>Take your business online & Start Selling On Para</h2>
                                <p>It’s time to leverage the internet and grow your business. Build your digital presence and sell online - For Free! </p>
                                <Link to="/set-up-as-business">
                                    <button className="btn btn-online-business">Know More <ArrowRightAltIcon /></button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Footer