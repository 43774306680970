import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const ContactListApi = {
    getContactList: async () => {
        const token = Token.getToken();
        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/admin/fetch-contact-list`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    }

}