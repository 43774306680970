import React, { useEffect, useState } from 'react';
import AlertTriangle from '../../../assets/images/modalPopup/alert-triangle.svg';
import Modal from 'react-bootstrap/Modal';
import { Form, Input, Spin, notification } from 'antd'
import { DashboardLayout } from '../Layout/Layout';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';
import CheckmarkCircle from '../../../assets/images/modalPopup/checkmark-circle.svg';
import { useHistory } from 'react-router-dom';
import { UserApi } from '../../../apis/admin/UserApi';
import { Pagination } from 'antd';
import Swal from 'sweetalert2';

const AdminUser = () => {
    const [loading, setLoader] = useState(false);
    const [form] = Form.useForm();
    const [UserList, setUserList] = useState([])
    const history = useHistory()
    const [TotalData, setTotalData] = useState(0)
    const [User_Id, setUser_Id] = useState("")
    const [ModalShow, setModalShow] = useState(false)
    const HandlePagination = (PageNumber) => {
        UserApi.PagiNationAdminUser(PageNumber)
            .then((res) => {
                setTotalData(res.data.count)
                setUserList(res.data.data)
            })
    }
    const onFinish = async (value) => {
        const data = {
            password: value.password,
            user_id: User_Id
        };
        await UserApi.ChangePass(data)
            .then((res) => {
                console.log(res)
                Swal.fire({
                    text: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        setModalShow(false)
                    }
                }
                )
            })
    }
    const OnDropDownActive = async (e, id) => {
        let status;
        if (e.target.value == "Active") {
            status = 1;
        }
        if (e.target.value == "Inactive") {
            status = 0;
        }
        const data = {
            status,
            user_id: id

        }
        
        UserApi.user_StatusUpdate(data)
            .then((res) => {
                notification.success({message: res.data.message})
            })
    }
    useEffect(() => {
        // UserApi.getUserList()
        //     .then(res => {
        //         setUserList(res.data.data)
        //     })

        UserApi.PagiNationAdminUser(1)
            .then((res) => {
                setTotalData(res.data.count)
                setUserList(res.data.data)
            })
    }, []);
    const AddAdminUser = () => {
        // form.resetFields();
        history.push("/admin/Admin-User-Add")
    }
    
    const EditAdminUser = (id) => {

        history.push(`/admin/Admin-User-Edit/${id}`)

    }
    const DeleteAdminUser = async (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            imageUrl: AlertTriangle,
            showCancelButton: true,
            confirmButtonColor: '#00CA84',
            cancelButtonColor: '#FF5858',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoader(true)
                UserApi.DeleteAdminUser(id)
                    .then(res => {
                        setLoader(false)
                        const newUserList = UserList.filter((user) => user.id != id)
                        setUserList(newUserList)
                    })
                    .catch(function (error) {
                        setLoader(false)
                        console.log(error);
                    });
            }
        })
    }
    
    return (
        <DashboardLayout page='Admin-User'>
            <div className="w-100 " style={{ marginTop: "100px" }}>

                <div className="sidebar-content">

                    <div className="d-flex justify-content-between">
                        <h3 className="mb-3">Admin Users</h3>
                        <button className="btn btn-sm btn-outline-green mb-3 text-secondary"
                            onClick={() => AddAdminUser()}><PersonAddAltOutlinedIcon /> Add New Admin</button>
                    </div>
                    <div className="paraTabs">
                        <div className="table-responsive paraRounded-table mt-4">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="fw-500">Name</th>
                                        <th scope="col" className="fw-500">Email</th>
                                        <th scope="col" className="fw-500">Phone Number</th>
                                        <th scope="col" className="fw-500">Status</th>
                                        <th scope="col" className="fw-500">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {UserList?.map((user, i) => {
                                        return <tr key={user.id}>
                                            <td className="align-middle"><p className="mb-0">{user.first_name + " " + user.last_name}</p></td>
                                            <td className="align-middle"><p className="mb-0">{user.email}</p></td>
                                            <td className="align-middle"><p className="mb-0">{user.mobile_number}</p></td>
                                            <td className="align-middle"><p className="mb-0 text-success">
                                                <select className='mr-2 border-0'
                                                    onChange={(e) => OnDropDownActive(e, user.id)}
                                                >
                                                    <option value="Inactive" className=" d-none">Inactive</option>
                                                    <option selected={user.user_status} value="Inactive">Inactive</option>
                                                    <option selected={user.user_status} value="Active">Active</option>
                                                </select>
                                            </p></td>
                                            <td className="align-middle">
                                                <button className="btn btn-sm btn-outline-green"
                                                    onClick={() => EditAdminUser(user.id)}><EditOutlinedIcon /></button>
                                                <button className="btn btn-sm btn-outline-green ml-3"
                                                    onClick={() => { setModalShow(true); setUser_Id(user.id) }}><LockResetOutlinedIcon />
                                                </button>
                                                <button className={`btn btn-sm btn-outline-red ml-3 ${i == 0 ? "d-none" : ""}`}
                                                    onClick={() => DeleteAdminUser(user.id)}><DeleteOutlineOutlinedIcon /></button>
                                            </td>
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-5">
                        <Pagination defaultCurrent={1} onChange={HandlePagination} total={TotalData} />
                    </div>
                </div>

            </div>

            <Modal className="loginFormModal " centered
                show={ModalShow}
                onHide={() => setModalShow(false)}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    className="p-5"
                >
                    <div className="mb-0">
                        <label>Enter New Password</label>
                        <Form.Item
                            className="form-item mb-3"
                            name="password"
                            rules={[
                                { required: true, message: 'Please input your password.' },
                                { min: 8, message: 'Password must be atleast 8 characters long.' },
                                {
                                    pattern: new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/),
                                    message: "Password should contain uppercase characters (A-Z), lowercase characters (a-z), digits (0-9) and special characters @!*& etc."
                                }
                            ]}
                        >
                            <Input.Password
                                onPaste={(e) => {
                                    e.preventDefault()
                                    return false;
                                }} onCopy={(e) => {
                                    e.preventDefault()
                                    return false;
                                }}
                                placeholder="Input password" />
                        </Form.Item>
                    </div>

                    <div className="mb-0">
                        <label>Confirm  Password</label>
                        <Form.Item
                            className="form-item mb-3"
                            name="confirmPassword"
                            rules={[
                                { required: true, message: 'Please input your confirm password.' },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two password that you entered do not match!');
                                    },
                                })
                            ]}
                        >
                            <Input.Password
                                onPaste={(e) => {
                                    e.preventDefault()
                                    return false;
                                }} onCopy={(e) => {
                                    e.preventDefault()
                                    return false;
                                }}
                                placeholder="Confirm password" />
                        </Form.Item>
                    </div>
                    <div className="mb-3">
                        <Form.Item className="form-item mb-2">
                            <Spin spinning={loading} delay={500}>
                                <button className="btn btnPara-primary btn-md btn-block" htmlType="submit">
                                    Continue
                                </button>
                            </Spin>
                        </Form.Item>
                    </div>
                </Form>
            </Modal>


        </DashboardLayout >
    );
};

export default AdminUser;