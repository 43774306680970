import '../../../assets/css/UserPanel/userPanel.css';
import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom';
import { DashboardLayout } from '../Sidebar wrapper/Layout'
import { Redirect } from 'react-router-dom'
import { Token } from '../../../Token';

import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Card, Row, Col, Modal, Container, Form } from 'react-bootstrap';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import SampleProducts from '../../../assets/images/products/sample-image.svg';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';

import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import WbIncandescentOutlinedIcon from '@material-ui/icons/WbIncandescentOutlined';

import { Tabs, Tab } from 'react-bootstrap';
import { PercentageOutlined } from '@ant-design/icons';
import { Input, Switch, Checkbox, DatePicker, Space } from 'antd';
import AttachMoneyRoundedIcon from '@material-ui/icons/AttachMoneyRounded';

const Ads = () => {

    const params = useParams()

    // const useStyles = makeStyles((theme) => ({
    //     root: {
    //         width: '100%',
    //     },
    //     backButton: {
    //         marginRight: theme.spacing(1),
    //     },
    //     instructions: {
    //         marginTop: theme.spacing(1),
    //         marginBottom: theme.spacing(1),
    //     },
    // }));


    function getSteps() {
        return ['Promotion Type', 'Promotion details', 'Preview'];
    }

    // const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };


    const [showNewPromotion, setNewPromotionShow] = useState(false);
    const NewPromotionClose = () => setNewPromotionShow(false);
    const NewPromotionShow = () => setNewPromotionShow(true);

    function onChange(checked) {
        console.log(`switch to ${checked}`);
    }

    function onChange(checkedValues) {
        console.log('checked = ', checkedValues);
    }


    if (Token.isLoggedIn()) {
        return (
            <DashboardLayout page='ads'>
                <div className="w-100 sidebar-right-content-spacing">
                    <div className="sidebar-content">
                        <div className="blackBox-heading d-flex justify-content-between">
                            <p className="align-self-center mb-0">You are viewing {Token.getFullName()}</p>
                            <Link className="btn py-0" to={`/seller/business/${params.business_id}`}><ArrowBackIcon /> Home</Link>
                        </div>
                        <h4>Ads</h4>
                        <p>Allow users to apply discount codes on the total order and save money in order to boost your sales.</p>
                        <div className="text-right">
                            <button className="btn btnPara-primary" onClick={NewPromotionShow}>Add Promotion</button>
                        </div>

                        <div className="promotions-box mt-4">
                            <h4>Active Promotions</h4>
                            <p className="text-ParaGrey">Allow users to apply discount codes on the total order and save money in order to boost your sales.</p>
                            <div className="paraTabs">
                                <Tabs defaultActiveKey="OpenOrders" transition={false} id="orderTabs">
                                    <Tab eventKey="OpenOrders" title="Order Promotions">
                                        <div class="table-responsive rounded-paraTable mt-4">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th className="fw-500">DISCOUNT NAME</th>
                                                        <th className="fw-500">DISCOUNT TYPE</th>
                                                        <th className="fw-500">DISCOUNT</th>
                                                        <th className="fw-500">AVAILED</th>
                                                        <th className="fw-500">START</th>
                                                        <th className="fw-500">END</th>
                                                        <th className="fw-500">START</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="align-middle"><p className="mb-0">MyPara</p></td>
                                                        <td className="align-middle"><p className="mb-0">Flash Sale</p></td>
                                                        <td className="align-middle"><p className="mb-0">$10 off</p></td>
                                                        <td className="align-middle"><p className="mb-0">64</p></td>
                                                        <td className="align-middle"><p className="mb-0">09/02/2020</p></td>
                                                        <td className="align-middle"><p className="mb-0">02/02/2020</p></td>
                                                        <td className="align-middle">
                                                            <div className="d-inline">
                                                                <select className="form-selectOption option-redText d-inline">
                                                                    <option className="option-greenText" value="1">Live</option>
                                                                    <option className="option-redText" value="0">Disabled</option>
                                                                    <option className="option-Text" value="2">Approved</option>
                                                                </select>
                                                                <Link className="btn btn-outline-green ml-3"><EditOutlinedIcon /></Link>
                                                                <Link className="btn btn-outline-red ml-3"><DeleteOutlineOutlinedIcon /></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab>
                                    <Tab eventKey="ClosedOrders" title="Product promotions">
                                        <div class="table-responsive rounded-paraTable mt-4">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th className="fw-500">PRODUCT NAME</th>
                                                        <th className="text-center fw-500">AMOUNT</th>
                                                        <th className="text-center fw-500">PROMOTION</th>
                                                        <th className="text-center fw-500">AVAILED</th>
                                                        <th className="text-center fw-500">STATUS</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="align-middle">
                                                            <div className="d-flex">
                                                                <img src={SampleProducts} alt="" className="mr-2" width="56" height="56" />
                                                                <div className="align-self-center">
                                                                    <p className="mb-0 fw-500">Vanilla Ice Cream</p>
                                                                    <p className="mb-0 greyText ft-size13">SKU: <span className='greenText'>24985729</span></p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="align-middle text-center"><p className="mb-0">$10</p></td>
                                                        <td className="align-middle text-center"><p className="mb-0">$8</p></td>
                                                        <td className="align-middle text-center"><p className="mb-0">64</p></td>
                                                        <td className="align-middle text-center">
                                                            <div className="d-inline">
                                                                <select className="form-selectOption option-redText d-inline">
                                                                    <option className="option-greenText" value="1">Active</option>
                                                                    <option className="option-redText" value="0">Inactive</option>
                                                                </select>
                                                                <Link className="btn btn-sm btn-outline-green ml-3"><EditOutlinedIcon /></Link>
                                                                <Link className="btn btn-sm btn-outline-red ml-3"><DeleteOutlineOutlinedIcon /></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="align-middle">
                                                            <div className="d-flex">
                                                                <img src={SampleProducts} alt="" className="mr-2" width="56" height="56" />
                                                                <div className="align-self-center">
                                                                    <p className="mb-0 fw-500">Vanilla Ice Cream</p>
                                                                    <p className="mb-0 greyText ft-size13">SKU: <span className='greenText'>24985729</span></p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="align-middle text-center"><p className="mb-0">$10</p></td>
                                                        <td className="align-middle text-center"><p className="mb-0">$8</p></td>
                                                        <td className="align-middle text-center"><p className="mb-0">64</p></td>
                                                        <td className="align-middle text-center">
                                                            <div className="d-inline">
                                                                <select className="form-selectOption option-redText d-inline">
                                                                    <option className="option-greenText" value="1">Active</option>
                                                                    <option className="option-redText" value="0">Inactive</option>
                                                                </select>
                                                                <Link className="btn btn-sm btn-outline-green ml-3"><EditOutlinedIcon /></Link>
                                                                <Link className="btn btn-sm btn-outline-red ml-3"><DeleteOutlineOutlinedIcon /></Link>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </Tab>

                                </Tabs>
                            </div>


                        </div>
                    </div>
                </div>

                {/* ===== Modal: New promotion ======== */}
                <Modal centered size="lg" show={showNewPromotion} onHide={NewPromotionClose}>
                    <Modal.Header className='d-flex justify-content-center'>
                        <Modal.Title className='ft-size18'>New promotion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='stepper-progressBar-box'>
                            <Stepper activeStep={activeStep} alternativeLabel className='p-0'>
                                {steps.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <div>
                                {activeStep === steps.length ? (
                                    <div>
                                        <Typography className='my-2'>All steps completed</Typography>
                                        <Button onClick={handleReset}>Reset</Button>
                                    </div>
                                ) : (
                                    <div>
                                        {/* <Typography className='my-2'>{getStepContent(activeStep)}</Typography> */}
                                        <Typography className='my-4'>
                                            {(() => {
                                                switch (activeStep) {
                                                    case 0:
                                                        return <div>
                                                            <ul className="onCheck-colorBox pl-0">
                                                                <li className="onCheck-colorBox-list">
                                                                    <input type="radio" name="checkBorder_color" id="checkBorder-color1" className="input-colorBorder" />
                                                                    <label htmlFor="checkBorder-color1">
                                                                        <div className='colorBox-card p-3'>
                                                                            <h6>Coupon Promotion</h6>
                                                                            <p className='mb-0'>Coupon discounts are availed by users on checkout by entering a ‘discount code’. Discount codes can be a % or $ style discount with a limit and a condition. Eg. 20% off on orders over 40$ upto 3$</p>
                                                                        </div>
                                                                    </label>
                                                                </li>
                                                                <li className="onCheck-colorBox-list">
                                                                    <input type="radio" name="checkBorder_color" id="checkBorder-color2" className="input-colorBorder" />
                                                                    <label htmlFor="checkBorder-color2">
                                                                        <div className='colorBox-card p-3'>
                                                                            <h6>Flash Sale</h6>
                                                                            <p className='mb-0'>This type of Promotion allows you to have a flat amount off on all orders for all users, for a specific time duration. Flash sales do not required a code to be availed. Eg. For the next 3 hours $2 off on all orders. </p>
                                                                        </div>
                                                                    </label>
                                                                </li>
                                                                <li className="onCheck-colorBox-list">
                                                                    <input type="radio" name="checkBorder_color" id="checkBorder-color3" className="input-colorBorder" />
                                                                    <label htmlFor="checkBorder-color3">
                                                                        <div className='colorBox-card p-3'>
                                                                            <h6>Product Promotion</h6>
                                                                            <p className='mb-0'>Offer discounts on best sellers or products that arent selling. This type of promotion allows you markdown certain products or categories of products for a certain time period.</p>
                                                                        </div>
                                                                    </label>
                                                                </li>
                                                            </ul>
                                                        </div>;
                                                    case 1:
                                                        return <div>
                                                            <div class="alert alert-para-primary d-flex" role="alert">
                                                                <div className="align-self-center mr-3">
                                                                    <WbIncandescentOutlinedIcon />
                                                                </div>
                                                                <p className='mb-0'>Flash sales are accesable to all shoppers and is a flat rate that applies to all products in your shop.</p>
                                                            </div>

                                                            {/* <div className='py-3 rounded-8 greyBox'>
                                                                <Container>
                                                                    <Row>
                                                                        <Col xs={6}>
                                                                            <div>
                                                                                <Form.Label className='ft-size14'>Coupon Name</Form.Label>
                                                                                <Input placeholder="Eg. Para 2020" />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <div>
                                                                                <Form.Label className='ft-size14'>If cart total is greater than</Form.Label>
                                                                                <Input placeholder="Enter amount" prefix={<AttachMoneyRoundedIcon style={{ fontSize: '16px' }} />} />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col xs={6}>
                                                                            <div>
                                                                                <Form.Label className='ft-size14'>Discount Amount</Form.Label>
                                                                                <Input placeholder="Eg. 40%" prefix={<PercentageOutlined style={{ fontSize: '14px' }} />} />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <div>
                                                                                <Form.Label className='ft-size14'>Limit</Form.Label>
                                                                                <Input placeholder="Upto: 5$" suffix={<AttachMoneyRoundedIcon style={{ fontSize: '16px' }} />} />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={12}>
                                                                            <div className='d-flex mt-3'>
                                                                                <Switch defaultChecked onChange={onChange} />
                                                                                <p className='mb-0 small ml-2'>Switch to cash discount</p>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col xs={6}>
                                                                            <div>
                                                                                <Form.Label className='ft-size14'>Start date</Form.Label>
                                                                                <DatePicker className='form-control' onChange={onChange} />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <div>
                                                                                <Form.Label className='ft-size14'>End date</Form.Label>
                                                                                <DatePicker className='form-control' onChange={onChange} />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                            </div> */}

                                                            {/* <div className='py-3 rounded-8 greyBox'>
                                                                <Container>
                                                                    <Row>
                                                                        <Col xs={6}>
                                                                            <div>
                                                                                <Form.Label className='ft-size14'>Discount Amount</Form.Label>
                                                                                <Input placeholder="Eg. 40%" prefix={<PercentageOutlined style={{ fontSize: '14px' }} />} />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <div>
                                                                                <Form.Label className='ft-size14'>Limit</Form.Label>
                                                                                <Input placeholder="Upto: 5$" suffix={<AttachMoneyRoundedIcon style={{ fontSize: '16px' }} />} />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={12}>
                                                                            <div className='d-flex mt-3'>
                                                                                <Switch defaultChecked onChange={onChange} />
                                                                                <p className='mb-0 small ml-2'>Switch to cash discount</p>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr />
                                                                    <Row>
                                                                        <Col xs={6}>
                                                                            <div>
                                                                                <Form.Label className='ft-size14'>Start date</Form.Label>
                                                                                <DatePicker className='form-control' onChange={onChange} />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xs={6}>
                                                                            <div>
                                                                                <Form.Label className='ft-size14'>End date</Form.Label>
                                                                                <DatePicker className='form-control' onChange={onChange} />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Container>
                                                            </div> */}

                                                            <div class="apply-promotion-tableBox">
                                                                <div class="table-responsive rounded-paraTable">
                                                                    <table class="table">
                                                                        <tbody>
                                                                            <tr className='apply-promotion-row apply-promotion-row-selected'>
                                                                                <td className="align-middle text-center"><Checkbox value="A" defaultChecked></Checkbox></td>
                                                                                <td className="align-middle">
                                                                                    <div className="d-flex">
                                                                                        <img src={SampleProducts} alt="" className="mr-2" width="56" height="56" />
                                                                                        <div className="align-self-center">
                                                                                            <p className="mb-0 fw-500">Vanilla Ice Cream</p>
                                                                                            <p className="mb-0 greyText ft-size13">Current price:<span className='greenText'> $10</span></p>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="align-middle text-center"><p className="mb-0">Price after discount</p></td>
                                                                                <td className="align-middle text-center"><p className="mb-0">$8</p></td>
                                                                            </tr>
                                                                            <tr className='apply-promotion-row'>
                                                                                <td className="align-middle text-center"> <Checkbox value="B" ></Checkbox></td>
                                                                                <td className="align-middle">
                                                                                    <div className="d-flex">
                                                                                        <img src={SampleProducts} alt="" className="mr-2" width="56" height="56" />
                                                                                        <div className="align-self-center">
                                                                                            <p className="mb-0 fw-500">Vanilla Ice Cream</p>
                                                                                            <p className="mb-0 greyText ft-size13">SKU: <span className='greenText'>24985729</span></p>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="align-middle text-center"><p className="mb-0">Price after discount</p></td>
                                                                                <td className="align-middle text-center"><p className="mb-0">$8</p></td>
                                                                            </tr>
                                                                            <tr className='apply-promotion-row apply-promotion-row-selected'>
                                                                                <td className="align-middle text-center"> <Checkbox value="C" defaultChecked></Checkbox></td>
                                                                                <td className="align-middle">
                                                                                    <div className="d-flex">
                                                                                        <img src={SampleProducts} alt="" className="mr-2" width="56" height="56" />
                                                                                        <div className="align-self-center">
                                                                                            <p className="mb-0 fw-500">Vanilla Ice Cream</p>
                                                                                            <p className="mb-0 greyText ft-size13">SKU: <span className='greenText'>24985729</span></p>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="align-middle text-center"><p className="mb-0">Price after discount</p></td>
                                                                                <td className="align-middle text-center"><p className="mb-0">$8</p></td>
                                                                            </tr>
                                                                            <tr className='apply-promotion-row'>
                                                                                <td className="align-middle text-center"> <Checkbox value="D"></Checkbox></td>
                                                                                <td className="align-middle">
                                                                                    <div className="d-flex">
                                                                                        <img src={SampleProducts} alt="" className="mr-2" width="56" height="56" />
                                                                                        <div className="align-self-center">
                                                                                            <p className="mb-0 fw-500">Vanilla Ice Cream</p>
                                                                                            <p className="mb-0 greyText ft-size13">SKU: <span className='greenText'>24985729</span></p>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="align-middle text-center"><p className="mb-0">Price after discount</p></td>
                                                                                <td className="align-middle text-center"><p className="mb-0">$8</p></td>
                                                                            </tr>
                                                                            <tr className='apply-promotion-row'>
                                                                                <td className="align-middle text-center"> <Checkbox value="E"></Checkbox></td>
                                                                                <td className="align-middle">
                                                                                    <div className="d-flex">
                                                                                        <img src={SampleProducts} alt="" className="mr-2" width="56" height="56" />
                                                                                        <div className="align-self-center">
                                                                                            <p className="mb-0 fw-500">Vanilla Ice Cream</p>
                                                                                            <p className="mb-0 greyText ft-size13">SKU: <span className='greenText'>24985729</span></p>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="align-middle text-center"><p className="mb-0">Price after discount</p></td>
                                                                                <td className="align-middle text-center"><p className="mb-0">$8</p></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>

                                                        </div>;
                                                    case 2:
                                                        return <div>
                                                            <div class="d-flex justify-content-between">
                                                                <h6>Code</h6>
                                                                <h6>MyPara</h6>
                                                            </div>
                                                            <div class="d-flex justify-content-between">
                                                                <h6>Action</h6>
                                                                <h6>Cart total</h6>
                                                            </div>
                                                            <div class="d-flex justify-content-between">
                                                                <h6>Condition</h6>
                                                                <h6>Cart total Greater than $20</h6>
                                                            </div>
                                                            <div class="d-flex justify-content-between">
                                                                <h6>Discount</h6>
                                                                <h6>40% off</h6>
                                                            </div>
                                                            <div class="d-flex justify-content-between">
                                                                <h6>Limit</h6>
                                                                <h6>3$</h6>
                                                            </div>
                                                            <div class="d-flex justify-content-between">
                                                                <h6>Start Date</h6>
                                                                <h6>10/12/20</h6>
                                                            </div>
                                                            <div class="d-flex justify-content-between">
                                                                <h6>Limit</h6>
                                                                <h6>10/25/20</h6>
                                                            </div>
                                                            <hr />
                                                            <h6 className='ft-size18 greenText'>40% off on cart total greater than $20 upto 3$</h6>
                                                            <hr />
                                                            <div class="d-flex justify-content-between mt-4">
                                                                <h6 className='mb-0'>Publish live</h6>
                                                                <Switch defaultChecked onChange={onChange} />
                                                            </div>
                                                        </div>;
                                                    default:
                                                        return 'Unknown stepIndex';
                                                }
                                            })()}
                                        </Typography>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className='d-flex justify-content-end'>
                            <button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className="btn btn-outline-secondary mr-3"
                            >
                                Back
                            </button>
                            <button variant="contained" className="btn btnPara-primary btnWithSvg" onClick={handleNext}>
                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                <ArrowForwardRoundedIcon style={{ fontSize: '20px', top: '8px' }} />
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </DashboardLayout>
        )
    }
    else {
        return <Redirect to='/' />
    }
}

export default Ads
