import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const ProfileAPI = {
    fetchProfile: async () => {
        const token = Token.getToken();

        var data = { "user_id": Token.getUserID() };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/my-profile`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    fetchAddresses: async () => {
        const token = Token.getToken();

        const data = { user_id: Token.getUserID() };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/get-addresses`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    saveAddress: async (data) => {
        const token = Token.getToken();

        data.user_id = Token.getUserID();
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/add-address`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    updateAddress: async (data) => {
        const token = Token.getToken();

        data.user_id = Token.getUserID();
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/update-address`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    deleteAddress: async (address_id) => {
        const token = Token.getToken();
        let data = {
            address_id: address_id,
            user_id: Token.getUserID()
        }
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/delete-address`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
}