import React, { useState, useEffect, useRef } from 'react';
import '../../../../assets/css/UserPanel/userPanel.css';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
// import ListGroup from 'react-bootstrap/ListGroup';
import { Form, message, Upload, Input, Spin, Radio, Switch, Select, Button, Space } from 'antd';
import { DashboardLayout } from '../../Sidebar wrapper/Layout';
import { useHistory, useParams } from 'react-router-dom';
import lang from '../../../../language/en.json';
import { CategoryAPI } from '../../../../apis/user/CategoryAPI';
import { PlusOutlined } from "@ant-design/icons";
import { Container, Row, Col } from 'react-bootstrap'
import { ProductAPI } from '../../../../apis/user/ProductAPI';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../../../assets/images/modalPopup/close-circle.svg';
import { BusinessAPI } from '../../../../apis/user/BusinessAPI';
import { Editor } from '@tinymce/tinymce-react';
import { CheckoutAPI } from '../../../../apis/user/CheckoutAPI';

export default function AddProduct() {
	const history = useHistory();
	const params = useParams();
	const business_slug = params.business_id;
	const [form] = Form.useForm();
	const [loading, setLoader] = useState(false);
	const [categories, setCategories] = React.useState([]);
	const [business_category, setBusinesCategory] = React.useState('');
	const [isEnabledDelivery, setIsEnabledDelivery] = React.useState(false);
	const [short_description, setShort_description] = useState("")
	const { Option } = Select;
	const { Dragger } = Upload;
	const { TextArea } = Input;
	const [Description, setDescription] = useState('')
	const editorRef = useRef(null);
	const [DeliveryType, setDeliveryType] = useState("")
	useEffect(() => {
		setLoader(true);
		BusinessAPI.fetchBusiness(business_slug)
			.then(function (response) {
				setBusinesCategory(response.data.business.category)
				setIsEnabledDelivery(response.data.business.disAblePostalCode)
			}).catch(function (error) {
				console.log(error);
			});
		CheckoutAPI.getAvailablePostalCode(business_slug)
			.then(res => {
				setDeliveryType(res.data.business.delivery)
				setLoader(false)
			})
		CategoryAPI.getUserCategoriesForDropdown(business_slug)
			.then(res => {
				setCategories(res.data.categories)
			}).catch({

			})

		form.setFieldsValue({ category_id: params.category_id })





	}, []);

	// 	const fileProps = {

	// 	beforeUpload: (file) => {
	// 	  const isPNG = file.type === 'image/png';
	// 	  if (!isPNG) {
	// 		message.error(`${file.name} is not a png file`);
	// 	  }
	// 	  return isPNG || Upload.LIST_IGNORE;
	// 	},
	// 	onChange: (info) => {
	// 	  console.log(info.fileList);
	// 	},
	//   };

	const fileProps = {
		name: 'file',
		multiple: true,
		accept: 'image/*',
		action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
		async onChange(info) {
			const { status } = info.file;
		},
		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
	};

	const onFinish = async (value) => {
		value.description = Description
		value.short_description = short_description
		var photos = []
		await value.product_photos.fileList.map((element, index) => {
			const reader = new FileReader();
			reader.readAsDataURL(element.originFileObj)
			reader.onload = () => {
				photos[index] = reader.result;
			}
		});

		value.business_slug = business_slug;
		value.product_photos = photos;
		setLoader(true);
		setTimeout(() => {
			ProductAPI.saveProduct(value)
				.then(res => {
					setTimeout(() => {
						history.push(`/seller/business/${business_slug}/my-shop/products`);
						Swal.fire({
							title: res.data.message,
							imageUrl: CheckmarkCircle,
							imageAlt: 'success image',
							confirmButtonColor: '#00CA84'
						});
						setLoader(false);
					}, 2000)
				}).catch(error => {
					console.log(error)
					setLoader(false);
					Swal.fire({
						title: error.response.data.message || error.Error,
						imageUrl: CloseCircle,
						imageAlt: 'error image',
						confirmButtonColor: '#00CA84'
					});
				})
		}, 2000)
	}

	const order = {
		po_number: "123abc",
		carrier: "Fastway",
		customize_addon: [
			{
				item_code: "Cheese",
				quantity: "30"
			}
		]
	};

	//Create form fields based off how many customize_addon are in the order
	const itemInputs = order.customize_addon.map((item) => {
		return {
			item_code: item.item_code,
			quantity: item.quantity,
		};
	});
	// ==================

	const children = [];
	// for (let i = 10; i < 36; i++) {
	// 	children.push(<Option key={i.toString(36) + i}>{i.toString(36) + i}</Option>);
	// }

	return (
		<DashboardLayout page='products'>


			<div className="w-100 sidebar-right-content-spacing">
				<Spin spinning={loading} delay={500}>
					<div className="addProduct-section">
						<div className="d-flex align-self-center addProduct-mainHeadingBox">
							<button className="btn py-0" onClick={() => history.goBack()}> <ArrowBackIcon /></button>
							<h3 className="mb-0">Add Product</h3>
						</div>
						<Form
							layout="vertical"
							form={form}
							onFinish={onFinish}
						>
							<div className="addProduct-Box">
								<h5 className="addProduct-heading">Fill out the following information to start selling.</h5>
								<div class="my-4">
									<div class="form-group">
										<label htmlFor="exampleInputEmail1" className="fw-500">Product Name <span className="">*</span></label>
										<p class="label-subText">Give your product a short, clear name</p>
										<Form.Item className='ant-select-lg'
											name="product_name"
											rules={[{ required: true, message: lang.product_name_require_error_msg }]}
										>
											<Input className="form-control" placeholder="Product Name" />
										</Form.Item>
									</div>
									{/* <div className="d-flex flex-wrap">
									<p className="mb-0 text-paraDark align-self-center">Mark product as addon</p>
									<Form.Item className='ant-select-lg mb-0'
										name="product_mark_addon"
									>
										<Switch className="mx-2" />
									</Form.Item>
									<p className="greyText mb-0">This means that this product will not appear as a listing on your shop page</p>
								</div> */}
								</div>

								<div class="my-4">
									<div class="form-group">
										<label htmlFor="exampleInputEmail1" className="fw-500">SKU <span className="">*</span></label>
										<p class="label-subText">Unique identification to a specific product</p>
										<Form.Item className='ant-select-lg'
											name="product_sku"
											rules={[{ required: true, message: lang.sku_require_error_msg }]}
										>
											<Input className="form-control" placeholder="ABPZ11900" />
										</Form.Item>
									</div>
								</div>

								<div className="row my-4">
									<div className="col-md-4">
										<div className="mb-2">
											<label htmlFor="exampleInputEmail1" className="fw-500">Price <span className="">*</span></label>
										</div>
										<Form.Item className='ant-select-lg'
											name="product_price"
											rules={[
												// { type: 'float' },
												{ required: true, message: lang.price_require_error_msg }
											]}
										>
											{/* <InputNumber
											// precision={2}
											step='0.00' */}
											<Input
												placeholder="£9.99"
												suffix={
													<LoyaltyIcon style={{ color: 'rgba(0,0,0,.45)' }} />
												}
											/>
										</Form.Item>
									</div>
									<div className="col-md-4">
										<div className="mb-2">
											<label htmlFor="exampleInputEmail1" className="fw-500">Stock</label>
										</div>
										<div className="d-flex  pt-2">
											<Form.Item className='mb-0'
												name="out_of_stock"
											// rules={[{ required: true, message: lang.stock_require_error_msg }]}
											>
												<Switch className="mx-2" />
											</Form.Item>
											<p className='align-self-center mb-0'>Out of stock</p>
										</div>
									</div>
									<div className="col-md-4">
										<div className="mb-2">
											<label htmlFor="exampleInputEmail1" className="fw-500">Quantity</label>
										</div>
										<Form.Item className='mb-0'
											name="quantity"
											rules={[{ required: true, message: 'Please input your quantity.' }]}
										>
											<Input
												placeholder="Ex. 100"
											// suffix={
											// 	<LoyaltyIcon style={{ color: 'rgba(0,0,0,.45)' }} />
											// }
											/>
										</Form.Item>
									</div>
								</div>

								<hr />

								<div class="my-4">
									<div className="mb-2">
										<label htmlFor="exampleInputEmail1" className="fw-500">Add to category <span className="">*</span></label>
										<p class="label-subText">Add your product under a specifc category to make browsing easier</p>
									</div>
									<Form.Item className='ant-select-lg'
										name="category_id"
										rules={[{ required: true, message: lang.select_category_require_error_msg }]}
									>
										<Select
											showSearch
											// mode="multiple"
											style={{ width: '100%' }}
											placeholder="Search to Select"
											optionFilterProp="children"
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											filterSort={(optionA, optionB) =>
												optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
											}
										>
											{
												categories.map(category => {
													return (
														<Option key={category.category._id} value={category.category._id}>{category.category.category_name}</Option>
													)
												})
											}
										</Select>
									</Form.Item>
								</div>

								{/* <div>
								<ListGroup as="ul">
									<ListGroup.Item as="li">
										<p className="mb-0">Desserts</p>
									</ListGroup.Item>
									<ListGroup.Item as="li">
										<p className="mb-0">Ice creams</p>
									</ListGroup.Item>
								</ListGroup>
							</div> */}

								<div class="my-4">
									<div className="mb-2">
										<label htmlFor="exampleInputEmail1" className="fw-500">Product Image <span className="">*</span></label>
										<p class="label-subText">Used to represent your product on your shop front page. Upload upto 3 images</p>
									</div>
									<Form.Item
										name="product_photos"
										rules={[{ required: true, message: lang.photos_require_error_msg }]}
									>
										<Dragger listType='picture' beforeUpload={(file) => {
											const Size = file.size <= '1000000';
											if (!Size) {
												alert(`Please upload file size less than 1MB`);
												return Size || Upload.LIST_IGNORE;
											}


										}} {...fileProps} className="rounded-8">
											<p className="ant-upload-text">Drag and drop your images here or <span className="greenText fw-500 textUnderline">Click to browse</span> Files</p>
											<p className="ant-upload-hint">1200x1200 (1:1) recommended, upto 1 mb each</p>
										</Dragger>
									</Form.Item>
								</div>

								{
									// DeliveryType != "Pick up Only" && <div class="my-4">

									isEnabledDelivery &&
									<div class="my-4">
										<div className="mb-2">

											<label htmlFor="exampleInputEmail1" className="fw-500">Instant delivery <span className="">*</span></label>
										</div>
										<div className="row">
											<div class="col-md-auto col-12 align-self-center">
												<Form.Item
													name="instant_delivery"
													rules={[{ required: true, message: lang.instant_delivery_require_error_msg }]}
												>
													<Radio.Group value="1">
														<Radio value={true}>Yes</Radio>
														<Radio value={false}>No</Radio>
													</Radio.Group>
												</Form.Item>
											</div>
											<div class="col-md-6 col-12">
												<Form.Item className='ant-select-lg'
													name="instant_delivery_price"
												// rules={[{ required: true, message: lang.photos_require_error_msg }]}
												>
													<Input
														placeholder="9.99"
														suffix={
															<LoyaltyIcon style={{ color: 'rgba(0,0,0,.45)' }} />
														}
													/>
												</Form.Item>
											</div>
										</div>
									</div>
								}

								<div class="my-4">
									<div className="mb-2">
										<label htmlFor="exampleInputEmail1" className="fw-500">Short Description</label>
									</div>
									<Form.Item
										name="short_description"
									// rules={[{ required: true, message: lang.short_description_require_error_msg }]}
									>
										<Input.TextArea value={short_description}
											maxLength={201}

											onChange={(event) => {
												setShort_description(event.target.value)

											}} className={`${short_description.length > 200 && "border border-danger"}  form-control position-relative`} rows="3" placeholder="Vanilla filled chocolate goodness. A treat for the family gatherings and meetings with friends. This product is gluten free and Vegan, so dont forget to let loose!" />
										<span className="position-absolute " style={{ top: "74px", right: "20px", fontSize: "11px" }}>{short_description.length}/200</span>
									</Form.Item>
								</div>
								<div className='boxes_ays'>
									<div className='row'>
										<div className='col-xl-12 col-md-12 col-xs-12  mb-4'>
											<label className='labeltext_ays fw-500' htmlFor="describeyourbusiness">Description </label>
											{/* <Form.Item
											name="description"
										> */}
											<Editor className="form-control"
												apiKey='68r82ciq6emwun43ptzljmjdzym1atmw19a9jzwm2y163ctj'
												value={Description}
												onEditorChange={(newText) => setDescription(newText)}
												onInit={(evt, editor) => editorRef.current = editor}
												id="descibeBusiness"
												init={{
													selector: 'textarea',
													height: 500,
													menubar: false,
													menubar: 'view',
													plugins: 'pageembed',
													toolbar: 'undo redo | formatselect | ' +
														'bold italic underline| alignleft aligncenter ' +
														'alignright alignjustify | bullist numlist outdent indent | ' +
														'removeformat | pageembed',

													content_style: 'body { font-family:Gotham; font-size:16px }'
												}}
											/>
											{/* </Form.Item> */}

											{/* <textarea autoComplete='off' type="text" className="form-control " value={state.desc} onChange={(e) => setState({ desc: e.target.value })} id="descibeBusiness" placeholder="Descrilbe your business here in details...." /> */}
										</div>
									</div>
								</div>

								{
									business_category == 'Food' &&
									<div class="my-4">
										<div className="mb-2">
											<label htmlFor="exampleInputEmail1" className="fw-500">Veg/Non Veg</label>
										</div>
										<div>
											<Form.Item
												name="veg_category"
											>
												<Radio.Group>
													<Radio value='Veg'>Veg</Radio>
													<Radio value='No Veg'>None Veg</Radio>
													<Radio value='Vegan'>Vegan</Radio>
												</Radio.Group>
											</Form.Item>

										</div>
									</div>
								}

								<div class="my-4">
									<div className="mb-2">
										<label htmlFor="exampleInputEmail1" className="fw-500">Add tags</label>
									</div>
									<Form.Item className='ant-select-lg'
										name="product_tags"
									>
										<Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode">
											{children}
										</Select>
									</Form.Item>
								</div>
								<hr />

								{/* <div>
								<div className="mb-2">
									<label htmlFor="exampleInputEmail1" className="fw-500">Add customizations/Addons</label>
									<p class="label-subText">Allow the user to customize this product with add ons or toppings.</p>
								</div>
								<Form.List className="w-100 d-block" name="customize_addon" initialValue={itemInputs}>
									{(fields, { add, remove }) => (
										<>
											<div className="d-flex justify-content-end">
												<Form.Item>
													<Button
														onClick={() => add()}
														icon={<PlusOutlined />}
														className='btn btnPara-primary'
													>
														Create new addon
													</Button>
												</Form.Item>
											</div>
											{fields.map((field) => (
												<Space className='w-100 d-block'
													key={field.key}
												>
													<Container className="p-0">
														<Row>
															<Col xs="12" md="5">
																<Form.Item className=''
																	{...field}
																	name={[field.name, "item_code"]}
																	fieldKey={[field.fieldKey, "item_code"]}
																>
																	<Input placeholder="Addon Name" />
																</Form.Item>
															</Col>
															<Col xs="12" md="5">
																<Form.Item className=''
																	{...field}
																	name={[field.name, "quantity"]}
																	fieldKey={[field.fieldKey, "quantity"]}
																>
																	<Input placeholder="£10" />
																</Form.Item>
															</Col>
															<Col xs="12" md="2">
																<Form.Item>
																	<div className=''>
																		<button className='btn btn-outline-red btn-block px-2' onClick={() => remove(field.name)}>Remove</button>
																	</div>
																</Form.Item>

															</Col>
														</Row>
													</Container>
												</Space>
											))}

										</>
									)}
								</Form.List>
							</div>

							<hr /> */}
								<div class="my-4">
									<div className="mb-2">
										<label htmlFor="exampleInputEmail1" className="fw-500">Meta Description</label>
									</div>
									<Form.Item className='ant-select-lg'
										name="meta_description"
									>
										<TextArea
											placeholder="Meta Description"
											autoSize={{ minRows: 3, maxRows: 5 }} />
									</Form.Item>

								</div>

								<div class="my-4">
									<div className="mb-2">
										<label htmlFor="exampleInputEmail1" className="fw-500">Meta tags</label>
									</div>
									{/* <input type="text" class="form-control" placeholder="Cake" /> */}
									<Form.Item className='ant-select-lg'
										name="meta_tag"
									>
										<Input className="form-control" placeholder="Cake" />
									</Form.Item>
								</div>

								<div className="form-group d-flex justify-content-end my-5">
									<Form.Item className="form-item">
										<div className='d-flex w-100 justify-content-end'>
											{/* <button className="btn textUnderline mr-3">Save as draft</button> */}
											<Spin spinning={loading} delay={500}>
												<Button className="btn btnPara-primary btn-lg" htmlType="submit">
													Finish
												</Button>
											</Spin>
										</div>
									</Form.Item>
								</div>
							</div>
						</Form>
					</div>
				</Spin>
			</div>

		</DashboardLayout>
	)
}
