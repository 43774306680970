import '../../../../src/assets/css/UserPanel/userPanel.css';
import React, { useState, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { DashboardLayout } from '../Sidebar wrapper/Layout';
import { Redirect } from 'react-router-dom';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import searchIcon from '../../../assets/images/svg-icons/search-icon.svg';
import { Tabs, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { Token } from '../../../Token';
import { OrderAPI } from '../../../apis/user/OrderAPI';
import moment from 'moment';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../../assets/images/modalPopup/close-circle.svg';
import { Pagination } from 'antd';

const MANAGEORDERS = () => {

    const params = useParams();
    const business_slug = params.business_id;

    const [loading, setLoader] = useState(false);
    const [products, setProducts] = React.useState([]);
    const [TotalData, setTotalData] = useState(0)


    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            orders: [],
            o_id: '',
            orderID: '',
            shopName: '',
            houseName: '',
            orderAddress: '',
            postalCode: '',
            dateTime: '',
            orderAmount: 0,
            finalOrderAmount: 0,
            orderDiscount: 0,
            deliveryCharge: 0,
            orderDiscountType: "Cash",
            orderStatus: '',
            orderProducts: [],
            totalProductDiscount: 0,
            payment_method: 'Stripe',
            payment_status: 'Paid',
            Note: "",
            count: 0
        }
    );

    useEffect(() => {
        getOrders();
    }, []);

    const getOrders = () => {
        setLoader(true)
        OrderAPI.fetchShopAllOrders(params.business_id)
            .then(function (res) {
                setLoader(false)
                setState({ count: res?.data?.count })
                setState({ orders: res.data.orders })
            }).catch(function (error) {
                setLoader(false)
                console.log(error);
            });
    }
    const HandlePagination = (PageNumber) => {
        OrderAPI.PaginationShopAllOrders(PageNumber, business_slug)
            .then((res) => {
                console.log(res)
                setState({ count: res?.data?.count })
                if (res.data?.orders) {
                    setState({ orders: res?.data?.orders })
                }
            })
    }
    const onChangeOrderStatus = (e, order_id) => {
        setLoader(true)
        OrderAPI.changeOrderStatus(e.target.value, order_id)
            .then(function (res) {
                getOrders();
                setLoader(false)
                Swal.fire({
                    text: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                });
            }).catch(function (error) {
                setLoader(false)
                console.log(error);
            });
    }

    const onChangePaymentStatus = (e, order_id) => {
        setLoader(true)
        OrderAPI.changePaymentStatus(e.target.value, order_id)
            .then(function (res) {
                getOrders();
                setLoader(false)
                Swal.fire({
                    text: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                });
            }).catch(function (error) {
                setLoader(false)
                console.log(error);
            });
    }

    const onClickViewDetail = (order) => {
        let total_product_discount_price = 0;
        order.carts.forEach(cart => {
            var pPrice = cart.product_price;
            if (cart.product_discounted_price != 0) {
                let dPrice = pPrice - cart.product_discounted_price;
                total_product_discount_price = total_product_discount_price + (dPrice * parseInt(cart.quantity))
            }
        });

        setState({
            Note: order.leaveANote,
            o_id: order.id,
            orderID: order.order_id,
            payment_method: order.payment_method,
            payment_status: order.payment_status,
            shopName: order.business.business_name,
            postalCode: order.business.postal_code,
            houseName: order?.address?.house_name || '',
            orderAddress: order?.address?.address || "",
            dateTime: order?.date_time,
            orderAmount: order.order_amount,
            finalOrderAmount: order.final_order_amount,
            orderStatus: order.order_status,
            orderDiscount: order.order_discount,
            deliveryCharge: order.delivery_charge,
            orderDiscountType: order.order_discount_type,
            orderProducts: order.carts,
            totalProductDiscount: parseFloat(total_product_discount_price).toFixed(2)
        })
    }

    if (Token.isLoggedIn()) {
        return (
            <>
                <DashboardLayout page='manage_orders'>
                    <div className="w-100 sidebar-right-content-spacing">
                        <div className="sidebar-content">
                            <div className="blackBox-heading d-flex justify-content-between ">
                                <p className="align-self-center mb-0">You are viewing {Token.getFullName()}</p>
                                <Link className="btn py-0" to={`/seller/business/${params.business_id}`}><ArrowBackIcon /> Home</Link>
                            </div>
                            <div className="manageOrder-box">
                                <div className="row">
                                    <div className="col-12">
                                        <h2>Manage Orders</h2>
                                    </div>
                                </div>

                                <div className="paraTabs">
                                    <Tabs defaultActiveKey="OpenOrders" transition={false} id="orderTabs">
                                        <Tab eventKey="OpenOrders" title="Open orders">
                                            {/* <div className="has-searchBox my-4">
                                                <div className="form-group has-search">
                                                    <img src={searchIcon} alt="" className="form-control-feedback" />
                                                    <input type="text" className="form-control form-control-lg" placeholder="Search Open order ID, name etc." />
                                                </div>
                                            </div> */}
                                            <div className="table-responsive paraRounded-table">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Time</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Payment Type</th>
                                                            <th scope="col">Payment Status</th>
                                                            <th scope="col">Order Status</th>
                                                            {/* <th scope="col"></th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* <tr className="table-paraDark">
                                                            <td colspan="6" className="align-middle py-1"><p className="mb-0 greyText">Saturday, April 17th</p></td>
                                                        </tr> */}
                                                        {
                                                            state.orders.map((order, i) => {
                                                                if (order.order_status != 'Delivered') {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>
                                                                                <div>
                                                                                    <p className="mb-0">{`${order.user.first_name} ${order.user.last_name}`}</p>
                                                                                    <p className="mb-0 greyText">Order ID: {order.order_id}</p>
                                                                                </div>
                                                                            </td>
                                                                            <td className="align-middle"><p className="mb-0 greyText">{moment(order.date_time).format("h:mm A") + "," + moment(order.date_time).format("DD-MM-YYYY")}</p></td>
                                                                            <td className="align-middle">
                                                                                <p className="mb-0 ">
                                                                                    £{parseFloat(order.final_order_amount).toFixed(2)}
                                                                                </p>
                                                                            </td>
                                                                            <td className="align-middle"><p className="mb-0">{order.payment_method}</p></td>
                                                                            <td className="align-middle">
                                                                                <div>
                                                                                    <select className="form-selectOption option-redText"
                                                                                        onChange={(e) => {
                                                                                            onChangePaymentStatus(e, order.id)
                                                                                        }}>
                                                                                        <option className="option-redText" value="Unpaid" selected={order.payment_status == 'Unpaid'}>Unpaid</option>
                                                                                        <option className="option-redText" value="Paid" selected={order.payment_status == 'Paid'}>Paid</option>

                                                                                    </select>
                                                                                </div>
                                                                            </td>
                                                                            <td className="align-middle">
                                                                                <div>
                                                                                    <select className="form-selectOption option-redText"
                                                                                        onChange={(e) => {
                                                                                            onChangeOrderStatus(e, order.id)
                                                                                        }}>
                                                                                        <option className="option-redText" value="Processing" selected={order.order_status == 'Processing'}>Processing</option>
                                                                                        <option className="option-redText" value="Out For Delivery" selected={order.order_status == 'Out For Delivery'}>Out For Delivery</option>
                                                                                        <option className="option-redText" value="Delivered" selected={order.order_status == 'Delivered'}>Delivered</option>
                                                                                    </select>
                                                                                </div>
                                                                            </td>
                                                                            <td className="align-middle text-right">
                                                                                <button
                                                                                    className=" btn btn-view-order"
                                                                                    data-toggle="modal"
                                                                                    data-target="#order_details_modal"
                                                                                    onClick={() => onClickViewDetail(order)}
                                                                                >
                                                                                    View Order <ArrowForwardIcon style={{ fontSize: 16 }} />
                                                                                </button>
                                                                                {/* <button className="btn btn-light btnPara-sms btn-rounded ml-2"><SmsOutlinedIcon style={{ fontSize: 16 }} /></button> */}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="ClosedOrders" title="Closed orders">
                                            {/* <div className="has-searchBox my-4">
                                                <div className="form-group has-search">
                                                    <img src={searchIcon} alt="" className="form-control-feedback" />
                                                    <input type="text" className="form-control form-control-lg" placeholder="Search Close order ID, name etc." />
                                                </div>
                                            </div> */}
                                            <div className="table-responsive paraRounded-table">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Time</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Payment Type</th>
                                                            <th scope="col">Payment Status</th>
                                                            <th scope="col">Order Status</th>
                                                            {/* <th scope="col"></th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* <tr className="table-paraDark">
                                                            <td colspan="6" className="align-middle py-1"><p className="mb-0 greyText">Saturday, April 17th</p></td>
                                                        </tr> */}
                                                        {
                                                            state.orders.map((order, i) => {
                                                                if (order.order_status == 'Delivered') {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>
                                                                                <div>
                                                                                    <p className="mb-0">{`${order.user.first_name} ${order.user.last_name}`}</p>
                                                                                    <p className="mb-0 greyText">Order ID: {order.order_id}</p>
                                                                                </div>
                                                                            </td>
                                                                            <td className="align-middle"><p className="mb-0 greyText">{moment(order.date_time).format("h:mm A") + "," + moment(order.date_time).format("DD-MM-YYYY")}</p></td>
                                                                            <td className="align-middle"><p className="mb-0">£{order.final_order_amount}</p></td>
                                                                            <td className="align-middle"><p className="mb-0">{order.payment_method}</p></td>
                                                                            <td className="align-middle">
                                                                                <div>
                                                                                    <select className="form-selectOption option-redText"
                                                                                        onChange={(e) => {
                                                                                            onChangePaymentStatus(e, order.id)
                                                                                        }}>
                                                                                        <option className="option-redText" value="Unpaid" selected={order.payment_status == 'Unpaid'}>Unpaid</option>
                                                                                        <option className="option-redText" value="Paid" selected={order.payment_status == 'Paid'}>Paid</option>

                                                                                    </select>
                                                                                </div>
                                                                            </td>
                                                                            <td className="align-middle">
                                                                                <div>
                                                                                    <select className="form-selectOption option-redText"
                                                                                        onChange={(e) => {
                                                                                            onChangeOrderStatus(e, order.id)
                                                                                        }}>
                                                                                        <option className="option-redText" value="Processing" selected={order.order_status == 'Processing'}>Processing</option>
                                                                                        <option className="option-redText" value="Out For Delivery" selected={order.order_status == 'Out For Delivery'}>Out For Delivery</option>
                                                                                        <option className="option-redText" value="Delivered" selected={order.order_status == 'Delivered'}>Delivered</option>
                                                                                    </select>
                                                                                </div>
                                                                            </td>
                                                                            <td className="align-middle text-right">
                                                                                <button
                                                                                    className=" btn btn-view-order"
                                                                                    data-toggle="modal"
                                                                                    data-target="#order_details_modal"
                                                                                    onClick={() => onClickViewDetail(order)}
                                                                                >
                                                                                    View Order <ArrowForwardIcon style={{ fontSize: 16 }} />
                                                                                </button>
                                                                                {/* <button className="btn btn-light btnPara-sms btn-rounded ml-2"><SmsOutlinedIcon style={{ fontSize: 16 }} /></button> */}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="AllOrders" title="All orders">
                                            {/* <div className="has-searchBox my-4">
                                                <div className="form-group has-search">
                                                    <img src={searchIcon} alt="" className="form-control-feedback" />
                                                    <input type="text" className="form-control form-control-lg" placeholder="Search All order ID, name etc." />
                                                </div>
                                            </div> */}
                                            <div className="table-responsive paraRounded-table">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Time</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Payment Type</th>
                                                            <th scope="col">Payment Status</th>
                                                            <th scope="col">Order Status</th>
                                                            {/* <th scope="col"></th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {/* <tr className="table-paraDark">
                                                            <td colspan="6" className="align-middle py-1"><p className="mb-0 greyText">Saturday, April 17th</p></td>
                                                        </tr> */}
                                                        {
                                                            state.orders.map((order, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td>
                                                                            <div>
                                                                                <p className="mb-0">{`${order.user.first_name} ${order.user.last_name}`}</p>
                                                                                <p className="mb-0 greyText">Order ID: {order.order_id}</p>
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle"><p className="mb-0 greyText">{moment(order?.date_time).format("h:mm A")}</p></td>

                                                                        <td className="align-middle"><p className="mb-0">£{order.order_amount}</p></td>
                                                                        <td className="align-middle"><p className="mb-0">{order.payment_method}</p></td>
                                                                        <td className="align-middle">
                                                                            <div>
                                                                                <select className="form-selectOption option-redText"
                                                                                    onChange={(e) => {
                                                                                        onChangePaymentStatus(e, order.id)
                                                                                    }}>
                                                                                    <option className="option-redText" value="Unpaid" selected={order.payment_status == 'Unpaid'}>Unpaid</option>
                                                                                    <option className="option-redText" value="Paid" selected={order.payment_status == 'Paid'}>Paid</option>

                                                                                </select>
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle">
                                                                            <div>
                                                                                <select className="form-selectOption option-redText"
                                                                                    onChange={(e) => {
                                                                                        onChangeOrderStatus(e, order.id)
                                                                                    }}>
                                                                                    <option className="option-redText" value="Processing" selected={order.order_status == 'Processing'}>Processing</option>
                                                                                    <option className="option-redText" value="Out For Delivery" selected={order.order_status == 'Out For Delivery'}>Out For Delivery</option>
                                                                                    <option className="option-redText" value="Delivered" selected={order.order_status == 'Delivered'}>Delivered</option>
                                                                                </select>
                                                                            </div>
                                                                        </td>
                                                                        <td className="align-middle text-right">
                                                                            <button
                                                                                className=" btn btn-view-order"
                                                                                data-toggle="modal"
                                                                                data-target="#order_details_modal"
                                                                                onClick={() => onClickViewDetail(order)}
                                                                            >
                                                                                View Order <ArrowForwardIcon style={{ fontSize: 16 }} />
                                                                            </button>
                                                                            {/* <button className="btn btn-light btnPara-sms btn-rounded ml-2"><SmsOutlinedIcon style={{ fontSize: 16 }} /></button> */}
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>


                            </div>
                            <div className="d-flex justify-content-end mt-5">
                                <Pagination defaultCurrent={1} onChange={HandlePagination} total={state.count} />
                            </div>
                        </div>
                    </div>
                </DashboardLayout>

                {/* ///////////////// view order open modal code/////////////////// */}

                <div className="modal modal_outer right_modal fade" id="order_details_modal" tabIndex="-1" role="dialog" ariaLabelledby="myModalLabel5">
                    <div className="modal-dialog modal-dialog-scrollable" role="document">
                        <div className="modal-content">

                            <div className="modal-body get_quote_view_modal_body">
                                <div className="#">
                                    <div className="d-flex justify-content-between">
                                        <h4 className="mb-0" id="exampleModalLabel">Order #{state.orderID}</h4>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <h4 className="mb-1">{state.shopName}</h4>
                                    <p className="greyText ft-size14">
                                        {
                                            state?.orderAddress &&
                                            <span>{`${state?.houseName || ""}, ${state?.orderAddress || ""}, ${state.postalCode}`}, </span>
                                        }
                                        <span>{moment(state.dateTime).format("dddd Do MMM, YYYY, h:mm A")}</span>
                                    </p>
                                </div>
                                {/* <hr />
                                <div className="table-responsive">
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <td className="pl-0 py-1 pr-2">
                                                    <div className="d-flex">
                                                        <img src={vegIcon} alt="" className="img-fluid mr-2" />
                                                        <p className="mb-0">product_name</p>
                                                    </div>
                                                </td>
                                                <td className="py-1">xproduct-quantity</td>
                                                <td className="py-1 pr-0 text-right">£</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> */}
                                <hr />
                                <div className="d-flex align-items-end">
                                    <p className="mb-2 mr-2">Order status</p>
                                    <div className='order-status-opation'>
                                        <select className="form-selectOption option-redText"
                                            onChange={(e) => {
                                                onChangeOrderStatus(e, state.o_id)
                                            }}>
                                            <option className="option-redText" value="Processing" selected={state.orderStatus == 'Processing'}>Processing</option>
                                            <option className="option-redText" value="Out For Delivery" selected={state.orderStatus == 'Out For Delivery'}>Out For Delivery</option>
                                            <option className="option-redText" value="Delivered" selected={state.orderStatus == 'Delivered'}>Delivered</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="d-flex align-items-end">
                                    <p className="mb-2 mr-2">Payment status</p>
                                    <div className='order-status-opation'>
                                        <select className="form-selectOption option-redText"
                                            onChange={(e) => {
                                                onChangePaymentStatus(e, state.o_id)
                                            }}>
                                            <option className="option-redText" value="Unpaid" selected={state.payment_status == 'Unpaid'}>Unpaid</option>
                                            <option className="option-redText" value="Paid" selected={state.payment_status == 'Paid'}>Paid</option>
                                        </select>
                                    </div>
                                </div>
                                <h6><b>Order details</b></h6>
                                <div className="# manage-order-product-view-deatil">
                                    <div className='w-100'>

                                        <div className="row d-flex justify-content-between">
                                            <div className="col-5"><b>Product Name</b></div>
                                            <div className="col-3"><b>Quantity</b></div>
                                            <div className="col-4"><b>Total</b></div>
                                        </div>

                                        {
                                            state.orderProducts.map((product, i) => {
                                                return (
                                                    <div key={i}>
                                                        <div className="row d-flex justify-content-between">
                                                            <div className="col-5">{product.product_name}</div>
                                                            <div className="col-3">{product.quantity}</div>
                                                            <div className="col-4">{parseFloat(product.product_price).toFixed(2)}</div>

                                                            {/* <div className="col-4">£{(product.product_discounted_price == 0 ? parseFloat(product.product_price) : parseFloat(product.product_discounted_price) * parseInt(product.quantity)).toFixed(2)}</div> */}
                                                        </div>

                                                        {/* <div className="d-flex justify-content-between">
                                                        <p className="mb-2 ml-2">Extra Cheese</p>
                                                        <p className="mb-2 ">£0</p>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        <p className="mb-2 ml-2">Extra Meat</p>
                                                        <p className="mb-2">£0</p>
                                                    </div> */}
                                                    </div>
                                                )
                                            })
                                        }

                                        <hr />
                                        {/* <div className="row d-flex justify-content-between">
                                            <div className="col-8">Packaging charges</div>
                                            <div className="col-4">£0</div>
                                        </div>
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-8">Taxes</div>
                                            <div className="col-4">£0</div>
                                        </div> */}
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-8">Delivery Charge</div>
                                            <div className="col-4">£{state.deliveryCharge}</div>
                                        </div>
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-8 greenText">Discount</div>
                                            <div className="col-4 greenText">
                                                {/* {
                                                    state.orderDiscountType == 'Cash' ?
                                                    <h5 className="mb-0 greenText">£{(parseFloat(state.orderDiscount) + parseFloat(state.totalProductDiscount)).toFixed(2)}</h5> :
                                                    <h5 className="mb-0 greenText">£{(((parseFloat(state.orderAmount) / 100) * parseFloat(state.orderDiscount)) + parseFloat(state.totalProductDiscount)).toFixed(2)}</h5>
                                                } */}
                                                <h5 className="mb-0 greenText">£{parseFloat(state.orderDiscount).toFixed(2)}</h5>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-8">
                                                <h5>Bill total</h5>
                                            </div>
                                            <div className="col-4">
                                                {/* {
                                                    state.finalOrderAmount > 0 ?
                                                        <>
                                                            {
                                                                state.orderDiscountType == 'Cash' ?
                                                                    <h5 className="mb-0">£{(parseFloat(state.orderAmount) - (parseFloat(state.orderDiscount) + parseFloat(state.totalProductDiscount)) + parseFloat(state.deliveryCharge)).toFixed(2)}</h5> :
                                                                    <h5 className="mb-0">£{(parseFloat(state.orderAmount) - (((parseFloat(state.orderAmount) / 100) * parseFloat(state.orderDiscount)) + parseFloat(state.totalProductDiscount)) + parseFloat(state.deliveryCharge)).toFixed(2)}</h5>
                                                            }
                                                        </> :
                                                        <>0.00</>
                                                } */}
                                                <h5 className="mb-0">£{parseFloat(state.finalOrderAmount).toFixed(2)}</h5>

                                            </div>

                                        </div>
                                        {/* {/ <p className="mb-2 greenText">Paid via debit card</p> /} */}
                                        <p className="mb-2 greenText">Paid Via {state.payment_method}</p>

                                    </div>
                                </div>
                                <div className="# mt-4">
                                    <h5>Note: </h5>
                                    <p><small>{state?.Note}</small></p>
                                </div>
                                {/* <div className="# d-flex align-items-baseline">
                                    <button className='bg-white rounded p-2'>Message Customer</button>
                                    <p className='ml-4'>Help</p>
                                </div> */}

                            </div>

                        </div>
                    </div>
                </div>
                {/* ==== End: order_details_modal  =====  */}
            </>
        )

    }
    else {
        return <Redirect to='/' />
    }
}

export default MANAGEORDERS
