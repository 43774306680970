import React, { useReducer, useEffect } from 'react';
import '../../assets/css/UserPanel/userPanel.css';
import { Spinner } from 'react-bootstrap';
import setupbusiness from '../../assets/images/sellerProfile/setup-business.svg';
import { Link } from 'react-router-dom';
import { BusinessAPI } from '../../apis/user/BusinessAPI';

function MyBusinesses() {

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            heading: "myBusinesses",
            businesses: []
        }
    );

    useEffect(() => {
        BusinessAPI.fetchBusinesses()
            .then(function (response) {
                var data = response.data;
                setState({
                    businesses: data.businesses,
                    spinnervisibility: 'hidden'
                })
            }).catch(function (error) {
                console.log(error);
            });

    }, [])

    return (
        <>
            <div className="profile-info bg-white my-3 p-3 rounded shadow-sm">
                {state.businesses.length != 0 ?
                    state.businesses.map((business, index) => {
                        return <div key={index} className="media mybusinesses py-2">
                            <img src={business.company_logo} alt="" className="bd-placeholder-img mr-3 rounded-circle" width="48" height="48" />
                            <div className="media-body pb-3 mb-0 small">
                                <div className="d-flex justify-content-between align-items-center w-100">
                                    <div className="#">
                                        <h4 className="text-dark">{business.business_name}</h4>
                                        <p>{business.all_step_done == 'Yes' ? 'Live' : 'Incomplete Setup'}</p>
                                    </div>
                                    <div>
                                        <Link
                                            className="btn btn-outline-secondary"
                                            to={`/seller/business/${business.business_slug}`}>
                                            Go to Admin
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }) : <div className="d-flex justify-content-center"
                        style={{ visibility: state.spinnervisibility }}>
                            Business not added yet
                        {/* <Spinner animation="grow" variant="success" />
                        <Spinner animation="grow" variant="success" />
                        <Spinner animation="grow" variant="success" /> */}
                    </div>}
            </div>
            <div className="profile-info">
                <div className="media business-info bg-white my-3 p-3 rounded shadow-sm">
                    <div className="media-body mb-0 small">
                        <div className="d-md-flex justify-content-md-around align-items-center  w-100">
                            <div className="my-3 text-center">
                                <img src={setupbusiness} className="img-fluid" alt="" />
                            </div>
                            <div className="my-3 text-center">
                                <h5 className="text-dark  mb-4">Set up a new business</h5>
                                <Link
                                    className="btn btnPara-primary"
                                    to={`/sell`}>
                                    Create business
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyBusinesses
