import React from 'react';
import '../../../assets/css/UserPanel/userPanel.css';
import { DashboardLayout } from '../Sidebar wrapper/Layout'
import Card from 'react-bootstrap/Card'
import { Row, Col } from 'react-bootstrap'
import DocumentImage from '../../../assets/images/userPanel/documents.png';


export default function StoreDocuments() {
    return (

        <DashboardLayout page='store-section'>
            <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-content">
                    <h4>My Documents</h4>
                    <Row>
                        <Col lg="3" md="4">
                            <Card className='mb-2'>
                                <Card.Header>
                                    <Card.Img variant="top" src={DocumentImage} />
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <h5 className='mb-0'>Document 1.pdf</h5>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <div>
                                <h6 className='mb-0 redText'>Expired</h6>
                            </div>
                        </Col>

                        <Col lg="3" md="4">
                            <Card className='mb-2'>
                                <Card.Header>
                                    <Card.Img variant="top" src={DocumentImage} />
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <h5 className='mb-0'>Document 1.pdf</h5>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <div>
                                <h6 className='mb-0 greenText'>Valid</h6>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </DashboardLayout>

    )
}
