import React, { useState, useEffect, useReducer, Fragment } from 'react'
import '../../Components/styles/css/Order_Checkout/mycart.css'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import cartWhite from '../../assets/images/svg-icons/cart-white.svg';
import vegIcon from '../../assets/images/svg-icons/veg_icon.svg';
import nonvegIcon from '../../assets/images/svg-icons/nonveg-icon.svg';
import cakeimage from '../../assets/images/svg-icons/cakeimage.svg';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import noShopImg from '../../assets/images/products/no-products-img.svg';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import { manageCart, getCarts, setCarts, Token, clearFromCart, storeDiscount, getCartShop, getDiscount, removeDiscount, getFlashSaleDiscount } from '../../Token';
import Swal from 'sweetalert2';
import CloseCircle from '../../assets/images/modalPopup/close-circle.svg';
import AlertTriangle from '../../assets/images/modalPopup/alert-triangle.svg';
import CheckmarkCircle from '../../assets/images/modalPopup/checkmark-circle.svg';
import { Modal } from 'react-bootstrap';
import { Form, Input, Spin } from 'antd'
import { PromotionAPI } from '../../apis/user/PromotionAPI';
import { AddLocation, AddIcCall } from '@mui/icons-material/';
import DeleteIcon from '../../assets/images/svg-icons/deleticon.png'
import { MessageAPI } from '../../apis/user/MessageAPI';
import { GuestAPI } from '../../apis/GuestAPI';
import cakeimg from '../../assets/images/shopDetails/cakeimg.svg';
import SignIn from '../../Components/auth/SignIn';

function MYCART(props) {
    const history = useHistory()
    const [form] = Form.useForm();
    const [loading, setLoader] = useState(false);
    const [OrderShow, setOrderShow] = useState(false)
    let show = true
    const [ShowSingInModel, setShowSingInModel] = useState({
        _isShowSingInModel: false,

    });
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            count: 0,
            totalDiscountPrice: 0,
            totalCheckoutPrice: 0,
            isOpenCouponModel: false,
            couponAmount: false,
            // discount: 0,
            // discountType: 'Cash',
            cartProducts: [],
            similarProducts: [],
            finalAmount: 0,
            totalProductDiscount: 0,
            stateCards: getCarts(),
            business: {},
            couponDiscount: getDiscount() == null ? 0 : getDiscount().discount,
            couponDiscountType: getDiscount() == null ? 'Cash' : getDiscount().discount_type,
            cartTotalGraterAmount: getDiscount() == null ? 'Cash' : getDiscount().cart_total_grater_amount,
            showingBusiness: [],
        }
    );

    useEffect(() => {
        getTotalCheckoutPrice();
        getProductByCategory();
    }, [])

    const getProductByCategory = () => {
        let categories = [];
        for (var i = 0; i < Object.keys(getCarts()).length; i++) {
            categories[i] = getCarts()[Object.keys(getCarts())[i]].category;
        }

        const data = {
            business_id: getCartShop().business_id,
            categories_ids: categories
        }
        GuestAPI.getProductByCategory(data)
            .then(function (response) {
                let products = response.data.products;
                let business = response.data.business;
                setState({ similarProducts: products, business: business })
            }).catch(function (error) {
                console.log(error);
            });
    }

    const getTotalCheckoutPrice = () => {
        let total_product_price = 0;
        let total_product_discount_price = 0;
        let pArr = [];

        for (var i = 0; i < Object.keys(getCarts()).length; i++) {
            var pPrice = getCarts()[Object.keys(getCarts())[i]].product_price;

            if (getCarts()[Object.keys(getCarts())[i]].product_discounted_price != 0) {
                let dPrice = pPrice - getCarts()[Object.keys(getCarts())[i]].product_discounted_price;
                total_product_discount_price = total_product_discount_price + (dPrice * parseInt(getCarts()[Object.keys(getCarts())[i]].quantity))
            }

            total_product_price = total_product_price + (pPrice * parseInt(getCarts()[Object.keys(getCarts())[i]].quantity));
            pArr[i] = getCarts()[Object.keys(getCarts())[i]];
        }

        if (state.cartTotalGraterAmount > total_product_price) {
            removeDiscount()
            setState({
                couponDiscount: 0,
                couponDiscountType: 'Cash',
                cartTotalGraterAmount: null
            })
            window.location.reload()
        }

        let totalCouponCodeAndProductDiscount = state.couponDiscountType == 'Cash' ?
            (parseFloat(state.couponDiscount) + parseFloat(total_product_discount_price)).toFixed(2) :
            (((parseFloat(total_product_price) / 100) * parseFloat(state.couponDiscount)) + parseFloat(total_product_discount_price)).toFixed(2)

        const flashSaleDiscount = getFlashSaleDiscount();
        let totalFlashDscount = 0
        if (flashSaleDiscount) {
            totalFlashDscount = flashSaleDiscount.discount_type == 'Cash' ? (parseFloat(flashSaleDiscount.discount)).toFixed(2) :
                ((parseFloat(total_product_price) / 100) * parseFloat(flashSaleDiscount.discount)).toFixed(2)
        }

        const totalDiscountPrice = parseFloat(parseFloat(totalCouponCodeAndProductDiscount) + parseFloat(totalFlashDscount)).toFixed(2)
        // let finalAmount = state.couponDiscountType == 'Cash' ?
        //     (parseFloat(total_product_price) - (parseFloat(state.couponDiscount) + parseFloat(total_product_discount_price))).toFixed(2) :
        //     (parseFloat(total_product_price) - (((parseFloat(total_product_price) / 100) * parseFloat(state.couponDiscount)) + parseFloat(total_product_discount_price))).toFixed(2)
        const finalAmount = parseFloat(parseFloat(total_product_price) - parseFloat(totalDiscountPrice)).toFixed(2)
        
        setState({
            finalAmount: finalAmount <= 0 ? 0 : finalAmount,
            totalDiscountPrice: totalDiscountPrice,
            totalCheckoutPrice: total_product_price,
            totalProductDiscount: parseFloat(total_product_discount_price).toFixed(2),
            cartProducts: pArr
        })
    }

    const onClickClearCart = () => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            imageUrl: AlertTriangle,
            imageAlt: 'warning image',
            showCancelButton: true,
            confirmButtonColor: '#00CA84',
            cancelButtonColor: '#FF5858',
        }).then((result) => {
            if (result.isConfirmed) {
                clearFromCart();
                props.manageCartCount(Object.keys(getCarts()).length)
                history.push('/')
            }
        });
    }

    const applyDescountCode = (value) => {
        let order_amount = (parseFloat(state.totalCheckoutPrice) - parseFloat(state.totalProductDiscount)).toFixed(2)
        setLoader(true)
        let business_id = Object.keys(getCartShop()).length > 0 && getCartShop().business_id
        PromotionAPI.applyCouponCode(value.coupon_code, business_id, order_amount)
            .then(res => {
                setLoader(false)
                let coupon = res.data.coupon;
                storeDiscount(coupon)
                setState({
                    isOpenCouponModel: false,
                    discount: res.data.coupon.discount,
                    discountType: res.data.coupon.discount_type
                })
                Swal.fire({
                    text: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                })
                setTimeout(() => { getTotalCheckoutPrice(); }, 2000)
                window.location.reload()

            }).catch(function (error) {
                setLoader(false)
                Swal.fire({
                    title: error.response.data.message,
                    imageUrl: CloseCircle,
                    imageAlt: 'error image',
                    confirmButtonColor: '#00CA84'
                });
            });
    }


    const onCreateMessageThred = () => {
        let business_slug = Object.keys(getCartShop()).length > 0 && getCartShop().business_slug;
        const data = {
            business_id: Object.keys(getCartShop()).length > 0 && getCartShop().business_id
        }

        MessageAPI.createMessageRoom(data)
            .then(res => {
                history.push('/seller-profile/messages-' + business_slug)
            }).catch(err => {
                Swal.fire({
                    title: 'Please login to message',
                    imageUrl: AlertTriangle,
                    imageAlt: 'warning image',
                    confirmButtonColor: '#00CA84'
                });
            })
    }

    const setTotalCheckoutPrice = () => {
        props.manageCartCount(Object.keys(getCarts()).length)

        setState({
            stateCards: getCarts()
        })
    }

    const back = () => {
        const businessName = getCartShop().business_name.toLowerCase().replace(/\s/g, "")
        history.push(`/businesses/${businessName}`)
    }

    let showingBusiness;
    const DeleteItem = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            imageUrl: AlertTriangle,
            imageAlt: 'warning image',
            showCancelButton: true,
            confirmButtonColor: '#00CA84',
            cancelButtonColor: '#FF5858',
        }).then((result) => {
            if (result.isConfirmed) {
                const carts = {}
                const newCartProducts = []
                state.cartProducts.map((product, i) => {
                    if (product.product_id !== id) {
                        var mskeJSON = `{
                            "${product.product_id}": 
                                { 
                                    "category": "${product.category}", 
                                    "product_id": "${product.product_id}" ,
                                    "product_name": "${product.product_name}" ,
                                    "product_image": "${product.product_image}" ,
                                    "product_price": "${product.product_price}" ,
                                    "product_discounted_price": "${product.product_discounted_price}" ,
                                    "business": "${product.business}",
                                    "quantity": "${product.quantity}",
                                    "instant_delivery": ${product.instant_delivery},
                                    "instant_delivery_price": "${product.instant_delivery_price}",
                                    "veg_category":"${product?.veg_category}"
                                }
                            }`;
                        Object.assign(carts, JSON.parse(mskeJSON));
                        newCartProducts.push(product)

                    }
                })
                // localStorage.setItem('product_carts', JSON.stringify(carts));
                setCarts(carts)
                setState({ cartProducts: newCartProducts })
                props.manageCartCount(Object.keys(getCarts()).length)

                setTimeout(() => { getTotalCheckoutPrice() }, 200)

            }
        })

    }

    return (
        <div style={{ marginTop: '76px' }}>
            {/* <div className="black-box">
                <div className='container my-4'>
                    <div className='row'>
                        <div className='col-xl-8 col-lg-8 col-md-7 col-12'>
                            <div className="text-white pb-4">
                                <hr className="greenLine ml-0" />
                                <h1 className="shop-name ft-Gotham text-white">My Cart</h1>
                                <p className="address ft-size18"> Shop: {Object.keys(getCartShop()).length > 0 && getCartShop().business_name}</p>
                            </div>
                            <div className='d-flex align-items-center'>
                                <div className='mr-2'>
                                    <button className="btn btn-outline-white">Message Store</button>
                                </div>
                                {/* <div className='mr-2'>
                                    <button className="btn btn-outline-red"
                                        onClick={onClickClearCart}
                                    >Clear Cart</button>
                                </div> 
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-5 col-12 h-100 align-self-center">
                            <div className="text-md-center py-md-5 pt-5">
                                <img src={cartWhite} alt="company Logo" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            {
                state.cartProducts.length > 0 ?
                    <>
                        <div className='container my-4 py-4'>
                            <h1 className="shop-name ft-Gotham text-dark">Your order for {Object.keys(getCartShop()).length > 0 && getCartShop().business_name}</h1>
                            <div className='row'>
                                <div className='col-xl-3'>
                                    <p>
                                        {
                                            Object.keys(getCartShop()).length > 0 && getCartShop().mobile_number &&
                                            <img src='https://img.icons8.com/small/50/000000/phone.png' alt="call" style={{ width: '20px' }} />
                                        }
                                        {Object.keys(getCartShop()).length > 0 && getCartShop().mobile_number}
                                    </p>
                                </div>
                                <div className='col-xl-4'>
                                    <p><img src="https://img.icons8.com/material-rounded/16/000000/marker.png" style={{ width: '20px' }} /> {`${getCartShop().address} ${getCartShop().postal_code}`}</p>
                                </div>
                                <div className='col-xl-2'>
                                    <p>
                                        <a className='text-black'
                                            onClick={onCreateMessageThred}>
                                            <img src="https://img.icons8.com/material-outlined/16/000000/speech-bubble-with-dots.png" style={{ width: '25px' }} className='pr-1' />
                                            Message Store
                                        </a>
                                    </p>
                                </div>
                                {/* <div className='col-xl-3'>
                                    <p>Delivery in:<span className='text-green'> 46 minutes</span></p>
                                </div> */}
                            </div>
                        </div>

                        <div className='container'>
                            <div className='delivery-order-list'>
                                <div className="table-responsive">
                                    <table className="table table-borderless">
                                        <tbody>
                                            {
                                                state.cartProducts.map((product, i) => {
                                                    state.showingBusiness.push(product.product_name)

                                                    return (
                                                        <tr key={i}>
                                                            <td className="pl-0 py-2 pr-2">
                                                                <div className="d-flex">
                                                                    <img src={product.product_image} alt="company Logo" className="bd-placeholder-img mr-3 rounded" width="120" height="120" />
                                                                    <div className="w-100 align-self-center">
                                                                        <div className="d-flex">
                                                                            <h6 className="ft-size18 mb-0">
                                                                                <Link to={`/businesses/product/${product.product_id}`} className="text-dark">{product.product_name}</Link>
                                                                            </h6>

                                                                            {product.veg_category == "Veg" && <span className="vegLable ml-1" style={{ width: '15px', height: '15px' }}></span>
                                                                            }
                                                                            {product.veg_category == "Vegan" && <span className="veganLabel ml-1" style={{ width: '15px', height: '15px' }}></span>
                                                                            }
                                                                        </div>
                                                                        {/* <div>
                                                                            Available Quantity: {product.available_quantity}
                                                                        </div> */}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="align-middle py-2">
                                                                <div className="incrementDecrement-box">
                                                                    <button className="btn-decrement"
                                                                        onClick={() => {
                                                                            let qty = parseInt(product.quantity) - 1;
                                                                            if (qty > 0) {
                                                                                setState({ count: qty });
                                                                                manageCart(
                                                                                    {
                                                                                        category: product.category,
                                                                                        id: product.product_id,
                                                                                        product_name: product.product_name,
                                                                                        price: product.product_price,
                                                                                        quantity: product.available_quantity,
                                                                                        discounted_price: product.product_discounted_price,
                                                                                        instant_delivery: product.instant_delivery,
                                                                                        instant_delivery_price: product.instant_delivery_price,
                                                                                        business: product.business
                                                                                    }, product.product_image, qty)
                                                                                setTimeout(() => {
                                                                                    getTotalCheckoutPrice()
                                                                                    setTotalCheckoutPrice()
                                                                                }, 200)

                                                                            }
                                                                        }}  >
                                                                        <RemoveRoundedIcon />
                                                                    </button>
                                                                    <p className="mb-0">{product.quantity}</p>

                                                                    <button className="btn-increment"
                                                                        onClick={() => {
                                                                            let qty = parseInt(product.quantity) + parseInt(1);
                                                                            manageCart(
                                                                                {
                                                                                    category: product.category,
                                                                                    id: product.product_id,
                                                                                    product_name: product.product_name,
                                                                                    price: product.product_price,
                                                                                    quantity: product.available_quantity,
                                                                                    discounted_price: product.product_discounted_price,
                                                                                    instant_delivery: product.instant_delivery,
                                                                                    instant_delivery_price: product.instant_delivery_price,
                                                                                    business: product.business
                                                                                }, product.product_image, qty)
                                                                            setTimeout(() => {
                                                                                getTotalCheckoutPrice()
                                                                                setTotalCheckoutPrice()
                                                                            }, 200)
                                                                        }}>
                                                                        <AddRoundedIcon />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                            <td onClick={() => DeleteItem(product.product_id)} className='align-middle'>
                                                                <img src={DeleteIcon} alt="Delete Icon" style={{ width: '16px', cursor: "pointer" }} />
                                                            </td>

                                                            <td className="align-middle py-2 pr-0 text-right">
                                                                <p className="mb-0">£{(product.product_discounted_price == 0 ? parseFloat(product.product_price) : parseFloat(product.product_discounted_price) * parseInt(product.quantity)).toFixed(2)}</p>
                                                            </td>

                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <hr />
                                <div className="text-right">
                                    <div className="d-flex justify-content-end">
                                        <div>
                                            <h3 className="mb-0 d-flex align-items-center " style={{ marginLeft: "31px" }}>
                                                Sub Total :
                                                <h5 className="mb-0 ml-1">£{parseFloat(state.totalCheckoutPrice).toFixed(2)}</h5>

                                                {/* {
                                                    state.finalAmount > 0 ?
                                                        <>
                                                            {
                                                                state.couponDiscountType == 'Cash' ?
                                                                    <h5 className="mb-0 ml-1" > £{(parseFloat(state.totalCheckoutPrice) - parseFloat(state.couponDiscount)).toFixed(2)}</h5> :
                                                                    <h5 className="mb-0 ml-1"> £{(parseFloat(state.totalCheckoutPrice) - (((parseFloat(state.totalCheckoutPrice) / 100) * parseFloat(state.couponDiscount)))).toFixed(2)}</h5>
                                                            }
                                                        </> :
                                                        <>0.00</>
                                                } */}
                                            </h3>
                                            <h3 className="mb-0 d-flex align-items-center" style={{ marginLeft: "38px" }}>
                                                Discount :
                                                <h5 className="mb-0 ml-1">£{parseFloat(state.totalDiscountPrice).toFixed(2)}</h5>
                                                {/* {
                                                    state.couponDiscountType == 'Cash' ?
                                                        <h5 className="mb-0 ml-1">£{(parseFloat(state.couponDiscount) + parseFloat(state.totalProductDiscount)).toFixed(2)}</h5> :
                                                        <h5 className="mb-0 ml-1">£{(((parseFloat(state.totalCheckoutPrice) / 100) * parseFloat(state.couponDiscount)) + parseFloat(state.totalProductDiscount)).toFixed(2)}</h5>
                                                } */}

                                            </h3>
                                            <h3 className="mb-0 d-flex align-items-center">
                                                Total To Pay :
                                                £{parseFloat(state.finalAmount).toFixed(2)}
                                            </h3>
                                        </div>
                                    </div>
                                    <p>Delivery charges may apply</p>
                                    <div className="d-flex justify-content-between">
                                        <button onClick={back} className='btn btnPara-primary  my-2'><ArrowBackRoundedIcon style={{ fontSize: '20px', top: '8px' }}></ArrowBackRoundedIcon> Continue Shopping</button>
                                        <div className="d-flex justify-content-end flex-wrap">

                                            <button className="btn btn-outline-red my-2 mr-4 border-0"
                                                onClick={onClickClearCart}>
                                                <img className='' src={DeleteIcon} alt="delete icon" style={{ width: '16px' }} />
                                                Clear Cart
                                            </button>
                                            {
                                                Token.isLoggedIn() ?
                                                    <Fragment>
                                                        <button
                                                            className="btn btn-outline-primary my-2 mr-4"
                                                            onClick={() => setState({ isOpenCouponModel: true })}
                                                        >
                                                            Apply Discount Code
                                                        </button>
                                                        <Link className="btn btnPara-primary btnWithSvg my-2" to='/my-cart/checkout'>
                                                            Proceed to checkout <ArrowForwardRoundedIcon style={{ fontSize: '20px', top: '8px' }} />
                                                        </Link>
                                                    </Fragment> :
                                                    <Fragment>
                                                        <button
                                                            className="btn btn-outline-primary my-2 mr-4"
                                                            onClick={() => Swal.fire({
                                                                title: 'Please login to continue!',
                                                                imageUrl: CloseCircle,
                                                                imageAlt: 'error image',
                                                                confirmButtonColor: '#00CA84'
                                                            })}>
                                                            Apply Discount Code
                                                        </button>
                                                        <button
                                                            className="btn btnPara-primary btnWithSvg my-2"
                                                            // onClick={() => Swal.fire({
                                                            //     title: 'Please login to continue!',
                                                            //     imageUrl: CloseCircle,
                                                            //     imageAlt: 'error image',
                                                            //     confirmButtonColor: '#00CA84'
                                                            // })}
                                                            onClick={() => setShowSingInModel({ _isShowSingInModel: true })}

                                                        >
                                                            Proceed to checkout <ArrowForwardRoundedIcon style={{ fontSize: '20px', top: '8px' }} />
                                                        </button>

                                                    </Fragment>
                                            }

                                            {

                                                ShowSingInModel._isShowSingInModel ?
                                                    <SignIn
                                                        state={ShowSingInModel}
                                                        setState={setShowSingInModel}
                                                    /> :
                                                    null

                                            }
                                        </div>

                                    </div>
                                </div>
                                <hr />
                                <div className="">

                                    {
                                        state.similarProducts.map((product, i) => {
                                            if (!state.showingBusiness.includes(product.product_name) && product.out_of_stock == false) {
                                                if (show) {
                                                    show = false
                                                    return (
                                                        <h4>Add to your order!</h4>
                                                    )
                                                }

                                            }
                                        }

                                        )}
                                    <div className="row">

                                        {
                                            state.similarProducts.map((product, i) => {

                                                if (!state.showingBusiness.includes(product.product_name) && product.out_of_stock == false) {
                                                    return (

                                                        <div key={i} className="col-lg-3 col-md-4 col-sm-6 col-12">
                                                            <div className="addYourOrder-box mb-4">
                                                                <Link to={`/businesses/product/${product.id}`}>
                                                                    <div className="addYourOrder-imgBox" style={{ backgroundImage: `url(${product.product_photos.length > 0 ? product.product_photos[0] : cakeimg})` }}></div>
                                                                </Link>
                                                                <div className="addYourOrder-infoBox mt-2">
                                                                    <div className="d-flex">
                                                                        <h6 className="mb-0">{product.product_name} </h6>
                                                                        {product.veg_category == "Veg" && <span className="vegLable ml-1" style={{ width: '12px', height: '12px' }}></span>
                                                                        }
                                                                        {product.veg_category == "Vegan" && <span className="veganLabel ml-1" style={{ width: '12px', height: '12px' }}></span>
                                                                        }
                                                                    </div>
                                                                    <div className="d-flex">
                                                                        {
                                                                            parseFloat(product.price) == parseFloat(product.discounted_price) ?
                                                                                <p className="mb-0">£{parseFloat(product.price).toFixed(2)}</p> :
                                                                                <Fragment>
                                                                                    <p className="rough-price mb-0">£{parseFloat(product.price).toFixed(2)}</p>
                                                                                    <p className="mb-0">£{parseFloat(product.discounted_price).toFixed(2)}</p>
                                                                                </Fragment>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-5 col-md-6 col-sm-4 col-5 m-2">
                                                                    <div className="d-flex align-items-center justify-content-end h-100">
                                                                        <div className="d-md-flex align-items-center justify-content-end h-100 ">
                                                                            <div className="mobile-product-img">
                                                                                <img src={product.product_photos.length > 0 ? product.product_photos[0] : cakeimg} alt="" />
                                                                            </div>
                                                                            <div className="d-flex justify-content-end">
                                                                                <div className="add-product-btnbox">
                                                                                    <div className="incrementDecrement-box">
                                                                                        <button className="btn-decrement"
                                                                                            onClick={() => {
                                                                                                let qty = state.stateCards[product.id] != undefined ? parseInt(state.stateCards[product.id].quantity) - 1 : state.count - 1;
                                                                                                if (qty >= 1) {
                                                                                                    setState({ count: qty });
                                                                                                    manageCart(product, product.product_photos[0], qty)
                                                                                                    setTimeout(() => {
                                                                                                        setTotalCheckoutPrice()
                                                                                                        getTotalCheckoutPrice()
                                                                                                    }, 200)
                                                                                                }
                                                                                            }}>
                                                                                            <RemoveRoundedIcon />
                                                                                        </button>
                                                                                        <p className="mb-0">
                                                                                            {state.stateCards[product.id] != undefined ? state.stateCards[product.id].quantity : 0}
                                                                                        </p>
                                                                                        {/* <input
                                                                                            type="number"
                                                                                            value={state.stateCards[product.id] != undefined ? state.stateCards[product.id].quantity : 0}
                                                                                            onChange={(e) => {
                                                                                                const qty = e.target.value;
                                                                                                manageCart(product, product.product_photos[0], qty, state.business)
                                                                                                setTimeout(() => {
                                                                                                    setTotalCheckoutPrice()
                                                                                                    getTotalCheckoutPrice()
                                                                                                }, 200)
                                                                                            }}
                                                                                        /> */}

                                                                                        <button className="btn-increment"
                                                                                            onClick={() => {
                                                                                                let qty = state.stateCards[product.id] != undefined ? parseInt(state.stateCards[product.id].quantity) + parseInt(1) : parseInt(state.count) + parseInt(1);
                                                                                                manageCart(product, product.product_photos[0], qty, state.business)
                                                                                                setTimeout(() => {
                                                                                                    setTotalCheckoutPrice()
                                                                                                    getTotalCheckoutPrice()
                                                                                                }, 200)
                                                                                            }}>
                                                                                            <AddRoundedIcon />
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {/* <div>
                                                                <button className="btn btn-addYourOrder">Add To Cart +</button>
                                                            </div> */}
                                                            </div>
                                                        </div>


                                                    )
                                                }


                                            })
                                        }
                                        {/* <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                            <div className="addYourOrder-box mb-4">
                                                <div className="addYourOrder-imgBox" style={{ backgroundImage: "url(https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350)" }}></div>
                                                <div className="addYourOrder-infoBox mt-2">
                                                    <h6 className="mb-0">Name of Shop <img src={nonvegIcon} alt="nonveg" className="" width="16" height="16" /></h6>
                                                    <p className="">£13</p>
                                                </div>
                                                <div>
                                                    <button className="btn btn-addYourOrder">Add To Cart +</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                            <div className="addYourOrder-box mb-4">
                                                <div className="addYourOrder-imgBox" style={{ backgroundImage: "url(https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350)" }}></div>
                                                <div className="addYourOrder-infoBox mt-2">
                                                    <h6 className="mb-0">Name of Shop <img src={nonvegIcon} alt="nonveg" className="" width="16" height="16" /></h6>
                                                    <p className="">£13</p>
                                                </div>
                                                <div>
                                                    <button className="btn btn-addYourOrder">Add To Cart +</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                                            <div className="addYourOrder-box mb-4">
                                                <div className="addYourOrder-imgBox" style={{ backgroundImage: "url(https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350)" }}></div>
                                                <div className="addYourOrder-infoBox mt-2">
                                                    <h6 className="mb-0">Name of Shop <img src={nonvegIcon} alt="nonveg" className="" width="16" height="16" /></h6>
                                                    <p className="">£13</p>
                                                </div>
                                                <div>
                                                    <button className="btn btn-addYourOrder">Add To Cart +</button>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> :
                    <div className='container '>
                        <div className="text-center py-5" style={{ flex: 'auto' }}>
                            <div className="form-group ">
                                <img className='content-to-hide ' src={noShopImg} alt="No shop" />
                            </div>
                            <h2>Cart is empty</h2>
                        </div>
                    </div>
            }

            <Modal centered size="md" show={state.isOpenCouponModel} onHide={() => setState({ isOpenCouponModel: false })}>
                <Modal.Header closeButton>
                    <Modal.Title className='ft-size18'>Apply Discount Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        form={form}
                        onFinish={applyDescountCode}
                    >
                        <Form.Item
                            className="form-item"
                            name="coupon_code"
                            rules={[
                                { required: true, message: 'Please input your coupon code.' }
                            ]}
                        >
                            <Input placeholder="Coupon Code" />
                        </Form.Item>
                        <Form.Item className="form-item">
                            <Spin spinning={loading} delay={500}>
                                <button className="btn btnPara-primary btn-md btn-block" htmlType="submit">
                                    Apply
                                </button>
                            </Spin>
                        </Form.Item>
                    </Form>
                </Modal.Body>
            </Modal >


        </div>
    )
}

export default MYCART
