import React, { useEffect, useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Select } from 'antd';
import { Input } from 'antd';
import AttachmentRoundedIcon from '@material-ui/icons/AttachmentRounded';
import InsertPhotoOutlinedIcon from '@material-ui/icons/InsertPhotoOutlined';
import IconButton from '@material-ui/core/IconButton';
import io from "socket.io-client";
import config from "../../../config";
import { MessageAPI } from '../../../apis/user/MessageAPI';
import { Token } from '../../../Token';
import moment from 'moment';
import { DashboardLayout } from '../Sidebar wrapper/Layout';
import { useParams } from 'react-router-dom';
import CloseCircle from '../../../assets/images/modalPopup/close-circle.svg';
import Swal from 'sweetalert2';

const { Option } = Select;
const { TextArea } = Input;
const socket = io(config.API_BASE_URL);

export default function ShopMessage() {

    const chatParent = useRef(null);
    const params = useParams();
    const [messageThreads, setMessageThreads] = useState([]);
    const [messageList, setMessageList] = useState([]);
    const [message, setMessage] = useState('');
    const [selectedRoom, setSelectedRoom] = useState('');
    const [selectedBusiness, setSelectedBusiness] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [files, setFiles] = useState([]);
    const [preveiwSelectedFile, setPreveiwSelectedFile] = useState('');
    const [attachmentType, setAttachmentType] = useState('');

    useEffect(() => {
        getMessage();
    }, [])

    useEffect(() => {
        socket.on("receive_message", (data) => {
            setMessageList((list) => [...list, data]);
            var domNode = chatParent.current;
            if (domNode) {
                domNode.scrollTop = domNode.scrollHeight;
            }
        });
    }, [socket]);

    const getMessage = () => {
        MessageAPI.getShopMessageRoom(params.business_id)
            .then(res => {
                setMessageThreads(res.data)
                if (res.data.length > 0) {
                    selectBusiness(res.data[0])
                    var domNode = chatParent.current;
                    if (domNode) {
                        domNode.scrollTop = domNode.scrollHeight;
                    }
                }
            })
    }

    const joinRoom = (room) => {
        if (room !== '') {
            socket.emit("join_room", room);
        }
    };

    const selectBusiness = (th) => {
        setSelectedRoom(th.room_name)
        setSelectedBusiness(th.business)
        setSelectedUser(th.user)
        setMessageList(th.messages)
        joinRoom(th.room_name);
    }

    const onInputChangeFile = (event, type) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                setPreveiwSelectedFile(e.target.result)
            };
            reader.readAsDataURL(event.target.files[0]);
        }

        setFiles(event.target.files)
        setAttachmentType(type)
    };

    const onSend = () => {

        if (files.length == 0 && message == '') {
            Swal.fire({
                text: 'Please input your message!',
                imageUrl: CloseCircle,
                imageAlt: 'error image',
                confirmButtonColor: '#00CA84'
            });
            return false;
        }
        const data = new FormData();

        for (let i = 0; i < files.length; i++) {
            data.append('file', files[i]);
        }
        data.append('message', message);
        data.append('room_name', selectedRoom);
        data.append('business_id', selectedBusiness._id);
        data.append('attachment_type', attachmentType);
        data.append('user_id', selectedUser._id);

        // const data = {
        //     message: message,
        //     room_name: selectedRoom,
        //     user_id: selectedUser._id,
        //     business_id: selectedBusiness._id
        // }

        MessageAPI.saveShopMessage(data)
            .then(async (res) => {
                // socket

                const messageData = {
                    message: message,
                    file_path: res.data.filePath,
                    file_category: attachmentType,
                    room: selectedRoom,
                    sender: selectedBusiness._id,
                    receiver: Token.getUserID(),
                    created: moment().format('YYYY-MM-DD HH:mm:ss'),
                    is_ready: 0
                };

                await socket.emit("send_message", messageData);
                setMessageList((list) => [...list, messageData]);
                setMessage('')
                setFiles([])
                setPreveiwSelectedFile('')
                setAttachmentType('')
                var domNode = chatParent.current;
                if (domNode) {
                    domNode.scrollTop = domNode.scrollHeight;
                }
            })
    }

    return (
        <DashboardLayout page='categories'>
            <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-content">
                    <div className="chat-container">
                        <Row className='no-gutters'>
                            <Col lg={3} md={4} className='people-listBox flex-column'>
                                {/* --- people List ---- */}
                                <div className="people-list">
                                    <div className='chat-list mb-0'>
                                        <div className="select-messages">
                                            All Messages
                                            {/* <Select className="border-0" defaultValue="jack"
                                                style={{ width: 140 }} bordered={false}>
                                                <Option value="jack">All Messages</Option>
                                                <Option value="lucy">Lucy</Option>
                                                <Option value="Yiminghe">yiminghe</Option>
                                            </Select> */}
                                        </div>

                                        {
                                            messageThreads.map((th, i) => {
                                                return (
                                                    <div key={i} className={selectedUser._id == th.user._id ? "about-person onselectedchatthred" : "about-person"}
                                                        onClick={() => selectBusiness(th)}>
                                                        <div className="d-flex justify-content-between">
                                                            <div className='#'>
                                                                <h5 className="name">{`${th.user.first_name} ${th.user.last_name}`}</h5>
                                                                {/* <h5 className="name">{th.room_name}</h5> */}
                                                                <p className="time">
                                                                    <i className="fa fa-circle offline"></i>
                                                                    {th.created_last_message != '' ? `left ${moment(th.created_last_message).fromNow()}` : ''}
                                                                </p>
                                                            </div>
                                                            {/* <div className='align-self-center'>
                                                            <div className="message-count"><span className="chat-badge-danger">3</span></div>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                            <Col lg={9} md={8} className="chatBox flex-column">
                                {/* --- Chat Section---- */}
                                <div className="chat">
                                    {
                                        selectedUser &&
                                        <div className="chat-header clearfix">
                                            <div className="d-flex justify-content-between">
                                                <div className="#">
                                                    <div className="chat-about">
                                                        <h6 className="person-name">{`${selectedUser.first_name} ${selectedUser.last_name}`}</h6>
                                                        <p className="person-address">&nbsp;</p>
                                                        {/* <p className="person-address">24th street, Piccadilly circus</p> */}
                                                    </div>
                                                </div>
                                                {/* <div className="d-lg-block align-self-center">
                                                    <button className='btn btn-outline-primary btn-sm mr-3'>View Information</button>
                                                    <button className='btn btn-dark btn-sm'>Archive</button>
                                                </div> */}
                                            </div>
                                        </div>
                                    }

                                    <div className="chat-history">
                                        <div ref={chatParent} className="message-data py-2 my-2" style={{ overflowY: 'scroll' }}>
                                            {
                                                messageList.map((message, i) => {
                                                    if (message.sender == selectedBusiness._id) {
                                                        return (
                                                            <div key={i} className='my-message-box'>
                                                                <div className='clearfix text-right'>
                                                                    <div className='bubble'>
                                                                        {/* <h6 className="my-name">{selectedBusiness.business_name}</h6> */}
                                                                        {message.file_path != '' && <img src={message.file_path} width="120" height="120" alt='.' />}
                                                                        {
                                                                            message.message != '' &&
                                                                            <p className="message my-message float-right">
                                                                                {message.message}
                                                                            </p>
                                                                        }
                                                                        <p className="message-data-time">{moment(message.created).format('HH:mm A')}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (
                                                            <div key={i} className='person-message-box'>
                                                                <div className="clearfix">
                                                                    <div className='bubble'>
                                                                        {/* <h6 className="person-name">{`${selectedUser.first_name} ${selectedUser.last_name}`}</h6> */}
                                                                        {message.file_path != '' && <img src={message.file_path} width="120" height="120" alt='.' />}
                                                                        {
                                                                            message.message != '' &&
                                                                            <p className="message person-message clearfix">
                                                                                {message.message}
                                                                            </p>
                                                                        }
                                                                        <p className="message-data-time">{moment(message.created).format('HH:mm A')}</p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    }
                                                })
                                            }

                                        </div>

                                        <div className="chat-message shopmessages-content">
                                            <div className="mb-0 chat-msg-contain">
                                                <TextArea rows={3}
                                                    placeholder="Write A Message..."
                                                    bordered={false}
                                                    value={message}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                />

                                                {
                                                    preveiwSelectedFile != '' &&
                                                    <img src={preveiwSelectedFile} alt='preveiw-img' className='preveiw-img' />
                                                }

                                                <div className="chat-send ">
                                                    <div className="d-flex justify-content-between">
                                                        {/* <div className='d-flex'>
                                                        <button className='btn sendImage mr-2'><InsertPhotoOutlinedIcon /></button>
                                                        <button className='btn sendFile'><AttachmentRoundedIcon /></button>
                                                    </div> */}
                                                        <div className='d-flex align-items-center mt-2'>
                                                            <div className='mr-2'>
                                                                <input accept="image/*" className="d-none" id="icon-button-file" type="file"
                                                                    onChange={(e) => onInputChangeFile(e, 'image')} />
                                                                <label htmlFor="icon-button-file">
                                                                    <IconButton className='sendImage' aria-label="upload picture" component="span">
                                                                        <InsertPhotoOutlinedIcon />
                                                                    </IconButton>
                                                                </label>
                                                            </div>
                                                            <div>
                                                                <input
                                                                    accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                                                    className="d-none" id="icon-button-file" type="file"
                                                                    onChange={(e) => onInputChangeFile(e, 'doc')} />
                                                                <label htmlFor="icon-button-file">
                                                                    <IconButton className='sendFile' aria-label="upload picture" component="span">
                                                                        <AttachmentRoundedIcon />
                                                                    </IconButton>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className='align-self-center'>
                                                            <button className='btn btnPara-primary btn-sm ml-3'
                                                                onClick={onSend}
                                                            >Send</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}
