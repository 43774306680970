import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const MessageAPI = {
    createMessageRoom: async (data) => {
        const token = Token.getToken();

        data.user_id = Token.getUserID();
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/create-messsge-rooms`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    getMessageRoom: async () => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID() };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-messsge-rooms`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    getMessages: async (data) => {
        const token = Token.getToken();
        data.user_id = Token.getUserID();

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-messages`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    saveMessage: async (data) => {
        const token = Token.getToken();
        data.append('user_id', Token.getUserID());
        // data.user_id = Token.getUserID();

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/save-message`,
            headers: {
                'Authorization': `Bearer ${token}`,
                // 'Content-Type': 'application/json'
            },
            data: data
        });
    },
    getShopMessageRoom: async (business_slug) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), business_slug: business_slug };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-shop-messsge-rooms`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    saveShopMessage: async (data) => {
        const token = Token.getToken();

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/save-shop-message`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    }
}