import { Component } from 'react'
import FeatureCarousal from '../Components/home/carousel'
import FlashSale from '../Components/home/flash_sale'
import SearchExplore2 from '../Components/home/searchExplore2'
// import HotDeals from '../Components/home/HotDeals'
import { GuestAPI } from '../apis/GuestAPI'
import Footer from '../Components/home/footer'
import Gifting from '../Components/home/gifting'
import TOP_RATED_SHOPS from '../Components/home/top_rated_shops'
import TRENDING_PAGE from '../Components/home/trending_page'
import '../Components/styles/css/home/Home.css'

export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fridayFlashShops: [],
            flashCoupons: [],
            trendingShops: [],
            topRatedShops: []
        }
    }

    componentDidMount() {
        window.scrollTo({ top: 10, behavior: 'smooth' })
        GuestAPI.getBusinesses()
            .then(res => {
                this.setState({
                    fridayFlashShops: res.data.fridayFlashShops,
                    flashCoupons: res.data.flashCoupons,
                    trendingShops: res.data.trendingShops,
                    topRatedShops: res.data.topRatedShops
                })
            })
    }

    render() {
        return (
            <>
                <div className=' top-page-margin-2' style={{}}>
                    <div
                        data-aos-delay="50"
                        data-aos-duration="1000"
                        data-aos-anchor-placement="top-center"
                    >
                        <SearchExplore2 />
                    </div>
                    <div className='bg-white w-100 p-4'>
                        <div className='container'
                            // data-aos-delay="50"
                            
                            // data-aos-anchor-placement="top-center"
                        >
                            <div className='my-3'>
                                <p className='text-center'>Para reduces carbon footprint & brings the convenience of shopping online to your favorite nearby stores. Explore hundreds of popular businesses and check real-time inventory  24 hours a day. Plus, get an option to order online and pick-up today via convenient in-store pickup or same-day delivery.</p>
                            </div>
                        </div>
                        <div className='featureCarousal'
                           data-aos="zoom-in" 
                           data-aos-duration="1000" 
                        >
                            <FeatureCarousal />
                        </div>
                    </div>

                    <div className='bg-white'>
                        {this.state.fridayFlashShops.length > 0 &&
                            <FlashSale
                                fridayFlashShops={this.state.fridayFlashShops}
                                flashCoupons={this.state.flashCoupons}
                            />}
                    </div>

                    {/* <div>
                    {this.state.fridayFlashShops.length > 0 && <HotDeals hotDealsShops={this.state.fridayFlashShops} />}
                </div> */}

                    <div className='bg-white'>
                        {this.state.trendingShops.length > 0 && <TRENDING_PAGE trendingShops={this.state.trendingShops}/>}
                    </div>

                    <div>
                        <Gifting />
                    </div>

                    <div className='bg-white'>
                        {this.state.topRatedShops.length > 0 && <TOP_RATED_SHOPS topRatedShops={this.state.topRatedShops} />}
                    </div>

                    <div className='mb-5 bg-white' >
                        <Footer />
                    </div>
                </div>
            </>
        )
    }
}

