import Swal from 'sweetalert2';
import { CartAPI } from './apis/user/CartAPI';
import AlertTriangle from './assets/images/modalPopup/alert-triangle.svg';

export const Token = {
    saveToken: (user, token, role) => {
        setToken(token);
        setUserID(user.id);
        storeFullName(user.first_name + " " + user.last_name);
        storeEmail(user.email);
        storeProfilePicture(user.profile_picture);
        storeUserRole(role);
    },
    getToken: () => {
        return getToken()
    },
    getUserRole: () => {
        return getUserRole()
    },
    getUserID: () => {
        return getUserID()
    },
    getFullName: () => {
        return getFullName()
    },
    storeFullName: (name) => {
        return storeFullName(name);
    },
    getEmail: () => {
        return getEmail()
    },
    storeEmail: (email) => {
        return storeEmail(email);
    },
    getProfilePicture: () => {
        return getProfilePicture()
    },
    getUserRole: () => {
        return getUserRole()
    },
    isLoggedIn: () => {
        if (getToken()) {
            return true;
        }
        return false;
    },
    clearStorage: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user_id');
        localStorage.removeItem('full_name');
        localStorage.removeItem('email');
        localStorage.removeItem('profile_picture');
        localStorage.removeItem('user_role');

        localStorage.removeItem('cart_shop');
        localStorage.removeItem('coupon');
        localStorage.removeItem("product_carts");
        localStorage.removeItem("flash_coupon");
        // return localStorage.clear();
        return true;
    }
}

export const setToken = (token) => {
    localStorage.setItem('token', token);
}

export const getToken = () => {
    return localStorage.getItem('token');
}

const setUserID = (user_id) => {
    localStorage.setItem('user_id', user_id);
}

const getUserID = () => {
    return localStorage.getItem('user_id');
}

export const storeFullName = (name) => {
    localStorage.setItem('full_name', name);
}

export const getFullName = () => {
    return localStorage.getItem('full_name');
}

export const storeEmail = (name) => {
    localStorage.setItem('email', name);
}

export const getEmail = () => {
    return localStorage.getItem('email');
}

export const storeProfilePicture = (profile_picture) => {
    localStorage.setItem('profile_picture', profile_picture);
}

export const getProfilePicture = () => {
    return localStorage.getItem('profile_picture');
}

export const storeUserRole = (role) => {
    localStorage.setItem('user_role', role);
}

export const getUserRole = () => {
    return localStorage.getItem('user_role');
}

export const setCartShop = (shop) => {
    return localStorage.setItem('cart_shop', shop);
}

export const getCartShop = () => {
    return localStorage.getItem('cart_shop') == null ? {} : JSON.parse(localStorage.getItem('cart_shop'));
}

var countAPIGetCarts = 0
export const getCarts = () => {

    if (getToken() && countAPIGetCarts == 0) {
        CartAPI.getCarts()
            .then(res => {

                if (res.data.status) {
                    const cartData = res.data.cart_data
                    const flashCoupon = res.data.flashCoupon

                    const business = {
                        business_name: res.data.business.business_slug,
                        business_slug: res.data.business.business_slug,
                        business_id: res.data.business.id,
                        mobile_number: res.data.business.mobile_number,
                        address: res.data.business.address,
                        postal_code: res.data.business.postal_code
                    }

                    setCartShop(JSON.stringify(business));
                    localStorage.setItem('product_carts', cartData);
                    storeFlashSaleDiscount(flashCoupon)
                }
            }).catch(error => {
                console.log(error, 'get error carts data')
            })
        countAPIGetCarts = 1
    }
    return localStorage.getItem('product_carts') == null ? {} : JSON.parse(localStorage.getItem('product_carts'));
}

export const setCarts = (carts) => {
    if (getToken()) {
        const data = {
            cart_data: carts,
            user_id: getUserID(),
            business_id: getCartShop().business_id,
        }

        CartAPI.saveCarts(data)
            .then(res => {
                // console.log(res.data, 'save carts data')
            }).catch(error => {
                console.log(error, 'save error carts data')
            })
    }

    return localStorage.setItem('product_carts', JSON.stringify(carts));
}

export const manageCart = async (product, product_image, quantity, shop = null, flashCoupon = null) => {

    if (product.quantity < quantity) {
        Swal.fire({
            imageUrl: AlertTriangle,
            confirmButtonColor: '#00CA84',
            title: 'Oops...',
            text: 'Requested Quantity Not Available'
        })
        return false;
    }
    let carts = getCarts();
    var mskeJSON = `{
        "${product.id}": 
            { 
                "category": "${product.category}", 
                "product_id": "${product.id}" ,
                "product_name": "${product.product_name}" ,
                "product_image": "${product_image}" ,
                "product_price": "${product.price}" ,
                "product_discounted_price": "${product.discounted_price}" ,
                "business": "${product.business}",
                "available_quantity": "${product.quantity}",
                "quantity": "${quantity}",
                "instant_delivery": ${product.instant_delivery},
                "instant_delivery_price": "${product.instant_delivery_price}",
                "veg_category":"${product.veg_category}"
            }
        }`;
    let isAddCancel = false;
    if (Object.keys(carts).length > 0) {
        if (shop != null && Object.keys(getCartShop()).length > 0) {
            if (product.business !== getCartShop().business_id) {
                await Swal.fire({
                    text: "Product from different store already added in cart. Do you want to empty cart and add new product ?",
                    imageUrl: AlertTriangle,
                    showCancelButton: true,
                    confirmButtonColor: '#00CA84',
                    cancelButtonColor: '#FF5858',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {

                    if (result.isConfirmed) {
                        carts = {}
                        mskeJSON = `{
                            "${product.id}": 
                                { 
                                    "category": "${product.category}", 
                                    "product_id": "${product.id}" ,
                                    "product_name": "${product.product_name}" ,
                                    "product_image": "${product_image}" ,
                                    "product_price": "${product.price}" ,
                                    "product_discounted_price": "${product.discounted_price}" ,
                                    "business": "${product.business}",
                                    "quantity": "${quantity}",
                                    "available_quantity": "${product.quantity}",
                                    "instant_delivery": ${product.instant_delivery},
                                    "instant_delivery_price": "${product.instant_delivery_price}",
                                    "veg_category":"${product.veg_category}"
                                }
                            }`;
                        Object.assign(carts, JSON.parse(mskeJSON));

                        if (Object.keys(carts).length > 0) {
                            if (shop != null) {
                                let shopDetail = {
                                    business_name: shop.business_name,
                                    business_slug: shop.business_slug,
                                    business_id: shop.id,
                                    mobile_number: shop.mobile_number,
                                    address: shop.address,
                                    postal_code: shop.postal_code,
                                };

                                setCartShop(JSON.stringify(shopDetail));
                                storeFlashSaleDiscount(flashCoupon)
                            }
                        } else {
                            localStorage.removeItem('cart_shop');
                        }

                        setCarts(carts)
                        window.location.reload()
                    } else {
                        let carts = getCarts();
                        setCarts(carts)

                        isAddCancel = true
                    }
                });
            }
        }
    }


    setTimeout(() => {
        if (isAddCancel == false) {
            Object.assign(carts, JSON.parse(mskeJSON));
            if (Object.keys(carts).length > 0) {
                if (shop != null) {
                    let shopDetail = {
                        business_name: shop.business_name,
                        business_slug: shop.business_slug,
                        business_id: shop.id,
                        mobile_number: shop.mobile_number,
                        address: shop.address,
                        postal_code: shop.postal_code,
                    };

                    setCartShop(JSON.stringify(shopDetail));
                    storeFlashSaleDiscount(flashCoupon)
                }
            } else {
                localStorage.removeItem('cart_shop');
            }

        }
        // return localStorage.setItem('product_carts', JSON.stringify(carts));
        setCarts(carts)
    })
}

export const clearFromCart = () => {
    localStorage.removeItem('cart_shop');
    localStorage.removeItem('coupon');
    localStorage.removeItem("product_carts");
    localStorage.removeItem("flash_coupon");
    CartAPI.clearCarts();
    return true;
}

export const storeDiscount = (coupon) => {
    let data = { discount: coupon.discount, discount_type: coupon.discount_type, coupon_id: coupon.id, cart_total_grater_amount: coupon.cart_total_grater_amount };
    localStorage.setItem('coupon', JSON.stringify(data));
}

export const getDiscount = () => {
    return localStorage.getItem('coupon') == null ? null : JSON.parse(localStorage.getItem('coupon'));
}

export const storeFlashSaleDiscount = (flashCoupon) => {
    if (flashCoupon) {
        let data = { discount: flashCoupon.discount, discount_type: flashCoupon.discount_type, coupon_id: flashCoupon.id };
        localStorage.setItem('flash_coupon', JSON.stringify(data));
    } else {
        localStorage.removeItem("flash_coupon");
    }
}

export const getFlashSaleDiscount = () => {
    return localStorage.getItem('flash_coupon') == null ? null : JSON.parse(localStorage.getItem('flash_coupon'));
}

export const removeDiscount = () => {
    return localStorage.removeItem('coupon');
}