import React from 'react'
// import '../../Components/styles/css/Footer/mainfooter.css'
import '../../assets/css/Footer/mainfooter.css'
import sitelogo from '../../assets/images/home/siteLogo.svg'
import facebook from '../../assets/images/footer/fb.png'
import twitter from '../../assets/images/footer/twitter.png'
import instagram from '../../assets/images/footer/instagram.png'
import { Link, useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import AlertTriangle from '../../assets/images/modalPopup/alert-triangle.svg';
import { Token } from '../../Token'

export default function MainFooter() {

    let history = useHistory();

    const checkAccount = () => {

        if (Token.isLoggedIn()) {
            history.push('/seller-profile/pastOrders')
        } else {
            Swal.fire({
                title: 'Please login to continue.',
                imageUrl: AlertTriangle,
                imageAlt: 'warning image',
                confirmButtonColor: '#00CA84'
            });
        }
    }

    return (
        <div className='main-footer'>
            <div className='container-fluid'>
                <div className='row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-6 justify-content-center'>
                    <div className='col-lg-2 col-md-3 col-sm-4 col-12 footer-box'>
                        <div className='d-flex justify-content-center'>
                            <img src={sitelogo} height='52.02px' width='40.01px' alt="site logo" />
                        </div>
                    </div>
                    <div className='col footer-box'>
                        <h4 className='colHeading'>COMPANY</h4>
                        <ul className='list-unstyled mt-4'>
                            <li className='listItem'>
                                <Link className='clickable greyText' to='/who-we-are'>Who we are</Link>
                            </li>
                            <li className='listItem'>
                                <Link className='clickable greyText' to='/contact'>Contact</Link>
                            </li>
                            {/* <li className='listItem'>
                                <Link className='clickable greyText' to='/faq'>FAQs</Link>
                            </li>
                            <li className='listItem'>
                                <Link className='clickable greyText' to='/careers'>Careers</Link>
                            </li> */}
                        </ul>
                    </div>
                    <div className='col footer-box'>
                        <h4 className='colHeading'>FOR CUSTOMERS</h4>
                        <ul className='list-unstyled mt-4'>
                            <li className='listItem'>
                                <Link className='clickable greyText' to='/businesses'>Business</Link>
                            </li>
                            {/* <li className='listItem'>
                                <Link className='clickable greyText' to='/view-categories'>View categories</Link>
                            </li> */}
                            <li className='listItem' onClick={checkAccount} style={{ cursor: 'pointer' }}>
                                Account
                            </li>
                            <li className='listItem'>
                                <Link className='clickable greyText' to='/businesses'>Review businesses</Link>
                            </li>
                        </ul>
                    </div>
                    <div className='col footer-box'>
                        <h4 className='colHeading'>FOR BUSINESS</h4>
                        <ul className='list-unstyled mt-4'>
                            <li className='listItem'>
                                <Link className='clickable greyText' to='/why-sell-on-para'>Why sell on Para</Link>
                            </li>
                            <li className='listItem'>
                                <Link className='clickable greyText' to='/set-up-as-business'>How to get started</Link>
                            </li>
                            {/* <li className='listItem'>
                                <Link className='clickable greyText' to='/success-stories'>Success stories</Link>
                            </li> */}
                            <li className='listItem'>
                                <Link className='clickable greyText' to='/sell'>Create a store</Link>
                            </li>
                        </ul>
                    </div>
                    <div className='col footer-box'>
                        <h4 className='colHeading'>LEGAL</h4>
                        <ul className='list-unstyled mt-4'>
                            <li className='listItem'>
                                <Link className='clickable greyText' to='/privacy'>Privacy</Link>
                            </li>
                            <li className='listItem'>
                                <Link className='clickable greyText' to='/terms'>Terms</Link>
                            </li>
                            <li className='listItem'>
                                <Link className='clickable greyText' to='/content-integrate'>Content Integrity</Link>
                            </li>
                            {/* <li className='listItem'>
                                <Link className='clickable greyText' to='/report-fraud'>Report fraud</Link>
                            </li> */}
                        </ul>
                    </div>
                    <div className='col footer-box'>
                        <h4 className='colHeading'> SOCIAL</h4>
                        <div className='d-flex '>
                            <a href='https://www.facebook.com' target='_blank' rel="noreferrer"><img className='' src={facebook} height='32px' width='32px' alt="facebook logo" /></a>
                            <a href='https://www.twitter.com' target='_blank' rel="noreferrer"><img className='ml-3' src={twitter} height='32px' width='32px' alt="twitter logo" /></a>
                            <a href='https://www.instagram.com' target='_blank' rel="noreferrer"><img className='ml-3' src={instagram} height='32px' width='32px' alt="instagram logo" /></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
