import React, { useState, useReducer, useRef, useEffect } from 'react';
import { DashboardLayout } from '../Sidebar wrapper/Layout';
import defaultDocument from '../../../assets/images/document.png';
import { Form, Button, Spin, notification } from 'antd';
import { DocumentApi } from '../../../apis/user/DocumentApi';
import { useParams } from 'react-router-dom';

const Documents = () => {
    const params = useParams()
    const business_slug = params.business_id;
    const [loading, setLoading] = useState(false);
    // const logouploadRef = useRef()
    // const uploadfilesRef = useRef()
    const bannerImg1FilesRef = useRef()
    const bannerImg2FilesRef = useRef()
    const bannerImg3FilesRef = useRef()
    // const imageEditIdProofRef = useRef()
    // const imageEditShopLicenseRef = useRef()
    // const imageEditCertificateRef = useRef()

    const [Files1, setFiles1] = useState([])
    const [Files2, setFiles2] = useState([])
    const [Files3, setFiles3] = useState([])

    const [uploadedIdProofs, setUpdateIdProof] = useState([])
    const [uploadedShopLicenses, setUpdateShopLicense] = useState([])
    const [uploadedCertificates, setUpdateCertificate] = useState([])

    const [IdProof_new, setIdProof_new] = useState([])
    const [ShopLicense_new, setShopLicense_new] = useState([])
    const [Certificate_new, setCertificate_new] = useState("")

    const [attachmentType, setAttachmentType] = useState("")
    const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
        IdProof: "Id Proof",
        ShopLicense: "Shop License",
        Certificate: "Reg Certificate",
        IdProofShow: true,
        ShopLicenseShow: false,
        CertificateShow: false

    })

    useEffect(() => {
        getDocuments()
    }, [])

    const getDocuments = () => {
        DocumentApi.getDocument(business_slug)
            .then(res => {
                if (res.data.documents) {
                    setUpdateIdProof(res.data.documents.id_proof)
                    setUpdateCertificate(res.data.documents.registration_certificate)
                    setUpdateShopLicense(res.data.documents.shop_license)
                }
            })
    }

    const HandleSave = async () => {
        setLoading(true)
        const data = new FormData();

        data.append("business_slug", business_slug)

        // for old
        for (let index = 0; index < uploadedIdProofs.length; index++) {
            const element = uploadedIdProofs[index];
            data.append('uploadedIdProofs', element);
        }

        for (let index = 0; index < uploadedShopLicenses.length; index++) {
            const element = uploadedShopLicenses[index];
            data.append('uploadedShopLicenses', element);
        }

        for (let index = 0; index < uploadedCertificates.length; index++) {
            const element = uploadedCertificates[index];
            data.append('uploadedCertificates', element);
        }

        // for new
        for (let index = 0; index < Files1.length; index++) {
            const element = Files1[index];
            data.append('id_proof', element);
        }

        for (let index = 0; index < Files2.length; index++) {
            const element = Files2[index];
            data.append('shop_license', element);
        }

        for (let index = 0; index < Files3.length; index++) {
            const element = Files3[index];
            data.append('registration_certificate', element);
        }

        DocumentApi.DocumentUpdateAndCreate(data)
            .then(res => {
                setIdProof_new([])
                setShopLicense_new([])
                setCertificate_new([])
                setFiles1([])
                setFiles2([])
                setFiles3([])

                setLoading(false)

                getDocuments();
                notification.success({ message: res.data.message })
                window.scrollTo({
                    top: 10,
                    behavior: 'smooth',
                })
            }).catch(error => {
                console.log(error.message)
                setLoading(false)
            })
    }

    return (
        <div>
            <DashboardLayout page='documents'>
                <div className="w-100 sidebar-right-content-spacing">

                    <div className="sidebar-content py-3">
                        <h4>My Documents</h4>
                    </div>
                    <div className="sidebar-content">

                        <div className="form-group" >
                            <label className='labeltext_ays' htmlFor="businessname">Id Proof</label>
                            <input
                                multiple
                                ref={bannerImg1FilesRef} style={{ display: 'none' }}
                                type="file" accept="image/png, image/jpeg, image/jpg"
                                onChange={(e) => {
                                    if (e.target.files) {
                                        console.log(e.target.files)
                                        const files = Array.from(e.target.files);
                                        var arr = []
                                        /* Map each file to a promise that resolves to an array of image URI's */
                                        Promise.all(files.map(file => {
                                            return (new Promise((resolve, reject) => {
                                                const reader = new FileReader();
                                                reader.addEventListener('load', (ev) => {
                                                    resolve(ev.target.result);
                                                });
                                                reader.addEventListener('error', reject);
                                                reader.readAsDataURL(file);
                                            }));
                                        })).then(images => {
                                            /* Once all promises are resolved, update state with image URI array */
                                            var prev_img = [...IdProof_new]
                                            images.forEach(a_raw_img => {
                                                prev_img.push(a_raw_img)
                                            })
                                            setIdProof_new(prev_img)

                                        }, error => {
                                            console.error(error);
                                        });
                                        setFiles1(e.target.files)
                                        setAttachmentType(e.target.files.type)
                                    }
                                }} />

                            {
                                IdProof_new.length != 0 ?
                                    <div id="uploadfiles_ays1">
                                        <div className="d-flex ">
                                            <div className="col-10">
                                                {/* uploading new banner image files from local machine */}
                                                {IdProof_new ? IdProof_new.map((image, index) => {
                                                    return (

                                                        <div className='d-flex' key={`uploadfile+${index}`}>
                                                            <a href={image} target="_blank">
                                                                <img className='mt-1' src={image} style={{ height: '50px', width: '50px' }} />
                                                            </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <span className="text-danger" style={{ cursor: 'pointer' }} onClick={() => {

                                                                if (index > -1) {
                                                                    var array1 = [...IdProof_new]
                                                                    array1.splice(index, 1)
                                                                    setIdProof_new(array1)
                                                                }

                                                            }}><u>Delete</u></span></div>

                                                    )
                                                }) : null}
                                            </div>
                                            {/* 
                                            <div className="d-flex align-items-center col-2">
                                                <span id='underlinegreen1' style={{ cursor: 'pointer' }}
                                                    onClick={() => bannerImg1FilesRef.current.click()}>Upload More</span>
                                            </div> */}
                                        </div>

                                    </div> :
                                    <div id="uploadfiles_ays">
                                        <div id='upload_text_ays'> Drag or  &nbsp;<span id='underlinegreen' style={{ cursor: 'pointer' }} onClick={() => bannerImg1FilesRef.current.click()}>Upload</span>&nbsp;Files</div>
                                    </div>
                            }
                        </div>

                        <div className='row'>
                            {
                                uploadedIdProofs.map((file, index) => {
                                    return (
                                        <div className='ml-4 d-flex' key={`serverimg+${index}`}>
                                            <div className='mt-1 mr-4 text-center d-flex'>
                                                <a className='btn d-flex p-0 flex-column' href={file} target="_blank" rel="noopener noreferrer" download>
                                                    <span>
                                                        {
                                                            file.split('.').pop() == 'jpg' || file.split('.').pop() == 'jpeg' || file.split('.').pop() == 'png' ?
                                                                <img src={file} style={{ height: '150px', width: '150px' }} /> :
                                                                <img src={defaultDocument} style={{ height: '150px', width: '150px' }} />
                                                        }
                                                    </span>
                                                    <span className='btnPara-primary btn-sm w-100'>View</span>
                                                </a>

                                                <span className="text-danger"
                                                    style={{ cursor: 'pointer' }} onClick={() => {
                                                        if (index > -1) {
                                                            var idArray = [...uploadedIdProofs]
                                                            idArray.splice(index, 1)
                                                            setUpdateIdProof(idArray)
                                                        }
                                                    }}>
                                                    <span >
                                                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true"
                                                            style={{ color: 'white', background: 'red', fontSize: '1.5rem', lineHeight: '0.8', opacity: '1', fontWeight: '400' }}>
                                                            ×
                                                        </button>
                                                    </span>

                                                    {/* </span> */}

                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <br />
                        {/* Start shop license */}
                        <div className="form-group" >
                            <label className='labeltext_ays' htmlFor="businessname">Shop License</label>
                            <input
                                multiple
                                ref={bannerImg2FilesRef} style={{ display: 'none' }}
                                type="file" accept="image/png, image/jpeg, image/jpg"
                                onChange={(e) => {
                                    if (e.target.files) {
                                        /* Get files in array form */
                                        const files = Array.from(e.target.files);
                                        var arr = []
                                        /* Map each file to a promise that resolves to an array of image URI's */
                                        Promise.all(files.map(file => {
                                            return (new Promise((resolve, reject) => {
                                                const reader = new FileReader();
                                                reader.addEventListener('load', (ev) => {
                                                    resolve(ev.target.result);
                                                });
                                                reader.addEventListener('error', reject);
                                                reader.readAsDataURL(file);
                                            }));
                                        })).then(images => {
                                            /* Once all promises are resolved, update state with image URI array */
                                            var prev_img = [...ShopLicense_new]
                                            images.forEach(a_raw_img => {
                                                prev_img.push(a_raw_img)
                                            })
                                            setShopLicense_new(prev_img)

                                        }, error => {
                                            console.error(error);
                                        });
                                        setFiles2(e.target.files)

                                    }

                                }} />

                            {
                                ShopLicense_new.length != 0 ?
                                    <div id="uploadfiles_ays1">
                                        <div className="d-flex ">
                                            <div className="col-10">
                                                {/* Fetch banner images from the server */}

                                                {/* uploading new banner image files from local machine */}
                                                {ShopLicense_new ? ShopLicense_new.map((image, index) => {
                                                    return (

                                                        <div className='d-flex' key={`uploadfile+${index}`}>
                                                            <a href={image} target="_blank">
                                                                <img className='mt-1' src={image} style={{ height: '50px', width: '50px' }} />
                                                            </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <span className="text-danger" style={{ cursor: 'pointer' }} onClick={() => {

                                                                if (index > -1) {
                                                                    var array1 = [...ShopLicense_new]
                                                                    array1.splice(index, 1)
                                                                    setShopLicense_new(array1)
                                                                }

                                                            }}><u>Delete</u></span></div>

                                                    )
                                                }) : null}
                                            </div>

                                            {/* <div className="d-flex align-items-center col-2">
                                                <span id='underlinegreen1' style={{ cursor: 'pointer' }}
                                                    onClick={() => bannerImg2FilesRef.current.click()}>Upload More</span>
                                            </div> */}
                                        </div>

                                    </div> :
                                    <div id="uploadfiles_ays">
                                        <div id='upload_text_ays'> Drag or  &nbsp;<span id='underlinegreen' style={{ cursor: 'pointer' }} onClick={() => bannerImg2FilesRef.current.click()}>Upload</span>&nbsp;Files</div>
                                    </div>
                            }
                        </div>

                        <div className='row'>
                            {
                                uploadedShopLicenses.map((file, index) => {
                                    return (
                                        <div className='ml-4 d-flex' key={`serverimg+${index}`}>
                                            <div className='mt-1 mr-4 text-center d-flex'>
                                                <a className='btn d-flex p-0 flex-column' href={file} target="_blank" rel="noopener noreferrer" download>
                                                    <span>
                                                        {
                                                            file.split('.').pop() == 'jpg' || file.split('.').pop() == 'jpeg' || file.split('.').pop() == 'png' ?
                                                                <img src={file} style={{ height: '150px', width: '150px' }} /> :
                                                                <img src={defaultDocument} style={{ height: '150px', width: '150px' }} />
                                                        }
                                                    </span>
                                                    <span className='btnPara-primary btn-sm w-100'>View</span>
                                                </a>

                                                <span className="text-danger"
                                                    style={{ cursor: 'pointer' }} onClick={() => {
                                                        if (index > -1) {
                                                            var shopLicenseArray = [...uploadedShopLicenses]
                                                            shopLicenseArray.splice(index, 1)
                                                            setUpdateShopLicense(shopLicenseArray)
                                                        }
                                                    }}>
                                                    <span >
                                                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true"
                                                            style={{ color: 'white', background: 'red', fontSize: '1.5rem', lineHeight: '0.8', opacity: '1', fontWeight: '400' }}>
                                                            ×
                                                        </button>
                                                    </span>

                                                    {/* </span> */}

                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <br />
                        {/* Registration Certificate */}
                        <div className="form-group" >
                            <label className='labeltext_ays' htmlFor="businessname">Registration Certificate</label>
                            <input
                                multiple
                                ref={bannerImg3FilesRef} style={{ display: 'none' }}
                                type="file" accept="image/png, image/jpeg, image/jpg"
                                onChange={(e) => {
                                    if (e.target.files) {
                                        /* Get files in array form */
                                        const files = Array.from(e.target.files);
                                        var arr = []
                                        /* Map each file to a promise that resolves to an array of image URI's */
                                        Promise.all(files.map(file => {
                                            return (new Promise((resolve, reject) => {
                                                const reader = new FileReader();
                                                reader.addEventListener('load', (ev) => {
                                                    resolve(ev.target.result);
                                                });
                                                reader.addEventListener('error', reject);
                                                reader.readAsDataURL(file);
                                            }));
                                        }))
                                            .then(images => {
                                                /* Once all promises are resolved, update state with image URI array */
                                                var prev_img = [...Certificate_new]
                                                images.forEach(a_raw_img => {
                                                    prev_img.push(a_raw_img)
                                                })
                                                setCertificate_new(prev_img)

                                            }, error => {
                                                console.error(error);
                                            });
                                        setFiles3(e.target.files)
                                    }
                                }} />

                            {
                                Certificate_new.length != 0 ?
                                    <div id="uploadfiles_ays1">
                                        <div className="d-flex ">
                                            <div className="col-10">
                                                {/* Fetch banner images from the server */}

                                                {/* uploading new banner image files from local machine */}
                                                {Certificate_new ? Certificate_new.map((image, index) => {
                                                    return (

                                                        <div className='d-flex' key={`uploadfile+${index}`}>
                                                            <a href={image} target="_blank">
                                                                <img className='mt-1' src={image} style={{ height: '50px', width: '50px' }} />
                                                            </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <span className="text-danger" style={{ cursor: 'pointer' }} onClick={() => {

                                                                if (index > -1) {
                                                                    var array1 = [...Certificate_new]
                                                                    array1.splice(index, 1)
                                                                    setCertificate_new(array1)
                                                                }

                                                            }}><u>Delete</u></span></div>

                                                    )
                                                }) : null}
                                            </div>

                                            {/* <div className="d-flex align-items-center col-2">
                                                <span id='underlinegreen1' style={{ cursor: 'pointer' }}
                                                    onClick={() => bannerImg3FilesRef.current.click()}>Upload More</span>
                                            </div> */}
                                        </div>

                                    </div> :
                                    <div id="uploadfiles_ays">
                                        <div id='upload_text_ays'> Drag or  &nbsp;<span id='underlinegreen' style={{ cursor: 'pointer' }} onClick={() => bannerImg3FilesRef.current.click()}>Upload</span>&nbsp;Files</div>
                                    </div>
                            }
                        </div>

                        <div className='row'>
                            {
                                uploadedCertificates.map((file, index) => {
                                    return (
                                        <div className='ml-4 d-flex' key={`serverimg+${index}`}>
                                            <div className='mt-1 mr-4 text-center d-flex'>
                                                <a className='btn d-flex p-0 flex-column' href={file} target="_blank" rel="noopener noreferrer" download>
                                                    <span>
                                                        {
                                                            file.split('.').pop() == 'jpg' || file.split('.').pop() == 'jpeg' || file.split('.').pop() == 'png' ?
                                                                <img src={file} style={{ height: '150px', width: '150px' }} /> :
                                                                <img src={defaultDocument} style={{ height: '150px', width: '150px' }} />
                                                        }
                                                    </span>
                                                    <span className='btnPara-primary btn-sm w-100'>View</span>
                                                </a>

                                                <span className="text-danger"
                                                    style={{ cursor: 'pointer' }} onClick={() => {
                                                        if (index > -1) {
                                                            var certificateArray = [...uploadedCertificates]
                                                            certificateArray.splice(index, 1)
                                                            setUpdateCertificate(certificateArray)
                                                        }
                                                    }}>
                                                    <span >
                                                        <button type="button" class="close" data-dismiss="alert" aria-hidden="true"
                                                            style={{ color: 'white', background: 'red', fontSize: '1.5rem', lineHeight: '0.8', opacity: '1', fontWeight: '400' }}>
                                                            ×
                                                        </button>
                                                    </span>

                                                    {/* </span> */}

                                                </span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <div className='d-flex w-100 justify-content-end'>
                            <Spin spinning={loading} delay={500}>
                                <Button
                                    className="btn btnPara-primary btn-lg" onClick={HandleSave}>
                                    Save
                                </Button>
                            </Spin>
                        </div>
                    </div>

                </div>
            </DashboardLayout >
        </div >
    );
};

export default Documents;