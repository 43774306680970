import React, { useState, useEffect } from 'react';
import '../../../../src/assets/css/UserPanel/userPanel.css';
import { useParams, Link, useHistory } from 'react-router-dom';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { DashboardLayout } from '../Sidebar wrapper/Layout';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import deleteIcon from '../../../assets/images/svg-icons/delete_icon.svg';
import { Token } from '../../../Token';
import { Modal } from 'react-bootstrap';
import { Drawer } from 'antd';
import { CategoryAPI } from '../../../apis/user/CategoryAPI';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../../assets/images/modalPopup/close-circle.svg';
import AlertTriangle from '../../../assets/images/modalPopup/alert-triangle.svg';
import moment from 'moment';

const Categories = () => {

    const params = useParams()
    const history = useHistory()

    const [loading, setLoader] = useState(false);
    const [viewRequestShow, setViewRequestShow] = useState(false);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [categories, setCategories] = useState([]);
    const [requestedCategories, setRequestedCategories] = useState([]);
    const [searchCategories, setSearchCategories] = useState([]);
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [productsPermission, setProductsPermission] = React.useState([]);
    const [categoriesPermission, setCategoriesPermission] = React.useState([]);

    useEffect(() => {
        getCategories()
    }, []);

    const getCategories = () => {
        setTimeout(() => {
            CategoryAPI.getUserCategories(params.business_id)
                .then(res => {
                    setCategories(res.data.categories)
                    setRequestedCategories(res.data.requested_categories)
                    setIsAdmin(res.data.isAdmin)
                    const productPer = res.data.permissions?.productsPermission || [];
                    const CategoryPer = res.data.permissions?.categoriesPermission || [];
                    setProductsPermission(productPer)
                    setCategoriesPermission(CategoryPer)

                    if (res.data.requested_categories.length == 0) setViewRequestShow(false)
                }).catch({

                })
        }, 1000)
    }

    const onSelectActDct = (category_id, status) => {
        CategoryAPI.categoryStatus(category_id, status)
            .then(res => {
                getCategories()
            });
    }

    const onChangeSearch = (e) => {

        let mycat = e.target.value.toLowerCase().replace(/\b[a-z]/g, function (letra) {
            return letra.toUpperCase();
        });

        setCategoryName(mycat)
        setLoader(true);
        CategoryAPI.searchUserCategory(mycat)
            .then(res => {
                setSearchCategories(res.data.categories);
                setLoader(false);
            }).catch(error => {
                Swal.fire({
                    title: error.response.data.message,
                    imageUrl: CloseCircle,
                    imageAlt: 'error image',
                    confirmButtonColor: '#00CA84'
                });
                setLoader(false);
            })
    }

    const onClickRequestCategory = () => {
        setLoader(true);
        CategoryAPI.sendUserCategoryRequest(categoryName, params.business_id)
            .then(res => {
                getCategories();
                setDrawerVisible(false);
                Swal.fire({
                    title: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'error image',
                    confirmButtonColor: '#00CA84'
                });
                setLoader(false);
            }).catch(error => {
                setDrawerVisible(false);
                Swal.fire({
                    title: error.response.data.message,
                    imageUrl: CloseCircle,
                    imageAlt: 'error image',
                    confirmButtonColor: '#00CA84'
                });
                setLoader(false);
            })
    }

    const onClickRequestDeleteCategory = (category_id) => {
        setLoader(true);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            // icon: 'warning',
            imageUrl: AlertTriangle,
            showCancelButton: true,
            confirmButtonColor: '#00CA84',
            cancelButtonColor: '#FF5858',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                CategoryAPI.sendRequestDeleteCategory(category_id)
                    .then(res => {
                        getCategories();
                        Swal.fire({
                            title: res.data.message,
                            imageUrl: CheckmarkCircle,
                            imageAlt: 'error image',
                            confirmButtonColor: '#00CA84'
                        });
                        setLoader(false);
                    }).catch(error => {
                        Swal.fire({
                            title: error.response.data.message,
                            imageUrl: CloseCircle,
                            imageAlt: 'error image',
                            confirmButtonColor: '#00CA84'
                        });
                        setLoader(false);
                    })
            }
        })

    }

    const onClickSearchCategoryWithProduct = (category_id) => {
        CategoryAPI.addUserCategory(category_id, params.business_id)
            .then(res => {
                if (res.data.status) {
                    history.push(`/seller/business/${params.business_id}/my-shop/add-product/${category_id}`)
                }
                setLoader(false);
            }).catch(error => {
                Swal.fire({
                    title: error.response.data.message,
                    imageUrl: CloseCircle,
                    imageAlt: 'error image',
                    confirmButtonColor: '#00CA84'
                });
                setLoader(false);
            })
    }

    return (
        <DashboardLayout page='categories'>
            <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-content">
                    <div className="blackBox-heading d-flex justify-content-between ">
                        <p className="align-self-center mb-0">You are viewing {Token.getFullName()}</p>
                        <Link className="btn py-0" to={`/seller/business/${params.business_id}`}><ArrowBackIcon /> Home</Link>
                    </div>
                    <div className="categories-box">
                        <div className="row">
                            <div className="col-md-8">
                                <h4>Categories</h4>
                                <p className="text-ParaGrey">Here is a list of all some default categories you can add to your website. If you want to create custom categories, you may send a request to our Admin</p>
                            </div>
                            {
                                (categoriesPermission.includes("Create") || isAdmin) &&
                                <div className="col-md-4">
                                    <div className="text-center text-md-right">
                                        <button className="btn btnPara-primary" onClick={() => {
                                            {
                                                setDrawerVisible(true)
                                                setCategoryName('')
                                            }
                                        }}>Add Categories</button>
                                    </div>
                                </div>
                            }
                        </div>
                        <div class="table-responsive categories-table">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">CATEGORY NAME</th>
                                        {/* <th scope="col">PRIORITY</th> */}
                                        {/* <th scope="col">PRODUCTS</th> */}
                                        <th scope="col">DATE</th>
                                        <th scope="col">STATUS</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        categories.map(category => {
                                            return (
                                                <tr>
                                                    <th scope="row">{category.category.category_name}</th>
                                                    {/* <td>{category.priority}</td> */}
                                                    {/* <td>{category.category.products.length}</td> */}
                                                    <td>{moment(category.createdAt).format('DD-MM-YYYY')}</td>
                                                    <td>
                                                        <div>
                                                            <select disabled={category.category.products.length > 0} className="form-selectOption option-redText" onChange={(e) => onSelectActDct(category.id, e.target.value)}>
                                                                <option className="option-greenText" value="1" selected={category.user_category_status}>Active</option>
                                                                <option className="option-redText" value="0" selected={!category.user_category_status}>Inactive</option>
                                                            </select>
                                                        </div>
                                                    </td>
                                                    {
                                                        (productsPermission.includes("Create") || isAdmin) &&
                                                        <td className="text-center">
                                                            {category.user_category_status && <Link className="btn btn-outline-primary" to={`/seller/business/${params.business_id}/my-shop/add-product/${category.category._id}`}>Add Product</Link>}
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="category-requests-box d-flex justify-content-between flex-wrap">
                            <h6 className="align-self-center mb-0">{requestedCategories.length} category requests</h6>
                            {
                                requestedCategories.length > 0 &&
                                <button className="btn btn-outline-primary" onClick={() => setViewRequestShow(true)} >View requests</button>
                            }
                        </div>
                    </div>
                </div>
            </div>
            {/* ========== Modal Add New Category ============= */}

            <Drawer title="Find Category" placement="right" onClose={() => setDrawerVisible(false)} visible={drawerVisible}>
                <div className="find-category-drawer mb-4">
                    <h5>Request A New Category</h5>
                    <p>Enter the category you desire and our Admin will set it up for you!</p>
                    <Paper className='home_searchbottonbox d-flex justify-content-between'>
                        <div className='d-flex'>
                            <IconButton type="submit" style={{ color: '#00CA84', padding: 10 }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <InputBase className='home_inputsearchbox'
                                onChange={onChangeSearch}
                                value={categoryName}
                                placeholder="Eg. Home Bakery" />
                        </div>
                        {/* <button className='home_searchbutton'
                            disabled={categoryName == '' ? true : false}
                            onClick={onChangeSearch}>Search</button> */}
                    </Paper>
                </div>
                <h5 className="ft-size18 mb-3">Category Results</h5>
                {
                    searchCategories.map(scat => {
                        return (
                            <div className="card mb-3">
                                <div className="card-body position-relative">
                                    {/* <div className="position-absolute" style={{ top: '14px', right: '12px' }}>
                                        <button className="btn btn-sm"><img src={deleteIcon} alt="" /></button>
                                    </div> */}
                                    <h5 className="card-title ft-size18 text-capitalize">{scat.category_name}</h5>
                                    {/* <p className="card-text mb-0">Priority: <span>1</span></p>
                                    <p className="card-text">Products: <span>24</span></p>
                                    <button className="btn btnPara-primary mr-2">Add products</button>
                                <button className="btn btn-outline-primary">Edit</button> */}

                                    <button className="btn btnPara-primary mr-2" onClick={() => onClickSearchCategoryWithProduct(scat.id)}>Add products</button>
                                    {/* <Link className="btn btnPara-primary mr-2" to={`/seller/business/${params.business_id}/my-shop/add-product/${scat.id}`}>Add Product</Link> */}
                                </div>
                            </div>
                        )
                    })
                }

                {
                    categoryName &&
                    <div className="card mb-3">
                        <div className="card-body position-relative">
                            <h5 className="card-title ft-size18 text-capitalize">{categoryName}</h5>
                            <button className="btn btnPara-primary mr-2" onClick={onClickRequestCategory}>Send Request</button>
                        </div>
                    </div>
                }
            </Drawer>


            {/*====== Category requests ========*/}
            <Modal centered show={viewRequestShow} onHide={() => setViewRequestShow(false)} animation={false}>
                <Modal.Header>
                    <Modal.Title>Category requests</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='bgLightGrey rounded-8 p-3'>
                        <h6>Category Name</h6>
                        {
                            requestedCategories.map(rcar => {
                                return (
                                    <div className='d-flex justify-content-between flex-wrap'>
                                        <div className='my-2 align-self-center'><p className="mb-0">{rcar.category_name}</p></div>
                                        <div className='my-2'>
                                            <button className='btn btn-borderless-red'
                                                onClick={() => onClickRequestDeleteCategory(rcar.id)}>
                                                Remove
                                            </button>
                                            {/* <button className='btn btn-borderless-green'>
                                                Add Category
                                            </button> */}
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='mt-3'>
                        <h6>Note: </h6>
                        <p className='="ft-size15 lh-sm'>Adding a category adds the category into the database and can be used by all businesses on Para.</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-outline-dark' onClick={() => setViewRequestShow(false)}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>

        </DashboardLayout>
    )
}

export default Categories