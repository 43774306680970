import '../../../../src/assets/css/UserPanel/userPanel.css';
import React, { useState, useReducer, useEffect } from 'react'
import { DashboardLayout } from '../Sidebar wrapper/Layout'
import { Redirect } from 'react-router-dom'
import { Token } from '../../../Token';
import { CustomerAPI } from '../../../apis/user/CustomerAPI';
import { Link, useParams } from 'react-router-dom'
import { Pagination } from 'antd';

const Customers = () => {

    const params = useParams();
    const business_slug = params.business_id;
    const [products, setProducts] = React.useState([]);
    const [TotalData, setTotalData] = useState(0)

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            customers: [],
            count: 0,
        }
    );

    useEffect(() => {
        CustomerAPI.getCustomers(business_slug)
            .then(res => {
                let newArr = [];
                for (let index = 0; index < Object.keys(res.data.customers).length; index++) {
                    newArr[index] = res.data.customers[index]
                }

                setState({
                    customers: newArr,
                    count: res?.data?.count
                })
            });
    }, []);
    const HandlePagination = (PageNumber) => {
        CustomerAPI.PaginationGetCustomers(PageNumber, business_slug)
            .then(res => {
                let newArr = [];
                for (let index = 0; index < Object.keys(res.data.customers).length; index++) {
                    newArr[index] = res.data.customers[index]
                }

                setState({
                    customers: newArr,
                    count: res?.data?.count
                })
            });
    }
    console.log(state.customers, state.count)
    if (Token.isLoggedIn()) {
        return (
            <DashboardLayout page='customers'>
                <div className="w-100 sidebar-content">
                    <div className="sidebar-content">
                        <h2>CUSTOMERS</h2>
                    </div>
                    <div className="container">
                        <div className="chat-container">

                            <div className="table-responsive paraRounded-table">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">First Name</th>
                                            <th scope="col">Last Name</th>
                                            <th scope="col">Email</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            state?.customers.map((customer, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{customer.first_name}</td>
                                                        <td>{customer.last_name}</td>
                                                        <td>{customer.email}</td>

                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-5">
                        <Pagination defaultCurrent={1} onChange={HandlePagination} total={state.count} />
                    </div>
                </div>
            </DashboardLayout>
        )
    }
    else {
        return <Redirect to='/' />
    }
}

export default Customers
