import React, { useState, useReducer } from 'react'
import { Form, Input, Button, Spin, Checkbox } from 'antd'
import SignUpFlower from '../../assets/images/adminPanel/Auth/signup-flower.svg';
import Modal from 'react-bootstrap/Modal';
import { RiGoogleFill, RiFacebookFill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { Token } from '../../Token';
import { AuthAPI } from '../../apis/user/AuthAPI';
import Swal from 'sweetalert2';
import jwt_decode from "jwt-decode";
import CheckmarkCircle from '../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../assets/images/modalPopup/close-circle.svg';
import lang from '../../language/en.json';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import { GoogleLogin } from 'react-google-login';
import { GoogleLogin } from '@react-oauth/google';
import config from '../../config';
import TermsModel from '../containers/TermsModel';
import PrivacyModel from '../containers/PrivacyModel';
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import auth from '../../firebase.init';

const postcodes = require('node-postcodes.io');

function SignUp(props) {
    const [signInWithGoogle, user1, loading1, error1] = useSignInWithGoogle(auth);
    const [user2, loading2, error2] = useAuthState(auth);

    const [form] = Form.useForm();
    const [loading, setLoader] = useState(false);
    const [user, setUser] = useState({})

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            postal_code_validate_msg: '',
            _isShowTermModel: false,
            _isShowTPrivacyModel: false
        }
    );

    const onFinish = async (value) => {

        let result = await postcodes.validate(value.postal_code);

        if (result.result == true) {
            setState({ postal_code_validate_msg: '' });
        } else {
            setState({ postal_code_validate_msg: lang.postal_code_validate_msg });
            return false;
        }

        const data = {
            first_name: value.first_name,
            last_name: value.last_name,
            email: value.email,
            password: value.password,
            postal_code: value.postal_code,
            user_with: 'signup'
        };

        AuthAPI.signUp(data)
            .then(res => {
                Swal.fire({
                    title: 'Success!',
                    text: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                }).then((result) => {
                    if (result.isConfirmed) {
                        // window.location.reload()
                        window.location.href = '/set-up-as-business'
                    }
                });

            }).catch(err => {
                if (err.response) {
                    let error = [{
                        name: 'email',
                        // errors: [res.data.message],
                        errors: [err.response.data.message],
                    }];
                    form.setFields(error);
                }
            })
    }

  
    if (error1) {
        return (
          <div>
            <p>Error: {error1.message}</p>
          </div>
        );
      }
      if (loading1) {
        return <p>Loading...</p>;
      }
      if (user1) {
      const GoogleName = user2?.displayName.split(" ")
      const data = {
          first_name: GoogleName[0],
          last_name: GoogleName[1],
          email: user2?.email,
          postal_code: '',
          zipcode: '',
          condition_accepted: '',
          user_with: 'google'
      };
      AuthAPI.actionWithSocialLogin(data)
          .then(res => {
              Token.saveToken(res.data.user, res.data.token, 'enduser')
              // props.setState({ _isShowSingUpModel: true })

              Swal.fire({
                  title: 'Success!',
                  text: res.data.message,
                  imageUrl: CheckmarkCircle,
                  imageAlt: 'success image',
                  confirmButtonColor: '#00CA84'
              }).then((result) => {
                  if (result.isConfirmed) {
                      window.location.reload()
                  }
              });

          }).catch(err => {
              if (err.response) {
                  Swal.fire({
                      text: err.response.data.message,
                      imageUrl: CloseCircle,
                      imageAlt: 'error image',
                      confirmButtonColor: '#00CA84'
                  });
              }
          })
        
      }


      const responseFacebook = (response) => {
        if (response?.name) {
            const names = response.name.split(" ")

            const data = {
                first_name: names[0],
                last_name: names[1],
                email: response?.email,
                postal_code: '',
                zipcode: '',
                condition_accepted: '',
                user_with: 'google',
            };
            AuthAPI.actionWithSocialLogin(data)
            .then(res => {
                Token.saveToken(res.data.user, res.data.token, 'enduser')
                // props.setState({ _isShowSingUpModel: true })

                Swal.fire({
                    title: 'Success!',
                    text: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                });

            }).catch(err => {
                if (err.response) {
                    Swal.fire({
                        text: err.response.data.message,
                        imageUrl: CloseCircle,
                        imageAlt: 'error image',
                        confirmButtonColor: '#00CA84'
                    });
                }
            })
        }
        //call api to store response 
    }

    const checkEmail = (text) => {
        AuthAPI.checkEmail({ email: text })
            .then(res => {
                if (res.data.status) {
                    let error = [{
                        name: 'email',
                        // errors: [res.data.message],
                        errors: ['Please enter valid email address.'],
                    }];
                    form.setFields(error);
                }

            }).catch(err => {
                if (err.response) {
                    console.log('err.response', err.response)
                }
            })
    }

    return (
        <Modal className="loginFormModal" centered
            show={props.state._isShowSingUpModel}
            onHide={() => props.setState({ _isShowSingUpModel: false })}
        >

            {
                state._isShowTermModel == false && state._isShowTPrivacyModel == false ?
                    <>
                        <Modal.Header closeButton ></Modal.Header>
                        <Modal.Body className="p-0">
                            <div className="userLogin-box">
                                <div className="userLogin-headerBox">
                                    <h4 className="align-self-end mb-0 text-white fw-600">Join Para</h4>
                                    <div className="SignUpFlower-box">
                                        <img src={SignUpFlower} alt="" />
                                    </div>
                                </div>
                                <div className="userLogin-formBox">
                                    <Form
                                        form={form}
                                        onFinish={onFinish}
                                    >
                                        <div className="mb-0">
                                            <label>First Name</label>
                                            <Form.Item
                                                className="form-item mb-3"
                                                name="first_name"
                                                rules={[
                                                    { required: true, message: 'Please enter your first name.' }
                                                ]}
                                            >
                                                <Input placeholder="First Name" />
                                            </Form.Item>
                                        </div>
                                        <div className="mb-0">
                                            <label>Last Name</label>
                                            <Form.Item
                                                className="form-item mb-3"
                                                name="last_name"
                                                rules={[
                                                    { required: true, message: 'Please enter your last name.' }
                                                ]}
                                            >
                                                <Input placeholder="Last Name" />
                                            </Form.Item>
                                        </div>
                                        <div className="mb-0">
                                            <label>Your Email</label>
                                            <Form.Item
                                                className="form-item mb-3"
                                                name="email"
                                                rules={[
                                                    { required: true, message: 'Please enter your email address.' },
                                                    { type: 'email', message: 'Please enter valid email address.' }
                                                ]}
                                            >
                                                <Input placeholder="Email Address"
                                                    onChange={(e) => checkEmail(e.target.value)} />
                                            </Form.Item>
                                        </div>
                                        <div className="mb-0">
                                            <label>Enter Password</label>
                                            <Form.Item
                                                className="form-item mb-3"
                                                name="password"
                                                rules={[
                                                    { required: true, message: 'Please enter your password.' },
                                                    { min: 8, message: 'Password must be atleast 8 characters long.' },
                                                    {
                                                        pattern: new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/),
                                                        message: "Password should contain uppercase characters (A-Z), lowercase characters (a-z), digits (0-9) and special characters @!*& etc."
                                                    }
                                                ]}
                                            >
                                                <Input.Password
                                                    onPaste={(e) => {
                                                        e.preventDefault()
                                                        return false;
                                                    }} onCopy={(e) => {
                                                        e.preventDefault()
                                                        return false;
                                                    }}
                                                    placeholder="Input password" />
                                            </Form.Item>
                                        </div>

                                        <div className="mb-0">
                                            <label>Confirm  Password</label>
                                            <Form.Item
                                                className="form-item mb-3"
                                                name="confirmPassword"
                                                rules={[
                                                    { required: true, message: 'Please enter your confirm password.' },
                                                    ({ getFieldValue }) => ({
                                                        validator(rule, value) {
                                                            if (!value || getFieldValue('password') === value) {
                                                                return Promise.resolve();
                                                            }
                                                            return Promise.reject('The two password that you entered do not match!');
                                                        },
                                                    })
                                                ]}
                                            >
                                                <Input.Password
                                                    onPaste={(e) => {
                                                        e.preventDefault()
                                                        return false;
                                                    }} onCopy={(e) => {
                                                        e.preventDefault()
                                                        return false;
                                                    }}
                                                    placeholder="Confirm password" />
                                            </Form.Item>
                                        </div>

                                        <div className="mb-0">
                                            <label>Postal Code</label>
                                            <Form.Item
                                                className="form-item mb-3"
                                                name="postal_code"
                                                rules={[
                                                    { required: true, message: 'Please enter your Postal Code.' }
                                                ]}
                                            >
                                                <Input onChange={async (e) => {
                                                    let result = await postcodes.validate(e.target.value);

                                                    if (result.result == false && e.target.value != '') {
                                                        setState({ postal_code_validate_msg: lang.postal_code_validate_msg });
                                                    } else {
                                                        setState({ postal_code_validate_msg: '' });
                                                    }
                                                }}
                                                    placeholder="Postal Code" />
                                            </Form.Item>
                                            {state.postal_code_validate_msg !== "" && <p role="alert" class="ant-form-item-explain-error mb-0"> {state.postal_code_validate_msg} </p>}
                                        </div>
                                        <div className="mb-3 d-flex justify-content-start">
                                            <Form.Item
                                                className="mb-0"
                                                name="readTermsConditions"
                                                valuePropName="checked"
                                                rules={[
                                                    {
                                                        validator: (_, value) =>
                                                            value ? Promise.resolve() : Promise.reject(new Error('Please confirm terms and privacy policy.')),
                                                    }
                                                ]}
                                            >
                                                <Checkbox >
                                                    <span className='text-dark ft-size16'>I agree to the </span>
                                                    {/* <Link to='/terms' className='greenText ft-size16'>Terms of Use</Link> */}
                                                    <Link
                                                        className='greenText ft-size16'
                                                        onClick={() => setState({
                                                            _isShowTermModel: true,
                                                            _isShowTPrivacyModel: false
                                                        })}>Terms of Use</Link>
                                                    <span className='text-dark ft-size16'> and</span>
                                                    {/* <Link to='/privacy' className='greenText ft-size16'> Privacy Policy</Link> */}
                                                    <Link
                                                        className='greenText ft-size16'
                                                        onClick={() => setState({
                                                            _isShowTermModel: false,
                                                            _isShowTPrivacyModel: true
                                                        })}> Privacy Policy</Link>
                                                </Checkbox>
                                            </Form.Item>
                                        </div>
                                        <div className="mb-3">
                                            <Form.Item className="form-item mb-2">
                                                <Spin spinning={loading} delay={500}>
                                                    <button className="btn btnPara-primary btn-md btn-block" htmlType="submit">
                                                        Continue
                                                    </button>
                                                </Spin>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                    <hr />
                                    <div className='mx-2'>
                                        <div className='text-center w-100'>Or</div>
                                        <div className='mt-3 w-100'>
                                            <div>
                                                {/* <GoogleLogin
                                                    clientId={config.social.GOOGLE}
                                                    buttonText="Log in with Google"
                                                    cookiePolicy={'single_host_origin'}
                                                    onSuccess={responseGoogle}
                                                    onFailure={responseGoogleFailure}
                                                    isSignedIn={false}
                                                    render={renderProps => (
                                                        <Button onClick={renderProps.onClick} className='mb- btn-SignIn btn-block'>
                                                            <RiGoogleFill className='greenText mr-2' />
                                                            SIGN IN WITH GOOGLE
                                                        </Button>
                                                    )}
                                                /> */}
                                                <Button onClick={()=>signInWithGoogle()} className='mb- btn-SignIn btn-block'>
                                                    <RiGoogleFill className='greenText mr-2' />
                                                    SIGN IN WITH GOOGLE
                                                </Button>
                                                {/* <GoogleLogin
                                                            className='mb- btn-SignIn btn-block'
                                                            onSuccess={credentialResponse => {
                                                                const decoded = jwt_decode(credentialResponse.credential)
                                                                setUser(decoded)
                                                            }}
                                                            onError={() => {
                                                                console.log('Login Failed');
                                                            }}
                                                        />; */}
                                            </div>
                                            <div className='mt-2 w-100'>
                                                <FacebookLogin
                                                    appId={config.social.FB}
                                                    callback={responseFacebook}
                                                    fields="name,email,picture"
                                                    render={renderProps => (
                                                        <Button onClick={renderProps.onClick} className='mb-1 btn-SignIn btn-block'>
                                                            <RiFacebookFill className='greenText mr-2' />SIGN IN WITH FACEBOOK
                                                        </Button>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </> :
                    <>
                        <TermsModel
                            state={state}
                            setState={setState}
                        />

                        <PrivacyModel
                            state={state}
                            setState={setState}
                        />
                    </>
            }

        </Modal>
    )
}

export default SignUp
