import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useRef } from 'react';
import { AutoComplete, Button, Modal, Popover, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import { useEffect, useReducer, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FaCaretDown } from "react-icons/fa";
import { Link, useHistory, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalAPI } from '../apis/GlobalAPI';
import { GuestAPI } from '../apis/GuestAPI';
import { ProfileAPI } from '../apis/user/ProfileAPI';
import Sitelogo from '../assets/images/home/siteLogo.svg';
import profileIcon from '../assets/images/profile/profileIcon.svg';
import cartCircular from '../assets/images/svg-icons/cart-circular.svg';
import chatCircular from '../assets/images/svg-icons/chat-circular.svg';
import searchIcon from '../assets/images/svg-icons/loupe.png';
import { getCarts, Token, getFlashSaleDiscount } from '../Token';
import ForgotPassword from './auth/ForgotPassword';
import SignIn from './auth/SignIn';
import SignUp from './auth/SignUp';
import './styles/css/Header.css';
// const { Option } = Select;
const { Option } = AutoComplete;

function onChange(value) {
  console.log(`selected ${value}`);
}

function onSearch(val) {
  console.log('search:', val);
}

const Header = (props) => {

  let history = useHistory();
  let searchURL = useLocation().search;
  let searchData = new URLSearchParams(searchURL).get("serach");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const ref = useRef(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      search: searchData,
      _isShowSingUpModel: false,
      _isShowSingInModel: false,
      _isShowForgotModel: false,
      loggedIn: false,
      profilepic: "",
      full_name: "",
      lat: '',
      long: '',
      street_address: '',
      city: '',
      state: '',
      country: '',
      postal_code: '',
      _isShowHeaderAddress: window.location.pathname == '/businesses', // && window.location.pathname != '/',
      showhelpDrawer: false,
      totalCartPrice: 0,
      cartProducts: [],
      addresses: [],
      serachData: [],
      stickyClass: window.location.pathname == '/' ? 'headersearchpostion-none' : '',
      isShowTranspernHeader: false,

    }
  );

  useEffect(() => {
    window.onscroll = function () { myFunctionScroll() };
  }, [])

  const myFunctionScroll = () => {
    var warp = document.getElementById("warp");
    // var sticky = warp.offsetTop;
    if (window.pageYOffset >= 40) {
      setState({
        duration: 1000,
        behavior: "smooth",
        isShowTranspernHeader: true
      })

    } else {
      setState({
        isShowTranspernHeader: false
      })
    }

    if (window.pageYOffset >= 430) {
      setState({
        stickyClass: 'headersearchpostion-block',
        duration: 1000,
        behavior: "smooth"
      })

    } else {
      setState({
        stickyClass: window.location.pathname == '/' ? 'headersearchpostion-none' : 'headersearchpostion-block'
      })
    }
  }

  useEffect(async () => {
    let fetchAddresses = [];

    if (Token.isLoggedIn()) {

      await ProfileAPI.fetchProfile()
        .then(res => {
          setState({
            loggedIn: true,
            profilepic: res.data.profile_picture,
            first_name: res.data.first_name,
            last_name: res.data.last_name,
            full_name: res.data.first_name + ' ' + res.data.last_name,
            addresses: res.data.addresses
          });
          if (res.data.addresses.length > 0) {
            props.AddressesFunc(res.data.addresses[0])
            props.setUserLatLong({ lat: res.data.addresses[0].latitude, long: res.data.addresses[0].longitude })
          }
          fetchAddresses = res.data.addresses;

        }).catch(error => {
          logout();
        })
    }

    navigator.geolocation.getCurrentPosition(async function (position) {
      let lat = position.coords.latitude
      let long = position.coords.longitude;
      await GlobalAPI.getCurrentLocation(lat, long)
        .then(res => {
          let results = res.data.results;
          var street_address = "";
          var city = "";
          var state = "";
          var country = "";
          var postal_code = "";
          for (var i = 0, len = results[0].address_components.length; i < len; i++) {

            var ac = results[0].address_components[i];
            // if (results[0].types.indexOf("street_address") >= 0) street_address = results[0].formatted_address;
            if (ac.types.indexOf("locality") >= 0) city = ac.long_name;
            if (ac.types.indexOf("administrative_area_level_1") >= 0) state = ac.long_name;
            if (ac.types.indexOf("country") >= 0) country = ac.long_name;
            if (ac.types.indexOf("postal_code") >= 0) postal_code = ac.long_name;
          }

          if (fetchAddresses.length == 0) {
            setState({ addresses: [{ address: street_address != '' ? street_address : `${city}, ${state}, ${country}` }] });
            props.setUserLatLong({ lat: lat, long: long })
          }
        });
    });

  }, [])

  const logout = () => {
    setState({ loggedIn: false })
    Token.clearStorage();
    // window.location.reload();
    window.location.href = '/';
  }

  document.addEventListener("DOMContentLoaded", function () {
    var links = document.querySelectorAll(".navbar-nav li div a:not([href='#'])");
    for (var x = 0; x < links.length; x++) {
      links[x].onclick = function () {
        document.querySelector("button.navbar-toggler").click();
      }
    }
  });



  const onClickShowCartProduct = () => {
    setTimeout(() => {

      let total_product_price = 0;
      let pArr = [];
      for (var i = 0; i < Object.keys(getCarts()).length; i++) {
        var pPrice = getCarts()[Object.keys(getCarts())[i]].product_price;
        if (getCarts()[Object.keys(getCarts())[i]].product_discounted_price != 0) {
          pPrice = getCarts()[Object.keys(getCarts())[i]].product_discounted_price;
        }
        total_product_price = total_product_price + (pPrice * parseInt(getCarts()[Object.keys(getCarts())[i]].quantity));
        pArr[i] = getCarts()[Object.keys(getCarts())[i]];
      }
      const flashSaleDiscount = getFlashSaleDiscount();
      let totalCartPrice = total_product_price
      // if (flashSaleDiscount) {
      //   totalCartPrice = flashSaleDiscount.discount_type == 'Cash' ? (parseFloat(total_product_price) - parseFloat(flashSaleDiscount.discount)).toFixed(2) :
      //     (parseFloat(total_product_price) - (((parseFloat(total_product_price) / 100) * parseFloat(flashSaleDiscount.discount)))).toFixed(2)
      // }

      setState({
        totalCartPrice: totalCartPrice,
        stateCards: getCarts(),
        cartProducts: pArr
      })
    }, 200)
  }

  // const onChangeSearch = async (value) => {
  //   setState({ search: value });
  //   if (value != '') {
  //     GuestAPI.getSerachContent(value)
  //       .then(res => {
  //         setState({ serachData: res.data.businesses })
  //       }).catch(error => console.log(error.response))
  //   }


  // }
  function handleKeyPress(e) {
    const searchTexts = e.target.value
    if (e.code == 'Enter') {
      if (searchTexts) {
        localStorage.setItem('searchUrl', searchTexts.toString())
        history.push(`/businesses`);
        // ref.current.value = '';
        // document.getElementById("HeaderSearchText").Search = ""
        // document.getElementById("HeaderSearchText").nodeValue = ""
        document.getElementById("HeaderSearchText").value = "";
      }
    }
  }



  // const onSelectSearch = async (value) => {
  //   if (value != '') {
  //     localStorage.setItem('searchUrl', value.toString())
  //     history.push(`/businesses`);
  //   }
  // }

  // const [SelectedAdress,setSectedAdress] = useState([JSON.stringify(state?.addresses[0])])
  const cartContent = (
    <div className='shopping-cart'>
      {
        state.cartProducts.length ?
          <>
            <div className="shopping-cart-header">
              <span>Items</span><span className="cartbadge rounded-1">{state.cartProducts.length}</span>
              <div className="shopping-cart-total">
                <span className="lighter-text">Total:</span>
                <span className="shopping-cart-price">£{parseFloat(state.totalCartPrice).toFixed(2)}</span>
              </div>
            </div>
            <ul className="shopping-cart-items">
              {
                state.cartProducts.map(product => {

                  return (
                    <li key={Math.random()} className="clearfix">
                      <img src={product.product_image} className="img-fluid" alt="item1" style={{ height: '60px', width: "60px" }} />
                      <a href={`/businesses/product/${product.product_id}`} className="text-dark"><span className="item-name">{product.product_name}</span></a>
                      <span className="item-price">£{(product.product_discounted_price == 0 ? parseFloat(product.product_price) : parseFloat(product.product_discounted_price) * parseInt(product.quantity)).toFixed(2)}</span>
                      <span className="item-quantity">Quantity: {product.quantity}</span>
                    </li>
                  )
                })
              }
            </ul>
            <a href='/my-cart' className="button">Checkout</a>
          </> :
          <>
            <h6>Cart empty</h6>
          </>
      }
    </div>
  );

  return (
    <div>
      {/* ////bg-light//// */}
      <nav className={`navbar navbar-expand-lg navbar-light bg-light ${state.isShowTranspernHeader ? 'navbar-light bg-light' : ''} fixed-top navbar-bg`}>

        < div className="container-md">

          <div className='d-flex'>
            <Link className="navbar-brand"
              to="/"
              onClick={() => setState({ _isShowHeaderAddress: false })}>
              <img title="Para" width='40px' height='50px' src={Sitelogo} alt="site logo" />
            </Link>

            {
              state.addresses.length > 0 && state._isShowHeaderAddress ?
                <div className="navbar-address-selectBox">
                  <Form.Group controlId="exampleForm.ControlSelect1" className="mb-0">
                    <Form.Label className="mb-0 ml-3 ft-size12 fw-400">Your Para</Form.Label><br />

                    <span className='haderaddresopation'>
                      <Select dropdownMatchSelectWidth={false} onSelect={(Option) => props.AddressesFunc(JSON.parse(Option))} defaultValue={state.addresses[0].address + "," + state.addresses[0].postal_code} className="SelectAddress" bordered={false} id='selectmyopation'>
                        {
                          state.addresses.map((address, i) => {
                            if (address?.postal_code) {
                              return (<Option key={i} value={JSON.stringify(address)}>{address.address + "," + address?.postal_code}</Option>)
                            }
                            else {
                              return (<Option key={i} value={JSON.stringify(address)}>{address.address}</Option>)

                            }
                          })
                        }
                      </Select>

                    </span>
                  </Form.Group>
                </div> : null
            }
          </div>

          <div className='ml-auto'>
            <div className='d-lg-none d-block'>
              {/* Search Link mobile */}
              {/* <Button type="primary" onClick={showModal}>
                Open Modal
              </Button> */}
              {/* <Link className="" type="primary" onClick={showModal}> <img className='btn-circular-cart search-icon-modal' src={searchIcon} alt="Search logo" /></Link> */}
              <Modal title="" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} footer={false} className="searchmodel">
                {/* <AutoComplete onSearch={onChangeSearch} placeholder="Search" className="navHeader-search"> */}
                <AutoComplete allowClear={true} onKeyDown={(e) => handleKeyPress(e)} type="text" placeholder="Search" className="navHeader-search" id="HeaderSearchText">
                </AutoComplete>
                {/* {
                    state.serachData.map((search, i) => {
                      return (
                        <Option key={search.business_slug} value={search.business_slug}>
                          <lable onClick={() => onSelectSearch(search.business_slug)}>{search.business_name}</lable>
                        </Option>
                      )
                    })
                  } */}
                {/* </AutoComplete> */}
                <input type="text" placeholder="Search" className="navHeader-search"></input>

              </Modal>


              {/* chat Link mobile */}
              {
                state.loggedIn &&
                <Link to="/seller-profile/messages">
                  {/* <img className='btn-circular-cart' src={chatCircular} alt="cart logo" /> */}
                </Link>
              }

              {/* cart Link mobile */}
              <Popover className='btn-circular-cart-checkout mr-3' content={cartContent} placement="bottom" trigger="click" >
                {/* <Button onClick={onClickShowCartProduct} className='p-0 bg-transparent header-addtocard-button mblSignUp' > */}
                <img onClick={onClickShowCartProduct} className='' src={cartCircular} alt="cart logo" />
                {props.totalCart > 0 && <span class="badge badge_number">{props.totalCart}</span>}

                {/* </Button> */}
              </Popover>
            </div>
          </div>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto ">
              <div className={`${state.stickyClass}  mobileviewInput`}>
                <AutoComplete allowClear="true" onKeyDown={(e) => handleKeyPress(e)} type="text" placeholder="Search" className="navHeader-search" id="HeaderSearchText">
                </AutoComplete>
                {/* <input type="text" onKeyPress={(e) => handleKeyPress(e)} /> */}

                {/* <Search placeholder="Search" onSearch={onSearch}  style={{ width: 200 }} /> */}


              </div>
              <li className="nav-item">
                <Link className="nav-link mx-2 text-dark "
                  to='/businesses'
                  activeClassName='nav-link'
                  onClick={() => setState({ _isShowHeaderAddress: true })}>
                  <i className="pi pi-users "></i>Businesses
                </Link>
              </li>


              <li className="nav-item">
                <Link className="nav-link mx-2 text-dark "
                  to='/who-we-are'
                  onClick={() => setState({ _isShowHeaderAddress: false })}
                  activeClassName='nav-link'>
                  <i className="pi pi-sign-in"></i>About Us
                </Link>
              </li>

              {state.loggedIn ?
                <li className="nav-item">
                  {/* <Link className="nav-link mx-2 text-dark "
                    to='/'
                    onClick={() => setState({ _isShowHeaderAddress: false })} activeClassName='nav-link'>
                    <i className="pi pi-sign-in"></i>Inbox
                  </Link> */}

                </li> :
                <li className="nav-item">
                  <Link className="nav-link mx-2 text-dark "
                    to='/set-up-as-business'
                    onClick={() => setState({ _isShowHeaderAddress: false })}
                    activeClassName='nav-link'>
                    <i className="pi pi-sign-in"></i>Sell on Para
                  </Link>
                </li>}

              {state.loggedIn ? null :
                <li className="nav-item full">
                  <Link className="nav-link mx-2 text-dark "
                    to='/'
                    onClick={() => setState({ _isShowSingUpModel: true, _isShowHeaderAddress: false })}
                    activeClassName='nav-link'>
                    <i className="pi pi-sign-in"></i>Sign Up
                  </Link>
                </li>}
              {state.loggedIn ? null :
                <li className="nav-item full">
                  <Link className="nav-link mx-2 text-dark "
                    to='/' onClick={() => setState({ _isShowSingInModel: true, _isShowHeaderAddress: false })}
                    activeClassName='nav-link'>
                    <i className="pi pi-sign-in" ></i>Login
                  </Link>
                </li>
              }

              {
                state.loggedIn &&
                <div className=' mobileviewInput'>
                  <Link to="/seller-profile/messages">
                    <img className='btn-circular-cart' src={chatCircular} alt="cart logo" />
                  </Link>
                </div>
              }

              <Popover className='btn-circular-cart-checkout mr-3 txt-menu' content={cartContent} placement="bottom" trigger="click" >
                {/* <Button onClick={onClickShowCartProduct} className='p-0 bg-transparent header-addtocard-button mblSignUp' > */}
                <img onClick={onClickShowCartProduct} className='btn-circular-cart' src={cartCircular} alt="cart logo" />
                {props.totalCart > 0 && <span class="badge badge_number">{props.totalCart}</span>}

                {/* </Button> */}
              </Popover>



              {state.loggedIn ?

                <li className="nav-item dropdown full mx-2">
                  <Link className="nav-link dropdown-toggle mblSignUp" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
                    Profile
                  </Link>
                  <div className="dropdown-menu" ariaLabelledby="navbarDropdown">
                    <Link className="dropdown-item" to="/seller-profile">My Order</Link>
                    <Link className="dropdown-item" to="/seller-profile">Manage addresses</Link>
                    <Link className="dropdown-item" to="/seller-profile">Payment methods</Link>
                    <Link className="dropdown-item" to="/seller-profile">Favourite stores</Link>
                    <Link className="dropdown-item" href="#!" onClick={logout}>Help</Link>
                    <div className="dropdown-divider"></div>
                    <Link className="dropdown-item greyText d-flex justify-content-between" href="#!" onClick={logout}><span>Logout</span> <ExitToAppIcon /></Link>
                  </div>
                </li>
                : null}
              {state.loggedIn ?

                <li className="nav-item full">
                  <Link className="nav-link mx-2 text-dark " to='#'
                    activeClassName='nav-link'><span className="pi pi-sign-in" onClick={logout} >
                      Logout</span></Link>
                </li>
                : null}
            </ul>
            {
              state.loggedIn ?

                <div className="d-flex">
                  {/* <div className="dropdown img ml-4 content-to-hide" > */}
                  <div className="dropdown " >


                    <button id='logoutbutton' data-toggle="dropdown" className='d-flex'>
                      {
                        state.profilepic ?
                          <img title={state.full_name}
                            style={{ borderRadius: '50%', objectFit: 'cover', height: '45px', width: '45px' }}
                            src={state.profilepic} alt="profile logo" /> :
                          <img width='100%' height='100%' src={profileIcon} alt="profile logo" />
                      }
                      <div className="user-name-email-box align-self-center ml-2 d-flex">
                        <p className="mb-0 user-name">{state.first_name}</p>
                        <p className="mb-0 user-name ml-1">{state.last_name}</p>
                        {/* <p className="mb-0 user-email">ajit.fargade@quagnitia.com</p> */}
                        <FaCaretDown />
                      </div>

                    </button>


                    <div className="dropdown-menu">
                      <a className="dropdown-item" href='/seller-profile/pastOrders' refresh="true">My Order</a>
                      <a className="dropdown-item" href='/seller-profile/manageAddresses' refresh="true">Manage Addresses</a>
                      {/* <a className="dropdown-item" href='/seller-profile/paymentMethods'>Payment Methods</a> */}
                      <a className="dropdown-item" href='/seller-profile/myBusinesses' refresh="true">My Businesses</a>
                      <a className="dropdown-item" href='/seller-profile/favouriteStores'>Favourite Businesses</a>
                      <a className="dropdown-item" href="/seller-profile/myInfo">My Info</a>
                      {/* <Link className="dropdown-item" to='javascript:void(0)' onClick={() => setState({ showhelpDrawer: true })}>Help</Link> */}
                      <div className="dropdown-divider"></div>
                      <Link className="dropdown-item greyText d-flex justify-content-between" href="#!" onClick={logout}><span>Logout</span> <ExitToAppIcon /></Link>
                    </div>

                  </div>
                </div>
                :
                <>
                  <li className='signupbutton sign-up-btn text-center content-to-hide mblSignUp'>
                    <button className="btn btn-signup" onClick={() => setState({ _isShowSingUpModel: true })}>Sign Up</button>
                  </li>
                  <li className='loginbutton sign-in-btn text-center  content-to-hide mblLogin' >
                    <button className="btn btn-login" onClick={() => setState({ _isShowSingInModel: true })}>Login</button>
                  </li>
                </>
            }
          </div>

        </div >
        {/* Container End*/}
      </nav >
      <div id="warp"></div>


      {/* start auth */}
      {
        state._isShowSingInModel ?
          <SignIn
            state={state}
            setState={setState}
          /> :
          null
      }

      {
        state._isShowSingUpModel &&
        <SignUp
          state={state}
          setState={setState}
        />
      }

      {
        state._isShowForgotModel &&
        <ForgotPassword
          state={state}
          setState={setState}
        />
      }

      {/* End auth */}

      {/* {
        state.showhelpDrawer &&
        <HelpDrawer showhelpDrawer={state.showhelpDrawer} setHeaderState={setState} />
      } */}

    </div >
  );
}

export default Header