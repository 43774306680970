import React, { useState, useRef, useEffect, useReducer } from 'react'
import '../../../assets/css/BusinessSteps/businessSteps.css';
import { useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Form, Input, InputNumber, Spin, Select } from 'antd';
import lang from '../../../language/en.json';
import { BusinessAPI } from '../../../apis/user/BusinessAPI';
import PlacesAutocomplete from 'react-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../assets/images/modalPopup/checkmark-circle.svg';
import AlertTriangle from '../../../assets/images/modalPopup/alert-triangle.svg';
import { DashboardLayout } from '../Sidebar wrapper/Layout';
import axios from 'axios';
import config from '../../../config';

const postcodes = require('node-postcodes.io');

export default function SHOP_SETTINGS(props) {
    const params = useParams()
    const editorRef = useRef(null);
    const [loading, setLoader] = useState(false);
    const [form] = Form.useForm();
    // const [Focus,setFocus]=useState(false)
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            business_id: '',
            business_description: '',
            address: '',
            address_error_msg: '',
            latitude: "",
            longitude: "",
            postal_code: "",
            business_category: "",
            postal_error_msg: "",

            Mondaystatus: ['open', '9am', '9am'],
            Tuesdaystatus: ['open', '9am', '9pm'],
            Wednesdaystatus: ['open', '9am', '9pm'],
            Thursdaystatus: ['open', '9am', '9pm'],
            Fridaystatus: ['open', '9am', '9pm'],
            Saturdaystatus: ['open', '9am', '9pm'],
            Sundaystatus: ['open', '9am', '9pm'],
            Monday: 'open',
            Tuesday: 'open',
            Wednesday: 'open',
            Thursday: 'open',
            Friday: 'open',
            Saturday: 'open',
            Sunday: 'open',
            bannerImg: [],
            bannerImg_new: [],
            logo: '',
            upfiles: [],
            buffer: 0,
            progressVisible: "none",
            Focus: false,
        }
    );

    const logouploadRef = useRef()
    const uploadfilesRef = useRef()
    const bannerImgFilesRef = useRef()

    const [upfiles, setUpfiles] = useState([])
    const [upfiles_new, setUpfiles_new] = useState([])

    const [bannerImg, setBannerImg] = useState([])
    const [bannerImg_new, setBannerImg_new] = useState([])

    useEffect(async () => {
        const business_slug = params.business_id;
        await BusinessAPI.fetchBusiness(business_slug)
            .then((response) => {

                // if (response.data.message === "Business not exist.") {
                //     props.handlebuffer(95)
                //     props.handleProgress("none")
                //     return
                // } else {

                // props.handlebuffer(100)
                // props.handleProgress("none")

                setState({
                    business_id: response.data.business.id,
                    business_description: response.data.business.business_description,
                    address: response.data.business.address,
                    latitude: response.data.business.latitude,
                    longitude: response.data.business.longitude,
                    postal_code: response.data.business.postal_code,

                    logo: response.data.business.company_logo,
                    Mondaystatus: response.data.business.monday,
                    Tuesdaystatus: response.data.business.tuesday,
                    Wednesdaystatus: response.data.business.wednesday,
                    Thursdaystatus: response.data.business.thursday,
                    Fridaystatus: response.data.business.friday,
                    Saturdaystatus: response.data.business.saturday,
                    Sundaystatus: response.data.business.sunday,
                    Monday: response.data.business.monday[0],
                    Tuesday: response.data.business.tuesday[0],
                    Wednesday: response.data.business.wednesday[0],
                    Thursday: response.data.business.thursday[0],
                    Friday: response.data.business.friday[0],
                    Saturday: response.data.business.saturday[0],
                    Sunday: response.data.business.sunday[0],
                    business_category: response.data.business.category
                });

                form.setFieldsValue({
                    business_name: response.data.business.business_name,
                    mobile_number: response.data.business.mobile_number,
                    tag_line: response.data.business.tag_line,
                    address: response.data.business.address,
                    postal_code: response.data.business.postal_code,
                    short_description: response.data.business.short_description,
                    facebook_profile_link: response.data.business.facebook_profile_link,
                    instagram_profile_link: response.data.business.instagram_profile_link,
                    twitter_profile_link: response.data.business.twitter_profile_link,
                    google_profile_link: response.data.business.google_profile_link,
                    cuisine: response.data.business.cuisine,
                    average_meal_for_2: response.data.business.average_meal_for_2,
                    minimun_order_value: response.data.business.minimun_order_value,
                    maximun_order_value: response.data.business.maximun_order_value,
                    open_hours: response.data.business.open_hours,
                    food_type: response.data.business.food_type,
                    delivery: response.data.business.delivery,
                    delivery_everywhere: response.data.business.delivery_everywhere,
                });

                setBannerImg(response.data.business.banner_image)
                setUpfiles(response.data.business.other_photos)
                // }
            }).catch((error) => {
                console.log(error);
            });
    }, [])

    const handleSelectForMap = async (address, placeId) => {
        setState({ address: address })
        if (address) {
            setState({ address_error_msg: '' });
        } else {
            setState({ address_error_msg: lang.address_require_error_msg });
        }

        geocodeByAddress(address)
            .then(results =>
                getLatLng(results[0])
            ).then(async (latLng) => {
                setState({ latitude: latLng.lat, longitude: latLng.lng });
                let result = await postcodes.geo(parseFloat(latLng.lat), parseFloat(latLng.lng))
                if (result.result != null) {
                    setState({ postal_code: result.result[0].postcode })
                    setState({ postal_error_msg: '' });

                }
            }).catch(error => console.error('Error', error));
    };

    const onFinish = async (value) => {

        if (state.address == '') {
            setState({ address_error_msg: lang.address_require_error_msg })
            return false;
        } else if (state.postal_code == '') {
            setState({ postal_error_msg: lang.postal_code_require_error_msg })
            return false;
        }

        let result = await postcodes.validate(state.postal_code);
        if (result.result) {
            setState({ postal_error_msg: '' });
        } else {
            setState({ postal_error_msg: lang.postal_code_validate_msg });
            return false;
        }

        value.address = state.address;
        value.latitude = state.latitude;
        value.longitude = state.longitude;
        value.postal_code = state.postal_code;

        value.business_description = state.business_description;

        // set images
        value.company_logo = state.logo;
        value.banner_image = state.bannerImg;
        value.banner_image_new = bannerImg_new;
        value.other_photos = upfiles;
        value.other_photos_new = upfiles_new;

        // set days
        value.monday = state.Mondaystatus;
        value.tuesday = state.Tuesdaystatus;
        value.wednesday = state.Wednesdaystatus;
        value.thursday = state.Thursdaystatus;
        value.friday = state.Fridaystatus;
        value.saturday = state.Saturdaystatus;
        value.sunday = state.Sundaystatus;

        setLoader(true)

        BusinessAPI.updateBusiness(value, state.business_id)
            .then(res => {
                setLoader(false)
                Swal.fire({
                    title: 'Success!',
                    text: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setTimeout(() => {
                            window.scrollTo({ top: 10, behavior: 'smooth' })
                        }, 500)
                    }
                })

            }).catch(function (error) {
                setLoader(false);
                Swal.fire({
                    title: error.response.data.message,
                    imageUrl: AlertTriangle,
                    imageAlt: 'warning image',
                    confirmButtonColor: '#00CA84'
                });
            });
    }

    const changingDayStatus = (day, status) => {
        var timings = []

        switch (day) {
            case 'Monday':
                timings = [...state.Mondaystatus]
                timings[0] = status.target.value

                return (
                    setState({
                        Monday: status.target.value,
                        Mondaystatus: timings
                    })

                )
            case 'Tuesday':
                timings = [...state.Tuesdaystatus]
                timings[0] = status.target.value

                return (
                    setState({
                        Tuesday: status.target.value,
                        Tuesdaystatus: timings
                    })

                )
            case 'Wednesday':
                timings = [...state.Wednesdaystatus]
                timings[0] = status.target.value

                return (
                    setState({
                        Wednesday: status.target.value,
                        Wednesdaystatus: timings
                    })

                )
            case 'Thursday':
                timings = [...state.Thursdaystatus]
                timings[0] = status.target.value

                return (
                    setState({
                        Thursday: status.target.value,
                        Thursdaystatus: timings
                    })

                )
            case 'Friday':
                timings = [...state.Fridaystatus]
                timings[0] = status.target.value

                return (
                    setState({
                        Friday: status.target.value,
                        Fridaystatus: timings
                    })

                )
            case 'Saturday':
                timings = [...state.Saturdaystatus]

                timings[0] = status.target.value
                return (
                    setState({
                        Saturday: status.target.value,
                        Saturdaystatus: timings
                    })

                )
            case 'Sunday':
                timings = [...state.Sundaystatus]

                timings[0] = status.target.value
                return (
                    setState({
                        Sunday: status.target.value,
                        Sundaystatus: timings
                    })
                )

            default: break;
        }
    }

    const daystarttime = (day, startTime) => {
        var timings = []

        switch (day) {
            case 'Monday':
                timings = [...state.Mondaystatus]
                timings[1] = startTime.target.value

                return setState({ Mondaystatus: timings })
            case 'Tuesday':
                timings = [...state.Tuesdaystatus]
                timings[1] = startTime.target.value
                return setState({ Tuesdaystatus: timings })
            case 'Wednesday':
                timings = [...state.Wednesdaystatus]
                timings[1] = startTime.target.value
                return setState({ Wednesdaystatus: timings })
            case 'Thursday':
                timings = [...state.Thursdaystatus]
                timings[1] = startTime.target.value
                return setState({ Thursdaystatus: timings })
            case 'Friday':
                timings = [...state.Fridaystatus]
                timings[1] = startTime.target.value
                return setState({ Fridaystatus: timings })
            case 'Saturday':
                timings = [...state.Saturdaystatus]
                timings[1] = startTime.target.value
                return setState({ Saturdaystatus: timings })
            case 'Sunday':
                timings = [...state.Sundaystatus]
                timings[1] = startTime.target.value
                return setState({ Sundaystatus: timings })

            default: break;
        }
    }

    const dayendtime = (day, endtime) => {
        var timings = []
        switch (day) {
            case "Monday":
                timings = [...state.Mondaystatus]
                timings[2] = endtime.target.value
                return setState({ Mondaystatus: timings })
            case "Tuesday":
                timings = [...state.Tuesdaystatus]
                timings[2] = endtime.target.value
                return setState({ Tuesdaystatus: timings })
            case 'Wednesday':
                timings = [...state.Wednesdaystatus]
                timings[2] = endtime.target.value
                return setState({ Wednesdaystatus: timings })
            case 'Thursday':
                timings = [...state.Thursdaystatus]
                timings[2] = endtime.target.value
                return setState({ Thursdaystatus: timings })
            case 'Friday':
                timings = [...state.Fridaystatus]
                timings[2] = endtime.target.value
                return setState({ Fridaystatus: timings })
            case 'Saturday':
                timings = [...state.Saturdaystatus]
                timings[2] = endtime.target.value
                return setState({ Saturdaystatus: timings })
            case 'Sunday':
                timings = [...state.Sundaystatus]
                timings[2] = endtime.target.value
                return setState({ Sundaystatus: timings })

            default: break;
        }
    }

    const time = []
    const fromtime = () => {
        for (let i = 1; i <= 12; i++) {
            time.push(`${i}am`)
        }
        for (let i = 1; i <= 12; i++) {
            time.push(`${i}pm`)
        }

    }

    const imageHandler = (e) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setState({ logo: reader.result })
            }
        }
        reader.readAsDataURL(e.target.files[0])
    }

    const { Option } = Select;
    function handleChange(value) {
        console.log(`selected ${value}`);
    }

    return (
        <DashboardLayout page='dashboard'>
            <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-content">
                    <div className='container-fluid p-0'>
                        {fromtime()}

                        <div className=''>
                            <p className='greenText mb-0'>Step 5</p>
                            <h4 className=''> About your business</h4>
                            <p className=''> Fill up the details to entitle most of the features </p>
                        </div>

                        <Form
                            layout="vertical"
                            form={form}
                            onFinish={onFinish}
                        >
                            <Row>
                                <Col xs={12}>
                                    <div className="form-group">
                                        <label>Business logo <super className="redText"><b>*</b></super></label>
                                        <div className='d-flex  align-items-center '>
                                            <div>
                                                <img className='business-logoImage' src={state.logo} alt="upload" />
                                                <input style={{ display: 'none' }} type='file' ref={logouploadRef}
                                                    accept="image/png, image/jpeg, image/jpg" onChange={imageHandler} />
                                            </div>

                                            <button className='btn btn-uploadLogo btn-md align-self-center'
                                                onClick={() => logouploadRef.current.click()}
                                                type='button'
                                            >
                                                Upload Logo
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <div className="form-group">
                                        <label>Business Name <super className="redText"><b>*</b></super></label>
                                        <Form.Item
                                            name="business_name"
                                            rules={[{ required: true, message: lang.business_name_require_error_msg }]}
                                        >
                                            <Input className="form-control text-capitalize" placeholder="Business Name" />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col xs={6} md={6}>
                                    <div className="form-group">
                                        <label>Mobile Number <super className="redText"><b>*</b></super></label>
                                        <Form.Item
                                            name="mobile_number"
                                            rules={[{ required: true, message: lang.mobile_number_require_error_msg }]}
                                        >

                                            <Input type='tel' className="form-control" placeholder="Mobile Number" />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col xs={6} md={6}>
                                    <div className="form-group">
                                        <label>Tag Line <super className="redText"><b>*</b></super></label>
                                        <Form.Item
                                            name="tag_line"
                                            rules={[{ required: true, message: lang.tag_line_require_error_msg }]}
                                        >
                                            <Input className="form-control" placeholder="Tag Line" />
                                        </Form.Item>
                                    </div>
                                </Col>
                                <Col xs={6} md={6}>
                                    <div className="form-group">
                                        <label>Address <super className="redText"><b>*</b></super></label>
                                        <PlacesAutocomplete
                                            value={state.address}
                                            onChange={(address) => setState({ address: address })}
                                            onSelect={handleSelectForMap}
                                            searchOptions={{
                                                componentRestrictions: { country: 'gb' },
                                                // types: ['city']
                                            }}
                                        >
                                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                <div>
                                                    <input
                                                        className="form-control"
                                                        {...getInputProps({
                                                            placeholder: 'Prefilled as per location detected',
                                                            className: 'location-search-input form-control',
                                                        })}
                                                        value={state?.address}

                                                    />

                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && <div>Loading...</div>}
                                                        {suggestions.map(suggestion => {
                                                            const className = suggestion.active
                                                                ? 'suggestion-item--active'
                                                                : 'suggestion-item';
                                                            // inline style for demonstration purpose
                                                            const style = suggestion.active
                                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                            return (
                                                                <div
                                                                    {...getSuggestionItemProps(suggestion, {
                                                                        className,
                                                                        style,
                                                                    })}
                                                                >
                                                                    <span>
                                                                        {
                                                                            state.postal_code ? suggestion.description.includes(state.postal_code) ? suggestion.description : "" : suggestion.description
                                                                        }

                                                                    </span>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                        {state.address_error_msg !== "" && <div className="text-danger"> {state.address_error_msg} </div>}
                                        {/* <Form.Item
                                name="address"
                                rules={[{ required: true, message: lang.address_require_error_msg }]}
                            >
                                <Input className="form-control" placeholder="Address" />
                            </Form.Item> */}
                                    </div>
                                </Col>
                                <Col xs={6} md={6}>

                                    <div className={`${state.postal_error_msg !== '' && "ant-form-item-has-error"} `}>
                                        <label>Postal Code <super className="redText"><b>*</b></super></label>
                                        <input type="text" class={`form-control ant-input ${state.postal_error_msg !== '' && 'borderPostcode'}`}
                                            placeholder="Postal Code" value={state.postal_code}
                                            onChange={async (e) => {
                                                setState({ postal_code: e.target.value })
                                                let result = await postcodes.validate(e.target.value)
                                                if (result.result) {
                                                    setState({ postal_error_msg: '' });
                                                    axios.get(`https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${e.target.value}|country:GB&key=${config.GOOGLE_MAPS_API_KEY}`)
                                                        .then(function (response) {
                                                            setState({ address: response?.data?.results[0]?.formatted_address })
                                                        })
                                                } else {
                                                    setState({ postal_error_msg: lang.postal_code_validate_msg });
                                                }
                                            }} />
                                        {state.postal_error_msg !== "" && <div className="ant-form-item-explain-error" style={{ fontSize: "14px" }}> {state.postal_error_msg} </div>}

                                        {/* <Form.Item
                                name="postal_code"
                                rules={[{ required: true, message: lang.postal_code_require_error_msg }]}
                            >
                                <Input className="form-control" placeholder="Postal Code" />
                            </Form.Item> */}
                                    </div>
                                </Col>
                                <Col xs={12} md={12}>
                                    <div className="form-group">
                                        <label>Short Description <super className="redText"><b>*</b></super></label>
                                        <Form.Item
                                            name="short_description"
                                            rules={[{ required: true, message: lang.short_description_require_error_msg }]}
                                        >
                                            <Input.TextArea className="form-control" placeholder="Short Description" />
                                        </Form.Item>
                                    </div>
                                </Col>
                            </Row>

                            <div className='boxes_ays my-4'>
                                <h5>Connect your Social Media</h5>
                                <p className=''>Information about your business so we can help you maximise your conversions.</p>
                                <Row>
                                    <Col xs={6} md={6}>
                                        <div className="form-group">
                                            <label>Facebook profile link</label>
                                            <Form.Item
                                                name="facebook_profile_link"
                                                rules={[{ type: "url", message: lang.facebook_profile_link_valide_error_msg }]}
                                            >
                                                <Input className="form-control" placeholder="Enter your Facebook Profile link" />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <div className="form-group">
                                            <label>Instagram profile link</label>
                                            <Form.Item
                                                name="instagram_profile_link"
                                                rules={[{ type: "url", message: lang.instagram_profile_link_valide_error_msg }]}
                                            >
                                                <Input className="form-control" placeholder="Enter your Instagram Profile link" />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <div className="form-group">
                                            <label>Twitter profile link</label>
                                            <Form.Item
                                                name="twitter_profile_link"
                                                rules={[{ type: "url", message: lang.twitter_profile_link_valide_error_msg }]}
                                            >
                                                <Input className="form-control" placeholder="Enter your Twitter Profile link" />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={6}>
                                        <div className="form-group">
                                            <label>Google profile link</label>
                                            <Form.Item
                                                name="google_profile_link"
                                                rules={[{ type: "url", message: lang.google_profile_link_valide_error_msg }]}
                                            >
                                                <Input className="form-control" placeholder="Enter your Google Profile link" />
                                            </Form.Item>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            {
                                state.business_category == 'Food' &&
                                <div className=' boxes_ays my-4'>
                                    <h5>Some specific questions for restaurants</h5>
                                    <p className=''>Information about your business so we can help you maximise your conversions.</p>
                                    <Row>
                                        <Col xs={6} md={6}></Col>
                                        <Col xs={6} md={6}></Col>
                                        <Col xs={6} md={6}></Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6} md={6}>
                                            <div className="form-group">
                                                <label>Cuisine <super className="redText"><b>*</b></super></label>
                                                <Form.Item
                                                    name="cuisine"
                                                    rules={[{ required: true, message: lang.cuisine_require_error_msg }]}
                                                >
                                                    <Input className="form-control" placeholder="Cuisine" />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <div className="form-group">
                                                <label>Average meal for 2 <super className="redText"><b>*</b></super></label>
                                                <Form.Item
                                                    name="average_meal_for_2"
                                                    rules={[{ required: true, message: lang.average_meal_for_2_require_error_msg }]}
                                                >
                                                    <Input className="form-control" placeholder="Average meal for 2" />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <div className="form-group">
                                                <label>Minimum Order Value <super className="redText"><b>*</b></super></label>
                                                <Form.Item
                                                    name="minimun_order_value"
                                                    rules={[{ required: true, message: lang.minimum_order_value_require_error_msg }]}
                                                >
                                                    <Input className="form-control" placeholder="$10" />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={6}>
                                            <div className="form-group">
                                                <label>Maximum Order Value <super className="redText"><b>*</b></super></label>
                                                <Form.Item
                                                    name="maximun_order_value"
                                                    rules={[{ required: true, message: lang.maximum_order_value_require_error_msg }]}
                                                >
                                                    <Input className="form-control" placeholder="$20" />
                                                </Form.Item>
                                            </div>
                                        </Col>

                                        {/* <Col xs={6} md={6}>
                                            <div className="form-group">
                                                <label>Open Hours <super className="redText"><b>*</b></super></label>
                                                {/* add Timing filed */}
                                                {/* <Form.Item
                                                    name="open_hours"
                                                    rules={[{ required: true, message: lang.open_hours_timing_require_error_msg }]}
                                                >
                                                    <Input className="form-control" placeholder="9AM - 9PM" />
                                                </Form.Item> */}
                                            {/* </div> */}
                                        {/* </Col> */}
                                         
                                        <Col xs={6} md={6}>
                                            <div className="form-group">
                                                <label>Food Type<super className="redText"><b>*</b></super></label>
                                                <Form.Item
                                                    name="food_type"
                                                    rules={[{ required: true, message: lang.food_type_require_error_msg }]}
                                                >
                                                    <Input className="form-control" placeholder="e.g. Asian food, fusion, healthy" />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                        {/* <Col xs={6} md={6}>
                                            <div className="form-group">
                                                <label>Delivery <super className="redText"><b>*</b></super></label>
                                                <Form.Item
                                                    name="delivery"
                                                    rules={[{ required: true, message: lang.delivery_type_doorstep_pickup_require_error_msg }]}
                                                >
                                                    <Select defaultValue="Doorstep Delivery" onChange={handleChange}>
                                                        <Option value="Doorstep Delivery">Doorstep Delivery</Option>
                                                        <Option value="Pick up Only">Pick up Only</Option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </Col> */}
                                        <Col xs={6} md={6}>
                                            <div className="form-group">
                                                <label>Delivery Everywhere<super className="redText"><b>*</b></super></label>
                                                <Form.Item
                                                    name="delivery_everywhere"
                                                    rules={[{ required: true, message: lang.delivery_everywhere_require_error_msg }]}
                                                >
                                                    <Input className="form-control" placeholder="e.g. Everywhere" />
                                                </Form.Item>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            }

                            <input ref={uploadfilesRef} style={{ display: 'none' }} type="file" accept="image/png, image/jpeg, image/jpg" multiple onChange={(e) => {

                                if (e.target.files) {
                                    /* Get files in array form */
                                    const files = Array.from(e.target.files);
                                    var arr = []

                                    /* Map each file to a promise that resolves to an array of image URI's */
                                    Promise.all(files.map(file => {

                                        return (new Promise((resolve, reject) => {
                                            const reader = new FileReader();
                                            reader.addEventListener('load', (ev) => {
                                                resolve(ev.target.result);
                                            });
                                            reader.addEventListener('error', reject);
                                            reader.readAsDataURL(file);
                                        }));
                                    }))
                                        .then(images => {
                                            /* Once all promises are resolved, update state with image URI array */
                                            var prev_img = [...upfiles_new]
                                            images.forEach(a_raw_img => {
                                                prev_img.push(a_raw_img)
                                            })
                                            setUpfiles_new(prev_img)
                                        }, error => {
                                            console.error(error);
                                        });
                                }

                            }} />

                            <div className='boxes_ays'>
                                <div className='row'>
                                    <div className='col-xl-12 col-md-12 col-xs-12  mb-4'>
                                        <label className='labeltext_ays' htmlFor="describeyourbusiness">Describe your business <super className="text-danger"><b>*</b></super></label>
                                        <Editor className="form-control"
                                            apiKey='68r82ciq6emwun43ptzljmjdzym1atmw19a9jzwm2y163ctj'
                                            value={state.business_description}
                                            onEditorChange={(newText) => setState({ business_description: newText })}
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            id="descibeBusiness"
                                            init={{
                                                selector: 'textarea',
                                                height: 500,
                                                menubar: false,
                                                menubar: 'view',
                                                plugins: 'pageembed',
                                                toolbar: 'undo redo | formatselect | ' +
                                                    'bold italic underline| alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | pageembed',

                                                content_style: 'body { font-family:Gotham; font-size:16px }'
                                            }}
                                        />
                                        {/* <textarea autoComplete='off' type="text" className="form-control " value={state.desc} onChange={(e) => setState({ desc: e.target.value })} id="descibeBusiness" placeholder="Descrilbe your business here in details...." /> */}
                                    </div>
                                </div>
                            </div>

                            {/* new multiple banner images for carousel */}
                            <div className="form-group" >
                                <label className='labeltext_ays' htmlFor="businessname">Banner Images</label>
                                <input ref={bannerImgFilesRef} style={{ display: 'none' }} type="file" accept="image/png, image/jpeg, image/jpg" multiple onChange={(e) => {

                                    if (e.target.files) {
                                        /* Get files in array form */
                                        const files = Array.from(e.target.files);
                                        var arr = []
                                        /* Map each file to a promise that resolves to an array of image URI's */
                                        Promise.all(files.map(file => {
                                            return (new Promise((resolve, reject) => {
                                                const reader = new FileReader();
                                                reader.addEventListener('load', (ev) => {
                                                    resolve(ev.target.result);
                                                });
                                                reader.addEventListener('error', reject);
                                                reader.readAsDataURL(file);
                                            }));
                                        }))
                                            .then(images => {
                                                /* Once all promises are resolved, update state with image URI array */
                                                var prev_img = [...bannerImg_new]
                                                images.forEach(a_raw_img => {
                                                    prev_img.push(a_raw_img)
                                                })
                                                setBannerImg_new(prev_img)

                                            }, error => {
                                                console.error(error);
                                            });
                                    }

                                }} />
                                {bannerImg.length != 0 || bannerImg_new.length != 0 ?
                                    <div id="uploadfiles_ays1">
                                        <div className="d-flex ">
                                            <div className="col-10">
                                                {/* Fetch banner images from the server */}
                                                {bannerImg ? bannerImg.map((image, index) => {
                                                    return (
                                                        <div className='d-flex' key={`serverimg+${index}`}>
                                                            <a href={image} target="_blank">
                                                                <img className='mt-1' src={image} style={{ height: '50px', width: '50px' }} />
                                                            </a>&nbsp;&nbsp;&nbsp;&nbsp; <span className="text-danger"
                                                                style={{ cursor: 'pointer' }} onClick={() => {

                                                                    if (index > -1) {
                                                                        var array1 = [...bannerImg]
                                                                        array1.splice(index, 1)
                                                                        setBannerImg(array1)
                                                                    }

                                                                }}><u>Delete</u></span></div>
                                                    )
                                                }) : null}
                                                {/* uploading new banner image files from local machine */}
                                                {bannerImg_new ? bannerImg_new.map((image, index) => {
                                                    return (

                                                        <div className='d-flex' key={`uploadfile+${index}`}>
                                                            <a href={image} target="_blank">
                                                                <img className='mt-1' src={image} style={{ height: '50px', width: '50px' }} />
                                                            </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <span className="text-danger" style={{ cursor: 'pointer' }} onClick={() => {

                                                                if (index > -1) {
                                                                    var array1 = [...bannerImg_new]
                                                                    array1.splice(index, 1)
                                                                    setBannerImg_new(array1)
                                                                }

                                                            }}><u>Delete</u></span></div>

                                                    )
                                                }) : null}
                                            </div>
                                            <div className="d-flex align-items-center col-2">
                                                <span id='underlinegreen1' style={{ cursor: 'pointer' }}
                                                    onClick={() => bannerImgFilesRef.current.click()}>Upload More</span>
                                            </div>
                                        </div>

                                    </div> : <div id="uploadfiles_ays">
                                        <div id='upload_text_ays'> Drag or  &nbsp;<span id='underlinegreen' style={{ cursor: 'pointer' }} onClick={() => bannerImgFilesRef.current.click()}>Upload</span>&nbsp;Files</div>
                                    </div>}
                            </div>

                            {/* other images */}
                            <div className="form-group" >
                                <label className='labeltext_ays' htmlFor="businessname">Other photos</label>

                                {upfiles.length != 0 || upfiles_new.length != 0 ?
                                    <div id="uploadfiles_ays1">
                                        <div className="d-flex ">
                                            <div className="col-10">
                                                {/* Fetch images from the server */}
                                                {upfiles ? upfiles.map((image, index) => {
                                                    return (

                                                        <div className='d-flex' key={`serverimg+${index}`}>
                                                            <a href={image} target="_blank">
                                                                <img src={image} style={{ height: '50px', width: '50px' }} />
                                                            </a>&nbsp;&nbsp;&nbsp;&nbsp; <span className="text-danger"
                                                                style={{ cursor: 'pointer' }} onClick={() => {

                                                                    if (index > -1) {
                                                                        var array1 = [...upfiles]
                                                                        array1.splice(index, 1)
                                                                        setUpfiles(array1)
                                                                    }

                                                                }}><u>Delete</u></span></div>

                                                    )
                                                }) : null}
                                                {/* uploading new files from local machine */}
                                                {upfiles_new ? upfiles_new.map((image, index) => {

                                                    return (

                                                        <div className='d-flex' key={`uploadfile+${index}`}>
                                                            <a href={image} target="_blank">
                                                                <img src={image} style={{ height: '50px', width: '50px' }} />
                                                            </a>&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <span className="text-danger" style={{ cursor: 'pointer' }} onClick={() => {

                                                                if (index > -1) {
                                                                    var array1 = [...upfiles_new]
                                                                    array1.splice(index, 1)
                                                                    setUpfiles_new(array1)
                                                                }

                                                            }}><u>Delete</u></span></div>

                                                    )
                                                }) : null}
                                            </div>
                                            <div className="d-flex align-items-center col-2">
                                                <span id='underlinegreen1' style={{ cursor: 'pointer' }}
                                                    onClick={() => uploadfilesRef.current.click()}>Upload More</span>
                                            </div>
                                        </div>

                                    </div> : <div id="uploadfiles_ays">
                                        <div id='upload_text_ays'> Drag or  &nbsp;<span id='underlinegreen' style={{ cursor: 'pointer' }} onClick={() => uploadfilesRef.current.click()}>Upload</span>&nbsp;Files</div>
                                    </div>}
                            </div>

                            <div className=" mr-xs-2 " id='openhours_ays' >
                                <label className='labeltext_ays' htmlFor="open hours" >Open hours <super className="text-danger"><b>*</b></super></label>
                                {/* mapping of days  */}
                                {/* Monday */}
                                <div className='row p-2 box_background'>
                                    <div className='d-flex align-items-center col-xl-6 col-xs-12 '>
                                        <div className='col-xl-6 col-md-6 col-xs-4  days_ays '>
                                            Monday
                                        </div>
                                        <div className='col-xl-6 col-md-6 col-xs-8 '>
                                            <select value={state.Monday} className='form-control' onChange={(event) => changingDayStatus('Monday', event)} >
                                                <option value="open">Open</option>
                                                <option value="closed">Closed</option>
                                            </select>
                                        </div>
                                    </div>
                                    {state.Monday === 'open' ?
                                        <div className='col-xl-6 col-xs-12 d-flex align-items-center mt-1 mb-1'>
                                            <div className='col-2 fromto_ays'>
                                                from
                                            </div>
                                            <div className='col-4 '>

                                                <select value={state.Mondaystatus[1]} className='form-control' onChange={(event) => daystarttime('Monday', event)}>
                                                    {time.map((hour, index) => {

                                                        return (
                                                            <option key={`starttimem+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-2 fromto_ays'>
                                                to
                                            </div>
                                            <div className='col-4 '>

                                                <select value={state.Mondaystatus[2]} className='form-control' onChange={(event) => dayendtime('Monday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`endtimem+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                        </div> : <div className='col-xl-6 col-xs-12 d-flex align-items-center mt-1 mb-1' style={{ visibility: 'hidden' }}>
                                            <div className='col-2 fromto_ays'>
                                                from
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Mondaystatus[1]} className='form-control' onChange={(event) => daystarttime('Monday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`starttimem+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-2 fromto_ays'>
                                                to
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Mondaystatus[2]} className='form-control' onChange={(event) => dayendtime('Monday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`endtimem+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                        </div>}

                                </div>
                                {/* Tuesday */}
                                <div className='row d-flex align-items-center p-2 box_background'>
                                    <div className='d-flex align-items-center col-xl-6 col-xs-12 '>
                                        <div className='col-xl-6 col-md-6 col-xs-4  days_ays '>
                                            Tuesday
                                        </div>
                                        <div className='col-xl-6 col-md-6 col-xs-8 '>
                                            <select value={state.Tuesday} className='form-control' onChange={(event) => changingDayStatus('Tuesday', event)} >
                                                <option value="open">Open</option>
                                                <option value="closed">Closed</option>
                                            </select>
                                        </div>
                                    </div>
                                    {state.Tuesday === 'open' ?
                                        <div className='col-xl-6 col-xs-12 d-flex align-items-center mt-1 mb-1'>
                                            <div className='col-2 fromto_ays'>
                                                from
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Tuesdaystatus[1]} className='form-control' onChange={(event) => daystarttime('Tuesday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`starttimet+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-2 fromto_ays'>
                                                to
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Tuesdaystatus[2]} className='form-control' onChange={(event) => dayendtime('Tuesday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`endtimet+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                        </div> : <div className='col-xl-6 col-xs-12 d-flex align-items-center mt-1 mb-1' style={{ visibility: 'hidden' }}>
                                            <div className='col-2 fromto_ays'>
                                                from
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Tuesdaystatus[1]} className='form-control' onChange={(event) => daystarttime('Tuesday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`starttimet+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-2 fromto_ays'>
                                                to
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Tuesdaystatus[2]} className='form-control' onChange={(event) => dayendtime('Tuesday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`endtimet+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                        </div>}

                                </div>
                                {/* Wednesday */}
                                <div className='row d-flex align-items-center p-2 box_background'>
                                    <div className='d-flex align-items-center col-xl-6 col-xs-12 '>
                                        <div className='col-xl-6 col-md-6 col-xs-4  days_ays '>
                                            Wednesday
                                        </div>
                                        <div className='col-xl-6 col-md-6 col-xs-8 '>
                                            <select value={state.Wednesday} className='form-control' onChange={(event) => changingDayStatus('Wednesday', event)} >
                                                <option value="open">Open</option>
                                                <option value="closed">Closed</option>
                                            </select>
                                        </div>
                                    </div>
                                    {state.Wednesday === 'open' ?
                                        <div className='col-xl-6 col-xs-12 d-flex align-items-center mt-1 mb-1'>
                                            <div className='col-2 fromto_ays'>
                                                from
                                            </div>
                                            <div className='col-4 '>
                                                <select defaultValue={state.Wednesdaystatus[1]} className='form-control' onChange={(event) => daystarttime('Wednesday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`starttime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-2 fromto_ays'>
                                                to
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Wednesdaystatus[2]} className='form-control' onChange={(event) => dayendtime('Wednesday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`endtime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                        </div> : <div className='col-xl-6 col-xs-12 d-flex align-items-center mt-1 mb-1' style={{ visibility: 'hidden' }}>
                                            <div className='col-2 fromto_ays'>
                                                from
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Wednesdaystatus[1]} className='form-control' onChange={(event) => daystarttime('Wednesday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`starttime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-2 fromto_ays'>
                                                to
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Wednesdaystatus[2]} className='form-control' onChange={(event) => dayendtime('Wednesday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`endtime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                        </div>}

                                </div>
                                {/*Thursday */}

                                <div className='row d-flex align-items-center p-2 box_background'>
                                    <div className='d-flex align-items-center col-xl-6 col-xs-12 '>
                                        <div className='col-xl-6 col-md-6 col-xs-4  days_ays '>
                                            Thursday
                                        </div>
                                        <div className='col-xl-6 col-md-6 col-xs-8 '>
                                            <select value={state.Thursday} className='form-control' onChange={(event) => changingDayStatus('Thursday', event)} >
                                                <option value='open'>Open</option>
                                                <option value='closed'>Closed</option>
                                            </select>
                                        </div>
                                    </div>
                                    {state.Thursday === 'open' ?
                                        <div className='col-xl-6 col-xs-12 d-flex align-items-center mt-1 mb-1'>
                                            <div className='col-2 fromto_ays'>
                                                from
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Thursdaystatus[1]} className='form-control' onChange={(event) => daystarttime('Thursday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`starttime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-2 fromto_ays'>
                                                to
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Thursdaystatus[2]} className='form-control' onChange={(event) => dayendtime('Thursday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`endtime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                        </div> : <div className='col-xl-6 col-xs-12 d-flex align-items-center mt-1 mb-1' style={{ visibility: 'hidden' }}>
                                            <div className='col-2 fromto_ays'>
                                                from
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Thursdaystatus[1]} className='form-control' onChange={(event) => daystarttime('Thursday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`starttime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-2 fromto_ays'>
                                                to
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Thursdaystatus[2]} className='form-control' onChange={(event) => dayendtime('Thursday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`endtime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                        </div>}

                                </div>
                                {/* Friday */}
                                <div className='row d-flex align-items-center p-2 box_background'>
                                    <div className='d-flex align-items-center col-xl-6 col-xs-12 '>
                                        <div className='col-xl-6 col-md-6 col-xs-4  days_ays '>
                                            Friday
                                        </div>
                                        <div className='col-xl-6 col-md-6 col-xs-8 '>
                                            <select value={state.Friday} className='form-control' onChange={(event) => changingDayStatus('Friday', event)} >
                                                <option value="open">Open</option>
                                                <option value="closed">Closed</option>
                                            </select>
                                        </div>
                                    </div>
                                    {state.Friday === 'open' ?
                                        <div className='col-xl-6 col-xs-12 d-flex align-items-center mt-1 mb-1'>
                                            <div className='col-2 fromto_ays'>
                                                from
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Fridaystatus[1]} className='form-control' onChange={(event) => daystarttime('Friday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`starttime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-2 fromto_ays'>
                                                to
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Fridaystatus[2]} className='form-control' onChange={(event) => dayendtime('Friday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`endtime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                        </div> : <div className='col-xl-6 col-xs-12 d-flex align-items-center mt-1 mb-1' style={{ visibility: 'hidden' }}>
                                            <div className='col-2 fromto_ays'>
                                                from
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Fridaystatus[1]} className='form-control' onChange={(event) => daystarttime('Friday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`starttime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-2 fromto_ays'>
                                                to
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Fridaystatus[2]} className='form-control' onChange={(event) => dayendtime('Friday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`endtime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                        </div>}

                                </div>
                                {/* Saturday */}
                                <div className='row d-flex align-items-center p-2 box_background'>
                                    <div className='d-flex align-items-center col-xl-6 col-xs-12 '>
                                        <div className='col-xl-6 col-md-6 col-xs-4  days_ays '>
                                            Saturday
                                        </div>
                                        <div className='col-xl-6 col-md-6 col-xs-8 '>
                                            <select value={state.Saturday} className='form-control' onChange={(event) => changingDayStatus('Saturday', event)} >
                                                <option value="open">Open</option>
                                                <option value="closed">Closed</option>
                                            </select>
                                        </div>
                                    </div>
                                    {state.Saturday === 'open' ?
                                        <div className='col-xl-6 col-xs-12 d-flex align-items-center mt-1 mb-1'>
                                            <div className='col-2 fromto_ays'>
                                                from
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Saturdaystatus[1]} className='form-control' onChange={(event) => daystarttime('Saturday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`starttime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-2 fromto_ays'>
                                                to
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Saturdaystatus[2]} className='form-control' onChange={(event) => dayendtime('Saturday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`endtime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                        </div> : <div className='col-xl-6 col-xs-12 d-flex align-items-center mt-1 mb-1' style={{ visibility: 'hidden' }}>
                                            <div className='col-2 fromto_ays'>
                                                from
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Saturdaystatus[1]} className='form-control' onChange={(event) => daystarttime('Saturday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`starttime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-2 fromto_ays'>
                                                to
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Saturdaystatus[2]} className='form-control' onChange={(event) => dayendtime('Saturday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`endtime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                        </div>}

                                </div>
                                {/* Sunday */}
                                <div className='row d-flex align-items-center p-2 box_background'>
                                    <div className='d-flex align-items-center col-xl-6 col-xs-12 '>
                                        <div className='col-xl-6 col-md-6 col-xs-4  days_ays '>
                                            Sunday
                                        </div>
                                        <div className='col-xl-6 col-md-6 col-xs-8 '>

                                            <select value={state.Sunday} className='form-control' onChange={(event) => changingDayStatus('Sunday', event)} >
                                                <option value="open">Open</option>
                                                <option value="closed">Closed</option>
                                            </select>
                                        </div>
                                    </div>
                                    {state.Sunday === 'open' ?
                                        <div className='col-xl-6 col-xs-12 d-flex align-items-center mt-1 mb-1'>
                                            <div className='col-2 fromto_ays'>
                                                from
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Sundaystatus[1]} className='form-control' onChange={(event) => daystarttime('Sunday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`starttime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-2 fromto_ays'>
                                                to
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Sundaystatus[2]} className='form-control' onChange={(event) => dayendtime('Sunday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`endtime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                        </div> : <div className='col-xl-6 col-xs-12 d-flex align-items-center mt-1 mb-1' style={{ visibility: 'hidden' }}>
                                            <div className='col-2 fromto_ays'>
                                                from
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Sundaystatus[1]} className='form-control' onChange={(event) => daystarttime('Sunday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`starttime+${index}`} value={hour}>{hour}</option>
                                                        )

                                                    })}
                                                </select>
                                            </div>
                                            <div className='col-2 fromto_ays'>
                                                to
                                            </div>
                                            <div className='col-4 '>
                                                <select value={state.Sundaystatus[2]} className='form-control' onChange={(event) => dayendtime('Sunday', event)}>
                                                    {time.map((hour, index) => {
                                                        return (
                                                            <option key={`endtime+${index}`} value={hour}>{hour}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div>}
                                </div>
                            </div>

                            <div className="form-group d-flex justify-content-end my-5">
                                <Form.Item className="form-item">
                                    <Spin spinning={loading} delay={500}>
                                        <button className="btn btnPara-primary btn-lg" htmlType="submit">
                                            Finish
                                        </button>
                                    </Spin>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}
