import React, { useEffect, useState } from 'react';
import { DashboardLayout } from '../Layout/Layout';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom';
import { AdminUsersApi } from '../../../apis/admin/UserApi';
import { Spin, Pagination, notification } from 'antd';
import { BusinessAPI } from '../../../apis/admin/BusinessAPI';
import SearchIcon from "@material-ui/icons/Search";

const BusinessList = () => {

    const [loading, setLoader] = useState(false);
    const [businessList, setBusinessList] = useState([])
    const [TotalData, setTotalData] = useState(0)
    const [search, setSearch] = useState('')
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(0)

    useEffect(() => {
        loadData(search, 1)
    }, []);

    const HandlePagination = (PageNumber) => {
        loadData(search, PageNumber)
        setPage(PageNumber)
    }

    const loadData = async (search, page) => {
        const data = {
            search, limit, page
        }
        BusinessAPI.getBusinesses(data)
            .then((res) => {
                setTotalData(res.data.count)
                setBusinessList(res.data.data)
            })
    }

    const onSearch = async (search) => {
        loadData(search, page)
        setSearch(search)
    }

    const onChangeStatus = async (e, business_id) => {
        let status = e.target.value;
        const data = {
            business_id,
            status,
        }

        BusinessAPI.changeBusinessStatus(data)
            .then((res) => {
                // console.log(res)
                notification.success({ message: res.data.message })

            })
    }

    return (
        <DashboardLayout page='Business List'>
            <div className="w-100 " style={{ marginTop: "100px" }}>

                <div className="sidebar-content">

                    <div className="d-flex justify-content-between">
                        <h3 className="mb-3">Business List</h3>
                    </div>

                    <div className="paraTabs">
                        <div className="table-responsive paraRounded-table mt-4">
                            <div className="d-flex mb-3">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control product-search"
                                        placeholder="Search business"
                                        value={search}
                                        onChange={(e) => onSearch(e.target.value)}
                                    />
                                </div>
                            </div>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="fw-500">Name</th>
                                        <th scope="col" className="fw-500">Logo</th>
                                        <th scope="col" className="fw-500">Phone Number</th>
                                        <th scope="col" className="fw-500">Category</th>
                                        <th scope="col" className="fw-500">Status</th>
                                        <th scope="col" className="fw-500">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {businessList?.map((business, i) => {
                                        return <tr key={business.id}>
                                            <td className="align-middle">{business.business_name}</td>
                                            <td className="align-middle"><img src={business.company_logo} width="80" height="80" /></td>
                                            <td className="align-middle">{business.mobile_number}</td>
                                            <td className="align-middle">{business.category}</td>
                                            <td className="align-middle">
                                                <p className="mb-0 text-success">
                                                    <select className='mr-2 border-0'
                                                        onChange={(e) => onChangeStatus(e, business.id)}
                                                    >
                                                        <option value="Inactive" className=" d-none">Inactive</option>
                                                        <option selected={business.business_status_by_admin == 'Inactive'} value="Inactive">Inactive</option>
                                                        <option selected={business.business_status_by_admin == 'Active'} value="Active">Active</option>
                                                    </select>
                                                </p></td>
                                            <td className="align-middle">
                                                <Link to={`/admin/view-business/${business.business_slug
                                                    }`}
                                                    className="btn btn-sm btn-outline-green"
                                                >
                                                    <VisibilityIcon />
                                                </Link>
                                            </td>
                                        </tr>
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-5">
                        <Pagination defaultCurrent={1} onChange={HandlePagination} total={TotalData} />
                    </div>
                </div>

            </div>
        </DashboardLayout >
    );
};

export default BusinessList;