import '../../../../src/assets/css/AdminPanel/adminPanel.css';
import React, { useEffect, useState } from 'react'
import { DashboardLayout } from '../Layout/Layout'
import { Redirect } from 'react-router-dom'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SampleProducts from '../../../assets/images/products/sample-image.svg';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { OverviewApi } from '../../../apis/admin/OverViewApi';
const Overview = () => {
    const [Statistics, setStatistics] = useState({})
    useEffect(() => {
        OverviewApi.getDashboardStatistics()
            .then(res => setStatistics(res.data));
    }, []);
    return (
        <DashboardLayout page='overview'>
            {/* <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-content">
                    <h4>overview</h4>
                </div>
            </div> */}
            <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-content">
                    <div className="row">
                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-6 col-6 mb-4 flex-column">
                            <div className="card border-dark h-100">
                                <div className="card-body d-flex align-items-start flex-column h-100">
                                    <div className="mb-auto w-100">
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <p>Total Business</p>
                                            <p className="redText">{Statistics.totalNewBusiness} New</p>
                                        </div>
                                    </div>
                                    <h2 className="mb-0 fontFamily-Gotham font-weight-normal">{Statistics.totalBusiness}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-6 col-6 mb-4 flex-column">
                            <div className="card border-dark h-100">
                                <div className="card-body d-flex align-items-start flex-column h-100">
                                    <div className="mb-auto w-100">
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <p>Total Customers</p>
                                            <p className="redText">{Statistics.totalNewCustomers } New</p>
                                        </div>
                                    </div>
                                    <h2 className="mb-0 fontFamily-Gotham font-weight-normal">{Statistics.totalCustomers}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-6 col-6 mb-4 flex-column">
                            <div className="card border-dark h-100">
                                <div className="card-body d-flex align-items-start flex-column h-100">
                                    <div className="mb-auto w-100">
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <p>Total Orders</p>
                                            <p className="redText">{Statistics.totalNewOrders} New</p>
                                        </div>
                                    </div>
                                    <h2 className="mb-0 fontFamily-Gotham font-weight-normal">{Statistics.totalOrders}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-3 col-sm-6 col-6 mb-4 flex-column">
                            <div className="card border-dark h-100">
                                <div className="card-body d-flex align-items-start flex-column h-100">
                                    <div className="mb-auto w-100">
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <p>Total Products</p>
                                            <p className="redText">{Statistics.totalNewProducts} New</p>
                                        </div>
                                    </div>
                                    <h2 className="mb-0 fontFamily-Gotham font-weight-normal">{Statistics.totalProducts}</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="row mt-3 mb-3">
                        <div className="col-xl-7 col-lg-7 col-md-7 my-3">
                            <div className="paraSearchBox">
                                <div className="searchBox" component="form">
                                    <div className='d-flex w-100'>
                                        <SearchIcon />
                                        <InputBase className='inputSearchBox' placeholder="Eg. Home Bakery" />
                                    </div>
                                    <button className='searchButton'>Search</button>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-5 col-md-5 my-3">
                            <button className='btn btn-outline-primary btn-lg'>Add a new user</button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-7 col-lg-12 col-md-7 my-3">
                            <h5>Notifications</h5>
                            <div className="card">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="mb-0">Morning Rush wants to create a new category</p>
                                                <p className="mb-0 greyText">28th, June 2020 at 13:34PM</p>
                                            </div>
                                            <div className="align-self-center">
                                                <button className="btn btn-view-order">View <ArrowForwardIcon className="ml-1" style={{ fontSize: 16, }} /></button>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="mb-0">Morning Rush wants to create a new category</p>
                                                <p className="mb-0 greyText">28th, June 2020 at 13:34PM</p>
                                            </div>
                                            <div className="align-self-center">
                                                <button className="btn btn-view-order">View <ArrowForwardIcon className="ml-1" style={{ fontSize: 16, }} /></button>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="mb-0">Morning Rush wants to create a new category</p>
                                                <p className="mb-0 greyText">28th, June 2020 at 13:34PM</p>
                                            </div>
                                            <div className="align-self-center">
                                                <button className="btn btn-view-order">View <ArrowForwardIcon className="ml-1" style={{ fontSize: 16, }} /></button>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex justify-content-between">
                                            <div>
                                                <p className="mb-0">Morning Rush wants to create a new category</p>
                                                <p className="mb-0 greyText">28th, June 2020 at 13:34PM</p>
                                            </div>
                                            <div className="align-self-center">
                                                <button className="btn btn-view-order">View <ArrowForwardIcon className="ml-1" style={{ fontSize: 16, }} /></button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-12 col-md-5 my-3">
                            <h5>New Business Applications</h5>
                            <div className="card">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item">
                                        <div className="d-flex justify-content-between">
                                            <div className="media">
                                                <img src={SampleProducts} alt="" className="bd-placeholder-img mr-2 rounded" width="32" height="32" />
                                                <div className="media-body mb-0 border-gray">
                                                    <p className="mb-0">Cakes by K</p>
                                                    <p className="mb-0 greyText">Hannah Maneyapanda</p>
                                                </div>
                                            </div>
                                            <div className="align-self-center">
                                                <button className="btn btn-view-order">Review <ArrowForwardIcon className="ml-1" style={{ fontSize: 16, }} /></button>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex justify-content-between">
                                            <div className="media">
                                                <img src={SampleProducts} alt="" className="bd-placeholder-img mr-2 rounded" width="32" height="32" />
                                                <div className="media-body mb-0 border-gray">
                                                    <p className="mb-0">Morning Rush</p>
                                                    <p className="mb-0 greyText">Kartik Chhabriaa</p>
                                                </div>
                                            </div>
                                            <div className="align-self-center">
                                                <button className="btn btn-view-order">Review <ArrowForwardIcon className="ml-1" style={{ fontSize: 16, }} /></button>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <div className="d-flex justify-content-between">
                                            <div className="media">
                                                <img src={SampleProducts} alt="" className="bd-placeholder-img mr-2 rounded" width="32" height="32" />
                                                <div className="media-body mb-0 border-gray">
                                                    <p className="mb-0">Coffee & Cakes</p>
                                                    <p className="mb-0 greyText">Murtaza Zoomkawala</p>
                                                </div>
                                            </div>
                                            <div className="align-self-center">
                                                <button className="btn btn-view-order">Review <ArrowForwardIcon className="ml-1" style={{ fontSize: 16, }} /></button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Overview
