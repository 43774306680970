import React, { useState, useReducer } from 'react'
import { Form, Input, Button, Spin, Checkbox } from 'antd'
import SignUpFlower from '../../assets/images/adminPanel/Auth/signup-flower.svg';
import Modal from 'react-bootstrap/Modal';
import { RiGoogleFill, RiFacebookFill } from "react-icons/ri";
import { Token } from '../../Token';
import { AuthAPI } from '../../apis/user/AuthAPI';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../assets/images/modalPopup/close-circle.svg';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
// import GoogleLogin from 'react-google-login';
// import { GoogleLogin } from '@react-oauth/google';
import { useAuthState, useSignInWithGoogle } from 'react-firebase-hooks/auth';
import jwt_decode from "jwt-decode";
import config from '../../config';
import SampleProducts from '../../assets/images/products/sample-image.svg';
import auth from '../../firebase.init';

function SignIn(props) {
    const [signInWithGoogle, user1, loading1, error1] = useSignInWithGoogle(auth);
    const [user2, loading2, error2] = useAuthState(auth);

    const [form] = Form.useForm();
    const [loading, setLoader] = useState(false);
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            email: '',
            email_error: '',
            formStep: 1
        }
    );

    const onFinish = async (value) => {

        const data = {
            email: state.email,
            password: value.password
        };

        AuthAPI.signIn(data)
            .then(res => {
                props.setState({ _isShowSingInModel: false })

                Token.saveToken(res.data.user, res.data.token, 'enduser');
                Swal.fire({
                    text: `Welcome back ${res.data.user.first_name} ${res.data.user.last_name}`,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                });

            }).catch(err => {
                if (err.response) {
                    Swal.fire({
                        text: err.response.data.message,
                        imageUrl: CloseCircle,
                        imageAlt: 'error image',
                        confirmButtonColor: '#00CA84'
                    });
                }
            })
    }

            if (error1) {
              return (
                <div>
                  <p>Error: {error1.message}</p>
                </div>
              );
            }
            if (loading1) {
              return <p>Loading...</p>;
            }
            if (user1) {
            const GoogleName = user2?.displayName.split(" ")
            const data = {
                first_name: GoogleName[0],
                last_name: GoogleName[1],
                email: user2?.email,
                postal_code: '',
                zipcode: '',
                condition_accepted: '',
                user_with: 'google'
            };
            AuthAPI.actionWithSocialLogin(data)
                .then(res => {
                    Token.saveToken(res.data.user, res.data.token, 'enduser')
                    // props.setState({ _isShowSingUpModel: true })

                    Swal.fire({
                        title: 'Success!',
                        text: res.data.message,
                        imageUrl: CheckmarkCircle,
                        imageAlt: 'success image',
                        confirmButtonColor: '#00CA84'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            window.location.reload()
                        }
                    });

                }).catch(err => {
                    if (err.response) {
                        Swal.fire({
                            text: err.response.data.message,
                            imageUrl: CloseCircle,
                            imageAlt: 'error image',
                            confirmButtonColor: '#00CA84'
                        });
                    }
                })
              
            }


    const responseFacebook = (response) => {
        if (response?.name) {
            const names = response.name.split(" ")

            const data = {
                first_name: names[0],
                last_name: names[1],
                email: response?.email,
                postal_code: '',
                zipcode: '',
                condition_accepted: '',
                user_with: 'google'
            };
            AuthAPI.actionWithSocialLogin(data)
            .then(res => {
                Token.saveToken(res.data.user, res.data.token, 'enduser')
                // props.setState({ _isShowSingUpModel: true })

                Swal.fire({
                    title: 'Success!',
                    text: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload()
                    }
                });

            }).catch(err => {
                if (err.response) {
                    Swal.fire({
                        text: err.response.data.message,
                        imageUrl: CloseCircle,
                        imageAlt: 'error image',
                        confirmButtonColor: '#00CA84'
                    });
                }
            })
        }
        //call api to store response 
    }

    const checkEmail = (text) => {
        AuthAPI.checkEmail({ email: text })
            .then(res => {
                if (res.data.status) {
                    setState({ email_error: '' })
                } else {
                    setState({ email_error: res.data.message })
                    let error = [{
                        name: 'email',
                        // errors: [res.data.message],
                        errors: ['Please enter valid email address.'],
                    }];
                    form.setFields(error);
                }

            }).catch(err => {
                if (err.response) {
                    console.log('err.response', err.response)
                }
            })
    }
    return (
        <>
            {/* ====== Modal: Email ===================== */}
            <Modal className="loginFormModal" centered
                show={props.state._isShowSingInModel}
                onHide={() => props.setState({ _isShowSingInModel: false })}
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="p-0">
                    <div className="userLogin-box">
                        <div className="userLogin-headerBox">
                            <h4 className="align-self-end mb-0 text-white fw-600">Sign In</h4>
                            <div className="SignUpFlower-box">
                                <img src={SignUpFlower} alt="" />
                            </div>
                        </div>
                        <div className="userLogin-formBox">
                            <Form
                                form={form}
                                onFinish={onFinish}
                            >
                                {
                                    state.formStep == 1 ?

                                        <div>
                                            <div className="d-flex form-group">
                                                <p className='mb-0 mr-2'>New User? </p>
                                                <h6
                                                    role="button"
                                                    className='align-self-center mb-0'
                                                    onClick={() => {
                                                        props.setState({ _isShowSingUpModel: true })
                                                        props.setState({ _isShowSingInModel: false })
                                                    }}
                                                >Create an account</h6>
                                            </div>

                                            <div className="form-group mt-3">
                                                <label>Email address</label>
                                                <Form.Item
                                                    className="form-item"
                                                    name="email"
                                                    rules={[
                                                        { required: true, message: 'Please input your email address.' },
                                                        { type: 'email', message: 'Please enter valid email address.' }
                                                    ]}
                                                >
                                                    <Input placeholder="Email address" onChange={(e) => {
                                                        if (e.target.value.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                                                            setState({ email: e.target.value })
                                                        } else {
                                                            setState({ email: '' })
                                                        }

                                                        checkEmail(e.target.value);

                                                    }} />
                                                </Form.Item>
                                                {/* {
                                                    state.email_error !== "" &&
                                                    <div role="alert" class="ant-form-item-explain-error"
                                                        style={{ marginTop: '-20px', marginBottom: '-10px', fontSize: '14px' }}>
                                                        {state.email_error}
                                                    </div>
                                                } */}
                                            </div>

                                            <div className="form-group mt-3 d-flex justify-content-end">
                                                <Form.Item className="form-item mb-2">
                                                    <Spin spinning={loading} delay={500}>
                                                        <button className="btn btnPara-primary btn-md"
                                                            htmlType="button"
                                                            onClick={() => setState({ formStep: 2 })}
                                                            disabled={state.email == '' || state.email_error != ''}
                                                        >
                                                            Continue
                                                        </button>
                                                    </Spin>
                                                </Form.Item>
                                            </div>

                                            <div className='mx-2 pt-3' style={{ borderTop: '1px black solid' }} >
                                                <div className='text-center w-100'>Or</div>
                                                <div className='mt-3 w-100'>
                                                    <div>
                                                        {/* <GoogleLogin
                                                            clientId="110881339555-l20m4hc5pi6o79q0v2dmscgtkj656o25.apps.googleusercontent.com"
                                                            buttonText="Login"
                                                            onSuccess={responseGoogle}
                                                            onFailure={responseGoogle}
                                                            cookiePolicy={'single_host_origin'}
                                                            render={renderProps => (
                                                                <Button onClick={renderProps.onClick} className='mb- btn-SignIn btn-block'>
                                                                    <RiGoogleFill className='greenText mr-2' />
                                                                    SIGN IN WITH GOOGLE
                                                                </Button>
                                                            )}
                                                        /> */}
                                                        <Button onClick={()=>signInWithGoogle()} className='mb- btn-SignIn btn-block'>
                                                            <RiGoogleFill className='greenText mr-2' />
                                                            SIGN IN WITH GOOGLE
                                                        </Button>
                                                        {/* <GoogleLogin
                                                            className='mb-1 btn-SignIn btn-block'
                                                            onSuccess={credentialResponse => {
                                                                const decoded = jwt_decode(credentialResponse.credential)
                                                                setUser(decoded)
                                                            }}
                                                            onError={() => {
                                                                console.log('Login Failed');
                                                            }}
                                                            
                                                        /> */}

                                                    </div>
                                                    <div className='mt-2 w-100'>
                                                        <FacebookLogin
                                                            appId={config.social.FB}
                                                            callback={responseFacebook}
                                                            fields="name,email,picture"
                                                            render={renderProps => (
                                                                <Button onClick={renderProps.onClick} className='mb-1 btn-SignIn btn-block'>
                                                                    <RiFacebookFill className='greenText mr-2' />SIGN IN WITH FACEBOOK
                                                                </Button>
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> :

                                        <div>
                                            <div className="d-flex">
                                                <img src={SampleProducts} alt="" className="mr-2" width="50" height="50" />
                                                <div className="align-self-center">
                                                    <p className="mb-0 fw-500">PERSONAL ACCOUNT</p>
                                                    <p className="mb-0 greyText ft-size14 greenText">{state.email}</p>
                                                </div>
                                            </div>


                                            <div className="form-group mt-3">
                                                <label>Enter Password</label>
                                                <Form.Item
                                                    className="form-item"
                                                    name="password"
                                                    rules={[
                                                        { required: true, message: 'Please enter your password.' }
                                                    ]}
                                                >
                                                    <Input.Password
                                                        onPaste={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }} onCopy={(e) => {
                                                            e.preventDefault()
                                                            return false;
                                                        }}
                                                        placeholder="input password" />
                                                </Form.Item>
                                            </div>

                                            <div className="form-group mt-3 d-flex justify-content-between">
                                                <Form.Item className="align-self-center form-item mb-2">
                                                    <Checkbox ><span className='text-dark ft-size16'>Keep me signed In</span></Checkbox>
                                                </Form.Item>
                                                <Form.Item className="form-item mb-2">
                                                    <Spin spinning={loading} delay={500}>
                                                        <button className="btn btnPara-primary btn-md" htmlType="submit">
                                                            Continue
                                                        </button>
                                                    </Spin>
                                                </Form.Item>
                                            </div>

                                            <div className='mx-2 pt-3' style={{ borderTop: '1px black solid' }} >
                                                <div className='mt-3 w-100'>
                                                    <p className='mt-3 para-link-dark clickable'
                                                        onClick={() => props.setState({ _isShowForgotModel: true, _isShowSingInModel: false })}
                                                    > Reset your password</p>
                                                </div>
                                            </div>
                                        </div>
                                }
                            </Form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default SignIn
