import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import { useReducer } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { GuestAPI } from '../../apis/GuestAPI';
import bannerimg from '../../assets/images/home/banner_img.png';
import '../styles/css/home/searchExplore.css';

const SearchExplore2 = () => {

    let history = useHistory();
    let searchURL = useLocation().search;
    let searchData = new URLSearchParams(searchURL).get("serach");
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            search: searchData,
            serachData: [],
            stickyClass: ''
        }
    );

    const onChangeSearch = async (value) => {
        setState({ search: value });
        if (value != '') {
            GuestAPI.getSerachContent(value)
                .then(res => {
                    setState({ serachData: res.data.businesses })
                }).catch(error => console.log(error.response))
        }


    }

    const onClickSearch = async () => {

        if (state.search) {
            localStorage.setItem('searchUrl', state.search.toString())
            history.push(`/businesses`);
        }
    }
    const elem = document.getElementById("browser");

    elem?.addEventListener("keypress", (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            const searchTexts=document.getElementById("browser")?.value
            if ( searchTexts) {
                localStorage.setItem('searchUrl', searchTexts.toString())
                history.push(`/businesses`);
            }
        }
    });
    return (
        <>
            <div className='container-md'>
                <div className='row' id="wrap">
                    <div className='col-xl-5 col-lg-6 col-md-12 col-12'>
                        <div className='seeWhatsUpInPara-box'>
                            <h1 className='home_seewhatsupinpara'> See what's up in your Para</h1>
                            <div className='d-flex justify-content-start'>
                                {/* <p style={{ fontSize: '20px' }}>Para </p><p className="text-grey small pt-1 pl-2">[paa-da]</p> */}
                                <p style={{ fontSize: '20px' }}>Connect to businesses and their in-stock products at your favorite local shops.</p>
                            </div>
                            {/* <p className='font-italic text-grey small'>Noun</p> */}
                            <p className='small'>Start Shopping from your Neighborhood</p>
                        </div>
                        {/* <Paper className='home_searchbottonbox d-flex justify-content-between' component="form" >
                        <div className='d-flex'>
                            <IconButton type="submit" style={{ color: '#00CA84', padding: 10 }} aria-label="search">
                                <SearchIcon />
                            </IconButton>
                            <InputBase className='home_inputsearchbox' placeholder="Eg. Home Bakery" />
                        </div>
                        <button className='home_searchbutton'>Search</button>
                    </Paper> */}
                        <div id="warp">
                            <Paper className={`${state.stickyClass} home_searchbottonbox d-flex justify-content-between`} component="form" >
                                <div className='d-flex w-100 InputMobileHide'>
                                    <IconButton type="submit" style={{ color: '#00CA84', padding: 10 }} aria-label="search">
                                        <SearchIcon />
                                    </IconButton>

                                    <input className='home_inputsearchbox'
                                        placeholder="Eg. Home Bakery" list="browsers" name="businesses" id="browser"
                                        value={state.search}
                                        onChange={(e) => onChangeSearch(e.target.value)} />
                                    {/* <datalist id="browsers">
                                        {
                                            state.serachData.map((search, i) => {
                                                return (
                                                    <option key={i} value={search.business_slug} >
                                                        {search.business_name}
                                                    </option>
                                                )
                                            })
                                        }
                                    </datalist> */}
                                </div>
                                <button className='home_searchbutton' onClick={() => onClickSearch()}>Search</button>
                            </Paper>
                        </div>
                        <div className='my-5'>
                            <Link to='/businesses'>
                                <button className='btn btn-show-more btn-md btn-rounded'>View All Business</button>
                            </Link>
                        </div>
                    </div>
                    <div className='col-xl-7 col-lg-6 col-md-12 col-12 content-to-hide content-iconsflotting'>

                        <img src={bannerimg} className="homebannerimage" alt='map ' />

                        {/* ////////////////////////// Flotting Img////////////////////// */}
                        <div className="icons " >
                            <div className="icon-container google"
                                data-aos="fade-up"
                            // data-aos-delay="50"
                            // data-aos-duration="1000"
                            // data-aos-anchor-placement="top-center"
                            >
                                <div className="icon" style={{ 'width': '50px', 'height': '50px' }}></div>
                            </div>

                            <div className="icon-container digitalocean"
                                data-aos="fade-up"
                            // data-aos-delay="50"
                            // data-aos-duration="2000"
                            // data-aos-anchor-placement="top-center"
                            >
                                <div className="icon" style={{ 'width': '50px', 'height': '50px' }}></div>
                            </div>

                            <div className="icon-container bitbucket"
                                data-aos="fade-up"
                            // data-aos-delay="50"
                            // data-aos-duration="3000"
                            // data-aos-anchor-placement="top-center"
                            >
                                <div className="icon" style={{ 'width': '40px', 'height': '40px' }}></div>
                            </div>

                            <div className="icon-container microsoft"
                                data-aos="fade-up"
                            // data-aos-delay="50"
                            // data-aos-duration="4000"
                            // data-aos-anchor-placement="top-center"
                            >
                                <div className="icon" style={{ 'width': '50px', 'height': '50px' }}></div>
                            </div>

                            <div className="icon-container ubuntu"
                                data-aos="fade-up"
                            // data-aos-delay="50"
                            // data-aos-duration="5000"
                            // data-aos-anchor-placement="top-center"
                            >
                                <div className="icon" style={{ 'width': '35px', 'height': '35px' }}></div>
                            </div>

                            <div className="icon-container cloudflare"
                                data-aos="fade-up"
                            // data-aos-delay="50"
                            // data-aos-duration="6000"
                            // data-aos-anchor-placement="top-center"
                            >
                                <div className="icon" style={{ 'width': '45px', 'height': '45px' }}></div>
                            </div>

                            <div className="icon-container wordpress"
                                data-aos="fade-up"
                            // data-aos-delay="50"
                            // data-aos-duration="6000"
                            // data-aos-anchor-placement="top-center"
                            >
                                <div className="icon" style={{ 'width': '50px', 'height': '70px' }}></div>
                            </div>

                            <div className="icon-container nginx"
                                data-aos="fade-up"
                            // data-aos-delay="50"
                            // data-aos-duration="7000"
                            // data-aos-anchor-placement="top-center"
                            >
                                <div className="icon" style={{ 'width': '50px', 'height': '50px' }}></div>
                            </div>
                            <div className="icon-container facebook"
                                data-aos="fade-up"
                            // data-aos-delay="50"
                            // data-aos-duration="8000"
                            // data-aos-anchor-placement="top-center"
                            >
                                <div className="icon" style={{ 'width': '30px', 'height': '30px' }}></div>
                            </div>
                            <div className="icon-container instagram"
                                data-aos="fade-up"
                            // data-aos-delay="50"
                            // data-aos-duration="9000"
                            // data-aos-anchor-placement="top-center"
                            >
                                <div className="icon" style={{ 'width': '15px', 'height': '15px' }}></div>
                            </div>

                            <div className="icon-container github"
                                data-aos="fade-up"
                            // data-aos-delay="50"
                            // data-aos-duration="10000"
                            // data-aos-anchor-placement="top-center"
                            >
                                <div className="icon" style={{ 'backgroundImage': 'url(https://logos-world.net/wp-content/uploads/2020/11/GitHub-Emblem.png)', 'width': '30px', 'height': '30px' }}></div>
                            </div>

                            <div className="icon-container linkedin"
                                data-aos="fade-up"
                            // data-aos-delay="50"
                            // data-aos-duration="11000"
                            // data-aos-anchor-placement="top-center"
                            >
                                <div className="icon" style={{ 'width': '15px', 'height': '15px' }}></div>
                            </div>

                            <div className="icon-container twitter"
                                data-aos="fade-up"
                            // data-aos-delay="50"
                            // data-aos-duration="12000"
                            // data-aos-anchor-placement="top-center"
                            >
                                <div className="icon" style={{ 'width': '20px', 'height': '20px' }}></div>
                            </div>
                        </div>
                        {/* //////////////////////////End Flotting Img////////////////////// */}

                    </div>

                </div>
                {/* <div>
                <div className='my-3'>
                    <p className='text-center'>Para reduces carbon footprint & brings the convenience of shopping online to your favorite nearby stores. Explore hundreds of popular businesses and check real-time inventory  24 hours a day. Plus, get an option to order online and pick-up today via convenient in-store pickup or same-day delivery.</p>
                </div>
            </div> */}
            </div>
            <div className='onlybgcolor'></div>
        </>
    )
}

export default SearchExplore2