import '../../../../src/assets/css/AdminPanel/adminPanel.css';
import React, { useState } from 'react'
import { DashboardLayout } from '../Layout/Layout'
import SampleProducts from '../../../assets/images/products/sample-image.svg';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import { Redirect } from 'react-router-dom'

export default function Applications() {
    return (
        <DashboardLayout page='promotions'>
            <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-content">
                    <h3 className="mb-0">Applications</h3>
                    <div className="paraTabs">
                        <div className="table-responsive paraRounded-table mt-4">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th colspan="2" className="fw-500">
                                            <div className='w-100'>
                                                <div className='d-flex w-100'>
                                                    <SearchIcon className="greyText mt-1 mr-3" />
                                                    <InputBase className='w-100' placeholder="Search Applications" />
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-between">
                                                <div className="media">
                                                    <img src={SampleProducts} alt="" className="bd-placeholder-img mr-2 rounded" width="32" height="32" />
                                                    <div className="media-body mb-0 border-gray">
                                                        <p className="mb-0">Cakes by K</p>
                                                        <p className="mb-0 greyText">Hannah Maneyapanda</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p className="align-self-center greyText mb-0 mr-3">#PA0200</p>
                                                <button className="btn btn-view-order">Review <ArrowForwardIcon className="ml-1" style={{ fontSize: 16, }} /></button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-between">
                                                <div className="media">
                                                    <img src={SampleProducts} alt="" className="bd-placeholder-img mr-2 rounded" width="32" height="32" />
                                                    <div className="media-body mb-0 border-gray">
                                                        <p className="mb-0">Cakes by K</p>
                                                        <p className="mb-0 greyText">Hannah Maneyapanda</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="align-middle">
                                            <div className="d-flex justify-content-end">
                                                <p className="align-self-center greyText mb-0 mr-3">#PA0200</p>
                                                <button className="btn btn-view-order">Review <ArrowForwardIcon className="ml-1" style={{ fontSize: 16, }} /></button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}



