import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Countdown from "react-countdown";
import StarIcon from '@material-ui/icons/Star';
import LocationOnSharpIcon from '@material-ui/icons/LocationOnSharp';
import Discount from '../../assets/images/home/discount.svg'
import { ClockCircleOutlined, PhoneFilled, EyeOutlined } from '@ant-design/icons'
import knifeandfork from '../../assets/images/home/knifeandfork.png'
import moment from 'moment';

const Flash_sale = (props) => {

    // const [showMobileNumber, setShowMobileNumber] = useState(false)
    const [showReverseCounter, setShowReverseCounter] = useState('')

    let shopArr = [];
    props.fridayFlashShops.forEach((business, i) => {
        let ratingCount = 0;
        business.ratingreviews.map((review) => {
            ratingCount = parseInt(ratingCount) + parseInt(review.rating);
        })

        const weekday = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        const d = new Date();
        let today = weekday[d.getDay()];

        let openAt = '';
        let shopDayStatus = '';
        let todayActiveTime = false;
        switch (today) {
            case 'sunday':
                var currentTime = moment().format("HH:mm:ss");
                var fromTime = moment(business.sunday[1], ["H:mma"]).format("HH:mm:ss");
                var toTime = moment(business.sunday[2], ["H:mma"]).format("HH:mm:ss");

                todayActiveTime = (currentTime > fromTime && currentTime < toTime) ? true : false;
                shopDayStatus = business.sunday[0];
                openAt = business.sunday[1];
                break;
            case 'monday':
                var currentTime = moment().format("HH:mm:ss");
                var fromTime = moment(business.monday[1], ["H:mma"]).format("HH:mm:ss");
                var toTime = moment(business.monday[2], ["H:mma"]).format("HH:mm:ss");

                todayActiveTime = (currentTime > fromTime && currentTime < toTime) ? true : false;
                shopDayStatus = business.monday[0];
                openAt = business.monday[1];
                break;
            case 'tuesday':
                var currentTime = moment().format("HH:mm:ss");
                var fromTime = moment(business.tuesday[1], ["H:mma"]).format("HH:mm:ss");
                var toTime = moment(business.tuesday[2], ["H:mma"]).format("HH:mm:ss");

                todayActiveTime = (currentTime > fromTime && currentTime < toTime) ? true : false;
                shopDayStatus = business.tuesday[0];
                openAt = business.tuesday[1];
                break;
            case 'wednesday':
                var currentTime = moment().format("HH:mm:ss");
                var fromTime = moment(business.wednesday[1], ["H:mma"]).format("HH:mm:ss");
                var toTime = moment(business.wednesday[2], ["H:mma"]).format("HH:mm:ss");

                todayActiveTime = (currentTime > fromTime && currentTime < toTime) ? true : false;
                shopDayStatus = business.wednesday[0];
                openAt = business.wednesday[1];
                break;
            case 'thursday':
                var currentTime = moment().format("HH:mm:ss");
                var fromTime = moment(business.thursday[1], ["H:mma"]).format("HH:mm:ss");
                var toTime = moment(business.thursday[2], ["H:mma"]).format("HH:mm:ss");

                todayActiveTime = (currentTime > fromTime && currentTime < toTime) ? true : false;
                shopDayStatus = business.thursday[0];
                openAt = business.thursday[1];
                break;
            case 'friday':
                var currentTime = moment().format("HH:mm:ss");
                var fromTime = moment(business.friday[1], ["H:mma"]).format("HH:mm:ss");
                var toTime = moment(business.friday[2], ["H:mma"]).format("HH:mm:ss");

                todayActiveTime = (currentTime > fromTime && currentTime < toTime) ? true : false;
                shopDayStatus = business.friday[0];
                openAt = business.friday[1];
                break;
            case 'saturday':
                var currentTime = moment().format("HH:mm:ss");
                var fromTime = moment(business.saturday[1], ["H:mma"]).format("HH:mm:ss");
                var toTime = moment(business.saturday[2], ["H:mma"]).format("HH:mm:ss");

                todayActiveTime = (currentTime > fromTime && currentTime < toTime) ? true : false;
                shopDayStatus = business.saturday[0];
                openAt = business.saturday[1];
                break;
        }

        shopArr[i] = {
            imgurl: business.company_logo,
            shopDayStatus: shopDayStatus,
            todayActiveTime: todayActiveTime,
            openAt: openAt,
            business_slug: business.business_slug,
            shopName: business.business_name,
            shortDescription: business.short_description,
            mobileNumber: business.mobile_number,
            Location: business.address,
            rating: ratingCount / business.ratingreviews.length,
            discount: "25%",
            flashCoupon: props.flashCoupons[i]
        }
    });

    const [data] = useState(shopArr)
    const [itemToShow, setItemToShow] = useState(4)
    const [expanded, setExpanded] = useState(false)

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        // Render a countdown
        return (
            <span>
                {days}d {hours}h {minutes}min
            </span>
        );
    }

    if (props.fridayFlashShops.length > 0) {
        var now = moment().format("DD/MM/YYYY HH:mm:ss");
        // var then = moment(shopArr[0].flashCoupon.end_date).format("DD/MM/YYYY HH:mm:ss");
        var then = "02/09/2022 14:20:30";

        var ms = moment(then, "DD/MM/YYYY HH:mm:ss").diff(moment(now, "DD/MM/YYYY HH:mm:ss"));
        var d = moment.duration(ms)._milliseconds;

        // setShowReverseCounter(d)
    }

    return (
        <div>
            <div className='flashSale-section flashSale_sectionContent py-4 '>
                <div className='container-fluid '>
                    <div className='row'>

                        <div className='col-xl-6 col-md-6 col-xs-12'>
                            <div className='d-flex justify-content-center justify-content-md-start mb-3'>
                                <h3 className='flashSale-heading fw-500'>Flash Sale</h3>
                                {/* <h3 className='flashSale-heading fw-500'>Flash Sales & Hot Deals </h3> */}
                            </div>
                        </div>
                        <div className='col-xl-6 col-md-6 col-xs-12'>
                            <div className='d-flex justify-content-center justify-content-md-end'>
                                {/* <div className='sale-end-timeBox'>
                                    <AccessTimeIcon style={{ height: '20px', width: '20px', marginTop: '-4px' }} />
                                    {
                                        showReverseCounter != '' &&
                                        <>Ends in &nbsp;<Countdown date={Date.now() + parseInt(showReverseCounter)} renderer={renderer} /></>
                                    }
                                    <>Ends in &nbsp;<Countdown date={Date.now() + parseInt(showReverseCounter)} renderer={renderer} /></>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className='row '>
                        {
                            data.slice(0, itemToShow).map((item, index) => {

                                return (
                                    <div key={index} className="col-xl-3 col-lg-3 col-md-3 pr-0"
                                        data-aos="fade-right"
                                        data-aos-delay="50"
                                        data-aos-duration={500 * (index)}
                                        data-aos-easing="ease-in-sine"
                                    >
                                        <div className="card flashSale-card">
                                            <div className="card-body p-0">
                                                <Link to={{ pathname: `/businesses/${item.business_slug}` }} className="mb-1">
                                                    <div className="text-center mb-2">
                                                        <img className="flashSale-img" src={item.imgurl} alt="Card " />
                                                    </div>
                                                    {/* <div className='col-xl-12 col-md-12 col-xs-12'>
                                                        <div className='d-flex justify-content-center justify-content-md-end'>
                                                            <small className='fw-500 text-danger d-flex '>
                                                                <ClockCircleOutlined className='p-1' style={{ fontSize: '13px' }} />
                                                                {item.shopDayStatus == 'open' && item.todayActiveTime == true ? 'Open' : `Closed - Opens at ${item.openAt}`}
                                                            </small>
                                                        </div>
                                                    </div> */}
                                                    <h4 className="mb-1 px-2 text-capitalize">{item.shopName}</h4>
                                                    <p className='discription px-2 text-justify discription'>{item.shortDescription}</p>
                                                    <div className="d-flex location-map px-1 py-2">
                                                        <p className="shop-location two-line-truncate">
                                                            <LocationOnSharpIcon className="shop-locationIcon" />{item.Location}
                                                        </p>
                                                    </div>
                                                    <p className=' px-2 mb-1 text-center border-danger disTextContaine'>
                                                        {/* 1/2 price on selected {item.shopName} */}
                                                        {/* Products upto {item.flashCoupon.discount_type == 'Percent' ? `${item.flashCoupon.discount}%` : `£${item.flashCoupon.discount}`}, off! */}
                                                        {/* {item.flashCoupon.discount_type == 'Percent' ? `${item.flashCoupon.discount}%` : `£${item.flashCoupon.discount}`} off Till 31st March */}
                                                        {item.flashCoupon.discount_type == 'Percent' ? `${item.flashCoupon.discount}%` : `£${item.flashCoupon.discount}`} off Till {moment(item.flashCoupon.end_date).format('Do MMM')}
                                                    </p>
                                                    <div className='d-flex ratignRangecontent px-2 py-1 justify-content-between'>
                                                        {
                                                            item.rating > 0 &&
                                                            <div className={item.rating <= 3 ? 'shop-ratingBox ratingBox-red' : 'shop-ratingBox'}>
                                                                <div className="shop-rating d-flex">
                                                                    <StarIcon />
                                                                    <p className="mb-0">{item.rating}</p>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className='d-flex align-items-center discount-offBox ml-auto'>
                                                            {/* <img src={Discount} alt="Card" />
                                                            <p className='mb-0 align-self-center ml-1'>{item.discount}</p>
                                                            <p className='mb-0 align-self-center ml-1'><span >off</span></p> */}
                                                            <small className='fw-500 text-danger d-flex align-items-baseline'>
                                                                <ClockCircleOutlined className='pr-1 ClockCircleOutlined' />
                                                                {item.shopDayStatus == 'open' && item.todayActiveTime == true ? 'Open' : `Closed-Opens at ${item.openAt}`}
                                                            </small>
                                                        </div>

                                                    </div>
                                                </Link>
                                                <div className='d-flex flashSale-card-viewmenu mt-1' >
                                                    <div className='col-xl-6 col-md-6 col-sm-6 p-0 border-end'>
                                                        <p className='text-dark border mb-0 p-1 d-flex align-items-center justify-content-center' style={{ fontSize: "13px" }}>
                                                            <PhoneFilled className='p-2' style={{ fontSize: '13px' }} />
                                                            <a href={`tel:${item.mobileNumber}`}
                                                                // onClick={() => setShowMobileNumber(true)}
                                                                className="text-dark">Call</a>
                                                            {/* { setShowMobileNumber && <p href="tel:+4733378901" className="show-number">{item.mobileNumber}</p>} */}

                                                        </p>
                                                    </div>
                                                    <div className='col-xl-6 col-md-6 col-sm-6 p-0'>
                                                        <p className='text-dark border mb-0 p-1 d-flex align-items-center justify-content-center' style={{ fontSize: "13px" }}>
                                                            <EyeOutlined className='p-2' style={{ fontSize: "13px", }} />
                                                            <Link to={{ pathname: `/businesses/${item.business_slug}` }} className="grind-view-detial text-dark " >View Detail</Link>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className='d-flex justify-content-center my-5'>
                    <Link className="btn btn-show-more btn-md btn-rounded"
                        to={'/businesses/filter/flash_sale'}>
                        <span>Show more</span>
                    </Link>
                </div>
                {/* {
                    data.length > 4 &&
                    <div className='d-flex justify-content-center my-5'>
                        <button className="btn btn-show-more btn-md btn-rounded"
                            onClick={() => {
                                setItemToShow(itemToShow === 4 ? 6 : 4)
                                setExpanded(itemToShow === 4 ? true : false)
                            }}>
                            {
                                expanded ?
                                    <span>Show less</span> :
                                    <span>Show more</span>
                            }</button>
                    </div>
                } */}
            </div>

        </div>
    )
}

export default Flash_sale