import React, { useState, useReducer } from 'react'
import '../styles/css/setUpAsBusiness/header.css';
import headerImage from '../../assets/images/home/Sign_up_as_business_illustration.svg';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import CloseCircle from '../../assets/images/modalPopup/close-circle.svg';
import { Token } from '../../Token';
import SignIn from '../auth/SignIn';
import ForgotPassword from '../auth/ForgotPassword';
import SignUp from '../auth/SignUp';

const Header = (props) => {

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            _isShowSingUpModel: false,
            _isShowSingInModel: false,
            _isShowForgotModel: false,
        }
    );

    return (
        <div className='business-setup-header' >
            <div className='d-flex justify-content-center ' style={{ width: '100%' }}>
                <div className='headerBox  b'>
                    <div className='headerText text-center'>It’s time to start selling online.</div>
                </div>
            </div>

            <div className='headerImage d-flex justify-content-center content-to-hide'>
                <img className='content-to-hide' src={headerImage} height='282px' width='544px' alt="header" />
            </div>

            <div className='d-flex justify-content-center buttonbox'>
                {
                    Token.isLoggedIn() ?
                        <Link to='/sell'><button id='buttonheader'>Set up your business on para</button></Link> :
                        <button
                            onClick={() => setState({ _isShowSingInModel: true })}
                            id='buttonheader'>Set up your business on para
                        </button>
                }
            </div>

            <div className='d-flex justify-content-center '>
                <div className='box-border col-xs-12 col-md-6 col-xl-10 row d-flex align-content-center' >
                    <div className='smallbox  col-xl-4 col-md-4 col-xs-12 '>
                        <p className='fraction text-center'>
                            1/3
                        </p>
                        <p className='description text-center'>
                            of Millenials prefer to interact with companies through digital platforms.
                        </p>
                    </div>
                    <div className='smallbox  col-xl-4 col-md-4 col-xs-12'>
                        <p className='fraction text-center'>
                            74%
                        </p>
                        <p className='description text-center'>
                            of retail companies are digitizing their business to sell online.
                        </p>
                    </div>
                    <div className='smallbox  col-xl-4 col-md-4 col-xs-12'>
                        <p className='fraction text-center'>
                            30%
                        </p>
                        <p className='description text-center'>
                            month on month increase in revenue for retail businesses that went digital in 2019
                        </p>
                    </div>
                </div>
            </div>

            {/* start auth */}
            {
                state._isShowSingInModel ?
                    <SignIn
                        state={state}
                        setState={setState}
                    /> :
                    null
            }

            {
                state._isShowSingUpModel &&
                <SignUp
                    state={state}
                    setState={setState}
                />
            }

            {
                state._isShowForgotModel &&
                <ForgotPassword
                    state={state}
                    setState={setState}
                />
            }

            {/* End auth */}
        </div>
    )
}

export default Header