import React, { useState, useEffect } from 'react'
import '../../../../src/assets/css/AdminPanel/adminPanel.css';
import { DashboardLayout } from '../Layout/Layout'
import { Tabs, Tab, Modal } from 'react-bootstrap';
import SampleProducts from '../../../assets/images/products/sample-image.svg';
import { Form, Input, Button, Spin, Switch } from 'antd'
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../../assets/images/modalPopup/close-circle.svg';
import AlertTriangle from '../../../assets/images/modalPopup/alert-triangle.svg';
import { CategoryAPI } from '../../../apis/admin/CategoryAPI';

const Categories = () => {
    const [form] = Form.useForm();
    const [loading, setLoader] = useState(false);
    const [is_form, setFormFor] = useState('add');
    const [category_id, setCategoryID] = useState();
    const [switchValue, setSwitchValue] = useState(false);
    const [categories, setCategories] = useState([]);
    const [requested_categories, setRequestedCategories] = useState([]);
    const [showModel, setModel] = useState(false);
    const [search, setSearch] = useState('')

    const handleCloseModel = () => setModel(false);
    const handleShowModel = () => setModel(true);

    useEffect(() => {
        getCategories(search);
    }, []);

    const getCategories = (search) => {
        setLoader(true);
        setTimeout(() => {
            CategoryAPI.getCategories(search)
                .then(res => {
                    setCategories(res.data.categories)
                    setRequestedCategories(res.data.requested_categories)
                    setLoader(false);
                }).catch(error => {
                    setLoader(false);
                })
        }, 1000)
    }

    const onSearch = async (search) => {
        getCategories(search)
        setSearch(search)
    }

    const onFinish = (value) => {

        setLoader(true)
        value.category_id = category_id;
        if (is_form == 'add') {
            CategoryAPI.saveCategory(value)
                .then(res => {
                    setLoader(false)
                    Swal.fire({
                        text: res.data.message,
                        imageUrl: CheckmarkCircle,
                        imageAlt: 'success image',
                        confirmButtonColor: '#00CA84'
                    });
                }).catch(function (error) {
                    setLoader(false)
                    Swal.fire({
                        text: error.response.data.message,
                        imageUrl: CloseCircle,
                        imageAlt: 'error image',
                        confirmButtonColor: '#00CA84'
                    });
                });
        } else {
            CategoryAPI.updateCategory(value)
                .then(res => {
                    Swal.fire({
                        title: 'Success!',
                        text: res.data.message,
                        imageUrl: CheckmarkCircle,
                        imageAlt: 'success image',
                        confirmButtonColor: '#00CA84'
                    });
                }).catch(error => {
                    Swal.fire({
                        text: error.response.data.message,
                        imageUrl: CloseCircle,
                        imageAlt: 'error image',
                        confirmButtonColor: '#00CA84'
                    });
                })
        }

        handleCloseModel();
        getCategories(search);
    }

    const getCategory = (category) => {
        setCategoryID(category.id)
        setFormFor('update')
        setSwitchValue(category.category_status)
        form.setFieldsValue({
            category_name: category.category_name,
            category_status: category.category_status
        });
        handleShowModel()
    }

    const onDelete = (category_id) => {
        setLoader(true);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            // icon: 'warning',
            imageUrl: AlertTriangle,
            showCancelButton: true,
            confirmButtonColor: '#00CA84',
            cancelButtonColor: '#FF5858',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                CategoryAPI.deleteCategory(category_id)
                    .then(res => {
                        getCategories(search);
                        Swal.fire({
                            title: res.data.message,
                            imageUrl: CheckmarkCircle,
                            imageAlt: 'success image',
                            confirmButtonColor: '#00CA84'
                        });
                        setLoader(false);
                    }).catch(error => {

                        Swal.fire({
                            title: error.response.data.message,
                            imageUrl: CloseCircle,
                            imageAlt: 'error image',
                            confirmButtonColor: '#00CA84'
                        });
                    })

            }
        })
    }

    const onApprove = (category_id, user_id) => {
        setLoader(true);

        CategoryAPI.approveCategory(category_id, user_id)
            .then(res => {
                getCategories(search);
                Swal.fire({
                    title: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                });
                setLoader(false);
            }).catch(error => {

                Swal.fire({
                    title: error.response.data.message,
                    imageUrl: CloseCircle,
                    imageAlt: 'error image',
                    confirmButtonColor: '#00CA84'
                });
            })


    }

    return (
        <DashboardLayout page='categories'>
            <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-content">
                    <div className="paraTabs">
                        <Tabs defaultActiveKey="CategoryDashboard" transition={false} id="categoryTabs">
                            <Tab eventKey="CategoryDashboard" title="Category Dashboard">
                                <div className="d-flex justify-content-end">
                                    <Button className='btn btnPara-primary' onClick={() => {
                                        form.setFieldsValue({
                                            category_name: null,
                                            category_status: null
                                        })
                                        handleShowModel()
                                        setFormFor('add')
                                    }}>
                                        Add Category
                                    </Button>
                                </div>

                                <div className="table-responsive paraRounded-table mt-4">
                                    <div className="d-flex mb-3">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control product-search"
                                                placeholder="Search category"
                                                value={search}
                                                onChange={(e) => onSearch(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="fw-500">CATEGORY NAME</th>
                                                <th scope="col" className="fw-500">CATEGORY STATUS</th>
                                                <th scope="col" className="fw-500 text-right pr-3">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                categories.map(category => {
                                                    return (
                                                        <tr>
                                                            <td className="align-middle">
                                                                <p className="mb-0">{category.category_name}</p>
                                                            </td>
                                                            <td className="align-middle"><p className="mb-0">{category.category_status ? 'Activated' : 'Deactivate'}</p></td>
                                                            <td className="align-middle">
                                                                <div className="d-flex justify-content-end">
                                                                    <button htmlType="button" class="btn btn-outline-primary mr-3"
                                                                        onClick={() => getCategory(category)}>Edit</button>
                                                                    <button htmlType="button" class="btn btn-outline-red"
                                                                        onClick={() => onDelete(category.id)}>Delete</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Tab>
                            <Tab eventKey="CategoryRequests" title="Category Requests">
                                <div className="table-responsive paraRounded-table mt-4">
                                    <div className="d-flex mb-3">
                                        <div className="input-group">
                                            <input
                                                type="text"
                                                className="form-control product-search"
                                                placeholder="Search category"
                                                value={search}
                                                onChange={(e) => onSearch(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="fw-500">CATEGORY NAME</th>
                                                <th scope="col" className="fw-500">CATEGORY APPROVED</th>
                                                <th scope="col" className="fw-500 text-right pr-3">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                requested_categories.map(category => {
                                                    return (
                                                        <tr>
                                                            <td className="align-middle">
                                                                <p className="mb-0">{category.category_name}</p>
                                                            </td>
                                                            <td className="align-middle">
                                                                <p className="mb-0">{category.is_approved_category ? 'Yes' : 'No'}</p>
                                                            </td>
                                                            <td className="align-middle">
                                                                <div className="d-flex justify-content-end">
                                                                    {/* <button htmlType="button" class="btn btn-outline-primary mr-3"
                                                                        onClick={() => getCategory(category)}>Edit</button> */}
                                                                    {
                                                                        !category.is_approved_category &&
                                                                        <button htmlType="button" class="btn btn-outline-red"
                                                                            onClick={() => onApprove(category.id, category.user)}>Approve</button>
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {/* <tr>
                                                <td className="align-middle">
                                                    <p className="mb-0">Best sellers 2</p>
                                                </td>
                                                <td className="align-middle">
                                                    <div className="d-flex">
                                                        <img src={SampleProducts} alt="" className="mr-2" width="32" height="32" />
                                                        <p className="mb-0 pl-1 align-self-center ">Name of business 2</p>
                                                    </div>
                                                </td>
                                                <td className="align-middle"><p className="mb-0">Neveah Simmons 2</p></td>
                                                <td className="align-middle">
                                                    <div className="d-flex justify-content-end">
                                                        <button type="button" class="btn btn-borderless-red mr-3">Reject</button>
                                                        <button type="button" class="btn btn-borderless-green">Create</button>
                                                    </div>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    {/* ============================ */}
                    <Modal centered show={showModel} onHide={handleCloseModel}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add category</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <Form
                                    form={form}
                                    onFinish={onFinish}
                                >
                                    <div className="form-group">
                                        <label for="exampleInputEmail1">Enter Name</label>
                                        <Form.Item
                                            className="form-item"
                                            name='category_name'
                                            rules={[
                                                { required: true, message: 'Please input your category name.' }
                                            ]}
                                        >
                                            <Input className="text-capitalize" placeholder="Baked goods" />
                                        </Form.Item>
                                    </div>
                                    <div className="form-group">
                                        <Form.Item
                                            className="form-item"
                                            name='category_status'
                                        >
                                            <Switch checked={switchValue} onChange={(v) => setSwitchValue(v)} />
                                        </Form.Item>
                                    </div>
                                    <div className="form-group d-flex justify-content-end">
                                        <Form.Item>
                                            <Button className='btn- btnPara-primary' htmlType='submit'>
                                                {is_form == 'add' ? 'Save' : 'Update'} category
                                            </Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* ============================ */}
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Categories
