import React, { useState, useReducer } from 'react'
import '../../../../assets/css/UserPanel/userPanel.css';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { TextField, FormControl, Checkbox } from '@material-ui/core';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../../assets/images/modalPopup/checkmark-circle.svg';
import { Token } from '../../../../Token';
import { useParams } from 'react-router-dom';
import { BusinessAPI } from '../../../../apis/user/BusinessAPI';
import { AutoComplete, Select } from 'antd';
import { useEffect } from 'react';
import { RoleAPI } from '../../../../apis/user/RoleAPI';

const { Option } = AutoComplete;
const AntdOption = Select.Option;

const ADD_USER_ACCESS = (props) => {
    const params = useParams()
    const business_slug = params.business_id;
    const [loading, setLoading] = useState(false);
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            email: "",
            emailError: "",
            emailErrorStatus: false,
            emailValid: false,
            is_enable_email: false,
            serachEmailData: [],
            role_id: null,
            roles: []
        }
    );

    useEffect(() => {
        setLoading(true)
        const data = {
            business_slug
        }

        RoleAPI.fetchDropdownRoles(data)
            .then((res) => {
                setState({ roles: res.data.data })
                setLoading(false)
            }).catch(function (error) {
                setLoading(false)
                console.log(error);
            });
    }, [])

    const submitform = () => {

        var data = {
            from_user_id: Token.getUserID(),
            to_user_email: state.email,
            business_slug: business_slug,
            role_id: state.role_id,
            is_enable_email: state.is_enable_email
        };

        BusinessAPI.shareBusiness(data)
            .then(res => {
                if (res.data.status) {
                    setState({
                        email: "",
                        emailError: "",
                        // emailErrorStatus: false,
                        emailValid: false
                    })
                }

                Swal.fire({
                    title: 'User Add!',
                    text: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                })
            })
    }

    const handleChange = (value) => {
        setState({ email: '', emailValid: false })
        BusinessAPI.onSearchEmail(value)
            .then(res => {
                setState({ serachEmailData: res.data.users })
            });
    }

    const onSelectSearchEmail = (email) => {
        setState({ email: email, emailValid: true })
    }

    return (
        <div>
            <div className="blackBox-heading d-flex justify-content-between">
                <p className="align-self-center mb-0">You are viewing</p>
                <button className="btn py-0" onClick={() => props.changepage('USERS')}> <ArrowBackIcon />User List</button>
            </div>
            <h4>Add User</h4>
            <div class="card" style={{ maxWidth: '600px', margin: 'auto' }}>
                <div class="card-body">
                    <div className="form-group add-user-access">
                        <label htmlfor="exampleFormControlInput1">Select Role</label>

                        <Select showSearch style={{ width: '100%' }}
                            onChange={(val) => setState({ role_id: val == "-1" ? null : val })}
                            defaultValue="-1"
                            optionFilterProp="children"
                            filterOption={(input, option) => option.props.children.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 || option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0}
                            className="select-content"
                        >
                            <Option value="-1">Select Role</Option>
                            {
                                state.roles.map(role => {
                                    return (
                                        <Option value={role.id}>{role.role_name}</Option>
                                    )
                                })
                            }

                        </Select>
                    </div>
                    <div className="form-group">
                        <label htmlfor="exampleFormControlInput1">User Email ID</label>

                        <FormControl fullWidth>
                            <AutoComplete
                                onSearch={handleChange}
                                placeholder="Enter User Email ID"
                                onSelect={(val, option) => onSelectSearchEmail(val)}
                            >
                                {
                                    state.serachEmailData.map((user, i) => {
                                        return (
                                            <Option key={i} value={user.email}>
                                                {user.email}
                                            </Option>
                                        )
                                    })
                                }
                            </AutoComplete>
                        </FormControl>
                    </div>

                    <div className="form-group">
                        <Checkbox onChange={(e) => setState({ is_enable_email: e.target.checked })} /> Enable Order Email
                    </div>

                    <button
                        disabled={!state.emailValid || !state.role_id}
                        className="btn btnPara-primary"
                        onClick={() => submitform()}
                    >Add User</button>
                </div>
            </div>
        </div>
    )
}


export default ADD_USER_ACCESS
