
import React, { useState } from "react";
import BodyWrapper from "./BodyWrapper";
import Aside from "./prosidebar";
import { IntlProvider } from 'react-intl';
import { FaBars } from 'react-icons/fa';

export const DashboardLayout = (props) => {
    const [rtl, setRtl] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [image, setImage] = useState(true);
    const [toggled, setToggled] = useState(false);
    const handleToggleSidebar = () => {
        setToggled(!toggled);
    };
    return (

        <BodyWrapper>
            <IntlProvider>
                <div className='Sidebar-MainContain'>
                    <div className='' style={{ marginTop: '75px' }}>
                        <Aside
                            image={image}
                            collapsed={collapsed}
                            rtl={rtl}
                            toggled={toggled}
                            handleToggleSidebar={handleToggleSidebar}
                            activelink={props.page}
                        />
                        <div className="pt-3">
                            <div className="btn-toggle" onClick={() => handleToggleSidebar()} >
                                <FaBars />
                            </div>
                        </div>
                    </div>
                    
                    {props.children}

                    {/* <div className="w-100 sidebar-right-content-spacing" >
                        {props.children}
                    </div> */}

                </div>
            </IntlProvider>
        </BodyWrapper>
    );
};
