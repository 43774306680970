import { Fragment, useEffect, useReducer, useState } from 'react';
import '../Components/styles/css/SellOnPara/sellonpara.css';

import Typography from '@material-ui/core/Typography';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import { Spin } from 'antd';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';
import { BusinessAPI } from '../apis/user/BusinessAPI';
import { CategoryAPI } from '../apis/user/CategoryAPI';
import CheckmarkCircle from '../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../assets/images/modalPopup/close-circle.svg';
import BusinessAddress from '../Components/Business/BusinessAddress';
import KnowBusiness from '../Components/Business/KnowBusiness';
import SocialMediaConnections from '../Components/Business/SocialMediaConnections';
import config from '../config';
import lang from '../language/en.json';

const postcodes = require('node-postcodes.io');

const SellOnPara = () => {

    let history = useHistory();
    const [loading, setLoader] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            company_logo: "",
            company_logo_error_msg: "",
            business_name: "",
            business_name_error_msg: "",
            business_category: "Food",
            business_category_error_msg: "",
            business_description: "",
            business_description_error_msg: "",

            facebook_profile_link: "",
            instagram_profile_link: "",
            twitter_profile_link: "",
            google_profile_link: "",

            address: "",
            address_error_msg: "",
            address_lat: config.DEFAULT_ADDRESS_LAT,
            address_long: config.DEFAULT_ADDRESS_LONG,
            postal_code: "",
            postal_error_msg: "",
            business_categories: []
        }
    )

    useEffect(() => {
        CategoryAPI.getBusinessCategories()
            .then(res => {
                setState({ business_categories: res.data.categories });
            })
    }, [])

    const steps = getSteps();
    function getSteps() {
        return ['Business', 'Social Media', 'Address'];
    }

    const handleNext = async () => {

        switch (activeStep) {
            case 0:
                // step 1
                if (state.company_logo == '') {
                    setState({ company_logo_error_msg: lang.company_logo_require_error_msg })
                    return false;
                } else if (state.business_name == '') {
                    setState({ business_name_error_msg: lang.business_name_require_error_msg })
                    return false;
                } else if (state.business_description == '') {
                    setState({ business_description_error_msg: lang.business_description_require_error_msg })
                    return false;
                }
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            case 1:
                // step 2
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                break;
            case 2:
                // step 3
                if (state.address == '') {
                    setState({ address_error_msg: lang.address_require_error_msg })
                    return false;
                } else if (state.postal_code == '') {
                    setState({ postal_error_msg: lang.postal_code_require_error_msg })
                    return false;
                }

                let result = await postcodes.validate(state.postal_code);
                if (result.result) {
                    setState({ postal_error_msg: '' });
                } else {
                    setState({ postal_error_msg: lang.postal_code_validate_msg });
                    return false;
                }
                // setActiveStep((prevActiveStep) => prevActiveStep + 1);
                saveBusiness('Yes')
                break;
            default:
                return 'Unknown stepIndex';
        }
    };

    const handleBack = () => {
        if (activeStep === 0) {
            history.go(-1)
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);

        }

    };

    const saveBusiness = async (all_step_done) => {
        let data = {
            company_logo: state.company_logo,
            business_name: state.business_name,
            business_category: state.business_category,
            business_description: state.business_description,
            facebook_profile_link: state.facebook_profile_link,
            instagram_profile_link: state.instagram_profile_link,
            twitter_profile_link: state.twitter_profile_link,
            google_profile_link: state.google_profile_link,
            address: state.address,
            address_lat: state.address_lat,
            address_long: state.address_long,
            postal_code: state.postal_code,
            all_step_done: all_step_done
        }

        setLoader(true)
        await BusinessAPI.saveBusiness(data)
            .then(res => {
                setLoader(false)
                Swal.fire({
                    title: `${all_step_done=='Yes'?'Congratulations!':"Your Business is saved as Draft."}`,
                    text: `${all_step_done=="Yes"?'Your Business is Registered Successfully!':"Please complete Setup to start Selling!"}`,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                });
                history.push('/seller-profile/myBusinesses');
            }).catch(function (error) {
                setLoader(false)
                Swal.fire({
                    title: error.response.data.message,
                    imageUrl: CloseCircle,
                    imageAlt: 'error image',
                    confirmButtonColor: '#00CA84'
                });
            });
    };

    return (
        <Fragment>
            <div className='container-fluid bgDark'>
                <div className='d-flex justify-content-center'>
                    <div className='w-100' style={{ marginTop: '120px', marginBottom: '120px' }}>
                        <Spin spinning={loading} delay={500}>
                            <div className='stepper-progressBar-box'>
                                <ProgressBar now={(activeStep / steps.length) * 100} className="stepper-progressBar" />
                                <div className="px-4 py-3">
                                    <Typography>
                                        {(() => {
                                            switch (activeStep) {
                                                case 0:
                                                    return <KnowBusiness state={state} setState={setState} />;
                                                case 1:
                                                    return <SocialMediaConnections state={state} setState={setState} />;
                                                case 2:
                                                    return <BusinessAddress state={state} setState={setState} />;
                                                default:
                                                    return 'Unknown stepIndex';
                                            }
                                        })()}
                                    </Typography>

                                    <div className="my-2 py-2 d-flex justify-content-between">
                                        <div>
                                            {
                                                (activeStep !== 0 && activeStep != steps.length) &&
                                                <button
                                                    className="btn btn-borderless-black"
                                                    onClick={() => saveBusiness('No')}>
                                                    Save For Later
                                                </button>
                                            }
                                        </div>
                                        <div>
                                            <button
                                                // disabled={activeStep === 0}

                                                onClick={handleBack}
                                                className="btn btn-outline-secondary mr-3"
                                            >
                                                Back
                                            </button>
                                            <button variant="contained" className="btn btnPara-primary btnWithSvg"
                                                onClick={handleNext}>
                                                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                                <ArrowForwardRoundedIcon style={{ fontSize: '20px', top: '8px' }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Spin>
                    </div>
                </div>
            </div>
        </Fragment>
    )

}

export default SellOnPara