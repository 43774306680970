import React from 'react'

export default function PaymentMethods() {
    return (
        <div className="profile-info">
            <div className="media my-3 bg-white p-3 rounded shadow-sm">
                <div className="media-body mb-0 small">
                    <div className="my-3">
                        <h4 className='text-center'>Payment methods not added yet</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}
