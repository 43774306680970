import React, { useEffect, useState } from 'react'
import '../../../../assets/css/UserPanel/userPanel.css';
import noProductsImg from '../../../../assets/images/products/no-products-img.svg';
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";
import { DashboardLayout } from '../../Sidebar wrapper/Layout';
import { useParams, Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { ProductAPI } from '../../../../apis/user/ProductAPI';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../../assets/images/modalPopup/checkmark-circle.svg';
import AlertTriangle from '../../../../assets/images/modalPopup/alert-triangle.svg';
import CloseCircle from '../../../../assets/images/modalPopup/close-circle.svg';
import { Pagination } from 'antd';

const ProductsHome = () => {
    const params = useParams();
    const business_slug = params.business_id;
    const [loading, setLoader] = useState(false);
    const [products, setProducts] = React.useState([]);
    const [TotalData, setTotalData] = useState(0)
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [productsPermission, setProductsPermission] = React.useState([]);

    const HandlePagination = (PageNumber) => {
        ProductAPI.getProductsPagination(PageNumber, business_slug)
            .then((res) => {
                setTotalData(res?.data?.count)
                if (res.data?.products) {
                    setProducts(res?.data?.products)
                }
            })
    }
    
    useEffect(() => {
        setLoader(true);
        ProductAPI.getProducts(business_slug)
            .then(res => {
                setIsAdmin(res.data.isAdmin)
                const per = res.data.permissions?.productsPermission || [];
                setProductsPermission(per)
                setProducts(res.data.products)
                setLoader(false);
            }).catch(error => {
                setLoader(false);
            })
    }, []);

    const onDelete = (product_id) => {
        setLoader(true);
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            // icon: 'warning',
            imageUrl: AlertTriangle,
            showCancelButton: true,
            confirmButtonColor: '#00CA84',
            cancelButtonColor: '#FF5858',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {

                ProductAPI.deleteProduct(product_id)
                    .then(res => {
                        Swal.fire({
                            title: res.data.message,
                            imageUrl: CheckmarkCircle,
                            imageAlt: 'success image',
                            confirmButtonColor: '#00CA84'
                        });
                        setLoader(false);
                    }).catch(error => {

                        Swal.fire({
                            title: error.response.data.message,
                            imageUrl: CloseCircle,
                            imageAlt: 'error image',
                            confirmButtonColor: '#00CA84'
                        });
                    })
                ProductAPI.getProducts(business_slug)
                    .then(res => {
                        setProducts(res.data.products)
                        setLoader(false);
                    }).catch(error => {
                        setLoader(false);
                    })
            }
        })
    }

    return (

        <DashboardLayout page='products'>
            <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-content">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-md-8">
                                <h2>My Products</h2>
                                <p className="greyText">Here is a list of all the products available in your store. You can edit, add or delete products from here.</p>
                            </div>
                            <div className="col-md-4">
                                <div className="text-center text-md-right">
                                    {
                                        (productsPermission.includes("Create") || isAdmin) &&
                                        <Link className="btn btnPara-primary" to={`/seller/business/${business_slug}/my-shop/add-product`}>Add Product</Link>
                                    }

                                </div>
                            </div>
                        </div>

                        {/* <div className="d-flex product-search-box">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <div className="input-group-text bg-white"><SearchIcon style={{ color: '#999999' }} /></div>
                                </div>
                                <input type="text" className="form-control product-search" id="inlineFormInputGroup" placeholder="Search products" />
                            </div>
                            <select id="inputState" className="form-control">
                                <option selected>selected</option>
                                <option>...</option>
                            </select>
                        </div>

                        <div className="product-filter-box text-center">
                            <button className="btn"><TuneIcon /> filter</button>
                        </div> */}

                        {
                            products.length > 0 ?
                                <div className="table-responsive paraRounded-table my-5">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">Price</th>
                                                <th scope="col">Category</th>
                                                <th scope="col">Addons</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Created By</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                products.map(product => {
                                                    return (
                                                        <tr key={product.id}>
                                                            <td>
                                                                <div className="media">
                                                                    <img src={product.product_photos[0]} className="bd-placeholder-img mr-3 rounded-lg" width="56" height="56" />
                                                                    <div className="media-body align-self-center mb-0">
                                                                        <div className="d-flex justify-content-between align-items-center w-100">
                                                                            <div className="#">
                                                                                <p className="mb-0">{product.product_name}</p>
                                                                                <p className="mb-0 greyText">SKU: <span className='greenText'>{product.sku}</span></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="align-middle"><p className="mb-0 greyText">£{product.price}</p></td>
                                                            <td className="align-middle"><p className="mb-0">{product.category?.category_name}</p></td>
                                                            <td className="align-middle"><p className="mb-0">{product.product_mark_addon ? 'Yes' : 'No'}</p></td>
                                                            <td className="align-middle"><p className={product.out_of_stock ? 'mb-0 redText' : 'mb-0 greenText'}>{product.out_of_stock ? 'Out Of Stock' : 'Active'}</p></td>
                                                            <td className="align-middle"><p className="mb-0">{product.user.first_name + " " + product.user.last_name}</p></td>
                                                            <td className="align-middle text-right">
                                                                {
                                                                    (productsPermission.includes("Edit") || isAdmin) &&
                                                                    <Link
                                                                        className="btn btn-outline-dark"
                                                                        to={`/seller/business/${business_slug}/my-shop/edit-product/${product.id}`}>
                                                                        Edit
                                                                    </Link>
                                                                }

                                                                {
                                                                    (productsPermission.includes("Delete") || isAdmin) &&
                                                                    <button className="btn btn-outline-dark" onClick={() => onDelete(product.id)} >Delete</button>
                                                                }
                                                            </td>
                                                        </tr>

                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div> :
                                <div className="text-center my-5">
                                    <div class="form-group">
                                        <img className='content-to-hide ' src={noProductsImg} alt="No Products" />
                                    </div>
                                    <h2>No Products yet</h2>
                                </div>
                        }

                    </div>
                    <div className="d-flex justify-content-end mt-5">
                        <Pagination defaultCurrent={1} onChange={HandlePagination} total={TotalData} />
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}


export default ProductsHome