import React, { useState } from 'react'
import image1 from '../../assets/images/home/paraForEveryone_img1.svg'
import image2 from '../../assets/images/home/paraForEveryone_img2.svg'
import { Link } from 'react-router-dom';
import '../../Components/styles/css/setUpAsBusiness/paraForEveryone.css'
import Swal from 'sweetalert2';
import CloseCircle from '../../assets/images/modalPopup/close-circle.svg';
import { Token } from '../../Token';
import SignIn from '../auth/SignIn';

const ParaForEveryone = (props) => {
    const [state, setState] = useState({
        _isShowSingInModel: false,

    });


    return (
        <div className=' '>
            <div className='para-for-everyone text-justify'>
                <div className='d-flex align-items-center'>
                    <div className='col-xs-12 col-md-8 col-xl-6 ml-xl-5 ml-md-5 ml-xs-2  '>
                        <div className='row feature1_topText  d-flex justify-content-center align-items-center'>
                            <p>If you are a home cook, baker, brick & mortar shop or a local service,
                                Para is a place for you to set up your digital shop and transact for free!</p>
                        </div>
                        <div className=' feature1_bottomText mt-3'>
                            <p> Para aims at breaking barriers faced by small local businesses
                                looking to grow using the internet as a marketing and sales medium.</p>
                        </div>
                    </div>
                    <div className='content-to-hide col-xl-5 '>
                        <div className=' d-flex justify-content-center'>
                            <img src={image1} width='496px' height='472px' alt="" />
                        </div>
                    </div>
                </div>
                <div className=' d-flex align-items-center'>
                    <div className=' content-to-hide col-xl-5 '>
                        <div className='  d-flex justify-content-center'>
                            <img src={image2} width='435px' height='268px' alt="" />
                        </div>
                    </div>
                    <div className='col-xl-6 col-md-8 col-xs-12  ml-xl-5 ml-md-5 ml-xs-2'>
                        <div className='row feature1_topText d-flex justify-content-center '>
                            <p>Para allows you to <span className='blueText'>amplify your digital presence </span>and attract new
                                customers with promotional and marketing features to boost your business.</p>
                        </div>
                        <div className=' feature1_bottomText mt-3 d-flex justify-content-center'>
                            With social fetaures and integration to poular social media platforms,
                            Para allows you to amplify your marketing from SEO to word of mouth.
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center buttonbox'>
                    {
                        Token.isLoggedIn() ?
                            <Link to='/sell'><button id='button'>Set up your business on para</button></Link> :
                            <button
                                onClick={() => setState({ _isShowSingInModel: true })}

                                id='button'>Set up your business on para
                            </button>
                    }
                    {
                        state._isShowSingInModel ?
                            <SignIn
                                state={state}
                                setState={setState}
                            /> :
                            null
                    }

                </div>
            </div>
        </div>
    )
}

export default ParaForEveryone