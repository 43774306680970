import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const RoleAPI = {
    fetchRoles: async (data) => {
        const token = Token.getToken();

        // var data = { "user_id": Token.getUserID() };
        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/user/get-roles?business_slug=${data.business_slug}&search=${data.search}&page=${data.page}&limit=${data.limit}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    fetchDropdownRoles: async (data) => {
        const token = Token.getToken();

        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/user/get-dropdown-roles?business_slug=${data.business_slug}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    fetchBusinessPermissions: async (business_slug) => {
        const token = Token.getToken();
        var data = {
            user_id: Token.getUserID(),
            business_slug: business_slug
        };

        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/get-business-permissions`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    saveRole: async (data) => {
        const token = Token.getToken();

        data.user_id = Token.getUserID();
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/save-role`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    getRole: async (role_id) => {
        const token = Token.getToken();
        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/user/get-role/${role_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    },
    updateRole: async (data) => {
        const token = Token.getToken();

        data.user_id = Token.getUserID();
        return axios({
            method: 'put',
            url: `${config.API_BASE_URL}/api/user/update-role`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    deleteRole: async (role_id) => {
        const token = Token.getToken();
        return axios({
            method: 'delete',
            url: `${config.API_BASE_URL}/api/user/delete-role/${role_id}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });
    },
    changeBusinessUserRole: async (data) => {
        const token = Token.getToken();
        data.from_user_id = Token.getUserID();
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/change-business-user-role`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
}