import React, { useState, useEffect, useReducer } from 'react';
// import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarContent } from 'react-pro-sidebar';

import './sidebar.css'
import LocalOfferRoundedIcon from '@material-ui/icons/LocalOfferRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import Dashboard from '@material-ui/icons/Dashboard';
import MessageIcon from '@material-ui/icons/Message';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import { BusinessAPI } from '../../../apis/user/BusinessAPI';
import { RoleAPI } from '../../../apis/user/RoleAPI';


const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar, activelink }) => {
  let history = useHistory();
  const params = useParams();
  const [isAdmin, setIsAdmin] = useState(false);
  const [productsPermission, setProductsPermission] = useState(false);
  const [categoriesPermission, setCategoriesPermission] = useState(false);
  const [manageOrdersPermission, setManageOrdersPermission] = useState(false);
  const [customersPermission, setCustomersPermission] = useState(false);
  const [promotionsPermission, setPromotionsPermission] = useState(false);
  const [userSettingsPermission, setUserSettingsPermission] = useState(false);
  const [businessSettingsPermission, setBusinessSettingsPermission] = useState(false);
  const [deliverySettingsPermission, setDeliverySettingsPermission] = useState(false);
  const [documentsPermission, setDocumentsPermission] = useState(false);
  const [messagesPermission, setMessagesPermission] = useState(false);

  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      business_name: '',
      business_category: '',
      business_slug: params.business_id,
      dashboard: false,
      messages: false,
      products: false,
      categories: false,
      manage_orders: false,
      customers: false,
      promotions: false,
      ads: false,
      analytics: false,
      user_settings: false,
      shop_settings: false,
      delivery_settings: false,
      businesses: []
    }
  );

  useEffect(() => {
    RoleAPI.fetchBusinessPermissions(state.business_slug)
      .then(res => {
        setIsAdmin(res.data.isAdmin)
        if (res.data.data?.role) {
          const permission = res.data.data.role.permission_modules[0];
          setProductsPermission(permission.productsPermission.length > 0 ? true : false)
          setCategoriesPermission(permission.categoriesPermission.length > 0 ? true : false)
          setCustomersPermission(permission.customersPermission.length > 0 ? true : false)
          setManageOrdersPermission(permission.manageOrdersPermission.length > 0 ? true : false)
          setUserSettingsPermission(permission.userSettingsPermission.length > 0 ? true : false)
          setBusinessSettingsPermission(permission.businessSettingsPermission.length > 0 ? true : false)
          setDeliverySettingsPermission(permission.deliverySettingsPermission.length > 0 ? true : false)
          setDocumentsPermission(permission.documentsPermission.length > 0 ? true : false)
          setPromotionsPermission(permission.promotionsPermission.length > 0 ? true : false)
          setMessagesPermission(permission.messagesPermission.length > 0 ? true : false)
        }
      }).catch(error => {
        console.log(error)
      })
  }, [])

  useEffect(() => {

    switch (activelink) {
      case 'dashboard':
        setState({
          dashboard: true,
          messages: false,
          products: false,
          categories: false,
          manage_orders: false,
          customers: false,
          promotions: false,
          ads: false,
          analytics: false,
          user_settings: false,
          shop_settings: false,
          delivery_settings: false,

        })
        break;
      case 'products':
        setState({
          dashboard: false,
          messages: false,
          products: true,
          categories: false,
          manage_orders: false,
          customers: false,
          promotions: false,
          ads: false,
          analytics: false,
          user_settings: false,
          shop_settings: false,
          delivery_settings: false,

        })
        break;
      case 'categories':
        setState({
          dashboard: false,
          messages: false,
          products: false,
          categories: true,
          manage_orders: false,
          customers: false,
          promotions: false,
          ads: false,
          analytics: false,
          user_settings: false,
          shop_settings: false,
          delivery_settings: false,

        })
        break;
      case 'manage_orders':
        setState({
          dashboard: false,
          messages: false,
          products: false,
          categories: false,
          manage_orders: true,
          customers: false,
          promotions: false,
          ads: false,
          analytics: false,
          user_settings: false,
          shop_settings: false,
          delivery_settings: false,

        })
        break;
      case 'customers':
        setState({
          dashboard: false,
          messages: false,
          products: false,
          categories: false,
          manage_orders: false,
          customers: true,
          promotions: false,
          ads: false,
          analytics: false,
          user_settings: false,
          shop_settings: false,
          delivery_settings: false,

        })
        break;
      case 'promotions':
        setState({
          dashboard: false,
          messages: false,
          products: false,
          categories: false,
          manage_orders: false,
          customers: false,
          promotions: true,
          ads: false,
          analytics: false,
          user_settings: false,
          shop_settings: false,
          delivery_settings: false,

        })
        break;
      case 'ads':
        setState({
          dashboard: false,
          messages: false,
          products: false,
          categories: false,
          manage_orders: false,
          customers: false,
          promotions: false,
          ads: true,
          analytics: false,
          user_settings: false,
          shop_settings: false,
          delivery_settings: false,

        })
        break;
      case 'analytics':
        setState({
          dashboard: false,
          messages: false,
          products: false,
          categories: false,
          manage_orders: false,
          customers: false,
          promotions: false,
          ads: false,
          analytics: true,
          user_settings: false,
          shop_settings: false,
          delivery_settings: false,

        })
        break;
      case 'user_settings':
        setState({
          dashboard: false,
          messages: false,
          products: false,
          categories: false,
          manage_orders: false,
          customers: false,
          promotions: false,
          ads: false,
          analytics: false,
          user_settings: true,
          shop_settings: false,
          delivery_settings: false,

        })
        break;
      case 'shop_settings':
        setState({
          dashboard: false,
          messages: false,
          products: false,
          categories: false,
          manage_orders: false,
          customers: false,
          promotions: false,
          ads: false,
          analytics: false,
          user_settings: false,
          shop_settings: true,
          delivery_settings: false,

        })
        break;
      case 'delivery_settings':
        setState({
          dashboard: false,
          messages: false,
          products: false,
          categories: false,
          manage_orders: false,
          customers: false,
          promotions: false,
          ads: false,
          analytics: false,
          user_settings: false,
          shop_settings: false,
          delivery_settings: true,

        })
        break;
      default:
        break;
    }

    BusinessAPI.fetchBusiness(state.business_slug)
      .then(function (response) {
        setState({
          business_name: response.data.business.business_name,
          business_category: response.data.business.category
        })
      }).catch(function (error) {
        console.log(error);
      });

    BusinessAPI.fetchBusinesses()
      .then(function (response) {
        var data = response.data
        if (data.businesses.length > 0) {
          setState({ businesses: data.businesses })
        }
      }).catch(function (error) {
        console.log(error);
      });
  }, [activelink])

  const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
  const [openKeys, setOpenKeys] = React.useState(['sub1']);

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <ProSidebar
      image={image ? false : false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="lg"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div className="sidebar-topHeader">
          <p>Active</p>
          <h6 data-toggle="dropdown" style={{ cursor: 'pointer' }}>
            {`${state.business_name}  ▼`}
          </h6>
          {/* </button> */}
          <div className="dropdown-menu">
            {state.businesses.map((business, index) => {
              return <a key={index} className="dropdown-item"
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  history.push(`/seller/business/${business.business_slug}`)
                  window.location.reload(false);
                }}>
                {business.business_name}
              </a>
            })}
          </div>

        </div>
      </SidebarHeader >

      <SidebarContent>
        <Menu iconShape="circle" mode="inline" openKeys={openKeys} onOpenChange={onOpenChange}>
          <MenuItem icon={<Dashboard />} active={state.dashboard}
            onClick={() => history.push(`/seller/business/${state.business_slug}`)} >
            Dashboard
          </MenuItem>
          {
            (productsPermission || categoriesPermission || manageOrdersPermission || customersPermission || isAdmin) &&
            <SubMenu title="My Business"
              // defaultOpen={state.products || state.categories || state.manage_orders || state.customers}
              icon={<ShoppingCart />} >
              {
                (productsPermission || isAdmin) &&
                <MenuItem active={state.products} onClick={() => history.push(`/seller/business/${state.business_slug}/my-shop/products`)}>Products</MenuItem>
              }
              {
                (categoriesPermission || isAdmin) &&
                <MenuItem active={state.categories} onClick={() => history.push(`/seller/business/${state.business_slug}/my-shop/categories`)}>Categories</MenuItem>
              }
              {
                (manageOrdersPermission || isAdmin) &&
                <MenuItem active={state.manage_orders} onClick={() => history.push(`/seller/business/${state.business_slug}/my-shop/manage-orders`)} >Manage Orders</MenuItem>
              }
              {
                (customersPermission || isAdmin) &&
                <MenuItem active={state.customers} onClick={() => history.push(`/seller/business/${state.business_slug}/my-shop/customers`)}>Customers</MenuItem>
              }
            </SubMenu>
          }

          {/* <MenuItem icon={<ShoppingCartRoundedIcon />}> Customers</MenuItem> */}
          {
            (promotionsPermission || isAdmin) &&
            <SubMenu key="sub2" title="Marketing" icon={<LocalOfferRoundedIcon />}>
              <MenuItem key="1" active={state.promotions} onClick={() => history.push(`/seller/business/${state.business_slug}/marketing/promotions`)}>Promotions</MenuItem>
              {/* <MenuItem key="2" active={state.ads} onClick={() => history.push(`/seller/business/${state.business_slug}/marketing/ads`)}>Ads</MenuItem>
            <MenuItem key="3" active={state.analytics} onClick={() => history.push(`/seller/business/${state.business_slug}/marketing/analytics`)}>Analytics</MenuItem> */}
            </SubMenu>
          }

          {/* <MenuItem icon={<ExploreRoundedIcon />}> Analytics</MenuItem> */}
          {/* {  (userSettingsPermission || deliverySettingsPermission || documentsPermission || isAdmin) && */}
          <SubMenu key="sub3" title="Settings" icon={<SettingsRoundedIcon />}>

            <MenuItem key="1" active={state.user_settings}
              onClick={() => history.push(`/seller/business/${state.business_slug}/settings/user-settings`)}>
              User Settings
            </MenuItem>

            {
              (businessSettingsPermission || isAdmin) &&
              <MenuItem key="2" active={state.shop_settings}
                onClick={() => history.push(`/seller/business/${state.business_slug}/settings/shop-settings`)}>
                Business Settings
              </MenuItem>
            }

            {
              // ((deliverySettingsPermission || isAdmin) && state.business_category == 'Food') &&
              (deliverySettingsPermission || isAdmin) &&
              <MenuItem key="2" active={state.delivery_settings}
                onClick={() => history.push(`/seller/business/${state.business_slug}/settings/delivery-settings`)}>
                Delivery Settings
              </MenuItem>
            }
            {
              (documentsPermission || isAdmin) &&
              <MenuItem active={state.messages}
                onClick={() => history.push(`/seller/business/${state.business_slug}/documents`)} >
                Documents
              </MenuItem>
            }

            {
              (isAdmin) &&
              <MenuItem onClick={() => history.push(`/seller/business/${state.business_slug}/roles-list`)} >
                Manage Roles
              </MenuItem>
            }
          </SubMenu>


          {
            (messagesPermission || isAdmin) &&
            <MenuItem icon={<MessageIcon />} active={state.messages}
              onClick={() => history.push(`/seller/business/${state.business_slug}/messages`)} >
              Message
            </MenuItem>
          }

        </Menu>

      </SidebarContent>
    </ProSidebar >
  );
};

export default Aside;