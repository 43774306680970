import axios from "axios";
import config from "../../config";
export const CheckoutAPI = {

    getAvailablePostalCode: async (shopSlug, selectedPostlaCode) => {
        const data = {
            shopSlug, selectedPostlaCode
        }
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/business/checkout`,
            data: data
        });
    }
}
