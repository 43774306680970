import '../../../../src/assets/css/AdminPanel/adminPanel.css';
import React, { useState } from 'react'
import { DashboardLayout } from '../Layout/Layout'
import { Redirect } from 'react-router-dom'

const Messages = () => {
    
        return (
            <DashboardLayout page='messages'>
                <div className="w-100 sidebar-right-content-spacing">
                    <div className="sidebar-content">
                        <h4>Messages</h4>
                    </div>
                </div>
            </DashboardLayout>
        )
    
}

export default Messages
