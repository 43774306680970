import React, { useState } from 'react'
import { Form, Input, Spin } from 'antd'
import SignUpFlower from '../../../assets/images/adminPanel/Auth/signup-flower.svg';
import { AdminAuth } from '../../../apis/admin/AdminAuth';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../../assets/images/modalPopup/close-circle.svg';
import { Token } from '../../../Token';

function AdminLogin() {

    const [form] = Form.useForm();
    const [loading, setLoader] = useState(false);

    const onFinish = (value) => {
        setLoader(true)
        AdminAuth.login(value)
            .then(res => {
                setLoader(false)
                Token.saveToken(res.data.user, res.data.token, 'admin');
                Swal.fire({
                    text: `Welcome back ${res.data.user.first_name} ${res.data.user.last_name}`,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    confirmButtonColor: '#00CA84'
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = '/admin/overview';
                    }
                });
            }).catch(function (error) {
                setLoader(false)
                Swal.fire({
                    text: error.response.data.message,
                    imageUrl: CloseCircle,
                    imageAlt: 'error image',
                    confirmButtonColor: '#00CA84'
                });
            });
    }

    return (
        <div className="d-flex w-100 h-100">
            <div className="container mt-5 pt-5">
                <div className="adminLogin-box">
                    <div className="adminLogin-headerBox">
                        <h4 className="align-self-end mb-0 text-white fw-600">Sign In</h4>
                        <div className="SignUpFlower-box">
                            <img src={SignUpFlower} alt="" /></div>
                    </div>
                    <div className="adminLogin-formBox">
                        <Form
                            form={form}
                            onFinish={onFinish}
                        >
                            <div className="form-group">
                                <label for="exampleInputEmail1">Email address</label>
                                <Form.Item
                                    className="form-item"
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Please input your email address.' },
                                        { type: 'email', message: 'Please enter valid email address.' }
                                    ]}
                                >
                                    <Input placeholder="Email address" />
                                </Form.Item>
                            </div>
                            <div className="form-group">
                                <label for="exampleInputPassword1">Password</label>
                                <Form.Item
                                    className="form-item"
                                    name="password"
                                    rules={[
                                        { required: true, message: 'Please input your password.' },
                                        { min: 8, max: 16, message: 'Password must be between 8 and 16 characters.' }
                                    ]}
                                >
                                    <Input.Password placeholder="Password" />
                                </Form.Item>
                            </div>
                            <div className="form-group">
                                <Form.Item className="form-item">
                                    <Spin spinning={loading} delay={500}>
                                        <button className="btn btnPara-primary btn-md btn-block" htmlType="submit">
                                            Submit
                                        </button>
                                    </Spin>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminLogin
