import React, { useReducer, useEffect } from 'react';
import '../../../../src/assets/css/UserPanel/userPanel.css';
import { DashboardLayout } from '../Sidebar wrapper/Layout';
import noProductsImg from '../../../assets/images/products/no-products-img.svg';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import setupbusiness from '../../../assets/images/sellerProfile/setup-business.svg';
import { Link, useParams } from 'react-router-dom'
import { Token } from '../../../Token';
import { BusinessAPI } from '../../../apis/user/BusinessAPI';
import { OrderAPI } from '../../../apis/user/OrderAPI';
import moment from 'moment';

const Dashboard = () => {
    const params = useParams();
    const business_slug = params.business_id;
    const [isAdmin, setIsAdmin] = React.useState(false);
    const [productsPermission, setProductsPermission] = React.useState([]);

    const [state, setState] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            totalRevenue: 0,
            totalCustomer: 0,
            totalSoldProduct: 0,
            totalOrder: 0,
            latestOrders: [],
            latestRatingReviews: []
        }
    );

    useEffect(() => {
        BusinessAPI.fetchBusinessDashboard(business_slug)
            .then(res => {
                setIsAdmin(res.data.isAdmin)
                const per = res.data.permissions?.productsPermission || [];
                setProductsPermission(per)

                setState({
                    totalRevenue: res.data.totalRevenue,
                    totalCustomer: res.data.totalCustomer,
                    totalSoldProduct: res.data.totalSoldProduct,
                    totalOrder: res.data.totalOrder,
                    latestOrders: res.data.latestOrders,
                    latestRatingReviews: res.data.latestRatingReviews
                })
            });
    }, []);

    return (
        <DashboardLayout page='dashboard'>
            <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-content">
                    <div className="blackBox-heading d-flex justify-content-between ">
                        <p className="align-self-center mb-0">You are viewing {Token.getFullName()}</p>
                        <Link to='/seller-profile/myBusinesses' className="btn py-0"><ArrowBackIcon /> Back</Link>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-6">
                            <div className="card border-dark">
                                <div className="card-body">
                                    <p>Total revenue</p>
                                    <h2 className="mb-0">£<span>{parseFloat(state.totalRevenue).toFixed(2)}</span></h2>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-sm-6 col-6">
                            <div className="card border-dark">
                                <div className="card-body">
                                    <p>Total customers</p>
                                    <h2 className="mb-0">{state.totalCustomer}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6 col-6">
                            <div className="card border-dark">
                                <div className="card-body">
                                    <p>Sold Products</p>
                                    <h2 className="mb-0">{state.totalSoldProduct}</h2>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-md-3 col-sm-6 col-6">
                            <div className="card border-dark">
                                <div className="card-body">
                                    <p>Avg. revenue/User</p>
                                    <h2 className="mb-0">£<span>0</span></h2>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="mt-5">
                        <h5 className="ft-size18 mb-3">Getting started</h5>
                        <div className="row">
                            <div className="col-xl-8 col-lg-12 col-md-12">
                                {
                                    (productsPermission.includes("Create") || isAdmin) &&
                                    <div className="mb-4">
                                        <div className="card border-dark">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-7 align-self-center">
                                                        <h5 className="mb-4">Get started by adding products</h5>
                                                        <Link className="btn btnPara-primary btnPara-withSvg" to={`/seller/business/${business_slug}/my-shop/add-product`}>Add a product <ArrowForwardIcon /></Link>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <img src={noProductsImg} alt="" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className="mb-4">
                                    <h5 className="ft-size18 text-paraDark mb-3">Latest Orders</h5>
                                    <div className="card border-dark">
                                        <div className="card-body">
                                            <div className="table-responsive paraRounded-table">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Time</th>
                                                            <th scope="col">Amount</th>
                                                            <th scope="col">Payment</th>
                                                            <th scope="col">Order Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            state.latestOrders.map((order, i) => {
                                                                if (order.order_status != 'Delivered') {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td>
                                                                                <div>
                                                                                    <p className="mb-0">{`${order.user.first_name} ${order.user.last_name}`}</p>
                                                                                    <p className="mb-0 greyText">Order ID: {order.order_id}</p>
                                                                                </div>
                                                                            </td>
                                                                            <td className="align-middle"><p className="mb-0 greyText">{moment(order.date_time).format("h:mm A")}</p></td>
                                                                            <td className="align-middle">
                                                                                <p className="mb-0 greyText">
                                                                                    £{parseFloat(order.final_order_amount).toFixed(2)}
                                                                                </p>
                                                                            </td>
                                                                            <td className="align-middle"><p className="mb-0 greenText">Stripe</p></td>
                                                                            <td className="align-middle"><p>{order.order_status}</p></td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {
                                                state.latestOrders.length == 0 &&
                                                <h5 className="text-center text-paraDark">You have no orders</h5>
                                            }
                                        </div>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <h5 className="ft-size18 text-paraDark mb-3">Latest Reviews</h5>
                                    <div className="card border-dark">
                                        <div className="card-body">
                                            <div className="table-responsive paraRounded-table">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Title</th>
                                                            <th scope="col">Rating</th>
                                                            <th scope="col">Tell Us More</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            state.latestRatingReviews.map((rv, i) => {
                                                                return (
                                                                    <tr key={i}>
                                                                        <td> <p className="mb-0">{`${rv.user.first_name} ${rv.user.last_name}`}</p> </td>
                                                                        <td className="align-middle"><p className="mb-0 greyText">{rv.title}</p></td>
                                                                        <td className="align-middle"><p className="mb-0 greyText">{rv.rating}</p></td>
                                                                        <td className="align-middle"><p className="mb-0 greenText">{rv.tell_us_more}</p></td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                            {
                                                state.latestRatingReviews.length == 0 &&
                                                <h5 className="text-center text-paraDark">You have no Rating Review</h5>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* right column */}
                            <div className="col-xl-4 col-lg-12 col-md-12">
                                <div className="card border-dark">
                                    <div className="card-body">
                                        <h5>Market your store to earn revenue online</h5>
                                        <div className="row">
                                            <div className="col-xl-7 col-md-6">
                                                <ul className="list-styledPara">
                                                    <li>Buy Ad space on Para</li>
                                                    <li>Promote products</li>
                                                    <li>View shop analytics</li>
                                                </ul>
                                                <Link className="btn btnPara-dark"
                                                    to={'/businesses/' + business_slug}
                                                >
                                                    Market your store
                                                </Link>
                                            </div>
                                            <div className="col-xl-5 col-md-6">
                                                <div className="mt-3">
                                                    <img src={setupbusiness} alt="" className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}

export default Dashboard