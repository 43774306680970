import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const CategoryAPI = {
    getCategories: async (search) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID() };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/admin/fetch-categories?search=${search}`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    saveCategory: async (data) => {
        const token = Token.getToken();

        data.admin_id = Token.getUserID();
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/admin/save-category`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    updateCategory: async (data) => {
        const token = Token.getToken();

        data.admin_id = Token.getUserID();
        return axios({
            method: 'put',
            url: `${config.API_BASE_URL}/api/admin/update-category`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    categoryStatus: async () => {
        return axios.put(`${config.API_BASE_URL}/api/admin/category-status`);
    },
    approveCategory: async (category_id, user_id) => {
        const token = Token.getToken();
        let data = {
            category_id: category_id,
            user_id: user_id
        }
        return axios({
            method: 'put',
            url: `${config.API_BASE_URL}/api/admin/approve-category`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    deleteCategory: async (category_id) => {
        const token = Token.getToken();
        let data = {
            category_id: category_id
        }
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/admin/delete-category`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
}