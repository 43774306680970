import React, { useState } from 'react';
import '../styles/css/setUpAsBusiness/businesses_with_para.css';
import Google from '../../assets/images/companies/google.svg';
import FB from '../../assets/images/companies/fb.svg';
import LinkedIn from '../../assets/images/companies/linkedin.svg';
import Asana from '../../assets/images/companies/asana.svg';
import Dribble from '../../assets/images/companies/dribble.svg';
import Behance from '../../assets/images/companies/behance.svg';
import Trello from '../../assets/images/companies/trello.svg';
import Slack from '../../assets/images/companies/slack.svg';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../assets/images/modalPopup/close-circle.svg';
import { Token } from '../../Token';
import SignIn from '../auth/SignIn';


const BUSINESSES_WITH_PARA = () => {
    const [state, setState] = useState({
        _isShowSingInModel: false,

    });

    return (
        <div className='col'>
            <div className='businesses-with-para'>
                <div className='heading text-center'>Businesses going digital with Para</div>
                <div className='companies_box'>
                    <div className=' '>
                        <div className='row col-12 d-flex justify-content-around'>
                            <img className='align-self-center' src={Google} alt="google logo" />
                            <img className='align-self-center' src={FB} alt="facebook logo" />
                            <img className='align-self-center' src={LinkedIn} alt="linkedin logo" />
                            <img className='align-self-center' src={Asana} alt="asana logo" />
                        </div>
                    </div>
                    <div className=' '>
                        <div className='row col-12 d-flex justify-content-around'>
                            <img className='align-self-center mt-4' src={Dribble} alt="dribble logo" />
                            <img className='align-self-center mt-4' src={Behance} alt="behance logo" />
                            <img className='align-self-center mt-4 ' src={Trello} alt="trello logo" />
                            <img className='align-self-center mt-2' src={Slack} alt="slack logo" />
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center buttonbox'>
                {
                    Token.isLoggedIn() ?
                        <Link to='/sell'> <button id='button'>Set up your business on para</button></Link> :
                        <button
                            onClick={() => setState({ _isShowSingInModel: true })}

                            id='button'>
                            Set up your business on para
                        </button>
                }
                {
                    state._isShowSingInModel ?
                        <SignIn
                            state={state}
                            setState={setState}
                        /> :
                        null
                }
            </div>
        </div>
    )
}

export default BUSINESSES_WITH_PARA