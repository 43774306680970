import React from 'react';
import { Fragment } from 'react';
import {  Row, Col, } from 'react-bootstrap';

export default function UserPastOrders() {
    return (
        <Fragment>
            <div className='p-3 mb-3 past-orders-info border rounded-8'>
                <Row>
                    <Col xl={6} lg={12} md={12}>
                        <div className="d-md-flex pb-4">
                            <div className='mt-2'>
                                <img src='https://picsum.photos/200/300' alt="" className="pastOrderImage" />
                            </div>
                            <div className="d-flex align-items-start flex-column h-100 mt-2">
                                <h5 className="mb-1">Cakes by K</h5>
                                <p className="greyText ft-size14 mb-2">Fulham Street, London 540023 Sunday 4th Mar, 2021, 11:54PM</p>
                                <p className='mt-auto mb-0'>Order #98204874387</p>
                            </div>
                        </div>
                    </Col>
                    <Col xl={6} lg={12} md={12}>
                        <p>Out for delivery (last changed 2 hours ago)</p>
                    </Col>
                </Row>
                <hr className="mb-4 mt-0" />
                <div className="d-md-flex justify-content-md-between flex-wrap">
                    <div className="d-flex flex-row flex-wrap">
                        <div className="mr-3"> <button className="btn btnPara-primary">View order details</button></div>
                        <div className="mr-3"> <button className="btn btn-outline-primary">Message Store</button></div>
                    </div>
                    <div className="p-2">
                        <p className="mb-0 text-lightDark">Total paid: $35</p>
                    </div>
                </div>
            </div>
            <div className='p-3 mb-3 past-orders-info border rounded-8'>
                <Row>
                    <Col xl={6} lg={12} md={12}>
                        <div className="d-md-flex pb-4">
                            <div className='mt-2'>
                                <img src='https://picsum.photos/200/300' alt="" className="pastOrderImage" />
                            </div>
                            <div className="d-flex align-items-start flex-column h-100 mt-2">
                                <h5 className="mb-1">Cakes by K</h5>
                                <p className="greyText ft-size14 mb-2">Fulham Street, London 540023 Sunday 4th Mar, 2021, 11:54PM</p>
                                <p className='mt-auto mb-0'>Order #98204874387</p>
                            </div>
                        </div>
                    </Col>
                    <Col xl={6} lg={12} md={12}>
                        <p>Out for delivery (last changed 2 hours ago)</p>
                    </Col>
                </Row>
                <hr className="mb-4 mt-0" />
                <div className="d-md-flex justify-content-md-between flex-wrap">
                    <div className="d-flex flex-row flex-wrap">
                        <div className="mr-3"> <button className="btn btnPara-primary">View order details</button></div>
                        <div className="mr-3"> <button className="btn btn-outline-primary">Message Store</button></div>
                    </div>
                    <div className="p-2">
                        <p className="mb-0 text-lightDark">Total paid: $35</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
