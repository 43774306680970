import React, { useState, useEffect, Fragment } from 'react'
import '../../Components/styles/css/Shops/shops.css'

import { Link, useLocation } from 'react-router-dom'
import StarIcon from '@material-ui/icons/Star';
import LocationOnSharpIcon from '@material-ui/icons/LocationOnSharp';
import { LinearProgress } from '@material-ui/core';
import FilterIcon from '../../assets/images/svg-icons/filterIcon.svg';
import { BusinessAPI } from '../../apis/user/BusinessAPI';
import { GuestAPI } from '../../apis/GuestAPI';
import Swal from 'sweetalert2';
import noShopImg from '../../assets/images/products/no-products-img.svg';
import AlertTriangle from '../../assets/images/modalPopup/alert-triangle.svg';
import { Checkbox, Collapse, Drawer, Form } from 'antd';
import moment from 'moment';
import { ClockCircleOutlined, PhoneFilled, EyeOutlined } from '@ant-design/icons'
import { Spinner } from 'react-bootstrap';
import CancelIcon from '@mui/icons-material/Cancel';
const { Panel } = Collapse;

const Shops = (props) => {
    const [form] = Form.useForm();
    const [shops, setShops] = useState();
    const [businesses, setBusinesses] = useState([]);
    const [progressVisible, setProgressVisible] = useState('visible');
    const [searchText, setSearchText] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [restart, setReStart] = useState(false)
    const [businessCategories, setBusinessCategories] = useState([]);
    const [NoDataMessage, setNoDataMessage] = useState("")
    const [FilterHide, setFilterHide] = useState(false)
    const [showFiltersText, setShowFiltersText] = useState([]);
    const [offersFilterText, setOffersFilterText] = useState([]);
    const [RatingFilterText, setRatingFilterText] = useState([]);
    const [CategoriesFilterText, setCategoriesFilterText] = useState([]);
    const forceUpdate = React.useReducer(bool => !bool)[1];
    const [SaleChecked, setSaleChecked] = useState([])
    const [loading, setLoading] = useState(false)
    const userLatLong = props.userLatLong || { lat: 0, long: 0 }
    const match = props.rootProps.match
    useEffect(() => {
        const searchText = localStorage.getItem('searchUrl');
        if (searchText) {
            BusinessAPI.guestGetBusiness(searchText, userLatLong)
                .then((res) => {
                    setSearchValue(res?.data?.searchText)
                    if (res?.data?.status == true) {
                        setShops(res.data)
                    }

                    if (res?.data?.status == true) {
                        setBusinesses(res.data.businesses);
                    }
                    if (res?.data?.status == false) {
                        setNoDataMessage(res?.data?.message)
                    }
                    setProgressVisible('hidden')
                    localStorage.removeItem('searchUrl')

                }).catch((error) => {

                    setProgressVisible('hidden')
                    Swal.fire({
                        title: 'Something is not right. Please check your connection',
                        imageUrl: AlertTriangle,
                        imageAlt: 'warning image',
                        confirmButtonColor: '#00CA84'
                    });
                })
        }
        else {
            const filterCat = match.params?.filter_name ? match.params.filter_name : 'allBusiness';
            BusinessAPI.guestGetBusiness(filterCat, userLatLong)
                .then((res) => {
                    setShops(res.data)
                    setBusinesses(res.data.businesses);

                    if (res.data.businesses.length == 0) {
                        setNoDataMessage('No Shop Found')
                    }
                    setProgressVisible('hidden')
                }).catch((error) => {
                    setProgressVisible('hidden')
                    Swal.fire({
                        title: 'Something is not right. Please check your connection',
                        imageUrl: AlertTriangle,
                        imageAlt: 'warning image',
                        confirmButtonColor: '#00CA84'
                    });
                })
        }

        GuestAPI.getBusinessCategories()
            .then((res) => {
                setBusinessCategories(res.data.categories)
            })

    }, [restart])
    const showDrawer = () => {
        setFilterHide(true);
    };
    const onClose = () => {
        setFilterHide(false);
    };
    const HandleSale = (check) => {
        setSaleChecked(check)
    }
    const onFinish = async (value) => {
        setLoading(true)

        const categories = value?.categories?.length
        const offer = value?.offers?.length
        const ratings = value?.ratings?.length
        const IntialState = []
        setShowFiltersText(IntialState)
        setOffersFilterText(IntialState)
        setCategoriesFilterText(IntialState)
        setRatingFilterText(IntialState)
        if (categories && offer && ratings || categories && offer || offer && ratings || categories && ratings || ratings || offer || categories) {
            let FiltersText = []
            GuestAPI.getFilterBusiness(value)
                .then((res) => {
                    if (res.data.status == false) {
                        setNoDataMessage(res.data.message)
                    }
                    if (res.data.FilterTextOffers) {

                        FiltersText.push(...res.data.FilterTextOffers)
                        setOffersFilterText(res.data.FilterTextOffers)
                    }
                    if (res.data.FilterTextCategories) {

                        FiltersText.push(...res.data.FilterTextCategories)
                        setCategoriesFilterText(res.data.FilterTextCategories)
                    }
                    if (res.data.FilterTextRatings) {

                        FiltersText.push(...res.data.FilterTextRatings)
                        setRatingFilterText(res.data.FilterTextRatings)
                    }

                    setShowFiltersText(FiltersText)
                    if (res.data?.businesses) {
                        const NewBuinsness = res.data?.businesses?.filter((business) => business != null)
                        setBusinesses(NewBuinsness)
                    }

                    setLoading(false)
                })
        }
        else {
            setLoading(true)

            const filterCat = match.params.filter_name ? match.params.filter_name : 'allBusiness';
            BusinessAPI.guestGetBusiness(filterCat, userLatLong)
                .then((res) => {
                    setShops(res.data)
                    setBusinesses(res.data.businesses);
                    if (res.data.businesses.length == 0) {
                        setNoDataMessage('No Shop Found')
                    }
                    setProgressVisible('hidden')
                    setLoading(false)

                }).catch((error) => {
                    setProgressVisible('hidden')
                    Swal.fire({
                        title: 'Something is not right. Please check your connection',
                        imageUrl: AlertTriangle,
                        imageAlt: 'warning image',
                        confirmButtonColor: '#00CA84'
                    });
                })
            setShowFiltersText([])

        }

    }
    const MakeFilterRemoveByone = (value) => {
        if (value == 'Flash Sale' || value == 'Coupon Promotion') {
            const offersFilterText = offersFilterText?.filter(c => c !== value) || [];
            const values = {
                categories: CategoriesFilterText,
                offers: offersFilterText,
                ratings: RatingFilterText,
            }
            setOffersFilterText(offersFilterText)
            setSaleChecked(offersFilterText)
            form.setFieldsValue({
                offers: offersFilterText,
            });
            forceUpdate()
            onFinish(values)

        }
        else if (value == 'Food' || value == 'Service') {
            const CategoriesFilterText = CategoriesFilterText?.filter(c => c !== value);
            const values = {
                categories: CategoriesFilterText,
                offers: offersFilterText,
                ratings: RatingFilterText,
            }
            setCategoriesFilterText(CategoriesFilterText)
            setSaleChecked(CategoriesFilterText)
            form.setFieldsValue({
                categories: CategoriesFilterText,
            });
            forceUpdate()
            onFinish(values)

        }
        else if (value == 0 || value == 2 || value == 3 || value == 4) {
            const RatingFilterText = RatingFilterText?.filter(c => c !== value);
            const values = {
                categories: CategoriesFilterText,
                offers: offersFilterText,
                ratings: RatingFilterText,
            }

            setRatingFilterText(RatingFilterText)
            setSaleChecked(RatingFilterText)
            form.setFieldsValue({
                ratings: RatingFilterText,
            });
            forceUpdate()
            onFinish(values)

        }

    }

    const onChangeDropdownFilter = async (e) => {
        if (showFiltersText?.length > 0) {
            setLoading(true)

            if (e?.target?.value == "Name (A - Z)") {
                const AscendingBusiness = businesses.sort((a, b) => a.business_name.localeCompare(b.business_name))
                setBusinesses(AscendingBusiness)
                setLoading(false)

                forceUpdate()
            }
            else if (e?.target?.value == "Name (Z - A)") {
                const AscendingBusiness = businesses.sort((a, b) => a.business_name.localeCompare(b.business_name))
                setBusinesses(AscendingBusiness.reverse())
                setLoading(false)

                forceUpdate()

            }

        }
        else {
            setLoading(true)

            GuestAPI.getDropdownFilterBusiness({ search: e.target.value })
                .then((res) => {
                    setBusinesses(res.data.businesses);
                    setLoading(false)

                })
        }
    }
    if (loading) {
        return <div className='d-flex align-items-center justify-content-center spinnerBackground' style={{ height: "60vh", width: "100%" }}><Spinner animation="border" variant="success" /></div>

    }
    if (businesses.length == 0) {
        if (NoDataMessage) {
            return <div className="text-center my-5 " style={{ flex: 'auto', height: "60vh" }}>
                <div className='w-100'>
                    <div className='container'>
                        <div className='mt-5'>
                            <h3 className='#'>Browse Businesses</h3>
                        </div>
                        <div className="d-md-flex justify-content-md-between mt-4">
                            <div className="align-self-center my-2 d-flex">
                                <h5 className='mb-0'>{businesses.length} Businesses</h5>
                                {searchValue && <h5 className='mt-0 ml-5'>{searchValue} <span style={{ cursor: 'pointer' }} onClick={() => { setSearchValue(""); setReStart(true) }}><CancelIcon></CancelIcon></span></h5>}
                                {showFiltersText && showFiltersText?.map((text) => {
                                    return <h5 className='mt-0 ml-5'>{text == "Coupon Promotion" ? "Discounts" : ["0", "2", "3", "4"].includes(text) ? `${text} Stars` : text} <span style={{ cursor: 'pointer' }} onClick={() => MakeFilterRemoveByone(text)}><CancelIcon></CancelIcon></span></h5>
                                })
                                }

                            </div>
                            <div className="d-flex align-self-center my-2">
                                <p className='mb-0 align-self-center' style={{ color: '#999999' }}>Sort:</p>
                                <select className='mr-2 border-0'
                                    onChange={(e) => onChangeDropdownFilter(e)}
                                >
                                    {/* <option value="Relevence">Relevence</option> */}
                                    {/* <option value="Price">Price</option> */}
                                    <option value="Name (A - Z)">A - Z</option>
                                    <option value="Name (Z - A)">Z - A</option>
                                    {/* <option value="Distance">Distance</option> */}
                                    {/* <option value="Rating">Rating</option> */}
                                </select>

                                <button
                                    className=" btn btn-view-ordermb-0 align-self-center mr-2 "
                                    data-toggle="modal"
                                    // data-target="#order_details_modal"
                                    // id="FilterButton"
                                    onClick={() => showDrawer()}
                                >
                                    Filters
                                    <img src={FilterIcon} alt="" />
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
                <Drawer
                    title="Filters"
                    placement='right'
                    closable={true}
                    onClose={onClose}
                    visible={FilterHide}
                    width="445"

                >
                    <Form
                        className='shopFilter'
                        form={form}
                        onFinish={onFinish}
                    >
                        <Collapse defaultActiveKey={['1', '2', '3']} ghost expandIconPosition="right">
                            <Panel header="Businesses" key="1">
                                <Form.Item
                                    className="form-item"
                                    name="categories"

                                >
                                    <Checkbox.Group style={{ width: '100%' }}>
                                        {
                                            businessCategories.map((bc) => {
                                                return (
                                                    <><Checkbox value={bc.category_name}>{bc.category_name}</Checkbox><br /></>
                                                )
                                            })
                                        }
                                    </Checkbox.Group>
                                </Form.Item>
                            </Panel>
                            <Panel header="Deals" key="2">
                                <Form.Item
                                    className="form-item"
                                    name="offers"
                                    initialValue={SaleChecked}
                                >
                                    <Checkbox.Group style={{ width: '100%' }} onChange={HandleSale}>
                                        <Checkbox value="Flash Sale">Flash sales</Checkbox><br />
                                        <Checkbox value="Coupon Promotion">Discounts</Checkbox> <br />
                                    </Checkbox.Group>
                                </Form.Item>
                            </Panel>
                            <Panel header="Rating " key="3">
                                <Form.Item
                                    className="form-item"
                                    name="ratings"
                                >
                                    <Checkbox.Group style={{ width: '100%' }}>
                                        <Checkbox value="4">4 stars and above</Checkbox><br />
                                        <Checkbox value="3">3 stars and above</Checkbox> <br />
                                        <Checkbox value="2">2 stars and above</Checkbox> <br />
                                        <Checkbox value="0">All</Checkbox> <br />

                                    </Checkbox.Group>
                                </Form.Item>
                            </Panel>

                        </Collapse>
                        <hr />
                        <div className=''>
                            <button type='submit' className="btn btn-success w-100" onClick={onClose}>Submit</button>
                        </div>
                    </Form>
                </Drawer>

                <div>
                    <div className="form-group ">
                        <img className='content-to-hide ' src={noShopImg} alt="No Business" />
                    </div>
                    <h2>No Business Found</h2>
                </div>
            </div>
        }
        return <div className='d-flex align-items-center justify-content-center spinnerBackground' style={{ height: "60vh", width: "100%" }}><Spinner animation="border" variant="success" /></div>
    }

    return (
        <div id='rootDiv'>

            <LinearProgress style={{ width: '100%', height: '7px', visibility: progressVisible }} />

            <div className='w-100'>

                <div className='container'>
                    <div className='mt-5'>
                        <h3 className='#'>Browse Businesses</h3>
                    </div>
                    <div className="d-md-flex justify-content-md-between mt-4">
                        <div className="align-self-center my-2 d-flex">
                            <h5 className='mb-0'>{businesses.length} Businesses</h5>
                            {searchValue && <h5 className='mt-0 ml-5'>{searchValue} <span style={{ cursor: 'pointer' }} onClick={() => { setSearchValue(""); setReStart(true) }}><CancelIcon></CancelIcon></span></h5>}
                            {showFiltersText && showFiltersText?.map((text) => {
                                return <h5 className='mt-0 ml-5'>{text == "Coupon Promotion" ? "Discounts" : ["0", "2", "3", "4"].includes(text) ? `${text} Stars` : text} <span style={{ cursor: 'pointer' }} onClick={() => MakeFilterRemoveByone(text)}><CancelIcon></CancelIcon></span></h5>
                            })
                            }

                        </div>
                        <div className="d-flex align-self-center my-2">
                            <p className='mb-0 align-self-center' style={{ color: '#999999' }}>Sort:</p>
                            <select className='mr-2 border-0'
                                onChange={(e) => onChangeDropdownFilter(e)}
                            >
                                <option value="Name (A - Z)" className=" d-none">A - Z</option>
                                {/* <option value="Price">Price</option> */}
                                <option value="Name (A - Z)">A - Z</option>
                                <option value="Name (Z - A)">Z - A</option>
                                {/* <option value="Distance">Distance</option> */}
                                {/* <option value="Rating">Rating</option> */}
                            </select>

                            <button
                                className=" btn btn-view-ordermb-0 align-self-center mr-2 "
                                data-toggle="modal"
                                // data-target="#order_details_modal"
                                // id="FilterButton"
                                onClick={() => showDrawer()}
                            >
                                Filters
                                <img src={FilterIcon} alt="" style={{ marginLeft: "10px" }} />
                            </button>
                        </div>
                    </div>

                </div>
            </div>


            {/* Rending all shops */}

            <div className="w-100 bg-white"
            // style={{ backgroundColor: '#E5E5E5' }}
            >
                <div className="container">
                    <div className="shops-box">
                        <div className="tab-content" id="shopTabContent">
                            <div className="tab-pane fade show active">
                                {/* reading online shops */}
                                <div className="row no-gutters">

                                    {
                                        businesses.length == 0 &&
                                        <div className="text-center my-5" style={{ flex: 'auto' }}>
                                            <div className="form-group ">
                                                <img className='content-to-hide ' src={noShopImg} alt="No shop" />
                                            </div>
                                            <h2>No shop yet</h2>
                                        </div>
                                    }

                                    {shops ?
                                        businesses.filter(singleshop => {
                                            if (searchText === "") {
                                                return singleshop
                                            }
                                            else {
                                                if (singleshop.business_name.toLowerCase().includes(searchText.toLowerCase()) ||
                                                    singleshop.address.toLowerCase().includes(searchText.toLowerCase())) {
                                                    return singleshop
                                                }
                                            }
                                        }).map((singleshop, index) => {


                                            const weekday = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
                                            const d = new Date();
                                            let today = weekday[d.getDay()];
                                            var currentTime = moment().format("HH:mm:ss");
                                            var fromTime = moment(singleshop[today][1], ["H:mma"]).format("HH:mm:ss");
                                            var toTime = moment(singleshop[today][2], ["H:mma"]).format("HH:mm:ss");

                                            let todayActiveTime = (currentTime > fromTime && currentTime < toTime) ? true : false;
                                            let shopDayStatus = singleshop[today][0];

                                            return <div key={`shops-${index}`} className="col-lg-3 col-md-6 p-2">
                                                <Link to={{ pathname: `/businesses/${singleshop.business_slug}` }} style={{ textDecoration: 'none' }} >
                                                    <div className='card shopsBox-card flashSale-card'>
                                                        <div className='shop-image img-thumbnail ' style={{ backgroundImage: `url(${singleshop.company_logo})` }}>
                                                            {/* <img className="card-img-top"  src={singleshop.company_logo} alt="Card cap" /> */}
                                                        </div>
                                                        <div className='card-body p-0'>

                                                            <h5 className="shop-shopname ddiscription mb-3 px-2 text-capitalize">{singleshop.business_name}</h5>
                                                            <p className='discription px-2  discription mt-3'>{singleshop.short_description}</p>
                                                            <div className="d-flex location-map px-1 py-2">
                                                                {/* <p className="shop-location two-line-truncate">
                                                                    <LocationOnSharpIcon className="shop-locationIcon" />{singleshop.address}
                                                                </p> */}
                                                                <div className="d-flex mt-3">
                                                                    <div className="py-0 align-self-center"><p className="mb-0 mr-2"><LocationOnSharpIcon /></p></div>
                                                                    <div className="py-0"><p className="mb-0">{singleshop.address}</p></div>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex ratignRangecontent px-2 py-1 justify-content-between'>
                                                                {

                                                                    singleshop.avg_rating > 0 ? <Fragment>

                                                                        <div className={singleshop.avg_rating <= 3 ? 'shop-ratingBox ratingBox-red' : 'shop-ratingBox'}>
                                                                            <div className="d-flex shop-rating">
                                                                                <StarIcon />
                                                                                <p className="mb-0">{singleshop?.avg_rating}</p>
                                                                            </div>
                                                                        </div>
                                                                    </Fragment> : ""
                                                                }

                                                                <div className='d-flex align-items-center discount-offBox ml-auto'>
                                                                    {/* <img src={Discount} alt="Card" />
                                                            <p className='mb-0 align-self-center ml-1'>{item.discount}</p>
                                                            <p className='mb-0 align-self-center ml-1'><span >off</span></p> */}
                                                                    <small className='fw-500 text-danger d-flex align-items-baseline' style={{ fontSize: "14px" }}>
                                                                        <ClockCircleOutlined className='pr-1 ClockCircleOutlined' />
                                                                        {shopDayStatus == 'open' && todayActiveTime == true ? 'Open' : `Closed-Opens at ${singleshop[today][1]}`}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                            <div className='d-flex flashSale-card-viewmenu mt-1' >
                                                                <div className='col-xl-6 col-md-6 col-sm-6 p-0 border-end'>
                                                                    <p className='text-dark border mb-0 p-1 d-flex align-items-center justify-content-center' style={{ fontSize: "13px" }}>
                                                                        <PhoneFilled className='p-2' style={{ fontSize: '13px' }} />
                                                                        <a href={`tel:${singleshop.mobileNumber}`}
                                                                            // onClick={() => setShowMobileNumber(true)}
                                                                            className="text-dark">Call</a>
                                                                        {/* { setShowMobileNumber && <p href="tel:+4733378901" className="show-number">{item.mobileNumber}</p>} */}

                                                                    </p>
                                                                </div>
                                                                <div className='col-xl-6 col-md-6 col-sm-6 p-0'>
                                                                    <p className='text-dark border mb-0 p-1 d-flex align-items-center justify-content-center' style={{ fontSize: "13px" }}>
                                                                        <EyeOutlined className='p-2' style={{ fontSize: "13px", }} />
                                                                        <Link to={{ pathname: `/businesses/${singleshop.business_slug}` }} className="grind-view-detial text-dark " >View Detail</Link>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        }) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Drawer
                title="Filters"
                placement='right'
                closable={true}
                onClose={onClose}
                visible={FilterHide}
                width="300"

            >
                <Form
                    className='shopFilter'
                    form={form}
                    onFinish={onFinish}
                >
                    <Collapse defaultActiveKey={['1', '2', '3']} ghost expandIconPosition="right">
                        <Panel header="Businesses" key="1">
                            <Form.Item
                                className="form-item"
                                name="categories"

                            >
                                <Checkbox.Group style={{ width: '100%' }}>
                                    {
                                        businessCategories.map((bc) => {
                                            return (
                                                <><Checkbox value={bc.category_name}>{bc.category_name}</Checkbox><br /></>
                                            )
                                        })
                                    }
                                </Checkbox.Group>
                            </Form.Item>
                        </Panel>
                        <Panel header="Deals" key="2">
                            <Form.Item
                                className="form-item"
                                name="offers"
                                initialValue={SaleChecked}
                            >
                                <Checkbox.Group style={{ width: '100%' }} onChange={HandleSale}>
                                    <Checkbox value="Flash Sale">Flash sales</Checkbox><br />
                                    <Checkbox value="Coupon Promotion">Discounts</Checkbox> <br />
                                </Checkbox.Group>
                            </Form.Item>
                        </Panel>
                        <Panel header="Rating " key="3">
                            <Form.Item
                                className="form-item"
                                name="ratings"
                            >
                                <Checkbox.Group style={{ width: '100%' }}>
                                    <Checkbox value="4">4 stars and above</Checkbox><br />
                                    <Checkbox value="3">3 stars and above</Checkbox> <br />
                                    <Checkbox value="2">2 stars and above</Checkbox> <br />
                                    <Checkbox value="0">All</Checkbox> <br />

                                </Checkbox.Group>
                            </Form.Item>
                        </Panel>

                    </Collapse>
                    <hr />
                    <div className=''>
                        <button type='submit' className="btn btn-success w-100" onClick={onClose}>Submit</button>
                    </div>
                </Form>
            </Drawer>

            {/* ==== End: order_details_modal  =====  */}
        </div >
    )

}

export default Shops
