import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import AdminHeader from '../Components/AdminPanel/Layout/AdminHeader';
import AdminLogin from '../Components/AdminPanel/Auth/AdminLogin'
import AdminOverview from '../Components/AdminPanel/Overview/Overview';
import AdminApplications from '../Components/AdminPanel/Applications/Applications';
import AddApplications from '../Components/AdminPanel/Applications/AddApplication';
import AdminUsers from '../Components/AdminPanel/Users/Users';
import UserDetails from '../Components/AdminPanel/Users/UserDetails';
import AdminBusinesses from '../Components/AdminPanel/Businesses/Businesses';
import AdminCategories from '../Components/AdminPanel/Categories/categories';
import AdminPromotions from '../Components/AdminPanel/Promotions/promotions';
import AdminTickets from '../Components/AdminPanel/Tickets/tickets';
import AdminMessages from '../Components/AdminPanel/Messages/messages';
import { getToken, getUserRole } from "../Token";
import ContactList from '../Components/AdminPanel/ContactList/ContactList';
import AdminUser from '../Components/AdminPanel/AdminUsers/AdminUser';
import AdminUserEdit from '../Components/AdminPanel/AdminUsers/AdminUserEdit';
import AdminUserAdd from '../Components/AdminPanel/AdminUsers/AdminUserAdd';
import BusinessList from '../Components/AdminPanel/Business/BusinessList';
import ViewBusiness from '../Components/AdminPanel/Business/ViewBusiness';
import CustomerList from '../Components/AdminPanel/Customer/CustomerList';


function AdminRoute({ match }) {

    let isAdmin = window.location.href.indexOf("admin") > -1 ? true : false;

    return (
        <React.Fragment>
            {(isAdmin && (getToken() != null && getUserRole() == 'admin')) && <AdminHeader />}

            <Route path={`${match.path}/login`} render={props => (
                getUserRole() != 'admin' ? <AdminLogin /> : <Redirect to={{ pathname: '/admin/overview' }} />
            )} />

            {/* <Route path={`${match.path}`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <Redirect to={{ pathname: '/admin/overview' }} /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} /> */}

            <Route path={`${match.path}/overview`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <AdminOverview /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />
            <Route path={`${match.path}/applications`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <AdminApplications /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />
            <Route path={`${match.path}/addApplication`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <AddApplications /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />
            <Route path={`${match.path}/users`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <AdminUsers /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />
            <Route path={`${match.path}/userDetails`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <UserDetails /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />
            <Route path={`${match.path}/businesses`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <AdminBusinesses /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />
            <Route path={`${match.path}/categories`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <AdminCategories /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />
            <Route path={`${match.path}/promotions`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <AdminPromotions /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />
            <Route path={`${match.path}/Contact-List`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <ContactList /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />
            <Route path={`${match.path}/Admin-User`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <AdminUser /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />
            <Route path={`${match.path}/Admin-User-Add`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <AdminUserAdd /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />
            <Route path={`${match.path}/Admin-User-Edit/:id`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <AdminUserEdit /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />


            <Route path={`${match.path}/tickets`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <AdminTickets /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />
            <Route path={`${match.path}/messages`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <AdminMessages /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />

            <Route path={`${match.path}/business-list`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <BusinessList /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />

            <Route path={`${match.path}/view-business/:business_slug`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <ViewBusiness /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />

            <Route path={`${match.path}/customer-list`} render={props => (
                (getToken() != null && getUserRole() == 'admin') ? <CustomerList /> : <Redirect to={{ pathname: '/admin/login' }} />
            )} />

        </React.Fragment>
    )
}

export default AdminRoute
