import React, { Fragment, useState } from 'react'
import profileIcon from '../../assets/images/profile/profileIcon.svg'
import Swal from 'sweetalert2';
import CheckmarkCircle from '../../assets/images/modalPopup/checkmark-circle.svg';
import CloseCircle from '../../assets/images/modalPopup/close-circle.svg';
import { Form, Input, Button, Spin, notification } from 'antd';
import { AuthAPI } from '../../apis/user/AuthAPI';
import { useParams, useHistory } from 'react-router-dom';
import SignUpFlower from '../../assets/images/adminPanel/Auth/signup-flower.svg';



const ResetPassword = () => {

    const [form] = Form.useForm();
    const [loading, setLoader] = useState(false);
    const params = useParams();
    const token = params.token;
    let history = useHistory();

    const onFinish = (value) => {
        value.token = token;
        setLoader(true)
        AuthAPI.resetPasssword(value)
            .then(res => {
                setLoader(false)
                Swal.fire({
                    title: 'Success!',
                    text: res.data.message,
                    imageUrl: CheckmarkCircle,
                    imageAlt: 'success image',
                    // imageHeight: 1500,
                    confirmButtonColor: '#00CA84'
                });
                history.push('/');
            }).catch(function (error) {
                setLoader(false);
                Swal.fire({
                    text: error.response.data.message,
                    imageUrl: CloseCircle,
                    imageAlt: 'error image',
                    // imageHeight: 1500,
                    confirmButtonColor: '#00CA84'
                });
            });
    }

    return (
        <Fragment>
            <div className="top-page-margin">
                <div className="container">
                    <div className="mb-5">
                        <div className="userLogin-box">
                            <div className="userLogin-headerBox">
                                <h4 className="align-self-end mb-0 text-white fw-600">Reset Password</h4>
                                <div className="SignUpFlower-box">
                                    <img src={SignUpFlower} alt="" />
                                </div>
                            </div>
                            <div className="userLogin-formBox">
                                <div className='d-flex'>
                                    <img className='mt-3' height='48px' width='48px' style={{ borderRadius: '100%' }} src={profileIcon} alt="profileicon" />
                                </div>
                                <Form
                                    form={form}
                                    onFinish={onFinish}
                                >
                                    <div className="form-group mt-3">
                                        <label htmlfor="enterPassword">Enter Password</label>
                                        <Form.Item
                                            className="form-item"
                                            name="password"
                                            rules={[
                                                { required: true, message: 'Please input your password.' },
                                                { min: 8, max: 16, message: 'Password must be between 8 and 16 characters.' },
                                                {
                                                    pattern: new RegExp(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/),
                                                    message: "Password should contain uppercase characters (A-Z), lowercase characters (a-z), digits (0-9) and special characters @!*& etc."
                                                }
                                            ]}
                                        >
                                            <Input.Password
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }} onCopy={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }}
                                                placeholder="Password" />
                                        </Form.Item>
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlfor="enterPassword">Enter Confirm Password</label>
                                        <Form.Item
                                            className="form-item"
                                            name="confirm_password"
                                            dependencies={['password']}
                                            rules={[
                                                { required: true, message: 'Please input your confirm password.' },
                                                ({ getFieldValue }) => ({
                                                    validator(rule, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject('The two passwords that you entered do not match!');
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }} onCopy={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }}
                                                placeholder="Confirm Password" />
                                        </Form.Item>
                                    </div>
                                    <div className="form-group mt-3">
                                        <Form.Item className="form-item">
                                            <Spin spinning={loading} delay={500}>
                                                <button className="btn btnPara-primary btn-md btn-block" htmlType="submit">
                                                    Submit
                                                </button>
                                            </Spin>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )


}

export default ResetPassword