import React, { useState, createContext } from 'react';

const JetContext = createContext();

function JetProvider(props) {

    const [Jets, setJets] = useState();
    const [userData, setUserData] = useState()
    const [userId, setUserId] = useState()
    const [businessId, setBusinessId] = useState()
    const [buttonText, setButtonText] = useState('Set up your shop')


    return (
        <JetContext.Provider value={{
            user: [userData, setUserData],
            id: [userId, setUserId],
            businessId: [businessId, setBusinessId],
            button: [buttonText, setButtonText]
        }}>
            {props.children}
        </JetContext.Provider>
    );
}

export { JetContext, JetProvider };