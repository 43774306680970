import React, { useEffect } from 'react'

export const SocialMediaConnections = (props) => {

    useEffect(() => {
        window.scroll({
            top: 100,
            left: 100,
            behavior: 'smooth'
          });
    }, [])

    return (
        <div className='my-2'>
            <div className="text-center">
                <h4 className="mb-0">Connect your Social Media</h4>
                <p>Information about your business so we can help you maximise your conversions.</p>
            </div>
            <div style={{ border: '1px #E6E6E6 solid', backgroundColor: '#E6E6E6' }}></div>
            <div className='my-2'>
                <div className="form-group">
                    <label className='label' htmlFor="Facebook profile link">Facebook profile link</label>
                    <input type='url' name='facebook_profile_link'
                        value={props.state.facebook_profile_link}
                        onChange={(e) => props.setState({ facebook_profile_link: e.target.value })}
                        autoComplete='off' className="form-control"
                        placeholder="Enter Facebook link" />
                </div>

                <div className="form-group">
                    <label className='label' htmlFor="Instagram Profile Link">Instagram Profile Link</label>
                    <input type='url' name='instagram_profile_link'
                        value={props.state.instagram_profile_link}
                        onChange={(e) => props.setState({ instagram_profile_link: e.target.value })}
                        autoComplete='off' className="form-control"
                        placeholder="Enter Instagram Link" />
                </div>

                <div className="form-group">
                    <label className='label' htmlFor="Twitter Profile Link">Twitter Profile Link</label>
                    <input type='url' name='twitter_profile_link'
                        value={props.state.twitter_profile_link}
                        onChange={(e) => props.setState({ twitter_profile_link: e.target.value })}
                        autoComplete='off' className="form-control"
                        placeholder="Enter Twitter Link" />
                </div>

                <div className="form-group">
                    <label className='label' htmlFor="Google Profile Link">Google Profile Link</label>
                    <input type='url' name='google_profile_link'
                        value={props.state.google_profile_link}
                        onChange={(e) => props.setState({ google_profile_link: e.target.value })}
                        autoComplete='off' className="form-control"
                        placeholder="Enter Google Link" />
                </div>
            </div>
        </div>
    )
}

export default SocialMediaConnections
