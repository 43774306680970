import axios from "axios";
import config from "../../config";
import { Token } from "../../Token";

export const CategoryAPI = {
    getBusinessCategories: async () => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID() };
        return axios({
            method: 'get',
            url: `${config.API_BASE_URL}/api/user/fetch-business-categories`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    getCategories: async () => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID() };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-categories`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    getUserCategories: async (business_slug) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), business_slug };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-user-categories`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    getUserCategoriesForDropdown: async (business_slug) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), business_slug };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/fetch-user-categories-for-dropdown`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    addUserCategory: async (category_id, business_slug) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), category_id: category_id, business_slug };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/add-category`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    searchUserCategory: async (categoryName) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), category_name: categoryName };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/search-category`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    sendUserCategoryRequest: async (categoryName, business_slug) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), category_name: categoryName, business_slug };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/send-category-request`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    sendRequestDeleteCategory: async (category_id) => {
        const token = Token.getToken();

        var data = { user_id: Token.getUserID(), category_id: category_id };
        return axios({
            method: 'post',
            url: `${config.API_BASE_URL}/api/user/send-request-category-delete`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
    categoryStatus: async (category_id, status) => {
        const token = Token.getToken();

        var data = {
            user_id: Token.getUserID(),
            user_category_id: category_id,
            category_status: status
        };

        return axios({
            method: 'put',
            url: `${config.API_BASE_URL}/api/user/category-status`,
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            data: data
        });
    },
}