import React, { useState } from "react";
import BodyWrapper from "./BodyWrapper";
import Aside from "./prosidebar";
import { IntlProvider } from 'react-intl';
import { FaBars } from 'react-icons/fa';
import { useEffect } from "react";
import { RoleAPI } from "../../../apis/user/RoleAPI";
import { useParams } from 'react-router-dom';

export const DashboardLayout = (props) => {
    const params = useParams()
    const business_slug = params.business_id;

    const [rtl, setRtl] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [image, setImage] = useState(true);
    const [toggled, setToggled] = useState(false);
    const handleToggleSidebar = () => {
        setToggled(!toggled);
    };

    return (

        <BodyWrapper>
            {/* {console.log('DashboardLayout', props.page)} */}
            <IntlProvider>
                <div className='Sidebar-MainContain'>
                    <div className='' style={{ marginTop: '75px' }}>

                            <Aside
                                image={image}
                                collapsed={collapsed}
                                rtl={rtl}
                                toggled={toggled}
                                handleToggleSidebar={handleToggleSidebar}
                                activelink={props.page}
                            />
                            
                        <div className="pt-3">
                            <div className="btn-toggle" onClick={() => handleToggleSidebar()} >
                                <FaBars />
                            </div>
                        </div>
                    </div>

                    {props.children}

                    {/* <div className="w-100 sidebar-right-content-spacing" >
                        {props.children}
                    </div> */}
                </div>
            </IntlProvider>
        </BodyWrapper>
    );
};
