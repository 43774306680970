import { Avatar, LinearProgress } from '@material-ui/core';
import Countdown from "react-countdown";
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import RoomIcon from '@material-ui/icons/Room';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import StarIcon from '@material-ui/icons/Star';
import TimerOutlinedIcon from '@material-ui/icons/TimerOutlined';
import React, { Fragment } from 'react';
import { Accordion, Button, Carousel, Col, Modal, Nav, Navbar, OverlayTrigger, Popover, Row, Spinner, Tab } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import cakeimg from '../../assets/images/shopDetails/cakeimg.svg';
import reviewicon from '../../assets/images/shopDetails/reviewicon.svg';
import smallcircle from '../../assets/images/shopDetails/smallcircle.svg';
import ADDREVIEWMODAL from '../../Components/addReviewModal';
import '../../Components/styles/css/Shopdetails/shopdetails.css';
import Fancybox from '../Fancybox';
// import FoodImg from '../assets/images/shopDetails/Photos/foodImg.svg';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneInTalkOutlinedIcon from '@material-ui/icons/PhoneInTalkOutlined';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import TelegramIcon from '@material-ui/icons/Telegram';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Rating from '@material-ui/lab/Rating';
import GoogleMapReact from 'google-map-react';
import {
    FacebookShareButton,
    LinkedinShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton
} from "react-share";
import { BusinessAPI } from '../../apis/user/BusinessAPI';
import FbIcon from '../../assets/images/shopDetails/socialIcons/fbIcon.svg';
import GoogleIcon from '../../assets/images/shopDetails/socialIcons/googleIcon.svg';
import TitterIcon from '../../assets/images/shopDetails/socialIcons/titter.svg';

import Swal from 'sweetalert2';
import { MessageAPI } from '../../apis/user/MessageAPI';
import AlertTriangle from '../../assets/images/modalPopup/alert-triangle.svg';
import CheckmarkCircle from '../../assets/images/modalPopup/checkmark-circle.svg';
import { getCarts, getFlashSaleDiscount, manageCart, Token } from '../../Token';
import SignIn from '../../Components/auth/SignIn';
var moment = require('moment-timezone');
moment.tz.setDefault("Europe/London");

const renderer = ({ days, hours, minutes }) => {
    // Render a countdown
    return (
        <span>
            {days} Days {hours} Hours
        </span>
    );
};
class Shopdetails extends React.Component {
    constructor(props) {
        super(props)
        this.myRef = React.createRef();
        this.state = {
            business_id: '',
            business_slug: '',
            option1: 'selected',
            option2: 'notselected',
            option3: 'notselected',
            menu: true,
            about: false,
            reviews: false,
            shopdata: "",
            business_name: "",
            progressVisible: "",
            addReviewModal: false,
            openReviewModel: "",
            popover: null,
            ratingreviews: [],
            ratingRatio: 0,
            count: 0,
            shopDayStatus: 'Closed',
            todayActiveTime: false,
            openAt: '9.30am',
            shareUrl: window.location.href,
            shareTitle: 'Title',
            addInWishlist: false,
            show: false,
            productData: [],
            isCartAdded: false,
            totalCheckoutPrice: 0,
            stateCards: getCarts(),
            business_category: '',
            isFlash: false,
            flashCoupon: {},
            flashSaleEndTimeCounter: '',
            sliceNumber: 4,
            shopName: "",
            isEnabledDelivery: false,
            isAvailableForSlectedPostalCode: false,
            businessesAvailability: [],
            selectedPostalCode: "",
            TodaysTimeFinish: false,
            start_date: moment().format('DD-MM-YYYY'),
            date: moment(moment(this?.state?.flashCoupon?.end_date).format('Do MMM YYYY'), "Do MMM YYYY").valueOf()
        }
        // this.getSaleEndDateTimeCounter = this.getSaleEndDateTimeCounter.bind(this);
    }


    componentDidMount = async () => {
        const shopId = this.props.props.match.params.shopname

        const PostalCodes = this.props.Addresses
        let selectedPoslalCode = PostalCodes?.postal_code
        if (selectedPoslalCode) {
            selectedPoslalCode = selectedPoslalCode.split(" ")[0];
        }
        // let selectedPostlaCode = 'EC1A 2BN'
        await BusinessAPI.guestViewBusiness(shopId, selectedPoslalCode)
            .then(res => {
                const weekday = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
                const d = new Date();
                let today = weekday[d.getDay()];
                let DateNum;
                if (d.getDay() >= 6) {
                    DateNum = -6
                }
                else {
                    DateNum = 1
                }
                let nextDay = weekday[d.getDay() + DateNum]
                switch (today) {
                    case 'sunday':
                        var currentTime = moment().format("HH:mm:ss");
                        var fromTime = moment(res.data.business.sunday[1], ["H:mma"]).format("HH:mm:ss");
                        var toTime = moment(res.data.business.sunday[2], ["H:mma"]).format("HH:mm:ss");
                        this.setState({ TodaysTimeFinish: (currentTime > toTime) ? true : false });
                        this.setState({ todayActiveTime: (currentTime > fromTime && currentTime < toTime) ? true : false })
                        this.setState({ shopDayStatus: res.data.business.sunday[0], openAt: res.data.business.sunday[1] })
                        break;
                    case 'monday':
                        var currentTime = moment().format("HH:mm:ss");
                        var fromTime = moment(res.data.business.monday[1], ["H:mma"]).format("HH:mm:ss");
                        var toTime = moment(res.data.business.monday[2], ["H:mma"]).format("HH:mm:ss");
                        this.setState({ TodaysTimeFinish: (currentTime > toTime) ? true : false });

                        this.setState({ todayActiveTime: (currentTime > fromTime && currentTime < toTime) ? true : false })
                        this.setState({ shopDayStatus: res.data.business.monday[0], openAt: res.data.business.monday[1] })
                        break;
                    case 'tuesday':
                        var currentTime = moment().format("HH:mm:ss");
                        var fromTime = moment(res.data.business.tuesday[1], ["H:mma"]).format("HH:mm:ss");
                        var toTime = moment(res.data.business.tuesday[2], ["H:mma"]).format("HH:mm:ss");
                        this.setState({ TodaysTimeFinish: (currentTime > toTime) ? true : false });

                        this.setState({ todayActiveTime: (currentTime > fromTime && currentTime < toTime) ? true : false })
                        this.setState({ shopDayStatus: res.data.business.tuesday[0], openAt: res.data.business.tuesday[1] })
                        break;
                    case 'wednesday':
                        var currentTime = moment().format("HH:mm:ss");
                        var fromTime = moment(res.data.business.wednesday[1], ["H:mma"]).format("HH:mm:ss");
                        var toTime = moment(res.data.business.wednesday[2], ["H:mma"]).format("HH:mm:ss");
                        this.setState({ TodaysTimeFinish: (currentTime > toTime) ? true : false });

                        this.setState({ todayActiveTime: (currentTime > fromTime && currentTime < toTime) ? true : false })
                        this.setState({ shopDayStatus: res.data.business.wednesday[0], openAt: res.data.business.wednesday[1] })
                        break;
                    case 'thursday':
                        var currentTime = moment().format("HH:mm:ss");
                        var fromTime = moment(res.data.business.thursday[1], ["H:mma"]).format("HH:mm:ss");
                        var toTime = moment(res.data.business.thursday[2], ["H:mma"]).format("HH:mm:ss");
                        this.setState({ TodaysTimeFinish: (currentTime > toTime) ? true : false });

                        this.setState({ todayActiveTime: (currentTime > fromTime && currentTime < toTime) ? true : false })
                        this.setState({ shopDayStatus: res.data.business.thursday[0], openAt: res.data.business.thursday[1] })
                        break;
                    case 'friday':
                        var currentTime = moment().format("HH:mm:ss");
                        var fromTime = moment(res.data.business.friday[1], ["H:mma"]).format("HH:mm:ss");
                        var toTime = moment(res.data.business.friday[2], ["H:mma"]).format("HH:mm:ss");
                        this.setState({ TodaysTimeFinish: (currentTime > toTime) ? true : false });

                        this.setState({ todayActiveTime: (currentTime > fromTime && currentTime < toTime) ? true : false })
                        this.setState({ shopDayStatus: res.data.business.friday[0], openAt: res.data.business.friday[1] })
                        break;
                    case 'saturday':
                        var currentTime = moment().format("HH:mm:ss");
                        var fromTime = moment(res.data.business.saturday[1], ["H:mma"]).format("HH:mm:ss");
                        var toTime = moment(res.data.business.saturday[2], ["H:mma"]).format("HH:mm:ss");
                        this.setState({ TodaysTimeFinish: (currentTime > toTime) ? true : false });
                        this.setState({ todayActiveTime: (currentTime > fromTime && currentTime < toTime) ? true : false })
                        this.setState({ shopDayStatus: res.data.business.saturday[0], openAt: res.data.business.saturday[1] })
                        break;
                }

                if (this.state.TodaysTimeFinish) {
                    switch (nextDay) {
                        case 'sunday':
                            this.setState({ openAt: res.data.business.sunday[1] })
                            break;
                        case 'monday':
                            this.setState({ openAt: res.data.business.monday[1] })
                            break;
                        case 'tuesday':
                            this.setState({ openAt: res.data.business.tuesday[1] })
                            break;
                        case 'wednesday':
                            this.setState({ openAt: res.data.business.wednesday[1] })
                            break;
                        case 'thursday':
                            this.setState({ openAt: res.data.business.thursday[1] })
                            break;
                        case 'friday':
                            this.setState({ openAt: res.data.business.friday[1] })
                            break;
                        case 'saturday':
                            this.setState({ openAt: res.data.business.saturday[1] })
                            break;
                    }
                }
                let ratingCount = 0;
                res.data.ratingreviews.map((review) => {
                    ratingCount = parseInt(ratingCount) + parseInt(review.rating);
                })

                if (res.data.isFlash) {
                    this.onSetFlashEndCounter(moment(res.data.flashCoupon.end_date))
                }

                this.setState({
                    isFlash: res.data.isFlash,
                    flashCoupon: res.data.flashCoupon,
                    business_category: res.data.business.category,
                    shopdata: res.data,
                    business_id: res.data.business.id,
                    business_slug: res.data.business.business_slug,
                    progressVisible: 'none',
                    ratingreviews: res.data.ratingreviews,
                    productData: res.data.productData,
                    start_date: res?.data?.flashCoupon?.start_date,
                    ratingRatio: ratingCount / res.data.ratingreviews.length,
                    shopName: shopId,
                    isAvailableForSlectedPostalCode: res.data.isAvailableForSlectedPostalCode,
                    isEnabledDelivery: res.data.business?.disAblePostalCode,
                    popover: (
                        <Popover id="popover-basic">
                            <Popover.Title as="h3" className="bgGreen text-white" >Shop Timing</Popover.Title>
                            <Popover.Content>
                                <strong>Monday - </strong>{res.data.business.monday[0] === 'open' ? `${moment(res.data.business.monday[1], ["hh:mma"]).format('hh:mm A')} to ${moment(res.data.business.monday[2], ["hh:mma"]).format('hh:mm A')}` : 'closed'} <br />
                                <strong>Tuesday - </strong>{res.data.business.tuesday[0] === 'open' ? `${moment(res.data.business.tuesday[1], ["hh:mma"]).format('hh:mm A')} to ${moment(res.data.business.tuesday[2], ["hh:mma"]).format('hh:mm A')}` : 'closed'} <br />
                                <strong>Wednesday - </strong> {res.data.business.wednesday[0] === 'open' ? `${moment(res.data.business.wednesday[1], ["hh:mma"]).format('hh:mm A')} to ${moment(res.data.business.wednesday[2], ["hh:mma"]).format('hh:mm A')}` : 'closed'} <br />
                                <strong>Thursday - </strong> {res.data.business.thursday[0] === 'open' ? `${moment(res.data.business.thursday[1], ["hh:mma"]).format('hh:mm A')} to ${moment(res.data.business.thursday[2], ["hh:mma"]).format('hh:mm A')}` : 'closed'} <br />
                                <strong>Friday - </strong>  {res.data.business.friday[0] === 'open' ? `${moment(res.data.business.friday[1], ["hh:mma"]).format('hh:mm A')} to ${moment(res.data.business.friday[2], ["hh:mma"]).format('hh:mm A')}` : 'closed'}<br />
                                <strong>Saturday - </strong>  {res.data.business.saturday[0] === 'open' ? `${moment(res.data.business.saturday[1], ["hh:mma"]).format('hh:mm A')} to ${moment(res.data.business.saturday[2], ["hh:mma"]).format('hh:mm A')}` : 'closed'}  <br />
                                <strong>Sunday - </strong>  {res.data.business.sunday[0] === 'open' ? `${moment(res.data.business.sunday[1], ["hh:mma"]).format('hh:mm A')} to ${moment(res.data.business.sunday[2], ["H:mma"]).format('hh:mm A')}` : 'closed'}  <br />
                            </Popover.Content>
                        </Popover>
                    )
                })
            }).catch(error => {
                this.setState({ shopdata: null, progressVisible: 'none' })
                console.log(error)
            })

        if (Token.isLoggedIn()) {
            await BusinessAPI.checkBusinesFavorite(this.state.business_id)
                .then(res => {
                    if (res.data.business_added == 'YES') {
                        this.setState({ addInWishlist: true })
                    }
                })
        }

        setTimeout(() => { this.setTotalCheckoutPrice(); }, 200)
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.Addresses !== this.props.Addresses) {
            const PostalCodes = this.props.Addresses
            const shopId = this.props.props.match.params.shopname

            let selectedPostalCode = PostalCodes?.postal_code
            if (selectedPostalCode) {
                selectedPostalCode = selectedPostalCode.split(" ")[0];
            }
            const SectedAddress = async () => {
                await BusinessAPI.guestViewBusiness(shopId, selectedPostalCode)
                    .then(res => {
                        this.setState({
                            isAvailableForSlectedPostalCode: res.data.isAvailableForSlectedPostalCode,
                            isEnabledDelivery: res.data.business?.disAblePostalCode,
                        })
                    })
            }
            SectedAddress()
        }
    }

    handleClose = () => { this.setState({ show: false }); };
    handleShow = () => { this.setState({ show: true }); };

    reviewModalVisibility = (value) => {
        this.setState({ addReviewModal: value })
    }

    handlechange = (option) => {
        if (option === 'menu') {
            this.setState({ option1: 'selected', option2: 'notselected', option3: 'notselected', menu: true, about: false, reviews: false })
        } else if (option === 'about') {
            this.setState({ option1: 'notselected', option2: 'selected', option3: 'notselected', menu: false, about: true, reviews: false, })
        } else if (option === 'reviews') {
            this.setState({ option1: 'notselected', option2: 'notselected', option3: 'selected', menu: false, about: false, reviews: true })
        }
    }

    iframeLoaded = () => {
        var iFrameID = document.getElementById('idIframe');
        document.getElementById("idIframe").contentDocument.body.style.fontFamily = "Gotham Rounded";
        if (iFrameID) {
            // here you can make the height, I delete it first, then I make it again
            iFrameID.height = "";
            iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + 20 + "px";
        }
    }

    handleReviewModal = (numText) => {
        if (Token.isLoggedIn()) {
            this.setState({ addReviewModal: true, openReviewModel: numText })
        } else {
            Swal.fire({
                title: 'Please login to review',
                imageUrl: AlertTriangle,
                imageAlt: 'warning image',
                confirmButtonColor: '#00CA84'
            });
        }
    }

    onClickFavorite = (value) => {
        if (Token.isLoggedIn()) {
            BusinessAPI.addRemoveWihList(this.state.business_id, value)
                .then(res => {
                    if (value == 'add') {
                        this.setState({ addInWishlist: true });
                    } else {
                        this.setState({ addInWishlist: false });
                    }

                    Swal.fire({
                        title: res.data.message,
                        imageUrl: CheckmarkCircle,
                        imageAlt: 'success image',
                        confirmButtonColor: '#00CA84'
                    });

                }).catch(error => {
                    Swal.fire({
                        title: error.response.data.message,
                        imageUrl: AlertTriangle,
                        imageAlt: 'warning image',
                        confirmButtonColor: '#00CA84'
                    });
                })
        } else {
            Swal.fire({
                title: 'Please login to favorite.',
                imageUrl: AlertTriangle,
                imageAlt: 'warning image',
                confirmButtonColor: '#00CA84'
            });
        }
    };

    setTotalCheckoutPrice = () => {
        let total_product_price = 0;
        let checkCart = false
        for (var i = 0; i < Object.keys(getCarts()).length; i++) {
            checkCart = true
            total_product_price = total_product_price + (getCarts()[Object.keys(getCarts())[i]].product_discounted_price * parseInt(getCarts()[Object.keys(getCarts())[i]].quantity));
        }
        this.props.manageCartCount(Object.keys(getCarts()).length)
        const flashSaleDiscount = getFlashSaleDiscount();
        let totalCartPrice = total_product_price
        // if (flashSaleDiscount) {
        //     totalCartPrice = flashSaleDiscount.discount_type == 'Cash' ? (parseFloat(total_product_price) - parseFloat(flashSaleDiscount.discount)).toFixed(2) :
        //         (parseFloat(total_product_price) - (((parseFloat(total_product_price) / 100) * parseFloat(flashSaleDiscount.discount)))).toFixed(2)
        // }

        this.setState({ isCartAdded: checkCart, totalCheckoutPrice: totalCartPrice, stateCards: getCarts() })
    }

    onCreateMessageThred = () => {
        const data = {
            business_id: this.state.business_id
        }

        MessageAPI.createMessageRoom(data)
            .then(res => {
                this.props.props.history.push('/seller-profile/messages-' + this.state.business_slug)
                // window.location.href = '/seller-profile/messages-' + this.state.business_slug
            }).catch(err => {
                // console.log(err, 'errerr')
                Swal.fire({
                    title: 'Please login to message',
                    imageUrl: AlertTriangle,
                    imageAlt: 'warning image',
                    confirmButtonColor: '#00CA84'
                });
            })
    }

    setCount = (counter) => {
        this.setState({ flashSaleEndTimeCounter: counter })
    }

    onSetFlashEndCounter = (date) => {
        var countDownDate = new Date(date).getTime();
        var x = setInterval(() => {
            var now = new Date().getTime();
            var distance = countDownDate - now;
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            let counterVar = `${('0' + days).slice(-2)} Days ${('0' + hours).slice(-2)} Hours`;
            let counterVar2 = `${('0' + hours).slice(-2)} Hours`
            let cournterVar3 = `${('0' + minutes).slice(-2)} Minutes`
            if (days > 0) {
                this.setCount(counterVar)
            }
            if (days == 0) {
                this.setCount(counterVar2)

            }
            if (hours == 0) {
                this.setCount(cournterVar3)
            }
            if (distance < 0) {
                clearInterval(x);
                this.setCount('EXPIRED')
            }
        }, 1000);
    }

    onChangeQuantytInput = (e, product, shopdata) => {
        const qty = e.target.value;
        this.setState({ count: qty })
        manageCart(product, product.product_photos[0], qty, shopdata.business, this.state.flashCoupon)
        setTimeout(() => { this.setTotalCheckoutPrice() }, 200)
    }

    render() {
        if (this.state.shopdata) {
            const { shopdata } = this.state;

            let ImageNumber;
            const AllImageNumber = shopdata?.business?.other_photos.length;
            shopdata?.business?.other_photos.slice(0, this.state.sliceNumber).map((rank, i, row) => {
                if (i + 1 === row.length) {
                    ImageNumber = i
                }
            })
            return (
                // <DashboardLayout>
                <div id="rootDiv">
                    <div className='w-100'>
                        <div className='container'>
                            <div className='mt-5 d-flex justify-content-between'>
                                <h2 class='text-capitalize'>{shopdata.business.business_name}</h2>
                                {shopdata.business.access_users.includes(localStorage.getItem("user_id")) && <Link to={`/seller/business/${this.state.shopName}`}>
                                    <button className="btn btnPara-primary">Go to Admin</button>
                                </Link>}
                            </div>
                            <div class="d-flex flex-wrap my-2">
                                <div class="py-0 my-2 pr-3  border-right d-flex">
                                    {/* <Rate allowHalf defaultValue={2.5} /> */}
                                    <div className="shopDetails-allRating-box ">
                                        <Rating icon={<StarRoundedIcon />} name="half-rating-read" defaultValue={this.state.ratingRatio} precision={0.5} readOnly className="mb-0" />
                                    </div>
                                    <p id="reviews"
                                        className="fw-500 ml-2 text-dark mb-0 align-self-center"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            this.handlechange('reviews')
                                            window.scrollTo({
                                                top: this.myRef.current.offsetTop,
                                                behavior: 'smooth',
                                            }
                                            )
                                        }} >
                                        {this.state.ratingreviews.length} Reviews</p>
                                </div>
                                <div className="py-0 my-2 px-2 align-self-center">
                                    <p className="mb-0">
                                        {
                                            Token.isLoggedIn() ?
                                                <>
                                                    <PhoneInTalkOutlinedIcon />
                                                    {shopdata.business.mobile_number}
                                                </> :
                                                <span role="button"
                                                    onClick={() => {
                                                        Swal.fire({
                                                            title: 'Please login to view contact details.',
                                                            imageUrl: AlertTriangle,
                                                            imageAlt: 'warning image',
                                                            confirmButtonColor: '#00CA84'
                                                        });
                                                        // <SignIn
                                                        //     state={true}
                                                        //     setState={this.setState}
                                                        // />
                                                    }}>Contact Us</span>
                                        }
                                    </p>
                                </div>
                            </div>

                            <div className="d-flex flex-wrap my-2">
                                <div className="py-0 my-2 pr-3 border-right"><p className="mb-0"><RoomIcon /> {shopdata.business.address}</p></div>
                                <div className="py-0 my-2 px-0"><p className="mb-0">{shopdata.business.food_type}</p></div>
                                <div className="py-0 my-2 px-3 border-right d-flex">
                                    <p className="mb-0">
                                        <span className="textOrange fw-500 text-capitalize">
                                            {
                                                this.state.shopDayStatus == 'open' && this.state.todayActiveTime == true ? 'open' : `closed - Opens at ${this.state.openAt}`
                                            }
                                        </span>
                                    </p>
                                    <div id="timing" className='px-1'>
                                        <OverlayTrigger placement="right" overlay={this.state.popover}>
                                            <InfoOutlinedIcon />
                                        </OverlayTrigger>
                                    </div>
                                    <div><img className='content-to-hide' src={smallcircle} alt="smallcircle" /></div>
                                </div>
                                {/* <div className="py-0 my-2 px-3"><p className="mb-0" id="delivery">{shopdata.business.delivery}</p></div> */}
                                {/* <div className="py-0 my-2 px-3"><p className="mb-0" id="delivery">Pick up Only</p></div> */}
                            </div>

                            <div className="d-flex flex-wrap my-2">
                                <div className="py-0 my-2 pr-3 border-right d-flex" >
                                    {
                                        this.state.addInWishlist ?
                                            <FavoriteIcon role="button" style={{ color: 'red' }} onClick={() => {
                                                this.onClickFavorite('remove');
                                            }} /> :
                                            <FavoriteBorderOutlinedIcon role="button" onClick={() => {
                                                this.onClickFavorite('add');
                                            }} />
                                    }
                                    <p className="mb-0 ml-2">Save</p>
                                </div>
                                <div className="py-0 my-2 px-2 border-right d-flex clickable" onClick={this.handleShow}>
                                    <ShareOutlinedIcon />
                                    <p className="mb-0 ml-2">Share</p>
                                    {/* <Button variant="primary" >
                                        Launch demo modal
                                    </Button> */}
                                </div>
                                {/* Start share link media */}
                                <Modal centered show={this.state.show} onHide={this.handleClose}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Go To Social link</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="d-flex flex-wrap Demo__container">
                                            <div className="socialNetwork-shareIcon mr-3">
                                                <FacebookShareButton
                                                    url={this.state.shareUrl}
                                                    quote={this.state.shareTitle}
                                                >
                                                    <FacebookIcon size={32} round />
                                                </FacebookShareButton>
                                            </div>

                                            <div className="socialNetwork-shareIcon mr-3">
                                                <TwitterShareButton
                                                    url={this.state.shareUrl}
                                                    title={this.state.shareTitle}
                                                >
                                                    <TwitterIcon size={32} round />
                                                </TwitterShareButton>
                                            </div>

                                            <div className="socialNetwork-shareIcon mr-3">
                                                <TelegramShareButton
                                                    url={this.state.shareUrl}
                                                    title={this.state.shareTitle}
                                                >
                                                    <TelegramIcon size={32} round />
                                                </TelegramShareButton>
                                            </div>
                                            <div className="socialNetwork-shareIcon mr-3">
                                                <WhatsappShareButton
                                                    url={this.state.shareUrl}
                                                    title={this.state.shareTitle}
                                                >
                                                    <WhatsAppIcon size={32} round />
                                                </WhatsappShareButton>
                                            </div>
                                            <div className="socialNetwork-shareIcon mr-3">
                                                <LinkedinShareButton url={this.state.shareUrl} >
                                                    <LinkedInIcon size={32} round />
                                                </LinkedinShareButton>
                                            </div>

                                        </div>
                                    </Modal.Body>
                                </Modal>
                                {/* End share link media */}
                                <div className="py-0 my-2 px-3 border-right d-flex">
                                    <BorderColorOutlinedIcon />
                                    <p className="mb-0 ml-2" style={{ cursor: 'pointer' }} onClick={() => this.handleReviewModal('first')}>Add a review</p>
                                    {
                                        this.state.addReviewModal && this.state.openReviewModel == 'first' ?
                                            <ADDREVIEWMODAL
                                                reviewModalVisibility={this.reviewModalVisibility}
                                                business={this.state.shopdata.business}
                                            /> :
                                            null
                                    }
                                </div>
                                <div className="py-0 my-2 px-3 d-flex">
                                    <SmsOutlinedIcon />
                                    <p className="mb-0 ml-2" style={{ cursor: 'pointer' }}
                                        onClick={this.onCreateMessageThred}>Message Store</p>
                                </div>
                            </div>

                            {
                                (moment(this?.state?.start_date).format('DD-MM-YYYY') <= moment().format('DD-MM-YYYY') && this?.state?.isFlash == true) &&
                                <div className="d-flex justify-content-between flash-sale-Box">
                                    <div className="py-0 my-2 px-3">
                                        <div className="vertical-line"></div>
                                        <h5 className="mb-1 pl-2 fw-500">Flash Sale</h5>
                                        <p className="mb-0 pl-2">
                                            Get {this.state.flashCoupon.discount_type == 'Percent' ? `${this.state.flashCoupon.discount}%` : `£${this.state.flashCoupon.discount}`} OFF on all Products till {moment(this.state.flashCoupon.end_date).format('Do MMM YYYY')}
                                            {/* Products upto {this.state.flashCoupon.discount_type == 'Percent' ? `${this.state.flashCoupon.discount}%` : `£${this.state.flashCoupon.discount}`}, off! */}
                                        </p>
                                    </div>
                                    <div className="py-0 my-2 px-3 align-self-center">
                                        <div className="d-flex">
                                            <TimerOutlinedIcon className="greenText" />
                                            <h6 className="greenText fw-500 align-self-center mb-0 ml-1 d-flex" style={{ marginTop: "2px" }}>
                                                <p style={{ marginRight: "4px" }}>Ends in </p>
                                                <p >{this.state.flashSaleEndTimeCounter}</p>

                                                {/*
                                                <Countdown
                                                    autoStart={true}
                                                    controlled={false}
                                                    date={moment(moment(this?.state?.flashCoupon?.end_date).format('YYYY-MM-DD HH:mm:ss'), "YYYY-MM-DD HH:mm:ss").valueOf()}
                                                    daysInHours={false}
                                                    precision={0}
                                                    zeroPadDays={3}
                                                    zeroPadTime={2}
                                                    renderer={renderer}
                                                /> */}
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                    <div className="w-100" style={{ backgroundColor: '#E5E5E5' }}>
                        <div className='container mt-4'>
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="card border-0 h-100">
                                        <div className="card-body">
                                            <h5>Details</h5>
                                            {
                                                this.state.business_category == 'Food' ?
                                                    <>
                                                        <div className="">
                                                            <p className="greyText mb-0">Cuisine</p>
                                                            <p>{shopdata.business.cuisine}</p>
                                                        </div>
                                                        <div className="">
                                                            <p className="greyText mb-0">Price range</p>
                                                            <div className="d-flex">
                                                                <p>£{shopdata.business.minimun_order_value}</p>
                                                                <p className="mx-2">-</p>
                                                                <p>£{shopdata.business.maximun_order_value}</p>
                                                            </div>
                                                        </div>
                                                    </> :
                                                    <div className="">
                                                        {/* <p className="greyText mb-0">Tag Line</p> */}
                                                        <p>{shopdata.business.tag_line}</p>
                                                        {/* <p className="greyText mb-0">Short Desciption</p> */}
                                                        <p>{shopdata.business.short_description}</p>
                                                    </div>
                                            }

                                            {/* {shopdata.business.open_hours && <div className="">
                                                <p className="greyText mb-0">Open hours</p>
                                                <p>{shopdata.business.open_hours}</p>
                                            </div>} */}
                                            {
                                                this.state.business_category == 'Food' &&
                                                <div className="">
                                                    <p className="greyText mb-0">Delivery</p>
                                                    <p>{shopdata.business.delivery_everywhere}</p>
                                                </div>
                                            }
                                            <div className="d-flex detailsSocial-linksBox">

                                                {
                                                    shopdata.business.google_profile_link != '' &&
                                                    <a href={shopdata.business.google_profile_link} target="blank" aria-label="google">
                                                        <Avatar><img src={GoogleIcon} alt="" /></Avatar>
                                                    </a>
                                                }
                                                {
                                                    shopdata.business.twitter_profile_link != '' &&
                                                    <a href={shopdata.business.twitter_profile_link} target="blank" aria-label="google">
                                                        <Avatar><img src={TitterIcon} alt="" /></Avatar>
                                                    </a>
                                                }

                                                {
                                                    shopdata.business.facebook_profile_link != '' &&
                                                    <a href={shopdata.business.facebook_profile_link} target="blank" aria-label="google">
                                                        <Avatar><img src={FbIcon} alt="" /></Avatar>
                                                    </a>
                                                }
                                                {
                                                    shopdata.business.instagram_profile_link != '' &&
                                                    <a href={shopdata.business.instagram_profile_link} target="blank" aria-label="google">
                                                        <Avatar><InstagramIcon /></Avatar>
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="card border-0 h-100">
                                        <div className="card-body">
                                            <h5>Location</h5>
                                            <div className="mb-3" style={{ height: '250px' }}>
                                                <GoogleMapReact
                                                    bootstrapURLKeys={'AIzaSyAxlZtPxXxJhmhfwEhSuEo8C9VJubGoQx8'}
                                                    center={{ lat: parseFloat(shopdata.business.latitude), lng: parseFloat(shopdata.business.longitude) }}
                                                    defaultZoom={6}
                                                >
                                                    <div
                                                        lat={parseFloat(shopdata.business.latitude)}
                                                        lng={parseFloat(shopdata.business.longitude)}>
                                                        <LocationOnIcon />
                                                    </div>
                                                </GoogleMapReact>
                                            </div>
                                            <div className="d-flex mt-3">
                                                <div className="py-0 align-self-center"><p className="mb-0 mr-2"><RoomIcon /></p></div>
                                                <div className="py-0"><p className="mb-0">{shopdata.business.address}</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 mb-4">
                                    <div className="card border-0 h-100">
                                        <div className="card-body">
                                            <h5>Photos</h5>
                                            <div className="row no-gutters image_gallery">
                                                <Fancybox options={{ infinite: true }}>
                                                    {shopdata.business.other_photos.slice(0, this.state.sliceNumber).map((image, index) => {
                                                        return (
                                                            <div className="col-lg-6 col-md-6 col-6">
                                                                <div className="m-1">
                                                                    <div data-fancybox="gallery" data-src={image} key={`other_image_${index}`} className='about-imagesBox'>
                                                                        <img className={`${index === ImageNumber ? "bg-img" : ""}`} src={image} alt="about_images" style={{ width: '100%', height: '100%' }} />
                                                                        {AllImageNumber > 4 && <div onClick={() => this.setState({ sliceNumber: this.state.sliceNumber + AllImageNumber })} className={`${index === 3 ? "overlay" : ""}`}>
                                                                            <p className="image_Count">+{AllImageNumber - 4}</p>
                                                                        </div>}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )
                                                    })}
                                                </Fancybox>
                                                {
                                                    shopdata.business.other_photos.length === 0 ? <p className='No_Pic'>No Picture</p> : ""
                                                }

                                            </div>


                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div id='restaruantnavbar' className="mt-0">
                            <div className='container'>
                                <Navbar expand="lg" className='shopDetail-navbar mb-2 position-relative' ref={this.myRef}>
                                    {/* <Navbar.Brand id='selected'>Menu</Navbar.Brand> */}
                                    <Navbar.Brand id={this.state.option1} onClick={() => this.handlechange('menu')}>Menu</Navbar.Brand>
                                    <Navbar.Brand id={this.state.option2} onClick={() => this.handlechange('about')}>About</Navbar.Brand>
                                    <Navbar.Brand id={this.state.option3} onClick={() => this.handlechange('reviews')}>Reviews</Navbar.Brand>

                                    {/* <Navbar.Toggle aria-controls="navbarScroll" className='ml-auto' /> */}

                                </Navbar>
                                {
                                    (!this.state.isAvailableForSlectedPostalCode && this.state.isEnabledDelivery) &&
                                    <Navbar expand="lg" className='shopDetail-navbar mb-2'>
                                        <p className="text-danger my-1 align-self-center">
                                            We cannot deliver to your selected address !
                                        </p>
                                    </Navbar>
                                }
                            </div>

                            <div id='solidline'></div>
                            <div className='container position-relative'>
                                {this.state.about === true ?
                                    <div className="about-shopContentBox">
                                        <h4> {shopdata.business.business_name}</h4>
                                        <h5>{shopdata.business.tag_line}</h5>
                                        <div dangerouslySetInnerHTML={{ __html: shopdata.business.business_description }} />
                                        <div className='aboutTopImage' >
                                            <Fancybox options={{ infinite: true }}>
                                                <Carousel fade>
                                                    {shopdata.business.banner_image.map((image, index) => {
                                                        return (
                                                            <Carousel.Item>
                                                                <div className='d-flex justify-content-center carouselbackgroundcolor p-4'>
                                                                    <img
                                                                        data-fancybox="gallery" data-src={image} key={`other_image_${index}`}
                                                                        src={image}
                                                                        alt=" slide"
                                                                        className='carouselImg w-100'
                                                                    />
                                                                </div>
                                                            </Carousel.Item>
                                                        )
                                                    })}
                                                </Carousel>
                                            </Fancybox>
                                        </div>

                                        {/* <div id="wholereview">
                                            <RichText render={shopdata.business.describe_your_business} htmlSerializer={htmlSerializer} />
                                            {shopdata.business.describe_your_business}
                                            <iframe scrolling='no' frameBorder="0" id="idIframe" onLoad={this.iframeLoaded} style={{ width: '100%' }} srcDoc={shopdata.business.describe_your_business}  ></iframe>
                                        </div> */}

                                    </div>
                                    : null}
                                {
                                    this.state.reviews ?
                                        <div className="reviews-contentBox">
                                            <div id="reviewsheading" className='d-flex justify-content-between'>
                                                <p className=' mt-4'>Reviews</p>
                                                <button className='btn btnPara-primary my-4' onClick={() => this.handleReviewModal('second')}>Add a review</button>
                                            </div><hr />                                            <div className="reviews-content">
                                                {shopdata && shopdata.ratingreviews ? shopdata.ratingreviews.map((data, index) => {

                                                    return (
                                                        <div>
                                                            <div className="media">
                                                                <div>
                                                                    {
                                                                        data.user && data.user.profile_picture ?
                                                                            <Avatar className='reviews-userProfile mr-3 mb-2' alt="Remy Sharp" src={data.user.profile_picture} /> :
                                                                            <img className='mr-3' src={reviewicon} alt="reviewicon" />
                                                                    }
                                                                    <div className="review-ratingBox ml-2">
                                                                        <div className="review-rating d-flex" >
                                                                            <StarIcon />
                                                                            <p className="mb-0">{data.rating}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="media-body">
                                                                    {data.user ? <p className="review-title">{data.user.first_name} {data.user.last_name}</p> : <h5 className="review-title">Henry Li</h5>}
                                                                    <h4 className="review-subtitle mt-0">{data.title}</h4>
                                                                    <p className="review-info">{data.tell_us_more.split(" ").map((word, index) => {
                                                                        if (index < 40) {
                                                                            return word + " "
                                                                        }
                                                                    })}</p>
                                                                    {
                                                                        data.photos.length > 0 &&
                                                                        <Accordion defaultActiveKey="0">
                                                                            <Accordion.Toggle as={Button} variant="" eventKey="1" className="review-read-more">
                                                                                Read more
                                                                            </Accordion.Toggle>
                                                                            <Accordion.Collapse eventKey="1">
                                                                                <div className="py-2">
                                                                                    <p className="review-info">{data.tell_us_more.split(" ").map((word, index) => {
                                                                                        if (index > 40) {
                                                                                            return word + " "
                                                                                        }
                                                                                    })}</p>
                                                                                    <div className='d-flex justify-content-start flex-wrap mb-2'>
                                                                                        {data.photos ? <Fancybox options={{ infinite: false }}>
                                                                                            {data.photos.map((image, index) => {
                                                                                                return (
                                                                                                    <img data-fancybox="" data-src={image} key={`other_image_${index}`} className='img-thumbnail mr-2' src={image} alt="about_images" />
                                                                                                )
                                                                                            })}

                                                                                        </Fancybox> : null}
                                                                                    </div>
                                                                                </div>
                                                                            </Accordion.Collapse>
                                                                        </Accordion>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <hr />
                                                        </div>

                                                    )
                                                })
                                                    : null}

                                                {/* <button className='btn btnPara-primary my-4' onClick={() => this.handleReviewModal('second')}>Add a review</button> */}
                                                {
                                                    this.state.addReviewModal && this.state.openReviewModel == 'second' ?
                                                        <ADDREVIEWMODAL
                                                            reviewModalVisibility={this.reviewModalVisibility}
                                                            business={this.state.shopdata.business}
                                                        /> :
                                                        null
                                                }
                                            </div>
                                        </div>
                                        : null
                                }
                                {this.state.menu ?
                                    <div>

                                        {/* <h3 id="menuheading">Menu</h3> */}
                                        {
                                            this.state.productData.length > 0 &&
                                            <Tab.Container id="#" defaultActiveKey={this.state.productData[0].category_id}>
                                                <Row>
                                                    <Col md={12} lg={4}>
                                                        <div className="category-tab-name">
                                                            <div>
                                                                <Nav variant="pills" className="flex-column">
                                                                    {
                                                                        this.state.productData.map(categoryData => {
                                                                            return (
                                                                                <Nav.Item key={Math.random()}>
                                                                                    <Nav.Link eventKey={categoryData.category_id}>{categoryData.category_name} <span>({categoryData.products.length})</span></Nav.Link>
                                                                                </Nav.Item>
                                                                            )
                                                                        })
                                                                    }
                                                                </Nav>
                                                            </div>
                                                            {/* {
                                                                this.state.business_category == 'Food' &&
                                                                <div className="mt-5">
                                                                    <hr />
                                                                    <div className="custom-control custom-switch">
                                                                        <input type="checkbox" className="custom-control-input" id="customSwitch1" />
                                                                        <label className="custom-control-label" for="customSwitch1">Vegan</label>
                                                                    </div>
                                                                </div>
                                                            } */}
                                                        </div>

                                                    </Col>
                                                    <Col md={12} lg={8}>
                                                        <div className="category-tab-content">
                                                            <Tab.Content>
                                                                {
                                                                    this.state.productData.map(categoryData => {
                                                                        return (
                                                                            <Tab.Pane eventKey={categoryData.category_id} key={Math.random()}>
                                                                                <h4 className="category-heading">{categoryData.category_name} <span>({categoryData.products.length})</span></h4>
                                                                                {
                                                                                    // this.state.business_category == 'Food' ?
                                                                                    <>
                                                                                        {

                                                                                            categoryData.products.map(product => {
                                                                                                return (
                                                                                                    <div key={product.id} className="category-boxes">
                                                                                                        <div className="row no-gutters">
                                                                                                            <div className="col-lg-7 col-md-6 col-sm-8 col-7">
                                                                                                                <div className="d-flex align-items-center h-100">
                                                                                                                    <div className="desktop-product-img">
                                                                                                                        <Link to={`/businesses/product/${product.id}`} style={{ width: "120px" }}>
                                                                                                                            <img className='img-fluid product_select_image' src={product.product_photos.length > 0 ? product.product_photos[0] : cakeimg} alt="" />
                                                                                                                        </Link>

                                                                                                                    </div>
                                                                                                                    <div className="d-flex align-items-center">
                                                                                                                        <div className="pr-2 px-md-3">
                                                                                                                            {/* <div className="bestseller-box">
                                                                                                                                    <StarIcon />
                                                                                                                                    <h6>Bestseller</h6>
                                                                                                                                </div> */}
                                                                                                                            <h5 className="product-name">
                                                                                                                                <Link to={`/businesses/product/${product.id}`} className="text-dark">{product.product_name}</Link>
                                                                                                                                {product.veg_category == "Veg" && <span className="vegLable ml-1" style={{ width: '12px', height: '12px' }}></span>
                                                                                                                                }
                                                                                                                                {product.veg_category == "Vegan" && <span className="veganLabel ml-1" style={{ width: '12px', height: '12px' }}></span>
                                                                                                                                }
                                                                                                                            </h5>
                                                                                                                            <div className="">
                                                                                                                                {
                                                                                                                                    parseFloat(product.price) == parseFloat(product.discounted_price) ?
                                                                                                                                        <p className="mb-0">£{parseFloat(product.price).toFixed(2)}</p> :
                                                                                                                                        <Fragment>
                                                                                                                                            <p className="rough-price mb-0">£{parseFloat(product.price).toFixed(2)}</p>
                                                                                                                                            <p className="mb-0">£{parseFloat(product.discounted_price).toFixed(2)}</p>
                                                                                                                                        </Fragment>
                                                                                                                                }
                                                                                                                                {product?.avg_rating > 0 && <div className="review-ratingBox  my-3">
                                                                                                                                    <div className="review-rating d-flex" >
                                                                                                                                        <StarIcon />
                                                                                                                                        <p className="mb-0">{product.avg_rating}</p>
                                                                                                                                    </div>
                                                                                                                                </div>}

                                                                                                                            </div>
                                                                                                                            {/* <div>
                                                                                                                                Available Quantity: {product.quantity}
                                                                                                                            </div> */}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="col-lg-5 col-md-6 col-sm-4 col-5">
                                                                                                                <div className="d-flex align-items-center justify-content-end h-100">
                                                                                                                    <div className="d-md-flex align-items-center justify-content-end h-100 ">
                                                                                                                        <div className="mobile-product-img">
                                                                                                                            <img src={product.product_photos.length > 0 ? product.product_photos[0] : cakeimg} alt="" />
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            (product.out_of_stock || product.quantity == 0) ?
                                                                                                                                <p className='text-danger'>Out of Stock</p> :
                                                                                                                                <div className="d-flex justify-content-end">
                                                                                                                                    {/* <div className="delivery-time-box">
                                                                                                                                    <p className="delivery-instant"><img src={flash} className="instantTimeIcon" alt="" /> Delivers in 3 days</p>
                                                                                                                                </div> */}
                                                                                                                                    <div className="add-product-btnbox">
                                                                                                                                        <div className="incrementDecrement-box">
                                                                                                                                            <button className="btn-decrement"
                                                                                                                                                onClick={() => {
                                                                                                                                                    let qty = this.state.stateCards[product.id] != undefined ? parseInt(this.state.stateCards[product.id].quantity) - 1 : this.state.count - 1;
                                                                                                                                                    if (qty >= 1) {
                                                                                                                                                        this.setState({ count: qty });
                                                                                                                                                        manageCart(product, product.product_photos[0], qty, shopdata.business, this.state.flashCoupon)
                                                                                                                                                        setTimeout(() => { this.setTotalCheckoutPrice() }, 200)

                                                                                                                                                    }
                                                                                                                                                }}>
                                                                                                                                                <RemoveRoundedIcon />
                                                                                                                                            </button>
                                                                                                                                            <p className="mb-0">
                                                                                                                                                {this.state.stateCards[product.id] != undefined ? this.state.stateCards[product.id].quantity : 0}
                                                                                                                                            </p>
                                                                                                                                            {/* <input
                                                                                                                                                type="number"
                                                                                                                                                value={this.state.stateCards[product.id] != undefined ? this.state.stateCards[product.id].quantity : 0}
                                                                                                                                                onChange={(e) => this.onChangeQuantytInput(e, product, shopdata)}
                                                                                                                                            /> */}
                                                                                                                                            <button className="btn-increment"
                                                                                                                                                onClick={() => {

                                                                                                                                                    let qty = this.state.stateCards[product.id] != undefined ? parseInt(this.state.stateCards[product.id].quantity) + parseInt(1) : 1;
                                                                                                                                                    this.setState({ count: qty })
                                                                                                                                                    manageCart(product, product.product_photos[0], qty, shopdata.business, this.state.flashCoupon)
                                                                                                                                                    setTimeout(() => { this.setTotalCheckoutPrice() }, 200)
                                                                                                                                                }}>
                                                                                                                                                <AddRoundedIcon />
                                                                                                                                            </button>
                                                                                                                                        </div>
                                                                                                                                        {/* <div className="add-product-btnbox">
                                                                                                                                          <button className='btn btn-add-product'
                                                                                                                                                onClick={() => manageCart(product, 1)}
                                                                                                                                                            >Add</button>
                                                                                                                                            </div> */}
                                                                                                                                        {/* <p className="customisable">Customisable</p> */}
                                                                                                                                    </div>
                                                                                                                                </div>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                    // :

                                                                                    // <div className='row no-gutters jus'>
                                                                                    //     {
                                                                                    //         categoryData.products.map(product => {
                                                                                    //             return (
                                                                                    //                 <div key={product.id} className='col-lg-4 col-md-4 col-sm-8 col-4'>
                                                                                    //                     <div className=" m-2 shadow-sm  p-3 mb-5 bg-body rounded">
                                                                                    //                         <div className="desktop-product-img w-auto mr-auto">
                                                                                    //                             <img className='img-fluid' src={product.product_photos.length > 0 ? product.product_photos[0] : cakeimg} alt="" />
                                                                                    //                         </div>
                                                                                    //                         <hr />
                                                                                    //                         <div className='desktop-product-name'>
                                                                                    //                             <h5 className="product-name">
                                                                                    //                                 <Link to={`/businesses/product/${product.id}`} className="text-dark">{product.product_name}</Link>

                                                                                    //                             </h5>
                                                                                    //                             <div className="d-flex ">
                                                                                    //                                 {
                                                                                    //                                     parseFloat(product.price) == parseFloat(product.discounted_price) ?
                                                                                    //                                         <p className="mb-0">£{parseFloat(product.price).toFixed(2)}</p> :
                                                                                    //                                         <Fragment>
                                                                                    //                                             <p className="rough-price mb-0">£{parseFloat(product.price).toFixed(2)}</p>
                                                                                    //                                             <p className="mb-0">£{parseFloat(product.discounted_price).toFixed(2)}</p>
                                                                                    //                                         </Fragment>
                                                                                    //                                 }
                                                                                    //                             </div>
                                                                                    //                             <div className="incrementDecrement-box">
                                                                                    //                                 <button className="btn-decrement"
                                                                                    //                                     onClick={() => {
                                                                                    //                                         let qty = this.state.stateCards[product.id] != undefined ? parseInt(this.state.stateCards[product.id].quantity) - 1 : this.state.count - 1;
                                                                                    //                                         if (qty >= 0) {
                                                                                    //                                             this.setState({ count: qty });
                                                                                    //                                             manageCart(product, product.product_photos[0], qty, shopdata.business, this.state.flashCoupon)
                                                                                    //                                             setTimeout(() => { this.setTotalCheckoutPrice() }, 200)
                                                                                    //                                         }
                                                                                    //                                     }}>
                                                                                    //                                     <RemoveRoundedIcon />
                                                                                    //                                 </button>
                                                                                    //                                 <p className="mb-0">
                                                                                    //                                     {this.state.stateCards[product.id] != undefined ? this.state.stateCards[product.id].quantity : 0}
                                                                                    //                                 </p>
                                                                                    //                                 <button className="btn-increment"
                                                                                    //                                     onClick={() => {
                                                                                    //                                         let qty = this.state.stateCards[product.id] != undefined ? parseInt(this.state.stateCards[product.id].quantity) + parseInt(1) : 1;
                                                                                    //                                         this.setState({ count: qty })
                                                                                    //                                         manageCart(product, product.product_photos[0], qty, shopdata.business, this.state.flashCoupon)
                                                                                    //                                         setTimeout(() => { this.setTotalCheckoutPrice() }, 200)
                                                                                    //                                     }}>
                                                                                    //                                     <AddRoundedIcon />
                                                                                    //                                 </button>
                                                                                    //                             </div>
                                                                                    //                         </div>
                                                                                    //                     </div>
                                                                                    //                 </div>
                                                                                    //             )
                                                                                    //         })
                                                                                    //     }
                                                                                    // </div>
                                                                                }

                                                                            </Tab.Pane>
                                                                        )
                                                                    })
                                                                }
                                                            </Tab.Content>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                {
                                                    this.state.isCartAdded &&
                                                    <Row>
                                                        <Col>
                                                            <div className="position-relative proceed-to-checkoutBoxNew">
                                                                <div className="card proceed-to-checkoutBox">
                                                                    <div className="card-body">
                                                                        <div className="d-flex justify-content-between">


                                                                            <Link
                                                                                to={'/my-cart'}
                                                                                // color="inherit"
                                                                                className="proceed-to-checkoutLink align-self-center  "
                                                                            >
                                                                                Proceed To Checkout <ArrowForwardRoundedIcon />
                                                                                <div>
                                                                                    <p className='mb-0 total-heading'>Total</p>
                                                                                    <p className='mb-0 total-price'>£{parseFloat(this.state.totalCheckoutPrice).toFixed(2)}</p>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                }
                                            </Tab.Container>
                                        }
                                    </div>

                                    : null}

                            </div>
                        </div>

                    </div>
                </div >
                // </DashboardLayout>

            )
        }
        else if (this.state.shopdata === null) {
            return (
                <div id="rootDiv">
                    <div className='d-flex justify-content-center' style={{ width: '100%' }}>
                        <div className='display-1 text-center'>Shop not found</div>
                    </div>
                </div>
            )
        }
        else {
            return <div id="rootDiv">
                <div style={{ height: '100vh', width: '100vw' }}>
                    <LinearProgress style={{ width: '100%', height: '7px', display: this.state.progressVisible }} />
                    <div className='d-flex align-items-center justify-content-center spinnerBackground' style={{ height: "100vh", width: "100%" }}><Spinner animation="border" variant="success" /></div>

                </div>
                {/* <div className='d-flex align-items-center justify-content-center spinnerBackground' style={{height:"60vh",width:"100%"}}><Spinner  animation="border" variant="success" /></div> */}
            </div>
        }

    }
}


export default Shopdetails
