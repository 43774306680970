import '../../../../src/assets/css/AdminPanel/adminPanel.css';
import React, { useState } from 'react'
import { DashboardLayout } from '../Layout/Layout'
import { Redirect } from 'react-router-dom'
import SampleProducts from '../../../assets/images/products/sample-image.svg';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Modal from 'react-bootstrap/Modal'
import { Input } from 'antd';
import Badge from 'react-bootstrap/Badge'


const Tickets = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { TextArea } = Input;

    return (
        <DashboardLayout page='users'>
            <div className="w-100 sidebar-right-content-spacing">
                <div className="sidebar-content">
                    <h3 className="mb-3">Tickets</h3>
                    <p>64 new tickets</p>
                    <div className='border rounded-8'>
                        <div className='row'>
                            <div className="col-6 col-sm-7 align-self-center" style={{ borderRight: '1px solid #dee2e6' }}>
                                <div className='p-2'>
                                    <div className='d-flex w-100 h-100' >
                                        <SearchIcon className="greyText mt-1 mr-3" />
                                        <InputBase className='w-100' placeholder="Search Applications" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 col-sm-5 align-self-center">
                                <div className=''>
                                    <button className='btn btn-lg btn-block ft-size16'>
                                        Create a ticket
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="paraTabs">
                        <div className="table-responsive paraRounded-table mt-4">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" className="fw-500">#</th>
                                        <th scope="col" className="fw-500">USER</th>
                                        <th scope="col" className="fw-500">STATUS</th>
                                        <th scope="col" className="fw-500"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="align-middle"><p className="mb-0">8724Y735</p></td>
                                        <td className="align-middle">
                                            <div className="d-flex">
                                                <img src={SampleProducts} alt="" className="mr-2" width="32" height="32" />
                                                <p className="mb-0 pl-1 align-self-center ">Hardwell</p>
                                            </div>
                                        </td>
                                        <td className="align-middle">
                                            <div className='d-flex'>
                                                <h6 className="badgeRed mb-0">New</h6>
                                            </div>
                                        </td>
                                        <td className="align-middle text-right">
                                            <button className='btn btn-lg  ft-size16' onClick={handleShow}>
                                                View ticket
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle"><p className="mb-0">8724Y733</p></td>
                                        <td className="align-middle">
                                            <div className="d-flex">
                                                <img src={SampleProducts} alt="" className="mr-2" width="32" height="32" />
                                                <p className="mb-0 pl-1 align-self-center ">Name of business</p>
                                            </div>
                                        </td>
                                        <td className="align-middle">
                                            <select className="form-selectOption option-redText">
                                                <option className="option-redText" value="0" >Unresolved</option>
                                                <option className="option-greenText" value="1">Closed</option>
                                            </select>
                                        </td>
                                        <td className="align-middle text-right">
                                            <button className='btn btn-lg ft-size16' onClick={handleShow}>
                                                View ticket
                                            </button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-middle"><p className="mb-0">8724Y733</p></td>
                                        <td className="align-middle">
                                            <div className="d-flex">
                                                <img src={SampleProducts} alt="" className="mr-2" width="32" height="32" />
                                                <p className="mb-0 pl-1 align-self-center ">Name of business</p>
                                            </div>
                                        </td>
                                        <td className="align-middle">
                                            <select className="form-selectOption option-greenText">
                                                <option className="option-greenText" value="0">Closed</option>
                                                <option className="option-redText" value="1" >Unresolved</option>
                                            </select>
                                        </td>
                                        <td className="align-middle text-right">
                                            <button className='btn btn-lg ft-size16' onClick={handleShow}>
                                                View ticket
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* ===== Modal: New promotion ========= */}

                    <Modal centered size="lg" show={show} onHide={handleClose}>
                        <Modal.Header className='d-flex justify-content-center'>
                            <Modal.Title>Ticket #8724Y73</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <h6 className="ft-size14">FROM</h6>
                                <div className="d-flex">
                                    <img src={SampleProducts} alt="" className="mr-2" width="32" height="32" />
                                    <p className="mb-0 pl-1 align-self-center ft-size14">Neveah Simmons</p>
                                </div>
                                <div className="mt-3">
                                    <h6 className="ft-size14">REGISTERED EMAIL ID</h6>
                                    <p className="ft-size14">neveah.simmons@gmail.com</p>
                                </div>
                                <div>
                                    <h6 className="ft-size14">MESSAGE</h6>
                                    <p className="ft-size14">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ullamcorper erat phasellus fermentum semper egestas in justo. Sagittis malesuada integer blandit commodo eu eu montes, aenean. Id et id lectus tincidunt laoreet nulla vulputate condimentum augue. Pellentesque blandit ut sed viverra ultrices non et id.</p>
                                </div>
                                <div>
                                    <TextArea className='form-control' rows={4} placeholder="Enter Comment" />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button className='btn btnPara-primary' onClick={handleClose}>
                                Send
                            </button>
                        </Modal.Footer>
                    </Modal>
                    {/* ============== */}

                </div>
            </div>
        </DashboardLayout>
    )

}

export default Tickets
